import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

export type ResponseType = {}

type AnalysisUpdateData = {
  plantId: string
  externalId: string
  stoppageStart: string
  stoppageEnd: string
  analysisId: number
}

export const useLinkStoppage = () => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (analysisData: AnalysisUpdateData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisData.analysisId}/link/stoppage`

      return (
        await axiosInstance.post<ResponseType>(
          path,
          {
            plantId: analysisData.plantId,
            externalId: analysisData.externalId,
            stoppageStart: analysisData.stoppageStart,
            stoppageEnd: analysisData.stoppageEnd
          },
          {}
        )
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['stoppages']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-list']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
