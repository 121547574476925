import Autorenew from '@mui/icons-material/Autorenew'
import Check from '@mui/icons-material/Check'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import Settings from '@mui/icons-material/Settings'
import {Fade, Menu, MenuItem, Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useNavigate} from 'react-router-dom'

import {FinishAnalysisModal} from '../../components/analysisSettings/FinishAnalysisModal'
import {AnalysisListInfo} from '../../types/analysis.types'
import {ChangeAnalysisOwnerDialog} from '../assignees/ChangeAnalysisOwner'

import {AnalysisDelete} from './AnalysisDelete'
import {ReopenAnalysisModal} from './ReopenAnalysisModal'

export const ItemActions: React.FC<{
  analysis: AnalysisListInfo
}> = ({analysis}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [changeOwnerDialogOpened, setChangeOwnerDialogOpened] = useState(false)
  const [finishAnalysisOpen, finishAnalysisSetOpen] = React.useState<boolean>(false)
  const [reopenAnalysisOpen, reopenAnalysisSetOpen] = React.useState<boolean>(false)
  const [selectedAnalysisId, setSelectedAnalysisId] = React.useState<number | undefined>(undefined)
  const analysisRoute = `../graphDetail/${analysis.id}/graph`
  const analysisSettingsRoute = `${analysisRoute}?openSettings=1`
  const analysisPrintRoute = `../graphDetail/${analysis.id}/summary?openPrint=1`

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (event) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  return (
    <>
      <>
        <Button
          startIcon={<MoreVertIcon />}
          id={`fade-button-${analysis.id}`}
          aria-controls="fade-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="text"
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }
          }}
        />
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {analysis.permissions.canFinish && (
            <MenuItem
              onClick={(e) => {
                setSelectedAnalysisId(analysis.id)
                finishAnalysisSetOpen(true)
                handleClose(e)
              }}
              sx={{height: 40}}
            >
              <Check color="success" sx={{marginRight: 1}} />
              {t('analysis.list.actions.finish')}
            </MenuItem>
          )}
          {analysis.permissions.canReopen && (
            <MenuItem
              onClick={(e) => {
                setSelectedAnalysisId(analysis.id)
                reopenAnalysisSetOpen(true)
                handleClose(e)
              }}
              sx={{height: 40}}
            >
              <Autorenew sx={{marginRight: 1}} />
              {t('analysis.list.actions.reopen')}
            </MenuItem>
          )}
          {analysis.permissions.canEdit && (
            <MenuItem onClick={() => navigate(analysisSettingsRoute)} sx={{height: 40}}>
              <Settings sx={{marginRight: 1}} />
              {t('analysis.list.actions.openSettings')}
            </MenuItem>
          )}
          {analysis.permissions.canDelete && (
            <MenuItem
              onClick={(e) => {
                setSelectedAnalysisId(analysis.id)
                setDeleteDialogOpened(true)
                handleClose(e)
              }}
              sx={{height: 40}}
            >
              <DeleteOutline color="error" sx={{marginRight: 1}} />
              {t('buttons.delete')}
            </MenuItem>
          )}
          {analysis.permissions.canSetOwner && (
            <MenuItem
              onClick={(e) => {
                setSelectedAnalysisId(analysis.id)
                setChangeOwnerDialogOpened(true)
                handleClose(e)
              }}
              sx={{height: 40}}
            >
              <ManageAccountsOutlinedIcon sx={{marginRight: 1}} />
              {t('buttons.changeOwner')}
            </MenuItem>
          )}
          <MenuItem
            component={Link}
            onClick={handleClose}
            to={analysisRoute}
            target="_blank"
            sx={{height: 40}}
          >
            <OpenInNewIcon sx={{marginRight: 1}} />
            {t('analysis.list.actions.newTab')}
          </MenuItem>
          <MenuItem onClick={() => navigate(analysisPrintRoute)} sx={{height: 40}}>
            <PrintOutlinedIcon sx={{marginRight: 1}} />
            {t('analysis.list.actions.openPrint')}
          </MenuItem>
        </Menu>
      </>
      {analysis.permissions.canFinish && selectedAnalysisId && (
        <FinishAnalysisModal
          analysisId={selectedAnalysisId.toString()}
          open={finishAnalysisOpen}
          setOpen={() => {
            setSelectedAnalysisId(undefined)
            finishAnalysisSetOpen(false)
          }}
        />
      )}
      {analysis.permissions.canReopen && selectedAnalysisId && (
        <ReopenAnalysisModal
          analysisId={selectedAnalysisId}
          opened={reopenAnalysisOpen}
          closeModal={() => {
            setSelectedAnalysisId(undefined)
            reopenAnalysisSetOpen(false)
          }}
        />
      )}
      {analysis.permissions.canDelete && selectedAnalysisId && (
        <AnalysisDelete
          analysisId={selectedAnalysisId}
          closeModal={() => {
            setSelectedAnalysisId(undefined)
            setDeleteDialogOpened(false)
          }}
          opened={deleteDialogOpened}
        />
      )}
      {analysis.permissions.canSetOwner && selectedAnalysisId && (
        <ChangeAnalysisOwnerDialog
          currentOwner={analysis.owner}
          analysisId={selectedAnalysisId}
          onClose={() => {
            setSelectedAnalysisId(undefined)
            setChangeOwnerDialogOpened(false)
          }}
          opened={changeOwnerDialogOpened}
          canSetOwner={true}
        />
      )}
    </>
  )
}
