import CompressIcon from '@mui/icons-material/Compress'
import ExpandIcon from '@mui/icons-material/Expand'
import {styled, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ChartSettingsProps {
  scaleToShowThreshold: boolean
  setScaleToShowThreshold: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledToggleButton = styled(ToggleButton)({
  maxWidth: '204px',
  minWidth: 'fit-content',
  // width: '20vw',
  borderRadius: '6px !important',
  border: 'transparent !important',
  color: '#00274D !important',
  padding: '12px !important',
  '&.Mui-selected': {
    backgroundColor: '#016AD4 !important',
    color: '#FFFFFF !important'
  },
  '&.MuiToggleButtonGroup-grouped:not(.Mui-selected):hover': {
    background: 'transparent !important'
  },
  background: 'transparent !important'
})

export const ChartSettings = ({
  scaleToShowThreshold,
  setScaleToShowThreshold
}: ChartSettingsProps) => {
  const {t} = useTranslation()

  const handleToggleThreshold = () => {
    setScaleToShowThreshold((prev) => !prev)
  }

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={scaleToShowThreshold}
        onChange={handleToggleThreshold}
        sx={{
          display: 'flex',
          border: '1px solid rgba(0, 39, 77, 0.15)',
          height: '48px'
        }}
      >
        <StyledToggleButton value={true} aria-label={t('chartSettings.enable')}>
          <ExpandIcon sx={{marginRight: 1}} />
        </StyledToggleButton>
        <StyledToggleButton value={false} aria-label={t('chartSettings.disable')}>
          <CompressIcon sx={{marginRight: 1}} />
        </StyledToggleButton>
      </ToggleButtonGroup>
    </>
  )
}
