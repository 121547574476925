import {AssetPartSignal, SignalsAssetPartMap} from '@hconnect/common/components/kmsStatus/types'
import {useMemo} from 'react'

export const useConnectedSignals = ({
  selectedSignals,
  kilnPartId
}: {
  selectedSignals: SignalsAssetPartMap
  kilnPartId: string
}) => {
  return useMemo(
    () => getConnectedSignals(selectedSignals, kilnPartId),
    [kilnPartId, selectedSignals]
  )
}

export const getConnectedSignals = (selectedSignals: SignalsAssetPartMap, startsWith: string) => {
  return Object.keys(selectedSignals)
    .filter((key) => key.startsWith(startsWith))
    .flatMap((key) => selectedSignals[key].map((signal: AssetPartSignal) => signal.id))
    .filter(Boolean)
}
