import {Box, Typography} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Statistics, SummaryStoppage} from '../../types/analysisPdfSummary.types'

export const SummaryStatistics: React.FC<{
  stats: Statistics
  stoppage?: SummaryStoppage
  info: {
    plant: string
    country: string
    category?: string
    equipmentName?: string
  }
}> = ({stats, stoppage, info}) => {
  const {t} = useTranslation(undefined, {keyPrefix: 'analysis.pdfSummary.statistics'})

  const renderConfig = {
    totalRootCauses: {
      tKey: 'totalRootCauses',
      count: stats.totalRootCauses ?? 0
    },
    totalLikelyCauses: {
      tKey: 'totalLikelyCauses',
      count: stats.totalLikelyCauses ?? 0
    },
    totalTasks: {
      tKey: 'totalTasks',
      count: stats.totalTasks ?? 0
    },
    totalAttachments: {
      tKey: 'totalAttachments',
      count: stats.totalAttachments ?? 0
    }
  }

  return (
    <Box sx={{width: '100%'}} display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography component="span" variant="h2">
          {t('sectionLabel')}
        </Typography>
        <Typography component="span" variant="body1">{`${info.plant}, ${info.country}`}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} gap={2}>
        {Object.keys(renderConfig).map((key) => (
          <Box
            key={key}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            textOverflow={'clip'}
            sx={{
              backgroundColor: 'white',
              border: '1.5px solid #016AD4',
              borderRadius: '8px',
              width: '125px',
              height: '125px'
            }}
          >
            <Typography
              variant="h4"
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: 400,
                fontSize: '28px'
              })}
            >
              {renderConfig[key].count}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: 600
              })}
            >
              {t(renderConfig[key].tKey)}
            </Typography>
          </Box>
        ))}
      </Box>

      {stoppage && (
        <Box display="flex" flexDirection={'column'} gap={1} mb={2}>
          <Typography variant="h4">Stoppage</Typography>
          <Typography variant="body1">Category: {info.category}</Typography>
          <Typography variant="body1">
            Code: {stoppage.stoppageCode.code} {stoppage.stoppageCode.text}
          </Typography>
          <Typography variant="body1">
            Equipment: {stoppage.equipment?.text ?? info.equipmentName}
          </Typography>
          <Typography variant="body1">Asset Type: {stoppage.stoppageCode.type}</Typography>
          <Typography variant="body1">Description: {stoppage.description ?? ''}</Typography>

          <Typography variant="body1">
            Stoppage start: {stoppage.start ? moment(stoppage.start).format('lll') : ''}
          </Typography>
          <Typography variant="body1">
            Stoppage end: {stoppage.end ? moment(stoppage.end).format('lll') : ''}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
