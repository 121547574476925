import Grid from '@mui/material/Unstable_Grid2'
import React, {useCallback, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import {CardWrapper} from '../../shared/components/styled'
import {SensorList} from '../../shared/containers/SensorList'
import {useKiln} from '../context/KilnContext'
import {setSearchAfterSvgClick} from '../helpers/KilnModel.helpers'

import {KilnModel} from './KilnModel'

export const ModelWrapper = () => {
  const [hoveredArea, setHoveredArea] = useState<string | undefined>()
  const [, setSearchParams] = useSearchParams()
  const {kilnId} = useKiln()

  const handleSvgClickCb = useCallback(
    (localNames: string[]) => {
      // TODO: this is too slow
      setSearchParams((params) => setSearchAfterSvgClick(params, localNames))
    },
    [setSearchParams]
  )

  const handleKilnMetricClick = useCallback(
    (localNames: string[]) => {
      setSearchParams((params) => {
        params.set('selected', localNames.join(','))
        params.set('filtered', localNames.join(','))
        return params
      })
    },
    [setSearchParams]
  )

  return (
    <React.Fragment>
      <Grid xs={12}>
        <CardWrapper
          display="flex"
          px={3}
          py={3}
          flexDirection="column"
          gap={2}
          data-test-id="kiln-model-card"
        >
          <KilnModel
            hoveredArea={hoveredArea}
            svgClickCb={handleSvgClickCb}
            onKilnMetricClick={handleKilnMetricClick}
          />
        </CardWrapper>
      </Grid>

      <Grid xs={12} md={12} lg={5} xl={4}>
        <SensorList setHoveredArea={setHoveredArea} kilnId={kilnId} />
      </Grid>
    </React.Fragment>
  )
}
