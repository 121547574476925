import {Box} from '@mui/material'
import type {ChartData, ChartOptions} from 'chart.js'
import {Chart} from 'chart.js/auto'
import 'chartjs-adapter-moment'
import {useRef, useEffect, MutableRefObject} from 'react'

type BaseChartProps = {
  data: ChartData
  options: ChartOptions
  extChartRef?: MutableRefObject<Chart | null>
  plugins?: any[]
}

// spacing between legend and chart, remove once available in config
const plugin = {
  beforeInit(chart) {
    const originalFit = chart.legend.fit
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)()
      this.height += 15
    }
  }
}

export const BaseChart = ({data, options, plugins, extChartRef}: BaseChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const intRef = useRef<Chart | null>(null)
  const chartRef = extChartRef ? extChartRef : intRef

  const renderChart = () => {
    if (!canvasRef.current) return

    chartRef.current = new Chart(canvasRef.current, {
      type: 'line',
      options,
      plugins: [plugin, ...(plugins ?? [])],
      data
    })
  }

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy()
      chartRef.current = null
    }
  }

  useEffect(() => {
    if (chartRef.current && options) {
      chartRef.current.options = options
      chartRef.current.update()
    }
  }, [options, chartRef])

  useEffect(() => {
    if (chartRef.current && data) {
      // update datasets individually to prevent resetting filters and triggering redraw on new data fetch
      if (data.datasets.length === chartRef.current.data.datasets.length) {
        chartRef.current.data.datasets.forEach(
          (dataset) =>
            (dataset.data = data.datasets.find((d) => d.label === dataset.label)?.data ?? [])
        )
      } else {
        // if the number of datasets has changed, we have to replace them at once
        chartRef.current.data.datasets = data.datasets
      }
      chartRef.current.data.labels = data.labels
      chartRef.current.update()
    }
  }, [data, chartRef])

  useEffect(() => {
    renderChart()

    return () => destroyChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{height: '400px', position: 'relative'}}>
      <canvas ref={canvasRef} />
    </Box>
  )
}
