import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {QueryOptions2Type} from '../../../shared/types/common.types'
import {AnalysisFull} from '../../types/analysis.types'

export const QueryKey = 'analysis-detail'

export const useGetAnalysisDetail = (
  analysisId: string,
  options?: QueryOptions2Type<AnalysisFull, AxiosError>,
  keyPrefix?: string
) => {
  const axiosInstance = useAxios()

  return useQuery<AnalysisFull, AxiosError>({
    queryKey: [QueryKey, analysisId, keyPrefix],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}`
      return (await axiosInstance.get<AnalysisFull>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
