import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {Box, Button, CircularProgress, TextField, Typography} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {nodeDescriptionValidation, nodeTitleValidation, validateAll} from '../../common/validator'
import {NodeDescriptionBox} from '../../components/node/NodeDescriptionBox'
import TaskStatusSwitch from '../../components/tasks/TaskStatusSwitch'
import {useEditTask} from '../../hooks/tasks/useEditTask'
import {File, Task, TaskStatuses} from '../../types/nodeDetail.types'
import {RcfaUserSearch} from '../assignees/RcfaUserSearch'
import {NodeAttachments} from '../node/nodeForm/NodeAttachments'

export const EditTask: React.FC<{
  nodeId: number
  analysisId?: string
  task: Task
  closeEdit: () => void
}> = ({nodeId, analysisId, closeEdit, task}) => {
  const {t} = useTranslation()
  const {data, mutate, isPending: isLoading} = useEditTask(analysisId, nodeId, task.id)
  const [item, setItem] = useState<Task>(task)
  const [validationError, setValidationError] = useState(new Map())
  const [files, setFiles] = useState<Array<File>>([])

  const validatorConfig = new Map([
    ['description', nodeDescriptionValidation],
    ['title', nodeTitleValidation]
  ])

  const taskDone = item.status === TaskStatuses.Done

  const getErrorText = (key: string) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const doSubmit = () => {
    const validationError = validateAll(validatorConfig, item)
    setValidationError(validationError)
    if (validationError.size === 0) {
      mutate({
        title: item.title,
        description: item.description,
        dueDate: item.dueDate,
        status: item.status,
        newAttachments: files,
        assignee:
          item.assignee && item.assignee.id
            ? item.assignee.id
            : (item.assignee as unknown as string),
        deleteAssignee: item.assignee === undefined,
        ...(taskDone && {
          resolutionDate: moment().toISOString(),
          resolutionDescription: item.resolutionDescription
        }),
        ...(!taskDone && {
          deleteResolutionDate: true,
          deleteResolutionDescription: true
        })
      })
    }
  }

  const setAssignee = (assignee) => {
    setItem((item) => ({...item, assignee: assignee}))
  }

  useEffect(() => {
    if (data !== undefined) closeEdit()
  }, [data, closeEdit])

  return (
    <Box>
      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box mb={0} style={{display: 'flex', flexDirection: 'column'}}>
            <TextField
              variant="filled"
              data-test-id="node-form-title"
              error={validationError.has('title')}
              fullWidth={true}
              helperText={getErrorText('title')}
              label={t('label.title')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setItem({...item, title: event.target.value})
              }
              defaultValue={item.title}
              required
            />
            <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
              {item.title.length}/{80}
            </Box>
          </Box>
          <Box mb={2}>
            <Typography mt={0} mb={1}>
              {t('tables.tasksList.status')}
            </Typography>
            <TaskStatusSwitch
              value={item.status ?? TaskStatuses.Pending}
              onChange={(newStatus) => setItem({...item, status: newStatus})}
            />
          </Box>
          <Box mb={2}>
            <RcfaUserSearch
              assignee={item.assignee ?? null}
              onChange={setAssignee}
              label={t('tables.tasksList.assignee')}
              legacyDesign
              textfieldVariant="filled"
            />
          </Box>
          <Box mb={3}>
            {taskDone && (
              <TextField
                variant="filled"
                id="resolutionDate"
                label={t('tables.tasksList.resolutionDate')}
                type="date"
                fullWidth={true}
                value={moment().format('YYYY-MM-DD')}
                InputLabelProps={{
                  shrink: true
                }}
                disabled
              />
            )}

            {!taskDone && (
              <TextField
                variant="filled"
                error={moment(item.dueDate).isBefore(moment().startOf('day'))}
                helperText={
                  moment(item.dueDate).isBefore(moment().startOf('day')) &&
                  t('task.dueDateIsInThePast')
                }
                id="date"
                label={t('tables.tasksList.dueDate')}
                type="date"
                fullWidth={true}
                defaultValue={item.dueDate ? moment(item.dueDate).format('YYYY-MM-DD') : null}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setItem({
                    ...item,
                    dueDate: e.target.value ? moment(e.target.value).toISOString() : e.target.value
                  })
                }}
              />
            )}
          </Box>
          <Box mb={4} style={{display: 'flex', flexDirection: 'column'}}>
            {taskDone && (
              <NodeDescriptionBox
                background={true}
                defaultValue={item.resolutionDescription ?? ''}
                onChange={(next) => setItem({...item, resolutionDescription: next})}
                label={t('task.resolutionDescription')}
              />
            )}
            {!taskDone && (
              <NodeDescriptionBox
                background={true}
                defaultValue={item.description}
                errorCode={validationError.get('description')}
                onChange={(next) => setItem({...item, description: next})}
                required
              />
            )}

            <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
              {item.description.length}/{5000}
            </Box>

            <NodeAttachments files={files} setFiles={setFiles} />
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'right'}}>
            <Button
              startIcon={<Close />}
              color="secondary"
              variant="outlined"
              onClick={() => closeEdit()}
              data-test-id="node-form-cancel-button"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={doSubmit}
              data-test-id="node-form-save-button"
              sx={{marginLeft: 1}}
            >
              {t('buttons.save')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
