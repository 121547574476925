import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {useKmsTours} from '../../plantMonitoring/kms/guidedTours/kmsTours'
import {useRcfaTours} from '../../rcfa/guidedTours/rcfaTours'

export const MaintainToursProvider = ({children}: {children?: React.ReactNode}) => {
  const {rcfaTours, enabledRcfaTours} = useRcfaTours()
  const {kmsTours, enabledKmsTours} = useKmsTours()

  return (
    <GuidedToursProvider
      tours={{...rcfaTours, ...kmsTours}}
      enabledTours={{...enabledRcfaTours, ...enabledKmsTours}}
    >
      {children}
    </GuidedToursProvider>
  )
}
