import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {CustomStoppageItem} from '../../types/stoppage.types'

export type ResponseType = {
  currentPage: number
  data: Array<CustomStoppageItem>
  totalCount: number
}

export const QueryKey = 'stoppage-search'

export const useSearchStoppages = (
  searchText: string,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, searchText],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/stoppagecodes?searchText=${searchText}`
      return (await axiosInstance.get<ResponseType>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
