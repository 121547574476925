import {Box, CircularProgress, LinearProgress} from '@mui/material'
import {Chart} from 'chart.js/auto'
import moment, {Moment} from 'moment'
import {MutableRefObject, useMemo} from 'react'

import {BaseChart} from '../../../../shared/components/graphs/BaseChart'
import {zoomLineChartOptions} from '../../../../shared/components/graphs/config/zoomLineChart.config'
import {rangeSelectPlugin, thresholdAreaPlugin} from '../../../../shared/components/graphs/plugins'
import {ChartError} from '../../components/ChartError'
import {useGetSignalPlotData} from '../../hooks/useGetSignalPlotData'

import {getSingleSignalData} from './chartHelpers'

interface SingleSensorChartProps {
  sensorId: string
  chartRef?: MutableRefObject<Chart | null>
  range?: {
    timeFrom: Moment
    timeTo: Moment
  }
  rangeSelectCallback: ({timeFrom, timeTo}: {timeFrom: Moment; timeTo: Moment}) => void
  scaleToShowThreshold?: boolean
  onHover?: (event, elements, chart) => void
}

export const SingleScalableChart = ({
  sensorId,
  range,
  rangeSelectCallback,
  scaleToShowThreshold,
  chartRef,
  onHover
}: SingleSensorChartProps) => {
  const {data, isLoading, isFetching} = useGetSignalPlotData({
    sensorIds: [sensorId],
    ...range
  })

  const graphUnit = data?.data[0]?.unit ?? ''
  const thresholdHighHigh = data?.data[0]?.thresholds?.highHigh
  const thresholdHigh = data?.data[0]?.thresholds?.high
  const thresholdLow = data?.data[0]?.thresholds?.low
  const thresholdLowLow = data?.data[0]?.thresholds?.lowLow
  const highStatus = data?.data[0]?.thresholds?.highStatus
  const lowStatus = data?.data[0]?.thresholds?.lowStatus
  const highHighStatus = data?.data[0]?.thresholds?.highHighStatus
  const lowLowStatus = data?.data[0]?.thresholds?.lowLowStatus

  const thresholdConfig = useMemo(
    () => ({
      highHigh: thresholdHighHigh,
      high: thresholdHigh,
      low: thresholdLow,
      lowLow: thresholdLowLow,
      highStatus: highStatus,
      lowStatus: lowStatus,
      highHighStatus: highHighStatus,
      lowLowStatus: lowLowStatus
    }),
    [
      highHighStatus,
      highStatus,
      lowLowStatus,
      lowStatus,
      thresholdHigh,
      thresholdHighHigh,
      thresholdLow,
      thresholdLowLow
    ]
  )

  const plugins = useMemo(() => [rangeSelectPlugin, thresholdAreaPlugin], [])

  const options = useMemo(
    () =>
      zoomLineChartOptions({
        unit: graphUnit,
        scaleToShowThreshold: scaleToShowThreshold,
        ...thresholdConfig,
        minimalDateRange: 60 * 5,
        onRangeSelection: (range: {startDate: string; endDate: string}) => {
          if (range?.startDate && range?.endDate)
            rangeSelectCallback({
              timeFrom: moment(range?.startDate),
              timeTo: moment(range?.endDate)
            })
        },
        onHover
      }),
    [graphUnit, rangeSelectCallback, scaleToShowThreshold, thresholdConfig, onHover]
  )

  const chartData = useMemo(() => getSingleSignalData({data}), [data])

  return isLoading ? (
    <Box sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  ) : !isLoading && !chartData.labels?.length ? (
    <ChartError />
  ) : (
    <div>
      {isFetching ? <LinearProgress /> : <div style={{height: '4px'}} />}
      <BaseChart data={chartData} options={options} plugins={plugins} extChartRef={chartRef} />
    </div>
  )
}
