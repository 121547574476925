import moment, {Moment} from 'moment'

import {Node} from '../types/canvasNodes.types'

export type Validator = (value, item: Partial<Node>) => string | undefined
export type ValidatorConfig = Map<string, Validator>

export const shouldNotBeEmpty: Validator = (value) => {
  return value ? undefined : 'shouldBe.notEmpty'
}

export const shouldNotBeEmptyString: Validator = (value: string) => {
  return value && value.length > 0 ? undefined : 'shouldBe.notEmpty'
}

export const nodeTitleValidation: Validator = (value: string) => {
  return value && value.trim().length > 0
    ? value.length <= 80
      ? undefined
      : 'shouldBe.notLonger80'
    : 'shouldBe.notEmpty'
}

export const nodeDescriptionValidation: Validator = (value: string) => {
  const escapedValue = value.replaceAll(/(<([^>]+)>)/gi, '')
  return escapedValue && escapedValue.trim().length > 0
    ? escapedValue.length <= 5000
      ? undefined
      : 'shouldBe.notLonger5000'
    : 'shouldBe.notEmpty'
}

export const shouldNotBeInPast: Validator = (value: Moment) => {
  return moment(value).isBefore(moment().startOf('day')) ? 'task.dueDateIsInThePast' : undefined
}

export const shouldBeNumber: Validator = (value) => {
  return Number.isNaN(value) ? 'shouldBe.number' : undefined
}

export const shouldNotBeEmptyArray: Validator = (value: string[] = []) => {
  return value.length > 0 ? undefined : 'shouldBe.notEmpty'
}

export const validateAll = (config: ValidatorConfig, item) => {
  const validationError = new Map()

  config.forEach((validator, key) => {
    const failed = validator(item[key], item)

    if (failed) {
      validationError.set(key, failed)
    }
  })

  return validationError
}
