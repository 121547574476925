import DeleteOutline from '@mui/icons-material/DeleteOutline'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Settings from '@mui/icons-material/Settings'
import {Fade, IconButton, Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Comment} from '../../../types/nodeDetail.types'

import {DeleteComment} from './DeleteComment'

export const CommentActionsButton: React.FC<{
  comment: Comment
  analysisId: string
  nodeId: number
  setSelectedCommentId: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({comment, analysisId, nodeId, setSelectedCommentId}) => {
  const {t} = useTranslation()
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="primary"
        data-test-id={`comment-action-button-${comment.id}`}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} TransitionComponent={Fade}>
        {comment.permissions.canEdit && (
          <MenuItem
            onClick={() => {
              setSelectedCommentId(comment.id)
              handleClose()
            }}
            sx={{height: 50, width: 200}}
            data-test-id={`comment-action-button-edit-${comment.id}`}
          >
            <Settings sx={{marginRight: 1}} />
            {t('buttons.edit')}
          </MenuItem>
        )}
        {comment.permissions.canDelete && (
          <MenuItem
            onClick={() => {
              setDeleteDialogOpened(true)
              handleClose()
            }}
            sx={{height: 50, width: 200}}
            data-test-id={`comment-action-button-delete-${comment.id}`}
          >
            <DeleteOutline color="error" sx={{marginRight: 1}} />
            {t('buttons.delete')}
          </MenuItem>
        )}
      </Menu>
      {comment.permissions.canDelete && (
        <DeleteComment
          nodeId={nodeId}
          commentId={comment.id}
          analysisId={analysisId}
          closeModal={() => setDeleteDialogOpened(false)}
          opened={deleteDialogOpened}
        />
      )}
    </>
  )
}
