/* eslint-disable complexity */
import {Close} from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import {Box, Grid, IconButton, LinearProgress, Skeleton, Stack, Typography} from '@mui/material'
import React, {ReactNode, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {ImageDetail} from '../../components/ImageDetail'
import {NodeMenu} from '../../components/node/NodeMenu'
import {RootNodeDetails} from '../../components/node/RootNodeDetails'
import {useGetAnalysisDetail} from '../../hooks/analysis/useGetAnalysisDetail'
import {useNodeDetail} from '../../hooks/node/useNodeDetail'
import {CanvasNode} from '../../types/canvasNodes.types'
import {NodeModalActiveView} from '../../types/nodeDetail.types'
import {NodeTasks} from '../task/NodeTasks'

import {AddCommentForm} from './comments/AddCommentForm'
import {NodeComments} from './comments/NodeComments'
import {MarkRootCause} from './MarkRootCause'
import {NodeAttachment} from './NodeAttachment'
import {NodeDelete} from './NodeDelete'
import {NodeVotes} from './NodeVotes'

export const NodeDetail = ({
  setActiveView,
  openTaskDetail,
  canvasNode,
  DragHandle,
  closeModal,
  setNewNode
}: {
  setActiveView: React.Dispatch<React.SetStateAction<NodeModalActiveView>>
  openTaskDetail: (taskId: string) => void
  canvasNode: CanvasNode
  DragHandle: JSX.Element
  closeModal: () => void
  setNewNode: Function
}) => {
  const analysisId = useUrlParam('analysisId')
  const {t} = useTranslation()
  const [isCommentActive, setIsCommentActive] = useState(false)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [activeImageId, setActiveImageId] = useState<number | undefined>(undefined)

  const {data: analysisData} = useGetAnalysisDetail(analysisId)
  const {data, isFetching, isPending: isLoading} = useNodeDetail(canvasNode.id.toString())
  const isMainNode = canvasNode.id === 0
  const canDelete = canvasNode?.children ? canvasNode.children.length <= 1 : false

  return (
    <>
      <Stack spacing={2} pb={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          paddingRight={2}
        >
          <Typography
            variant="h2"
            sx={{
              wordWrap: 'break-word',
              display: 'flex',
              gap: 1,
              alignItems: 'flex-start'
            }}
          >
            {DragHandle}
            {canvasNode.title}
          </Typography>
          <Box display="flex" flex="0 0 auto">
            {!isLoading && data && (
              <NodeMenu
                nodeId={canvasNode.id}
                openEditView={() => setActiveView('edit')}
                setRemoveDialogOpened={setRemoveDialogOpen}
                permissions={data.permissions}
                canDelete={canDelete}
                closeDetailModal={closeModal}
              />
            )}
            {isLoading && (
              <Box p="5px">
                <Skeleton variant="circular" width={20} height={20} />
              </Box>
            )}
            <IconButton onClick={() => closeModal()} color="primary">
              <Close />
            </IconButton>
          </Box>
        </Stack>
        {isFetching && !isLoading && <LinearProgress />}
        <Stack spacing={2} pr={1} sx={{maxHeight: '48vh', overflowY: 'auto'}}>
          {!isLoading && data?.votes && (
            <Grid display="flex" sx={{height: '40px'}} gap={1}>
              <MarkRootCause
                analysisId={analysisId}
                nodeId={data.id}
                rootCauseType={data.rootCauseType}
              />
              <NodeVotes
                voteList={data.votes}
                analysisId={analysisId}
                nodeId={data.id}
                permissions={data.permissions}
              />
            </Grid>
          )}
          {isLoading && canvasNode.id !== 0 && (
            <Skeleton variant="rounded" height={40} width={300} sx={{flex: '0 0 auto'}} />
          )}
          {(analysisData?.stoppage || analysisData?.category || analysisData?.areaTypeCode) &&
            isMainNode && (
              <RootNodeDetails analysisData={analysisData} dueDate={analysisData.dueDate} />
            )}
          <ReactQuill value={canvasNode.description} readOnly={true} theme={'bubble'} />
          {/* NODE ATTACHMENTS */}
          <NodeSection
            title={t('node.detail.attachments')}
            canSeeButton={!!data?.permissions?.canAddAttachment}
            onClick={() => setActiveView('edit')}
            showSkeleton={isLoading}
          >
            {!isLoading && !!data?.attachments?.length && (
              <Box display="flex" flexWrap="wrap">
                {data.attachments.map((attachment) => (
                  <NodeAttachment
                    attachment={attachment}
                    key={`file-${attachment.id}`}
                    setActiveImageId={setActiveImageId}
                  />
                ))}
              </Box>
            )}
            {isLoading && !!canvasNode.attachmentCount && (
              <Box gap={1} display="flex" flexWrap="wrap">
                {Array(canvasNode.attachmentCount)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton
                      key={`attachment-placeholder-${index}`}
                      height={100}
                      width={100}
                      variant="rectangular"
                      sx={{flex: '0 0 auto', m: '8px'}}
                    />
                  ))}
              </Box>
            )}
          </NodeSection>

          {/* NODE TASKS */}
          {!isMainNode && (
            <NodeSection
              title={t('label.tasks')}
              canSeeButton={!!data?.permissions?.canCreateTask}
              onClick={() => setActiveView('createTask')}
              showSkeleton={isLoading}
            >
              <NodeTasks
                nodeId={canvasNode.id}
                onTaskClick={openTaskDetail}
                expectedCount={canvasNode.taskCount}
              />
            </NodeSection>
          )}

          {/* NODE COMMENTS */}
          {!isMainNode && (
            <NodeSection
              title={t('comments.title')}
              canSeeButton={!!data?.permissions?.canAddComment && !isCommentActive}
              onClick={() => setIsCommentActive(true)}
              showSkeleton={isLoading}
            >
              {isCommentActive && analysisId && (
                <AddCommentForm
                  nodeId={canvasNode.id}
                  analysisId={analysisId}
                  closeForm={() => setIsCommentActive(false)}
                />
              )}
              <NodeComments nodeId={canvasNode.id} expectedCount={canvasNode.commentCount} />
            </NodeSection>
          )}
        </Stack>
      </Stack>
      {data && data.permissions.canDelete && (
        <NodeDelete
          nodeId={data.id}
          analysisId={analysisId}
          title={data.title}
          closeModal={() => setRemoveDialogOpen(false)}
          opened={removeDialogOpen}
          setNewNode={setNewNode}
        />
      )}
      {!!data?.attachments?.length && (
        <ImageDetail
          files={data.attachments}
          activeId={activeImageId}
          setActiveImageId={setActiveImageId}
          nodeId={data.id}
          analysisId={analysisId}
          canDeleteAttachment={data.permissions.canDeleteAttachment}
        />
      )}
    </>
  )
}

type NodeSectionProps = {
  title: string
  canSeeButton: boolean
  onClick: () => void
  children: ReactNode
  showSkeleton?: boolean
}

const NodeSection = ({title, canSeeButton, onClick, children, showSkeleton}: NodeSectionProps) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{title}</Typography>
        {showSkeleton && (
          <Box p="5px">
            <Skeleton variant="circular" width={20} height={20} sx={{flex: '0 0 auto'}} />
          </Box>
        )}
        {!showSkeleton && canSeeButton && (
          <IconButton
            color="primary"
            onClick={() => onClick()}
            data-test-id={`create-${title.toLowerCase()}-save-button`}
          >
            <Add />
          </IconButton>
        )}
      </Stack>
      {children}
    </Stack>
  )
}
