import {TextField, SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type Props = {
  'data-test-id'?: string
  current?: string
  errorCode?: string
  onChange: (next: string) => void
  required?: boolean
  className?: string
  label?: string
  defaultValue?: string
  sx?: SxProps<Theme>
  background?: boolean
}

export const NodeDescriptionBox: React.FC<Props> = (props) => {
  const {t} = useTranslation()

  return (
    <TextField
      data-test-id={props['data-test-id'] ?? 'node-form-description'}
      error={!!props.errorCode}
      fullWidth={true}
      helperText={props.errorCode && t(props.errorCode)}
      label={props.label ? props.label : t('label.description')}
      multiline
      className={props.className}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event.target.value)}
      required={props.required}
      minRows={3}
      variant={props.background ? 'filled' : 'outlined'}
      // maxRows={10}
      defaultValue={props.defaultValue ? props.defaultValue : ''}
      sx={props.sx}
    />
  )
}
