import {useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {createContext, useContext, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {findNode} from '../common/graphUtils'
import {useUpdateNode} from '../hooks/node/useUpdateNode'

import {useCanvasContext} from './CanvasContext'

type NodeMoveActionContextType = {
  sourceNode: number | null
  targetNode: number | null
  selectSourceNode: (nodeId: number) => void
  selectTargetNode: (targetNodeId: number) => Promise<void>
  cancelAction: () => void
  nodeMoveInProgress: boolean
  apiActionLoading: boolean
  movedNodes: Set<number>
}

export const NodeMoveActionContext = createContext<NodeMoveActionContextType>(
  null as unknown as NodeMoveActionContextType
)

export const NodeMoveActionProvider = ({children}: {children: React.ReactNode}) => {
  const {graphNodes} = useCanvasContext()
  const queryClient = useQueryClient()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const [sourceNode, setSourceNode] = useState<NodeMoveActionContextType['sourceNode']>(null)
  const [targetNode, setTargetNode] = useState<NodeMoveActionContextType['targetNode']>(null)
  const [nodeMoveInProgress, setNodeMoveInProgress] = useState(false)

  const {mutateAsync: updateNode, isPending: isLoading} = useUpdateNode()

  const sourceNodeData = useMemo(
    () => findNode(sourceNode ?? undefined, graphNodes),
    [sourceNode, graphNodes]
  )

  const movedNodes = useMemo(() => {
    if (!sourceNodeData) return new Set<number>()
    const descendants = [...sourceNodeData.children]

    for (const node of descendants) {
      const child = findNode(node, graphNodes)
      if (child?.children?.length) {
        descendants.push(...child.children)
      }
    }
    return new Set([sourceNodeData.id, ...descendants])
  }, [sourceNodeData, graphNodes])

  const selectSourceNode = (nodeId: number) => {
    setSourceNode(nodeId)
    setNodeMoveInProgress(true)
  }

  const selectTargetNode = async (targetNodeId: number) => {
    setTargetNode(targetNodeId)

    if (!sourceNodeData) {
      console.error('Source node not found in graph')
      cleanup()
      return
    }

    try {
      await updateNode({
        id: sourceNodeData.id,
        order: sourceNodeData.order,
        parent: targetNodeId,
        title: sourceNodeData.title,
        description: sourceNodeData.description,
        newAttachments: []
      })
      // move action can update many nodes at once so better to purge the cache
      queryClient.removeQueries({queryKey: ['node-detail']})
    } catch (error) {
      const message = `${t('errors.genericActionError')}${
        error instanceof AxiosError ? `: ${error?.message}` : ''
      }`

      enqueueSnackbar(message, {
        variant: 'error',
        onClick: () => closeSnackbar()
      })
    } finally {
      cleanup()
    }
  }

  const cleanup = () => {
    setSourceNode(null)
    setTargetNode(null)
    setNodeMoveInProgress(false)
  }

  return (
    <NodeMoveActionContext.Provider
      value={{
        sourceNode,
        targetNode,
        selectSourceNode,
        selectTargetNode,
        nodeMoveInProgress,
        cancelAction: cleanup,
        apiActionLoading: isLoading,
        movedNodes
      }}
    >
      {children}
    </NodeMoveActionContext.Provider>
  )
}

export const useNodeMoveAction = () => useContext(NodeMoveActionContext)
