import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {AnalysisStatistics} from '../../types/analysis.types'

export const QueryKey = 'analysis-statistics'

export const useGetAnalysisStatistics = (
  analysisId?: number,
  options?: UseQueryOptions<AnalysisStatistics, AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<AnalysisStatistics, AxiosError>({
    queryKey: [QueryKey, analysisId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/statistics`
      return (await axiosInstance.get<AnalysisStatistics>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
