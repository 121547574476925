import {AssetPartSignal} from '@hconnect/common/components/kmsStatus/types'
import {Box, Autocomplete, TextField, AutocompleteProps} from '@mui/material'
import {SyntheticEvent} from 'react'

type SignalAutocompleteOption = {
  id: string
  label: string
  description: string
}

export type SignalAutocompleteProps = {
  label: string
  onChange: (newValue: SignalAutocompleteOption[]) => void
  value: AssetPartSignal[]
  options: SignalAutocompleteOption[]
  AutocompleteProps?: Omit<
    AutocompleteProps<SignalAutocompleteOption, true, false, false>,
    | 'value'
    | 'onChange'
    | 'options'
    | 'renderInput'
    | 'renderOption'
    | 'isOptionEqualToValue'
    | 'multiple'
  >
}

export const SignalAutocomplete = ({
  label,
  onChange,
  value,
  options,
  AutocompleteProps
}: SignalAutocompleteProps) => {
  return (
    <Autocomplete
      options={options}
      fullWidth
      multiple
      value={value}
      onChange={(
        _: SyntheticEvent,
        newValue: Array<{id: string; label: string; description: string}>
      ) => {
        onChange(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <li {...props}>
          <Box
            sx={{
              padding: 1.5,
              flexGrow: 1,
              '& span': {
                color: '#586069'
              }
            }}
          >
            {option.label}
            <br />
            <span>{option.description}</span>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          sx={{
            '& .MuiFilledInput-root': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              },
              '&.Mui-focused': {
                backgroundColor: 'transparent'
              }
            }
          }}
        />
      )}
      {...AutocompleteProps}
    />
  )
}
