import {PmMain, PmInternalRoutes} from './general/pages/Main'
import {KmsMain, KmsRoutes} from './kms/pages/Main'

export const PmRoutes = [
  {
    path: 'kms/*',
    element: <KmsMain />,
    children: [...KmsRoutes]
  },
  {
    path: '*',
    element: <PmMain />,
    children: [...PmInternalRoutes]
  }
]
