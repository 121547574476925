/* eslint-disable complexity */
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import React from 'react'

import {NodeDetails} from '../../components/canvas/NodeDetails'
import {NodeText} from '../../components/canvas/NodeText'
import {useCanvasContext} from '../../context/CanvasContext'
import {useCanvasSettingsContext} from '../../context/CanvasSettingsContext'
import {useNodeMoveAction} from '../../context/NodeMoveActionContext'
import {
  activeBackground,
  defaultNodeBackground,
  likelyColor,
  primaryColor,
  unLikelyColor,
  rootCauseStrokeColor
} from '../../styles/themeColours'
import {CanvasMode, Node, CanvasNode} from '../../types/canvasNodes.types'
import {Probability, RootCauseType} from '../../types/nodeDetail.types'

const CLICK_DELAY = 200

const PROBABILITY_COLORS = {
  [Probability.likely]: likelyColor,
  [Probability.unlikely]: unLikelyColor,
  [Probability.inconclusive]: unLikelyColor
}

const _getNodeStroke = ({
  isActive,
  isHovered,
  rootCauseType,
  isOnRootCausePath,
  isMovedNode
}: {
  isActive?: boolean
  isHovered?: boolean
  rootCauseType?: RootCauseType
  isOnRootCausePath?: boolean
  isMovedNode?: boolean
}) => {
  if (isActive || isMovedNode) return primaryColor
  if (rootCauseType || isOnRootCausePath) return rootCauseStrokeColor
  if (isHovered) return activeBackground
  return defaultNodeBackground
}

export interface NodeProps {
  node: CanvasNode
  hoveredNode?: Node
  canvasMode: CanvasMode
  setHoveredNode: (a?: CanvasNode) => void
  setDetailModalOpen: () => void
  stoppageCode?: string
}

export const NodeCanvasDetail: React.FC<NodeProps> = ({
  node,
  hoveredNode,
  canvasMode,
  setHoveredNode,
  setDetailModalOpen,
  stoppageCode
}) => {
  const {nodeHeight, nodeWidth} = useCanvasSettingsContext()
  const {nodeMoveInProgress, selectTargetNode, movedNodes, sourceNode} = useNodeMoveAction()

  const {updateCollapsedAncestorNode, activeNode, updateActiveNode} = useCanvasContext()

  const _isDefaultMode = () => canvasMode === CanvasMode.default
  const isActive = activeNode?.id === node.id
  const isHovered = hoveredNode?.id === node.id
  const isOnRootCausePath =
    node.isOnPathToRootCauseFrom && node.isOnPathToRootCauseFrom?.length > 0 && node.id !== 0
  const isMovedNode = movedNodes.has(node.id)

  const nodeStyles = {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    ...(_isDefaultMode() ? {cursor: 'pointer'} : {}),
    pointerEvents: (isMovedNode ? 'none' : 'all') as React.CSSProperties['pointerEvents']
  }

  const stroke = _getNodeStroke({
    isActive,
    isHovered,
    rootCauseType: node.rootCauseType,
    isOnRootCausePath,
    isMovedNode
  })

  const handleClick = (e: React.MouseEvent) => {
    handleAllClicks(
      e,
      () => {
        if (nodeMoveInProgress) {
          void selectTargetNode(node.id)
          return
        }
        _isDefaultMode() && updateActiveNode(node.id)
        setDetailModalOpen()
      },
      () => {
        updateCollapsedAncestorNode(node.id)
      }
    )
  }

  return (
    <>
      <g key={`graphNode-${node.id}`}>
        <rect
          data-test-id={`canvasNode-${node.id}`}
          x={node.cx}
          y={node.cy}
          rx={8}
          ry={8}
          width={nodeWidth}
          height={nodeHeight}
          fill={isActive || isHovered ? activeBackground : '#FFF'}
          strokeWidth={
            node.rootCauseType || isOnRootCausePath || sourceNode === node.id ? '3px' : undefined
          }
          stroke={stroke}
          style={nodeStyles}
          onClick={(e) => handleClick(e)}
          onMouseEnter={() => {
            if (canvasMode === CanvasMode.default) {
              setHoveredNode(node)
            }
          }}
          onMouseLeave={() => setHoveredNode(undefined)}
        />
        {node.causeProbability &&
          node.causeProbability !== Probability.unknown &&
          node.cx &&
          node.cy && (
            <rect
              x={node.cx + 6}
              y={node.cy + 6}
              rx={3}
              width={5}
              height={nodeHeight - 12}
              fill={PROBABILITY_COLORS[node.causeProbability]}
            />
          )}
        {node.rootCauseType && (
          <FlagOutlinedIcon
            width={20}
            height={20}
            x={node.cx + 20}
            y={node.cy + nodeHeight / 2 - 14}
            htmlColor="black"
          />
        )}
        <NodeText
          node={node}
          nodeWidth={nodeWidth}
          nodeHeight={nodeHeight}
          styles={nodeStyles}
          canvasMode={canvasMode}
          setParentActive={() => {
            if (nodeMoveInProgress) {
              void selectTargetNode(node.id)
              return
            }
            _isDefaultMode() && updateActiveNode(node.id)
            setDetailModalOpen()
          }}
          setParentHover={setHoveredNode}
          stoppageCode={stoppageCode}
        />
        <NodeDetails
          node={node}
          nodeWidth={nodeWidth}
          nodeHeight={nodeHeight}
          styles={nodeStyles}
        />
      </g>
    </>
  )
}

let timer: ReturnType<typeof setTimeout> | undefined = undefined

const handleAllClicks = (
  event: React.MouseEvent,
  handleSingleClick: () => void,
  handleDoubleClick: () => void
) => {
  clearTimeout(timer)

  if (event.detail === 1) {
    timer = setTimeout(() => {
      handleSingleClick()
    }, CLICK_DELAY)
  } else if (event.detail === 2) {
    handleDoubleClick()
  }
}
