import {Autorenew, HourglassEmpty, Done} from '@mui/icons-material'
import {FormControlLabel, FormGroup, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {TaskStatuses} from '../../types/nodeDetail.types'

interface TaskStatusSwitchProps {
  value: TaskStatuses | string
  onChange?: (value: string) => void
  disabled?: boolean
}

const TaskStatusSwitch = React.memo<TaskStatusSwitchProps>(({value, disabled, onChange}) => {
  const {t} = useTranslation()
  const [status, setStatus] = useState<TaskStatuses | string>(value)
  const handleOnChange = (event, newValue: string) => {
    if (newValue && newValue !== status) {
      setStatus(newValue)
      if (onChange) onChange(newValue)
    }
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <ToggleButtonGroup
            exclusive
            fullWidth={true}
            value={status}
            onChange={handleOnChange}
            sx={{
              height: '58px',
              transform: 'translateX(12px)',
              padding: 0.5,
              border: '1px solid rgba(0, 39, 77, 0.15)',
              background: '#f5f6f7',
              boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)'
            }}
          >
            <ToggleButton
              sx={(theme) => ({
                borderRadius: '6px !important',
                border: 'transparent !important',
                '&.Mui-selected': {
                  color: '#FFFFFF !important',
                  backgroundColor: `${theme.palette.error.main} !important`
                }
              })}
              value={TaskStatuses.Pending}
              aria-label={t('taskStatuses.pending')}
            >
              <HourglassEmpty
                sx={{
                  display: 'block',
                  float: 'left',
                  marginRight: '5px'
                }}
              />
              <span>{t('taskStatuses.pending')}</span>
            </ToggleButton>
            <ToggleButton
              sx={{
                borderRadius: '6px !important',
                border: 'transparent !important',
                '&.Mui-selected': {
                  color: '#FFFFFF !important',
                  backgroundColor: '#016AD4 !important'
                }
              }}
              value={TaskStatuses.Ongoing}
              aria-label={t('taskStatuses.ongoing')}
            >
              <Autorenew
                sx={{
                  display: 'block',
                  float: 'left',
                  marginRight: '5px'
                }}
              />
              <span>{t('taskStatuses.ongoing')}</span>
            </ToggleButton>
            <ToggleButton
              sx={(theme) => ({
                borderRadius: '6px !important',
                border: 'transparent !important',
                '&.Mui-selected': {
                  color: '#FFFFFF !important',
                  backgroundColor: `${theme.palette.success.main} !important`
                }
              })}
              value={TaskStatuses.Done}
              aria-label={t('taskStatuses.done')}
            >
              <Done
                sx={{
                  display: 'block',
                  float: 'left',
                  marginRight: '5px'
                }}
              />
              <span>{t('taskStatuses.done')}</span>
            </ToggleButton>
          </ToggleButtonGroup>
        }
        label={''}
        disabled={disabled}
      />
    </FormGroup>
  )
})

TaskStatusSwitch.displayName = 'TaskStatusSwitch'
export default TaskStatusSwitch
