import {useMediaQuery, useTheme, GridSize, Theme} from '@mui/material'

export type BreakPoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type GridSetting = {
  xs?: boolean | GridSize | undefined
  sm?: boolean | GridSize | undefined
  md?: boolean | GridSize | undefined
  lg?: boolean | GridSize | undefined
  xl?: boolean | GridSize | undefined
}

export const useWidth = () => {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce<BreakPoint | null>((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

export const useMobileBreakPoint = () =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
