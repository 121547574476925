import {PermissionType} from '@hconnect/apiclient/src/types'
import {AnalysisListType} from '@hconnect/common/types'
import {useTranslation} from '@hconnect/uikit'
import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {useState} from 'react'
import {useSearchParams} from 'react-router-dom'

import {FilterWrapper} from '../../shared/containers/filters/FilterWrapper'
import {useListFilters} from '../../shared/hooks/listFiltersSort/useListFilters'
import {useListOrderBy} from '../../shared/hooks/listFiltersSort/useListOrderBy'
import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {AnalysisListSwitch} from '../components/AnalysisListSwitch'
import {NewAnalysisButton} from '../components/NewAnalysisButton'
import {getAvailableFilters} from '../config/analysisList.filters'
import {getAvailableColumns} from '../config/analysisList.orderBy'
import {AnalysisListTable} from '../container/analysisList/AnalysisList'
import {hasPermission} from '../hooks/usePermission'
import {AnalysisListProperty} from '../types/analysisList.types'

export const AnalysisList = () => {
  const {t} = useTranslation()
  const permissions = useGlobalContext().state.permissions
  const plantId = useUrlParam('plantId')
  const canReassign = permissions
    ? hasPermission(permissions, 'ADMINISTRATE_ROOT_CAUSE_FAILURE_ANALYSES' as PermissionType)
    : false

  const [canCreateAnalysis, setCanCreateAnalysis] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const page = parseInt(searchParams.get('page') || '0')
  const rowsNumber = parseInt(searchParams.get('rows') || '10')
  const listType = (searchParams.get('type') as AnalysisListType) || AnalysisListType.Mine

  const availableFilters = getAvailableFilters(listType, canReassign, plantId)
  const availableColumns = getAvailableColumns(listType, canReassign)

  const {filterState, apiFilters, dispatch} = useListFilters(
    availableFilters,
    searchParams.get('filters')
  )

  const {listOrder, handleOrderToggle} = useListOrderBy<AnalysisListProperty>(
    availableColumns.map((column) => column.id),
    searchParams.get('orderBy')
  )

  const handleListTypeChange = (newListType: AnalysisListType) => {
    if (!newListType) return
    setSearchParams((params) => {
      params.set('page', '0')
      params.set('type', newListType)
      return params
    })
  }

  return (
    <PageContainer>
      <Box display="flex" mb={3} flexWrap="wrap" gap={2} justifyContent="space-between">
        <PageTitle mb={0}>{t('pages.mainTitle')}</PageTitle>
        <FilterWrapper filters={filterState} dispatch={dispatch} />
      </Box>

      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          color: theme.palette.common.black,
          borderRadius: 1,
          padding: 1.5,
          position: 'relative',
          overflowX: 'auto',
          fontFamily: 'Inter'
        })}
      >
        <Box sx={{marginBottom: '24px'}}>
          <AnalysisListSwitch value={listType} onChange={handleListTypeChange} />
          {canCreateAnalysis && listType === AnalysisListType.Mine && <NewAnalysisButton />}
        </Box>
        <AnalysisListTable
          listType={listType}
          page={page}
          rowsNumber={rowsNumber}
          setCanCreateAnalysis={setCanCreateAnalysis}
          filters={apiFilters}
          order={listOrder}
          handleOrderToggle={handleOrderToggle}
          availableColumns={availableColumns}
        />
      </Box>
    </PageContainer>
  )
}
