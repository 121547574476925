import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus/KilnMetricStatus'
import {AssetPartSignal, HealthIndicatorsApi} from '@hconnect/common/components/kmsStatus/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Button, MenuItem, Skeleton, Typography, Grid, Menu} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {useKiln} from '../context/KilnContext'

export const KilnData = ({
  loading,
  onKilnMetricClick,
  healthIndicators
}: {
  loading: boolean
  onKilnMetricClick: (localNames: string[]) => void
  healthIndicators?: HealthIndicatorsApi
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {kilnId, kilnInfo, plantKilns, isWidget} = useKiln()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const hasMultipleKilns = !!plantKilns?.length && plantKilns.length > 1

  const handleMetricClick = (signals?: AssetPartSignal[]) => {
    const localNames = signals?.map((signal) => signal.id)
    if (!localNames?.length) return
    onKilnMetricClick(localNames)
  }

  const gridSettings = {
    xs: 5,
    md: 3,
    lg: isWidget ? 2 : 1
  }

  return (
    <Grid
      alignSelf="flex-start"
      color="text.primary"
      direction="row"
      gap={{
        xs: 1,
        sm: 3
      }}
      container
      mb={2}
      data-test-id="kiln-data"
    >
      {hasMultipleKilns && !isWidget && (
        <Button
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant="text"
          endIcon={<ExpandMoreIcon />}
          sx={{pl: 0, justifyContent: 'flex-start', display: 'flex'}}
          disabled={loading}
        >
          <Typography variant="h4" color="text.primary">
            {loading && <Skeleton variant="text" width={60} height={30} />}
            {!loading ? kilnInfo?.name || 'Unnamed kiln' : null}
          </Typography>
        </Button>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {plantKilns?.map((kiln) => (
          <MenuItem
            key={kiln.id}
            selected={kiln.id === kilnId}
            onClick={() => {
              navigate(`../${kiln.id}`)
              setAnchorEl(null)
            }}
          >
            {kiln.name || kiln.id}
          </MenuItem>
        ))}
      </Menu>

      <Grid item xs={gridSettings.xs} sm={gridSettings.md} lg={gridSettings.lg}>
        <KilnMetricStatus
          title={t('kilnMetrics.speed')}
          value={`${healthIndicators?.speed?.value?.toFixed(1) ?? '-'} ${
            healthIndicators?.speed?.unit ?? ''
          }`}
          status={healthIndicators?.speed?.status}
          loading={loading}
          onClick={() => handleMetricClick(kilnInfo?.layout?.speedSignals)}
          signals={kilnInfo?.layout?.speedSignals}
        />
      </Grid>
      <Grid item xs={gridSettings.xs} sm={gridSettings.md} lg={gridSettings.lg}>
        <KilnMetricStatus
          title={t('kilnMetrics.feed')}
          value={`${healthIndicators?.feed?.value?.toFixed(0) ?? '-'} ${
            healthIndicators?.feed?.unit ?? ''
          }`}
          status={healthIndicators?.feed?.status}
          loading={loading}
          onClick={() => handleMetricClick(kilnInfo?.layout?.feedSignals)}
          signals={kilnInfo?.layout?.feedSignals}
        />
      </Grid>
      <Grid item xs={gridSettings.xs} sm={gridSettings.md} lg={gridSettings.lg}>
        <KilnMetricStatus
          title={t('kilnMetrics.ovality')}
          loading={loading}
          status={healthIndicators?.ovality?.status}
          onClick={() => handleMetricClick(kilnInfo?.layout?.ovalitySignals)}
          signals={kilnInfo?.layout?.ovalitySignals}
          disabled={!kilnInfo?.layout?.ovalitySignals?.length}
        />
      </Grid>
      <Grid item xs={gridSettings.xs} sm={gridSettings.md} lg={gridSettings.lg}>
        <KilnMetricStatus title={t('kilnMetrics.axialBalance')} loading={loading} disabled />
      </Grid>
      <Grid item xs={gridSettings.xs} sm={gridSettings.md} lg={gridSettings.lg}>
        <KilnMetricStatus
          title={t('kilnMetrics.warp')}
          loading={loading}
          status={healthIndicators?.crank?.status}
          onClick={() => (isWidget ? navigate(`kms/${kilnId}/crank`) : navigate('crank'))}
          disabled={
            !kilnInfo?.layout?.crankSignals || !Object.keys(kilnInfo.layout.crankSignals).length
          }
          signals={kilnInfo?.layout?.crankSignals}
        />
      </Grid>
    </Grid>
  )
}
