import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {formatDate, formatDateTime} from '../../common/dateTimeUtils'
import {analysisAreaTypes, AnalysisFull} from '../../types/analysis.types'
import {StoppageSection} from '../stoppage/StoppageSection'

type RootNodeDetailsProps = {
  analysisData: AnalysisFull
  dueDate?: string | null
}

export const RootNodeDetails = ({analysisData, dueDate}: RootNodeDetailsProps) => {
  const {t} = useTranslation()
  const stoppage = analysisData.stoppage
  return (
    <Stack spacing={2} pt={2} mb={2}>
      <StoppageSection label={t('label.analysisCategoryLabel')} value={analysisData.category} />
      <StoppageSection
        label={t('label.searchAnalysisAreaTypeLabel')}
        value={analysisAreaTypes.find((t) => t.id === analysisData.areaTypeCode)?.label ?? ''}
      />
      {stoppage && (
        <>
          <StoppageSection
            label={t('stoppage.detail.stoppageCode')}
            value={`${stoppage?.stoppageCode?.code} - ${stoppage?.stoppageCode?.text}`}
          />
          <Stack direction="row" spacing={4}>
            <StoppageSection
              label={t('tables.analysisList.stoppageStart')}
              value={stoppage?.start ? formatDateTime(stoppage.start) : '-'}
            />
            <StoppageSection
              label={t('tables.analysisList.stoppageEnd')}
              value={stoppage?.end ? formatDateTime(stoppage.end) : '-'}
            />
          </Stack>
          <StoppageSection
            label={t('tables.tasksList.dueDate')}
            value={dueDate ? formatDate(dueDate) : '-'}
          />
          <StoppageSection
            label={t('stoppage.detail.equipment')}
            value={analysisData?.equipmentName}
          />
          <Stack direction="row" spacing={4}>
            <StoppageSection
              label={t('stoppage.detail.asset')}
              value={stoppage?.stoppageCode?.type}
            />
            <StoppageSection
              label={t('stoppage.detail.equipmentId')}
              value={stoppage?.equipment?.text}
              labelBottom={stoppage?.equipment?.id}
            />
          </Stack>
        </>
      )}
    </Stack>
  )
}
