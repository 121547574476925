import {ChartOptions} from 'chart.js'

import {chartTooltip, chartLegend, getTooltipLabel} from './common.config'

const lang = navigator.language
const isUsFormat = lang.toLowerCase() === 'en-us'

export const areaChartOptions = (): ChartOptions => {
  return {
    plugins: {
      legend: {...chartLegend},
      tooltip: {
        ...chartTooltip,
        itemSort: (a, b) => b.parsed.y - a.parsed.y,
        callbacks: {
          label: (context) =>
            getTooltipLabel({
              label: context.dataset.label,
              // @ts-expect-error - custom property is not in the type
              value: context.dataset.values?.[context.dataIndex],
              // @ts-expect-error - custom property is not in the type
              unit: context.dataset.unit
            }),
          title: (tooltipItems) => tooltipItems?.[0]?.label || ''
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.6
      }
    },
    scales: {
      y: {
        grid: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.05)'
        },
        border: {
          display: false
        },
        ticks: {
          padding: 20,
          callback: function () {
            return ''
          }
        }
      },
      x: {
        type: 'timeseries',
        time: {
          displayFormats: {
            day: isUsFormat ? 'D.MMM' : 'MMM.D',
            hour: isUsFormat ? 'H:mm, D.M' : 'H:mm M.D',
            minute: 'H:mm'
          },
          tooltipFormat: isUsFormat ? 'MM.DD.YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm:ss'
        },
        ticks: {
          padding: 10,
          source: 'auto',
          autoSkip: true,
          autoSkipPadding: 20,
          minRotation: 20,
          maxRotation: 50,
          sampleSize: 20
        },
        grid: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.05)'
        }
      }
    }
  }
}
