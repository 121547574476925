import {Box} from '@mui/material'

import {AnalysisSummaryContent} from './AnalysisSummaryContent'

export const AnalysisSummaryLayout = () => {
  // We have to use table layout trick to get the fixed header to work when printing
  return (
    <Box
      sx={{
        backgroundColor: '#F2F2F2',
        color: '#000000',
        width: '100%',
        '@media print': {
          thead: {display: 'table-header-group'},
          '@page': {
            size: 'A4',
            margin: '15mm 10mm 25mm 10mm'
          }
        }
      }}
    >
      <table width={'100%'}>
        <thead>
          <tr>
            <td>
              {/* Header space placeholder */}
              <Box
                sx={{
                  height: '85px',
                  mb: 2
                }}
                display="none"
                displayPrint="block"
              />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <AnalysisSummaryContent />
            </td>
          </tr>
        </tbody>
      </table>
      {/* Overwrite nested QuillJS style */}
      <style>
        {`.ql-editor {
                padding: 0;
                font-family: "Inter",sans-serif;
                font-weight: 400;
            }
            html {
              scroll-behavior: smooth;
            },`}
      </style>
    </Box>
  )
}
