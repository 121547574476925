import {Filter, AutocompleteOption, ApiFilter, SelectOptionsLoader} from '../../types/filter.types'

import {AutocompleteFilter} from './Autocomplete'

interface AutocompleteWithOptionsProps extends Filter<AutocompleteOption> {
  multipleOptions: boolean
  apiUrl: string
  apiParams?: object
  optionsLoader: SelectOptionsLoader
  apiFilters?: ApiFilter[]
}

export const AutocompleteWithOptions = ({
  id,
  apiUrl,
  apiParams,
  label,
  handleChange,
  value,
  multipleOptions,
  optionsLoader
}: AutocompleteWithOptionsProps) => {
  // TODO: for cascading filtering using apiFilters, this probably needs to be modified to mutation
  const {data, isFetching} = optionsLoader({id, apiUrl, filters: undefined, apiParams})

  return (
    <AutocompleteFilter
      options={data ?? []}
      loading={isFetching}
      id={id}
      label={label}
      handleChange={handleChange}
      value={value}
      multipleOptions={multipleOptions}
    />
  )
}
