import {AnalysisListType} from '@hconnect/common/types'
import FactoryIcon from '@mui/icons-material/FactoryOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import PermIdentity from '@mui/icons-material/PermIdentity'
import {ToggleButton, ToggleButtonGroup, Typography, styled} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useGetAnalysisListCounts} from '../hooks/analysisList/useGetAnalysisCounts'

export const AnalysisListSwitch = ({
  value,
  onChange
}: {
  value: AnalysisListType
  onChange: (value: AnalysisListType) => void
}) => {
  const {t} = useTranslation()
  const {data} = useGetAnalysisListCounts()

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={(e, newValue) => onChange(newValue as AnalysisListType)}
      sx={{
        height: '48px',
        border: '1px solid rgba(0, 39, 77, 0.15)',
        background: 'rgba(251, 251, 252, 1)'
        // boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.08)'
      }}
    >
      <StyledToggleButton
        value={AnalysisListType.Mine}
        aria-label={t('analysisList.buttons.mine')}
        data-test-id={`list-view-type-button-${AnalysisListType.Mine}`}
      >
        <PermIdentity />
        <Typography variant="h5">{`${t('analysisList.buttons.mine')} (${
          data?.mineCount ?? 0
        })`}</Typography>
      </StyledToggleButton>
      <StyledToggleButton
        value={AnalysisListType.Contributing}
        data-test-id={`list-view-type-button-${AnalysisListType.Contributing}`}
        aria-label={t('analysisList.buttons.contributing')}
      >
        <GroupsOutlinedIcon />
        <Typography variant="h5">{`${t('analysisList.buttons.contributing')} (${
          data?.contributingCount ?? 0
        })`}</Typography>
      </StyledToggleButton>
      <StyledToggleButton
        data-test-id={`list-view-type-button-${AnalysisListType.MyPlant}`}
        value={AnalysisListType.MyPlant}
        aria-label={t('analysisList.buttons.myPlant')}
      >
        <FactoryIcon />
        <Typography variant="h5">{`${t('analysisList.buttons.myPlant')} (${
          data?.myPlantCount ?? 0
        })`}</Typography>
      </StyledToggleButton>
      <StyledToggleButton
        data-test-id={`list-view-type-button-${AnalysisListType.Global}`}
        value={AnalysisListType.Global}
        aria-label={t('analysisList.buttons.global')}
      >
        <LanguageIcon />
        <Typography variant="h5">{`${t('analysisList.buttons.global')} (${
          data?.globalCount ?? 0
        })`}</Typography>
      </StyledToggleButton>
    </ToggleButtonGroup>
  )
}

const StyledToggleButton = styled(ToggleButton)(() => ({
  width: 'fit-content',
  borderRadius: '6px !important',
  border: 'transparent !important',
  display: 'flex',
  padding: '0 16px',
  gap: 5,
  '&.Mui-selected': {
    color: '#FFFFFF !important',
    backgroundColor: '#016AD4 !important'
  }
}))
