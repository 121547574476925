import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {Task} from '../../types/nodeDetail.types'

export type ResponseType = Task

export const QueryKey = 'task-detail'

export const useTaskDetail = (
  analysisId: string | undefined,
  nodeId: number,
  taskId: string,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, analysisId, nodeId, taskId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/tasks/${taskId}`
      return (await axiosInstance.get<ResponseType>(path)).data
    },

    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
