import {SensorReadings} from '@hconnect/common/components/kmsStatus/types'
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material'
import {Container} from '@mui/system'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {SignalStatusValue} from '../components/SignalStatusValue'
import {CardWrapper} from '../components/styled'
import {useSensors} from '../context/SensorsContext'
import {useGetSignals} from '../hooks/useGetSignals'
import {useGetTelemetry} from '../hooks/useGetTelemetry'
import {countAreaFreqs, getSensorGroupString} from '../utils/sensor.utils'

interface SensorListProps {
  setHoveredArea?: (hoveredArea?: string) => void
  kilnId?: string
}

export const SensorList = ({setHoveredArea, kilnId}: SensorListProps) => {
  const {t} = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const filteredArea = searchParams.get('area')
  const filteredIds = searchParams.get('filtered')?.split(',')
  const [sensorsReadings, setSensorsReadings] = useState<SensorReadings | undefined>(undefined)

  const {sensorData, selectedIds, isLoading, isError, toggleSensorSelected} = useSensors()

  const filteredSensors = useMemo(() => {
    if (!sensorData) return []
    if (filteredIds) {
      return sensorData.filter((sensor) => filteredIds.includes(sensor.localName)) ?? []
    }
    if (filteredArea === 'all' || !filteredArea) return sensorData
    if (filteredArea === 'Unknown area') {
      return sensorData.filter((sensor) => !sensor.group) ?? []
    }
    return (
      sensorData.filter((sensor) => sensor.group?.toLowerCase() === filteredArea?.toLowerCase()) ??
      []
    )
  }, [sensorData, filteredArea, filteredIds])

  const {data: sensorsReadingsData} = useGetSignals({
    options: {
      select: (data) =>
        data.reduce((acc, sensor) => {
          if (sensor.localName) {
            acc[sensor.localName] = {
              lastReading: sensor.lastReading,
              lastModelBreach: sensor.lastModelBreach
            }
          }
          return acc
        }, {} as SensorReadings),
      enabled: kilnId !== undefined
    },
    kilnId
  })

  // fetch last values for relevant signals
  const {data: telemetryData} = useGetTelemetry(
    filteredSensors.map((f) => f.localName),
    {
      // @ts-expect-error need to retype TODO
      select: (data) =>
        data.reduce((acc, sensor) => {
          if (sensor.localName) {
            acc[sensor.localName] = {
              lastReading: {
                value: sensor.value,
                status: sensor.threshold.status,
                recorded: sensor.dateTime,
                unit: sensor.unit
              },
              lastModelBreach: sensor.lastModelBreach
            }
          }
          return acc
        }, {} as SensorReadings),
      enabled: kilnId === undefined
    }
  )

  useEffect(() => {
    if (sensorsReadingsData) setSensorsReadings(sensorsReadingsData)
    if (telemetryData) setSensorsReadings(telemetryData as unknown as SensorReadings)
  }, [sensorsReadingsData, telemetryData])

  const areaFreqs = useMemo(() => countAreaFreqs(sensorData), [sensorData])

  const handleAreaChange = (value: string | null) => {
    if (!value) return
    setSearchParams((params) => {
      params.delete('filtered')
      params.set('area', value)
      return params
    })
  }

  return (
    <CardWrapper
      flexDirection="column"
      maxHeight={{
        xs: 'max(30vh, 300px)',
        lg: 'max(60vh, 300px)'
      }}
      minHeight="300px"
      overflow="hidden"
      data-test-id="signal-list"
    >
      <Box display="flex" flexDirection="column" color="text.primary" overflow="auto">
        <Box pt={2} pb={2} sx={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 100}}>
          <Box display="flex">
            <Container>
              <Typography variant="h4">{t('sensorList.listHeading')}</Typography>
              <Typography variant="caption">{t('sensorList.listCaption')}</Typography>
            </Container>

            {!isLoading && sensorData && (
              <FormControl sx={{mr: 2, minWidth: 'fit-content'}} size="small" variant="filled">
                <InputLabel id="demo-select-small">{t('sensorList.filterLabel')}</InputLabel>
                <Select
                  autoWidth
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={filteredIds ? 'custom' : filteredArea ?? 'all'}
                  label="Area"
                  onChange={(e) => handleAreaChange(e.target.value)}
                  data-testid="sensor-list-filter"
                >
                  <MenuItem value="all">{`All (${sensorData.length})`}</MenuItem>
                  {Object.keys(areaFreqs)
                    .sort()
                    .map((area) => (
                      <MenuItem key={area} value={area}>
                        {area} ({areaFreqs[area]})
                      </MenuItem>
                    ))}
                  <MenuItem value="custom" sx={{display: 'none'}}>
                    {`Custom (${filteredIds?.length})`}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
        <List aria-label="sensor list" disablePadding>
          {!isLoading &&
            filteredSensors &&
            filteredSensors
              .sort((a, b) => {
                const aStatus = sensorsReadings?.[a.localName]?.lastReading?.status
                const bStatus = sensorsReadings?.[b.localName]?.lastReading?.status
                const aHasModelBreach = !!sensorsReadings?.[a.localName]?.lastModelBreach
                const bHasModelBreach = !!sensorsReadings?.[b.localName]?.lastModelBreach

                if (aStatus === 'Alarm' && bStatus !== 'Alarm') return -1
                if (aStatus !== 'Alarm' && bStatus === 'Alarm') return 1

                if (aStatus === 'Warning' && bStatus !== 'Warning') return -1
                if (aStatus !== 'Warning' && bStatus === 'Warning') return 1

                if (aHasModelBreach && !bHasModelBreach) return -1
                if (!aHasModelBreach && bHasModelBreach) return 1

                return 0
              })
              .map((sensor, index) => (
                <React.Fragment key={`${sensor.localName}-${index}`}>
                  <ListItemButton
                    onMouseEnter={() => setHoveredArea && setHoveredArea(sensor.svgId)}
                    onMouseLeave={() => setHoveredArea && setHoveredArea()}
                    selected={selectedIds.includes(sensor.localName)}
                    onClick={() => toggleSensorSelected(sensor.localName)}
                    data-testid={'sensor-list-item'}
                    data-signal-id={sensor.localName}
                  >
                    <ListItemText
                      primary={`${sensor.localName} ${sensor.description}`}
                      secondary={getSensorGroupString(sensor)}
                      sx={{pl: 1, mr: 2, width: '100%'}}
                    />
                    <SignalStatusValue
                      lastReading={sensorsReadings?.[sensor.localName]?.lastReading}
                      lastModelBreach={sensorsReadings?.[sensor.localName]?.lastModelBreach}
                      unit={sensor.unit || telemetryData?.[sensor.localName]?.lastReading.unit}
                    />
                  </ListItemButton>
                  <Divider variant="middle" />
                </React.Fragment>
              ))}
          {(isLoading || (!sensorData && isError)) &&
            Array(10)
              .fill(0)
              .map((_, i) => (
                <React.Fragment key={i}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    pb={1}
                    pt={1}
                    ml={3}
                    mr={3}
                  >
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Skeleton variant="rounded" width={300} height={25} />
                      <Skeleton variant="rounded" width={150} height={20} />
                    </Box>
                    <Skeleton variant="rounded" width={100} height={30} />
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
        </List>
      </Box>
    </CardWrapper>
  )
}
