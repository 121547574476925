import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {OrderByApiProperty} from '../../../shared/hooks/listFiltersSort/useListOrderBy'
import {useAxios} from '../../../shared/hooks/useApi'
import {ApiFilter} from '../../../shared/types/filter.types'
import {Task} from '../../types/nodeDetail.types'

export type PagePermissions = {
  canCreateAnalysis: boolean
}

export type ResponseType = {
  currentPage: number
  data: Array<Task>
  totalCount: number
  permissions: PagePermissions
}

export const QueryKey = 'tasks-master-list'

export const useGetTasksList = (
  page: number,
  rowsNumber: number,
  filters?: ApiFilter[],
  order?: OrderByApiProperty[],
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, page, rowsNumber, filters, order],
    queryFn: async () => {
      const path = '/rootcausefailureanalysis/analyses/tasks'
      return (
        await axiosInstance.post<ResponseType>(
          path,
          {pageSize: rowsNumber, pageNumber: page, filters, orderBy: order},
          {}
        )
      ).data
    },

    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
