import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
export const QueryKey = 'node-attachment'

export const useFileData = (
  fileId: number,
  url: string,
  contentType: string,
  options?: UseQueryOptions<any, AxiosError>
) => {
  const axiosInstance = useAxios()
  return useQuery<any, AxiosError>({
    queryKey: [QueryKey, fileId.toString(), url],
    queryFn: async () => {
      const absUrl = `/documents/${url}`
      if (url) {
        return (
          await axiosInstance.get<ArrayBuffer>(absUrl, {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': contentType,
              Accept: contentType
            }
          })
        ).data
      } else {
        return false
      }
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    retry: false,
    staleTime: 1000 * 60 * 15, // cache images for 15 minutes

    // allow the callee to override the default options
    ...options
  })
}
