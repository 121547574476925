import {TextField, Autocomplete} from '@mui/material'
import {debounce} from '@mui/material/utils'
import {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {BaseTextField} from '../../../shared/components/FormInputs'
import {useSearchContributors} from '../../hooks/useSearchContributors'
import {UserInfo} from '../../types/user.types'

type RcfaUserSearchProps = {
  label: string
  onChange: (value: UserInfo | null) => void
  assignee: UserInfo | null
  clearInputOnChange?: boolean
  legacyDesign?: boolean
  textfieldVariant?: 'filled' | 'outlined' | 'standard'
}

export const RcfaUserSearch = ({
  label,
  onChange,
  assignee,
  clearInputOnChange,
  legacyDesign,
  textfieldVariant
}: RcfaUserSearchProps) => {
  const {t} = useTranslation()
  const {data: searchResults, mutate: searchUsers, isPending: isLoading} = useSearchContributors()
  const [search, setSearch] = useState<string>('')

  const debouncedSearchUsers = useMemo(
    () =>
      debounce((search: {emailIdOrPhone: string}) => {
        searchUsers(search)
      }, 400),
    [searchUsers]
  )

  return (
    <Autocomplete
      data-testid="user-search"
      fullWidth
      inputValue={search}
      value={assignee}
      loading={isLoading}
      loadingText={t('label.searchInProgress')}
      getOptionLabel={(option) => option.name ?? 'Unknown user'}
      noOptionsText={t('errors.noUsersFound')}
      filterOptions={(x) => x}
      onInputChange={(e, value, reason) => {
        setSearch(value)
        if (reason === 'reset') {
          return
        }
        value && debouncedSearchUsers({emailIdOrPhone: value})
      }}
      onChange={(e, data) => {
        onChange(data)
        if (clearInputOnChange) {
          setSearch('')
        }
      }}
      options={searchResults ?? []}
      componentsProps={{
        paper: {
          elevation: 12,
          sx(theme) {
            return {
              background: theme.palette.grey[50]
            }
          }
        }
      }}
      sx={
        legacyDesign
          ? {}
          : {
              '& .MuiInputBase-root': {
                height: '52px',
                borderRadius: '4px',
                background: '#fbfbfc',
                boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
                '&.Mui-disabled': {
                  background: '#fbfbfc',
                  ':hover': {
                    background: '#fbfbfc'
                  }
                }
              }
            }
      }
      renderInput={(params) =>
        legacyDesign ? (
          <TextField {...params} variant={textfieldVariant ?? 'outlined'} label={label} />
        ) : (
          <BaseTextField {...params} label={label} />
        )
      }
    />
  )
}
