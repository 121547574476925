import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowForward from '@mui/icons-material/ArrowForward'
import Close from '@mui/icons-material/Close'
import Delete from '@mui/icons-material/Delete'
import {Box, Button, CircularProgress, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Document, Page, pdfjs} from 'react-pdf'

import {TransparentButton} from '../common/TransparentButton'
import {useFileData} from '../hooks/attachments/useGetFile'
import {useRemoveAttachment} from '../hooks/attachments/useRemoveAttachment'
import {Attachment} from '../types/nodeDetail.types'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ImageDisplay: React.FC<{
  image: Attachment
  setActiveImageId: React.Dispatch<React.SetStateAction<number | undefined>>
  setNextImage: () => void
  analysisId?: string
  nodeId: number
  filesCount: number
  canDeleteAttachment: boolean
  taskId?: string
}> = ({
  image,
  setActiveImageId,
  setNextImage,
  nodeId,
  analysisId,
  filesCount,
  canDeleteAttachment,
  taskId
}) => {
  const [src, setSrc] = useState<string | null>(null)
  const {t} = useTranslation()
  const {data, isFetching, error} = useFileData(image.id, image.url, image.contentType)
  const {mutate} = useRemoveAttachment(analysisId, nodeId, taskId)
  const mimeImage = image.contentType.indexOf('image')
  const mimePdf = image.contentType.indexOf('pdf')
  const [numPages, setNumPages] = useState<number | null>(null)

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages as number)
  }

  useEffect(() => {
    if (data && mimeImage >= 0) {
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      setSrc(url)
    } else if (data && mimePdf >= 0) {
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      setSrc(url)
    } else if (error) {
      console.error(
        `Downloading the image ${JSON.stringify(image)} failed with error ${
          error?.message ?? error
        }.`
      )
    }
  }, [data, isFetching])

  const download = () => {
    if (src) {
      const tempLink = document.createElement('a')
      tempLink.href = src
      tempLink.setAttribute('download', image.fileName)
      tempLink.click()
    }
  }

  const nextImage = () => {
    setSrc(null)
    setNextImage()
  }

  const deleteAttachment = () => {
    mutate(image.id)
    setActiveImageId(undefined)
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          {filesCount > 1 && (
            <TransparentButton
              color="primary"
              startIcon={<ArrowForward />}
              onClick={nextImage}
              data-test-id="download-image-detail-button"
              customSx={{
                position: 'absolute',
                right: 30,
                top: '48%',
                background: 'rgba(255, 255, 255, 0.05)'
              }}
            >
              {t('buttons.next')}
            </TransparentButton>
          )}
          <TransparentButton
            color="primary"
            startIcon={<Close />}
            data-test-id="download-image-detail-button"
            onClick={() => setActiveImageId(undefined)}
            customSx={{
              position: 'absolute',
              right: 30,
              top: 200,
              background: 'rgba(255, 255, 255, 0.05)'
            }}
          >
            {t('buttons.close')}
          </TransparentButton>
          <div>
            {src && mimeImage >= 0 && (
              <Box
                component="img"
                src={src}
                alt={image.fileName}
                my={2}
                sx={{
                  maxHeight: '60vh',
                  display: 'block',
                  maxWidth: '70vw'
                }}
              />
            )}
            {src && mimePdf >= 0 && (
              <Box
                component={Document}
                onLoadSuccess={onDocumentLoadSuccess}
                file={src}
                sx={{
                  maxHeight: '60vh',
                  display: 'block',
                  maxWidth: '70vw',
                  marginY: 2,
                  overflowY: 'scroll'
                }}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Box>
            )}
            {error?.message && (
              <Box
                p={2}
                border={2}
                my={2}
                sx={(theme) => ({
                  fontWeight: 'bold',
                  textAlign: 'center',
                  borderColor: theme.palette.error.main
                })}
              >
                {t('errors.cannotGetImageDueToError')}
                <br />
                {error.message}
              </Box>
            )}
            <Box sx={{display: 'flex'}}>
              <Box mr={2}>
                <Typography>{image.fileName}</Typography>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'right'}}>
                <TransparentButton
                  color="primary"
                  startIcon={<ArrowDownward />}
                  onClick={download}
                  data-test-id="download-image-detail-button"
                  customSx={{
                    background: 'rgba(255, 255, 255, 0.05)'
                  }}
                >
                  {t('buttons.download')}
                </TransparentButton>

                {canDeleteAttachment && (
                  <Button
                    color="error"
                    variant="contained"
                    startIcon={<Delete />}
                    onClick={() => deleteAttachment()}
                    data-test-id="remove-image-detail-button"
                    sx={{
                      marginLeft: 1
                    }}
                  >
                    {t('buttons.delete')}
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        </>
      )}
    </Box>
  )
}

export const ImageDetail: React.FC<{
  files: Array<Attachment>
  activeId?: number
  setActiveImageId: React.Dispatch<React.SetStateAction<number | undefined>>
  analysisId?: string
  nodeId: number
  canDeleteAttachment: boolean
  taskId?: string
}> = ({files, activeId, setActiveImageId, nodeId, analysisId, canDeleteAttachment, taskId}) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    setActiveImageIndex(files.findIndex((i) => i.id === activeId))
  }, [files, activeId])

  const setNextImage = () => {
    const nextIndex = activeImageIndex ? activeImageIndex + 1 : 1
    const nextActiveIndex = nextIndex > files.length - 1 ? 0 : nextIndex
    setActiveImageIndex(nextActiveIndex)
  }

  return activeImageIndex !== undefined && activeImageIndex >= 0 ? (
    <ImageDisplay
      filesCount={files.length}
      image={files[activeImageIndex]}
      setActiveImageId={setActiveImageId}
      setNextImage={setNextImage}
      nodeId={nodeId}
      analysisId={analysisId}
      canDeleteAttachment={canDeleteAttachment}
      taskId={taskId}
    />
  ) : (
    <Box />
  )
}
