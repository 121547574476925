import {buttonOnBlueSx, mergeSxs} from '@hconnect/uikit/src/lib2'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration403.svg'
import CloseIcon from '@mui/icons-material/Close'
import {Button, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useLocation} from 'react-router-dom'

import {RequestAccessButton} from '../RequestAccessButton'

export const ErrorPrivateAnalysis = ({onGoBack}: {onGoBack: () => void}) => {
  const {t} = useTranslation()
  const location = useLocation()

  const analysisId = location?.pathname?.split('/').at(-2)
  if (!analysisId) throw new Error('Illegal route: analysisId is required')

  return (
    <BaseErrorPage
      caption={t('privateAnalysis.title')}
      description={t('privateAnalysis.text')}
      illustration={<Illustration />}
    >
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => onGoBack()}
          sx={mergeSxs(buttonOnBlueSx, {height: 48})}
          startIcon={<CloseIcon />}
        >
          {t('buttons.cancel')}
        </Button>
        <RequestAccessButton analysisId={analysisId} successCb={onGoBack} />
      </Stack>
    </BaseErrorPage>
  )
}
