import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionActions,
  Button,
  AccordionDetails,
  Skeleton,
  Stack
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetSuggestions} from '../../hooks/useGetSuggestions'

export const AiSuggestions = ({
  nodeTitle,
  selectedSuggestions,
  exclusive,
  onSelected,
  onExpand
}: {
  nodeTitle: string
  selectedSuggestions: string[]
  exclusive: boolean
  onSelected: ({value, checked}: {value: string; checked: boolean}) => void
  onExpand: () => void
}): JSX.Element => {
  const [showAll, setShowAll] = useState<boolean>(false)
  const {data: suggestions, isFetching} = useGetSuggestions(nodeTitle)
  const {t} = useTranslation()

  const suggestionDisabled = (suggestion: string) => {
    return exclusive && selectedSuggestions.length > 0 && !selectedSuggestions.includes(suggestion)
  }

  return (
    <Accordion
      onChange={onExpand}
      sx={{
        borderRadius: '8px',
        border: '1px solid #EEDEFA',
        background: 'linear-gradient(91.45deg, #F9F4FD 0%, #F4E9FC 100%)'
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            order: -1
          },
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }
        }}
      >
        <AutoAwesomeOutlinedIcon sx={{fontSize: 17}} />
        <Typography variant="h4">{t('ai.suggestionsTitle')}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          background: 'linear-gradient(91.45deg, #F9F4FD 0%, #F4E9FC 100%)'
        }}
      >
        {isFetching &&
          Array.from({length: 5}).map((_, index) => (
            <Stack direction="row" spacing={1} key={index}>
              <Skeleton variant="rectangular" height={16} width={16} sx={{borderRadius: '2px'}} />
              <Skeleton
                variant="rectangular"
                height={16}
                width={Math.random() * (250 - 160) + 160}
                sx={{borderRadius: '3px'}}
              />
            </Stack>
          ))}
        {!isFetching && suggestions?.length && (
          <FormControl>
            <FormGroup onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelected(e.target)}>
              {suggestions.slice(0, showAll ? suggestions.length : 5).map((suggestion) => (
                <FormControlLabel
                  control={<Checkbox disabled={suggestionDisabled(suggestion)} />}
                  value={suggestion}
                  label={suggestion}
                  slotProps={{typography: {variant: 'h5'}}}
                  key={suggestion}
                  checked={selectedSuggestions?.includes(suggestion)}
                  disabled={suggestionDisabled(suggestion)}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </AccordionDetails>
      {!showAll && suggestions?.length && suggestions?.length > 5 && (
        <AccordionActions sx={{display: 'flex', justifyContent: 'center'}}>
          <Button
            variant="text"
            startIcon={<AddToPhotosOutlinedIcon />}
            onClick={() => setShowAll(true)}
          >
            {t('buttons.loadMore')}
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  )
}
