import {Box} from '@mui/material'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

export const TableCellNavigate = ({children, to, ...rest}) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box
      onClick={() => {
        navigate(to, {state: location})
      }}
      sx={{
        height: '100%',
        width: '100%'
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}
