import {AnalysisStatus} from '@hconnect/common/types'
import {Loader} from '@hconnect/uikit/src/lib2'
import {Close, East} from '@mui/icons-material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Box,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Drawer,
  Modal,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams, useParams} from 'react-router-dom'

import {SummaryNode} from '../../components/analysisSummary/SummaryNode'
import {SummaryNodeGroup} from '../../components/analysisSummary/SummaryNodeGroup'
import {SummaryPrintHeader} from '../../components/analysisSummary/SummaryPrintHeader'
import {SummaryStatistics} from '../../components/analysisSummary/SummaryStatistics'
import {SummaryTasksList} from '../../components/analysisSummary/SummaryTasksList'
import {useGetAnalysisPdfSummary} from '../../hooks/analysis/useGetAnalysisPdfSummary'
import {SummarySectionId} from '../../types/analysisPdfSummary.types'

export const AnalysisSummaryContent = () => {
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const externalPrint = parseInt(searchParams.get('openPrint') || '0')
  const params = useParams()
  const [open, setOpen] = React.useState(false)
  const [openPrint, setOpenPrint] = React.useState(false)
  const [printableSections, setPrintableSections] = React.useState({
    likelyCauses: true,
    images: true,
    tasks: true,
    tasksList: true,
    comments: true
  })

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const {data, isFetching} = useGetAnalysisPdfSummary(Number(params.analysisId))

  const handlePrint = () => {
    setOpenPrint(true)
  }

  useEffect(() => {
    if (!open && openPrint) {
      window.print()
    }
  }, [openPrint, open])

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  }

  const changeSelection = (event, key: string) => {
    setPrintableSections((prev) => ({...prev, [key]: event.target.checked}))
  }

  useEffect(() => {
    if (externalPrint && !isFetching) handleOpen()
  }, [externalPrint, isFetching])

  return (
    <Box display="flex" justifyContent="space-around" sx={{position: 'relative'}}>
      {isFetching && <Loader height="60vh" color="primary.contrastText" />}
      {data && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            p={3}
            gap={2}
            sx={{width: '60vw', '@media print': {width: '100%', display: 'block'}}}
          >
            {/* Fixed position header displayed only when printing */}
            <SummaryPrintHeader
              owner={data.owner}
              startDate={new Date(data.createDate)}
              updateDate={new Date(data.updateDate)}
              status={data.status as AnalysisStatus}
            />

            {/* Statistics */}
            <NavigationAnchor id="summary-statistics" />
            <SummaryStatistics
              stats={{
                totalAttachments: data.statistics.totalAttachments,
                totalLikelyCauses: data.statistics.totalLikelyCauses,
                totalRootCauses: data.statistics.totalRootCauses,
                totalTasks: data.statistics.totalTasks
              }}
              info={{
                plant: data.plant,
                country: data.country,
                category: data.category,
                equipmentName: data.equipmentName
              }}
              stoppage={data.stoppage}
            />

            {/* Root node/analysis info */}
            <NavigationAnchor id="summary-info" />
            <SummaryNode
              node={{
                title: data.title,
                attachments: data.attachments,
                id: data.id,
                description: data.description
              }}
              tasks={data.tasks}
              hideImages={!printableSections.images}
              hideTasks={!printableSections.tasks}
              hideComments={!printableSections.comments}
            />
            {/* Print pageBreak */}
            <Box sx={{pageBreakBefore: 'always'}} />
            {/* Root cause paths */}
            {data.rootCausePaths.map((nodes, i) => (
              <React.Fragment key={`summary-rootcause-path-${nodes[0].id}`}>
                <NavigationAnchor id={`summary-root-path-${nodes[0].id}`} />
                <SummaryNodeGroup
                  nodes={nodes}
                  tasks={data.tasks}
                  sectionTitle={`${t('node.detail.rootCause')} (${i + 1}/${
                    data.rootCausePaths.length
                  })`}
                  hideImages={!printableSections.images}
                  hideTasks={!printableSections.tasks}
                  hideComments={!printableSections.comments}
                />
              </React.Fragment>
            ))}

            {/* Likely causes */}
            {data.likelyCauses.filter((n) => !n.isRootCause).length > 0 && (
              <>
                {/* Print pageBreak */}
                {printableSections.likelyCauses && <Box sx={{pageBreakBefore: 'always'}} />}
                <NavigationAnchor id="summary-likely-causes" />
                <SummaryNodeGroup
                  nodes={data.likelyCauses.filter((n) => !n.isRootCause)}
                  tasks={data.tasks}
                  sectionTitle={'Likely Causes'}
                  noLines={false}
                  hideInPrint={!printableSections.likelyCauses}
                  hideImages={!printableSections.images}
                  hideTasks={!printableSections.tasks}
                  hideComments={!printableSections.comments}
                />
              </>
            )}
            {data.tasks.length > 0 && (
              <>
                {/* Print pageBreak */}
                {printableSections.tasksList && <Box sx={{pageBreakBefore: 'always'}} />}
                <NavigationAnchor id="summary-tasks-list" />
                <SummaryTasksList
                  hideInPrint={!printableSections.tasksList}
                  tasks={data.tasks}
                  sectionTitle={'Tasks list'}
                />
              </>
            )}
          </Box>
          {/* Navigation */}
          <Drawer
            sx={{
              displayPrint: 'none',
              zIndex: 0,
              width: '250px',
              '& .MuiDrawer-paper': {
                width: '250px',
                boxSizing: 'border-box',
                background: 'none',
                border: 'none'
              },
              '& .MuiDrawer-root': {
                position: 'absolute'
              },
              '& .MuiPaper-root': {
                position: 'absolute'
              }
            }}
            variant="permanent"
            anchor="right"
          >
            <List sx={{background: '#F2F2F2', borderRadius: '6px'}}>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#summary-statistics">
                  <ListItemIcon sx={{minWidth: '30px'}}>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('analysis.pdfSummary.statistics.sectionLabel')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#summary-info">
                  <ListItemIcon sx={{minWidth: '30px'}}>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Analysis Info" />
                </ListItemButton>
              </ListItem>
              {data?.rootCausePaths.map((nodes, i) => (
                <ListItem disablePadding key={`summary-root-path-${nodes[0].id}`}>
                  <ListItemButton component="a" href={`#summary-root-path-${nodes[0].id}`}>
                    <ListItemIcon sx={{minWidth: '30px'}}>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${t('node.detail.rootCause')} (${i + 1}/${
                        data.rootCausePaths.length
                      })`}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem disablePadding key={'summary-likely-causes'}>
                <ListItemButton component="a" href={'#summary-likely-causes'}>
                  <ListItemIcon sx={{minWidth: '30px'}}>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${t('node.detail.likelyCause')}`} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#summary-tasks-list">
                  <ListItemIcon sx={{minWidth: '30px'}}>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tasks list" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleOpen}
                  sx={{
                    border: '1.5px solid #016AD4',
                    borderRadius: '8px',
                    textAlign: 'center',
                    ml: 5,
                    mr: 5
                  }}
                >
                  <ListItemText primary={t('analysis.list.actions.openPrint')} />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="anaysis-summary-print-modal"
            aria-describedby="anaysis-summary-print-modal"
          >
            <Box sx={modalStyle}>
              <Typography variant={'h4'} mb={2}>
                {t('analysis.pdfSummary.modal.title')}
              </Typography>
              <Typography mb={2}>{t('analysis.pdfSummary.modal.description')}</Typography>
              <FormGroup>
                {Object.keys(printableSections).map((key) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={printableSections[key]}
                        onClick={(e) => changeSelection(e, key)}
                      />
                    }
                    label={
                      <Typography fontWeight={500}>
                        {t(`analysis.pdfSummary.checkboxes.${key}`)}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  startIcon={<Close />}
                  onClick={handleClose}
                  variant={'text'}
                  sx={{marginRight: 1}}
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<East />}
                  variant={'contained'}
                  onClick={() => {
                    handleClose()
                    handlePrint()
                  }}
                >
                  {t('analysis.list.actions.openPrint')}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  )
}

const NavigationAnchor: React.FC<{id: SummarySectionId}> = ({id}) => {
  return <span id={id} style={{scrollMarginTop: '75px'}} />
}
