import {List, ListItem, ListItemButton, ListItemText, Skeleton} from '@mui/material'
import React from 'react'

import {TaskStatus} from '../../components/TaskStatus'
import {useNodeTasks} from '../../hooks/tasks/useNodeTasks'

export const NodeTasks: React.FC<{
  nodeId: number
  onTaskClick: (taskId: string) => void
  expectedCount?: number
}> = ({nodeId, onTaskClick, expectedCount}) => {
  const {data, isFetching} = useNodeTasks(nodeId.toString())

  if (isFetching && !!expectedCount) {
    return (
      <List disablePadding>
        {Array(expectedCount)
          .fill(0)
          .map((_, index) => (
            <ListItem
              key={`skeleton-${index}`}
              disablePadding
              divider={index < expectedCount - 1}
              sx={{display: 'flex', justifyContent: 'space-between'}}
            >
              <Skeleton height={46} width={250} />
              <Skeleton height={46} width={75} />
            </ListItem>
          ))}
      </List>
    )
  }

  return (
    <>
      {data && data.data.length > 0 && (
        <List disablePadding>
          {data.data.map((task, index) => (
            <ListItem key={`task-${task.id}`} disablePadding divider={index < data.data.length - 1}>
              <ListItemButton onClick={() => onTaskClick(task.id)} sx={{pl: 0, pr: 1}}>
                <ListItemText primary={task.title} primaryTypographyProps={{fontWeight: 500}} />
                <TaskStatus status={task.status} containerSx={{justifyContent: 'flex-end'}} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  )
}
