import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Stack, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {nodeDescriptionValidation, nodeTitleValidation, validateAll} from '../../common/validator'
import {AnalysisDescriptionTooltip} from '../../components/AnalysisDescriptionTooltip'
import {NodeUpdateData, useUpdateNode} from '../../hooks/node/useUpdateNode'
import {File, NodeDetailType} from '../../types/nodeDetail.types'

import {NodeAttachments} from './nodeForm/NodeAttachments'

export const EditNode: React.FC<{
  node: NodeDetailType
  closeForm: () => void
}> = ({node, closeForm}) => {
  const {t} = useTranslation()
  const {data, mutate, isPending: isLoading} = useUpdateNode()
  const [files, setFiles] = useState<Array<File>>([])

  const [item, setItem] = useState<Omit<NodeUpdateData, 'order' | 'parent' | 'newAttachments'>>({
    id: node.id,
    title: node.title,
    description: node.description ?? ''
  })

  const clearModal = () => {
    setItem(node)
    setValidationError(new Map())
    closeForm()
  }

  useEffect(() => {
    if (data?.selectedNodeId !== undefined && !isLoading) {
      clearModal()
    }
  }, [data, isLoading])

  const [validationError, setValidationError] = useState(new Map())

  const validatorConfig = new Map([
    ['description', nodeDescriptionValidation],
    ['title', nodeTitleValidation]
  ])

  const getErrorText = (key: string) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const doSubmit = () => {
    const validationError = validateAll(validatorConfig, item)
    setValidationError(validationError)
    if (validationError.size === 0) {
      mutate({
        id: node.id,
        order: node.order ? node.order : 0,
        parent: node.parent ? node.parent : 0,
        description: item?.description ?? '',
        title: item?.title ?? '',
        newAttachments: files
      })
    }
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['clean']
    ]
  }

  return (
    <Stack spacing={2} pr={1}>
      <Stack>
        <TextField
          data-test-id="node-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('label.title')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem({...item, title: event.target.value})
          }
          defaultValue={node.title}
          required
          disabled={!node.permissions.canEdit}
        />
        {node.permissions.canEdit && (
          <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
            {item.title?.length ? item.title.length : 0}/{80}
          </Box>
        )}
      </Stack>
      <Stack>
        {node.permissions.canEdit ? (
          <>
            <AnalysisDescriptionTooltip top={30} left={390} />
            <ReactQuill
              modules={modules}
              theme={'snow'}
              defaultValue={item.description}
              onChange={(next) => setItem({...item, description: next})}
              placeholder={`${t('label.description')} *`}
              formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
            />
            {validationError.has('description') && (
              <Box ml={1} sx={{float: 'left'}}>
                <Typography sx={(theme) => ({color: theme.palette.error.light, fontSize: 12})}>
                  {t(getErrorText('description'))}
                </Typography>
              </Box>
            )}
            <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
              {item.description?.length
                ? item.description.replaceAll(/(<([^>]+)>)/gi, '').length
                : 0}
              /{5000}
            </Box>
          </>
        ) : (
          <ReactQuill value={node.description} readOnly={true} theme={'bubble'} />
        )}
      </Stack>
      <NodeAttachments files={files} setFiles={setFiles} />
      <Box sx={{display: 'flex', justifyContent: 'right', gap: 1}}>
        <Button
          startIcon={<Close />}
          variant="outlined"
          color="secondary"
          onClick={clearModal}
          data-test-id="node-form-cancel-button"
        >
          {t('buttons.cancel')}
        </Button>
        <LoadingButton
          startIcon={<Check />}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          data-test-id="node-form-save-button"
          loading={isLoading}
          loadingPosition="start"
        >
          <span>{t('buttons.save')}</span>
        </LoadingButton>
      </Box>
    </Stack>
  )
}
