import SortIcon from '@mui/icons-material/Sort'
import {Box, SxProps, Theme} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

type ReturnIcon = (customSx?: SxProps<Theme>) => JSX.Element | undefined

type OrderType = 'asc' | 'desc' | 'none'

type OrderState = {
  getIcon: ReturnIcon
  next: OrderType
}

const ORDER_STATES: {[K in OrderType]: OrderState} = {
  none: {
    getIcon: (customSx) => <SortIcon sx={customSx} style={{color: 'transparent'}} />,
    next: 'asc'
  },
  asc: {
    getIcon: (customSx) => <SortIcon sx={customSx} style={{transform: 'scaleY(-1)'}} />,
    next: 'desc'
  },
  desc: {
    getIcon: (customSx) => <SortIcon sx={customSx} />,
    next: 'none'
  }
}

interface SortableListHeaderProps {
  labelTranslationKey: string
  sortable: boolean
  id: string
  currentDesc: boolean | undefined
  handleOrderToggle?: (col: any) => void
  padding?: string | number
}

export const SortableListHeader: React.FC<SortableListHeaderProps> = ({
  sortable,
  handleOrderToggle,
  id,
  currentDesc,
  labelTranslationKey,
  padding = '8px 16px'
}) => {
  const [hover, setHover] = useState(false)
  const currentOrder = currentDesc === undefined ? 'none' : currentDesc ? 'desc' : 'asc'

  const currentState = ORDER_STATES[currentOrder]
  const nextState = ORDER_STATES[currentState.next]

  const {t} = useTranslation()
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      alignItems="center"
      style={{padding: padding, cursor: sortable ? 'pointer' : 'default'}}
      onClick={sortable && handleOrderToggle ? () => handleOrderToggle(id) : undefined}
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
      data-test-id={`sortable-list-header-${id}`}
    >
      {t(labelTranslationKey)}
      {sortable && !hover && currentState.getIcon()}
      {sortable && hover && nextState.getIcon({opacity: 0.4})}
    </Box>
  )
}
