import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {RootCauseType} from '../../types/nodeDetail.types'

export type ResponseType = {
  id: number
  title: string
  description: string
  rootCauseType?: RootCauseType
}

type RootCausePatchData = {
  rootCauseType: RootCauseType | null
  title: string
  description: string
}

export const usePatchRootCause = (analysisId: number, nodeId: number) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (rootCauseData: RootCausePatchData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}`

      return (await axiosInstance.patch<ResponseType>(path, rootCauseData, {})).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['node-detail']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
