import {AssetPartSignal} from '@hconnect/common/components/kmsStatus/types'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert
} from '@mui/material'
import {useState} from 'react'

import {SignalAutocomplete} from './SignalAutocomplete'

export type HealthIndicatorsModalData = {
  label: string
  availableSignals: AssetPartSignal[]
  selectedSignals: AssetPartSignal[]
  onSubmit: (signals: AssetPartSignal[]) => void
}

export type HealthIndicatorSignalsModalProps = {
  handleClose: () => void
  healthIndicatorsModalData: HealthIndicatorsModalData
}

export const HealthIndicatorSignalsModal = ({
  handleClose,
  healthIndicatorsModalData: {label, availableSignals, selectedSignals, onSubmit}
}: HealthIndicatorSignalsModalProps) => {
  const [signals, setSignals] = useState<AssetPartSignal[]>(selectedSignals)

  const doSubmit = () => {
    onSubmit(signals)
    handleClose()
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle component="div" flexDirection="column" display="flex">
        <Typography variant="h3">{label}</Typography>
      </DialogTitle>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <Alert severity="warning" sx={{width: '100%'}}>
          Only signals associated with the corresponding kiln part are available.
        </Alert>
        <SignalAutocomplete
          label={label}
          value={signals}
          onChange={(newSignals) => setSignals(newSignals)}
          options={availableSignals.map((signal) => ({
            id: signal.id,
            label: signal.id,
            description: signal.description
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button startIcon={<Close />} variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<Check />}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          sx={{marginLeft: 1}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
