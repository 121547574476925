import {SignalData} from '@hconnect/common/components/kmsStatus/types'
import {Box, Typography} from '@mui/material'

import {getSensorGroupString} from '../utils/sensor.utils'

interface SensorTextBoxProps {
  sensor?: SignalData
}

export const SensorTextBox = ({sensor}: SensorTextBoxProps) => {
  return sensor ? (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5" sx={{alignSelf: 'flex-start'}}>
        {`${sensor.localName} ${sensor.description}`}
      </Typography>
      <Typography variant="caption" sx={{alignSelf: 'flex-start'}}>
        {getSensorGroupString(sensor)}
      </Typography>
    </Box>
  ) : null
}
