import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import {styled} from '@mui/system'
import {SyntheticEvent, useMemo, useState} from 'react'

import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {useGetUpmSignals} from '../../../shared/hooks/useGetUpmSignals'

import {useConnectedSignals} from './hooks/useConnectedSignals'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'
import {buildLocations, getKilnPartSvg} from './SignalsModal.helpers'
import {SignalsModalData} from './SignalsModal.types'

export type SignalsModalProps = {
  kilnId: string
  handleClose: () => void
  signalsModalData: SignalsModalData
}

const StyledPill = styled(Box)(() => ({
  display: 'block',
  boxSizing: 'content-box',
  borderRadius: '50%',
  width: 16,
  height: 16,
  padding: 4,
  background: '#F7F9FA',
  color: '#52667A',
  border: '1px solid #E8ECF0',
  textAlign: 'center',
  fontSize: 12,
  lineHeight: '16px'
}))

export const SignalsModal = ({handleClose, signalsModalData}: SignalsModalProps) => {
  const plantId = useUrlParam('plantId')
  const {data} = useGetUpmSignals({entityId: plantId, entity: 'plant'})
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()

  const [selectedSignals, setSelectedSignals] = useState(kilnLayout.selectedSignals ?? {})

  const {layout, type} = signalsModalData
  const kilnPartId = layout.id

  const connectedSignals = useConnectedSignals({kilnPartId, selectedSignals})

  const locations = useMemo(
    () => buildLocations({id: kilnPartId, signalsModalData}),
    [kilnPartId, signalsModalData]
  )

  const disabledSignals: string[] = useMemo(
    () =>
      Object.values(selectedSignals)
        .flatMap((signals: any[]) => signals.map((signal) => signal.id))
        .filter(Boolean),
    [selectedSignals]
  )

  const doSubmit = () => {
    dispatch({type: LayoutActionType.SET_SELECTED_SIGNALS, payload: selectedSignals})
    handleClose()
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle component="div" flexDirection="column" display="flex">
        <Typography variant="h3">{layout?.name ?? kilnPartId}</Typography>
        <Typography variant="caption">{connectedSignals.length} signal(s) connected</Typography>
      </DialogTitle>
      <DialogContent
        sx={{display: 'flex', gap: 4, flexDirection: type === 'kiln-shell' ? 'column' : 'row'}}
      >
        <Box flexShrink={0}>{getKilnPartSvg(signalsModalData)}</Box>
        <Box
          flexGrow={1}
          sx={{
            overflow: 'auto'
          }}
          maxHeight="60vh"
          display="flex"
          flexDirection="column"
          gap={1.5}
        >
          {locations.map((location) => (
            <Box key={location.id} display="flex" gap={1} alignItems="center">
              <StyledPill>{location.locationNumber}</StyledPill>
              <Autocomplete
                id={location.id}
                options={
                  data?.map((d) => ({
                    id: d.localName,
                    label: d.localName,
                    description: d.description
                  })) ?? []
                }
                fullWidth
                multiple
                value={selectedSignals[location.id] ?? []}
                onChange={(
                  _: SyntheticEvent,
                  newValue: {id: string; label: string; description: string}[]
                ) => {
                  setSelectedSignals({...selectedSignals, [location.id]: newValue})
                }}
                getOptionDisabled={(option) => disabledSignals.includes(option.id)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        padding: 1.5,
                        flexGrow: 1,
                        '& span': {
                          color: '#586069'
                        }
                      }}
                    >
                      {option.label}
                      <br />
                      <span>{option.description}</span>
                    </Box>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={location.label}
                    variant="filled"
                    sx={{
                      '& .MuiFilledInput-root': {
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent'
                        },
                        '&.Mui-focused': {
                          backgroundColor: 'transparent'
                        }
                      }
                    }}
                  />
                )}
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<Close />} variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<Check />}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          sx={{marginLeft: 1}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
