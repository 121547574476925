import {useQueryClient} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {CreateNodeType} from '../../types/nodeDetail.types'

import {useCreateNode} from './useCreateNode'

export const useBulkCreateNodes = () => {
  const {t} = useTranslation()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const queryClient = useQueryClient()

  // onSuccess callback MUST be provided to override queries invalidation after each step
  const {mutateAsync: createNodeAsync} = useCreateNode({
    onError: (data, variables) => {
      enqueueSnackbar(`${t('errors.genericActionError')}: '${variables.Title}'`, {
        variant: 'error'
      })
    },
    onSuccess: (data, variables) => {
      enqueueSnackbar(`${t('node.createdMessage')}: '${variables.Title}'`, {
        variant: 'success'
      })
    }
  })

  const bulkCreateNodes = useCallback(
    async ({
      titles,
      parentId,
      onFinish
    }: {
      titles: string[]
      parentId: number
      onFinish: () => void
    }) => {
      setInProgress(true)
      for (const title of titles) {
        try {
          const addData: CreateNodeType = {
            Parent: parentId,
            Title: title,
            Description: title,
            NewAttachments: [],
            CreationMethod: 'createdByAIBulk'
          }
          await createNodeAsync(addData)
        } catch {
          /* PASS */
        }
      }
      await queryClient.invalidateQueries()
      setInProgress(false)
      onFinish?.()
    },
    [createNodeAsync, queryClient]
  )

  return {bulkCreateNodes, inProgress}
}
