import ArrowForward from '@mui/icons-material/ArrowForward'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import {Button, Stack, Typography} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePatchAnalysis} from '../../hooks/analysis/usePatchAnalysis'
import {UserInfo} from '../../types/user.types'

import {AssigneeDetail} from './AssigneeDetail'
import {RcfaUserSearch} from './RcfaUserSearch'

type ManageContributorsProps = {
  analysisId: number
  contributors: UserInfo[]
  canEdit: boolean
  owner?: UserInfo
  onSucessCb?: () => void
  skipAction?: () => void
}

export const ManageContributors = ({
  analysisId,
  contributors,
  owner,
  canEdit,
  onSucessCb,
  skipAction
}: ManageContributorsProps) => {
  const {t} = useTranslation()
  const [newContributors, setNewContributors] = useState<UserInfo[]>([])
  const {mutate, isPending: isLoading} = usePatchAnalysis()

  const submitDisabled = !newContributors.length
  const showSkipButton = skipAction !== undefined

  const addContributors = () => {
    if (!newContributors?.length) return
    mutate(
      {
        Id: analysisId,
        contributorsToAdd: newContributors.map((contributor) => contributor.id)
      },
      {
        onSuccess: () => {
          setNewContributors([])
          onSucessCb?.()
        }
      }
    )
  }

  const removeContributor = (id: string) => {
    mutate({
      Id: analysisId,
      contributorsToRemove: [id]
    })
  }

  const handleContributorChange = (newAssignee: UserInfo | null) => {
    if (!newAssignee) return
    if (contributors.some((contributor) => contributor.id === newAssignee.id)) return
    if (newContributors.some((contributor) => contributor.id === newAssignee.id)) return
    if (owner?.id === newAssignee.id) return

    setNewContributors((prev) => {
      return [...prev, newAssignee]
    })
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
        <Typography variant="h4">{t('cards.contributorsList')}</Typography>
      </Stack>
      {contributors.map((contributor) => (
        <AssigneeDetail
          key={`contributor-${contributor.id}`}
          assignee={contributor}
          removeAction={canEdit ? () => removeContributor(contributor.id) : undefined}
        />
      ))}
      {newContributors.map((contributor) => (
        <AssigneeDetail
          key={`contributor-${contributor.id}`}
          assignee={contributor}
          committed={false}
          removeAction={
            canEdit
              ? () => setNewContributors((prev) => prev.filter((c) => c.id !== contributor.id))
              : undefined
          }
        />
      ))}
      {canEdit && (
        <>
          <RcfaUserSearch
            label={t('label.addContributorsLabel')}
            assignee={null}
            clearInputOnChange
            onChange={handleContributorChange}
          />
          <Stack
            direction="row"
            gap={2}
            sx={{
              alignSelf: 'flex-end'
            }}
          >
            {showSkipButton && (
              <Button
                color="secondary"
                variant="outlined"
                endIcon={<ArrowForward />}
                onClick={skipAction}
                data-test-id="contributors-skip-button"
                sx={{
                  width: 'fit-content',
                  minWidth: 100,
                  height: 48,
                  alignSelf: 'flex-end',
                  border: '1px solid #00274D1A',
                  boxShadow: '0px 2px 2px 0px #00000014'
                }}
              >
                {t('buttons.skip')}
              </Button>
            )}

            <LoadingButton
              data-testid="contributors-invite-button"
              startIcon={<PersonAddOutlinedIcon />}
              color="secondary"
              variant="outlined"
              onClick={addContributors}
              loading={isLoading}
              loadingPosition="start"
              disabled={submitDisabled}
              sx={{
                width: 'fit-content',
                minWidth: 100,
                height: 48,
                alignSelf: 'flex-end',
                border: '1px solid #00274D1A',
                boxShadow: '0px 2px 2px 0px #00000014'
              }}
            >
              <span>{t('buttons.invite')}</span>
            </LoadingButton>
          </Stack>
        </>
      )}
    </Stack>
  )
}
