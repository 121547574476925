import {BoltOutlined} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import InsertDriveFile from '@mui/icons-material/InsertDriveFileOutlined'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import {Fade, Menu, MenuItem, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {REL_ROUTES} from '../routes'

export const NewAnalysisButton: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
        color="primary"
        variant="contained"
        data-test-id={'analysis-add-button'}
        id={'analysis-add-button'}
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{position: 'absolute', right: '15px', top: '12px'}}
      >
        {t('buttons.createAnalysis')}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        style={{
          marginTop: 3
        }}
      >
        <MenuItem
          onClick={() => navigate(`../${REL_ROUTES.newAnalysis}?type=blank`)}
          sx={{height: 50}}
          data-test-id="blank-analysis-create-button"
        >
          <InsertDriveFile sx={{marginRight: 1}} />
          {t('analysis.new.blank')}
        </MenuItem>
        <MenuItem
          onClick={() => navigate(`../${REL_ROUTES.stoppageList}`)}
          sx={{height: 50}}
          data-test-id="cockpit-analysis-create-button"
          id="cockpit-analysis-create-button"
        >
          <BoltOutlined sx={{height: 50}} />
          {t('analysis.new.fromStoppage')}
        </MenuItem>
      </Menu>
    </>
  )
}
