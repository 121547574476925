import {useLocation, useNavigate} from 'react-router'

import {ErrorDeletedAnalysis} from '../components/errors/ErrorDeletedAnalysis'
import {ErrorPrivateAnalysis} from '../components/errors/ErrorPrivateAnalysis'
import {REL_ROUTES} from '../routes'

// Handle RCFA specific errors here otherwise rethrow to Maintain Global Error Boundary
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RcfaErrorFallback = ({error, resetErrorBoundary}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    const locationState = location?.state
    if (locationState?.pathname) {
      navigate(`${locationState.pathname}${locationState?.search}`, {replace: true})
    } else {
      navigate(REL_ROUTES.analysisList, {replace: true})
    }
  }

  // Private analysis
  if (error?.response?.status === 403 && error?.response?.data?.detail?.includes('Private')) {
    return <ErrorPrivateAnalysis onGoBack={goBack} />
  }
  // Deleted analysis
  if (error?.response?.status === 404 && error?.response?.data?.detail?.includes('is deleted')) {
    return <ErrorDeletedAnalysis onGoBack={goBack} />
  }

  throw error
}
