export const primaryColor = '#018AE6'
export const activeBackground = 'rgba(1, 138, 230, 0.08)'
export const activeBorder = 'rgba(1, 138, 230, 0.5)'
export const defaultNodeBackground = '#FFF'
export const likelyColor = '#00BD62'
export const unLikelyColor = '#DA0901'
export const hoverColor = '#f5f9ff'
export const canvasBackgroundColor = '#E6EAED'
export const rootCauseStrokeColor = '#00274D'
export const defaultStrokeColor = '#54708C'
