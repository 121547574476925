import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import {Box, Stack, Typography} from '@mui/material'
import moment from 'moment'
import React from 'react'
import ReactQuill from 'react-quill'

import {stripHtmlTags} from '../../../shared/utils/strings'
import {SummaryNodeTaskType, SummaryNodeType} from '../../types/analysisPdfSummary.types'
import {TaskStatuses} from '../../types/nodeDetail.types'
import {TaskStatus} from '../TaskStatus'

import {SummaryAttachments} from './SummaryAttachments'

const TAGS_WHITELIST: string[] = ['ol', 'li', 'br', 'ul', 'p', 'span']

export const SummaryNode: React.FC<{
  node: SummaryNodeType
  tasks: SummaryNodeTaskType[]
  hideImages?: boolean
  hideTasks?: boolean
  hideComments?: boolean
}> = ({node, tasks, hideImages, hideTasks, hideComments}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      displayPrint="block"
      sx={{minHeight: '25px', width: '100%', pb: 3}}
    >
      <Typography variant="h4" mb={1}>
        {node.title}
      </Typography>
      <ReactQuill
        value={stripHtmlTags(node.description, TAGS_WHITELIST)}
        readOnly={true}
        theme={'bubble'}
        style={{height: 'fit-content'}}
      />
      {tasks
        .filter((t) => t.nodeId === node.id)
        .map((task) => (
          <Box
            key={task.id}
            display="flex"
            flexDirection="column"
            gap={1}
            mb={3}
            displayPrint={hideTasks ? 'none' : 'block'}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <AssignmentOutlinedIcon />
                <Typography variant="body1" style={{fontWeight: 600}}>
                  {task.title}
                </Typography>
              </Box>
              <TaskStatus status={task.status as TaskStatuses} />
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="body1">
                Assigned to: {task.assignee ? task.assignee.name : 'Not assigned'}
              </Typography>
              <Typography variant="body1">
                {task.dueDate ? moment(task.dueDate).format('L') : '-'}
              </Typography>
            </Box>

            <Typography variant="body1">{task.description}</Typography>
          </Box>
        ))}
      {node.comments && node.comments.length > 0 && (
        <Box displayPrint={hideComments ? 'none' : 'block'}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <ChatBubbleOutlineIcon />
              <Typography variant="body1" style={{fontWeight: 600}}>
                Comments
              </Typography>
            </Box>
          </Box>
          {node.comments.map((comment) => (
            <Stack direction="row" justifyContent="space-between" key={comment.id} mb={2}>
              <Stack>
                <Typography sx={{fontWeight: 400, fontSize: '12px', lineHeight: '16px'}}>
                  {comment.text}
                </Typography>
                <Typography sx={{fontWeight: 400, fontSize: '10px', lineHeight: '16px'}}>
                  {comment.createdBy.name} on{' '}
                  {moment(comment.createDate).format('DD.MM.YYYY hh:mm')}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Box>
      )}

      <SummaryAttachments attachments={node.attachments} hideImages={hideImages} />
    </Box>
  )
}
