import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {GraphLevel} from '../../types/canvasNodes.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'delete-node'

export const useDeleteNode = (analysisId: string | undefined) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (nodeId: number) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}`
      return (await axiosInstance.delete<ResponseType>(path, {})).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
