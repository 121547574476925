import React from 'react'

import {CanvasMode, Node} from '../../types/canvasNodes.types'

export interface RootCauseLinesProps {
  from: Node
  to: Node
  index: number
  canvasMode: CanvasMode
  setParentHover: Function
  collapseDescendants: (Node) => void
}

export const RootCauseLines: React.FC<RootCauseLinesProps> = ({
  from,
  to,
  index,
  setParentHover,
  canvasMode,
  collapseDescendants
}) => {
  const isRootCausePath =
    from.isOnPathToRootCauseFrom &&
    from.isOnPathToRootCauseFrom?.length > 0 &&
    to.isOnPathToRootCauseFrom &&
    to.isOnPathToRootCauseFrom?.length > 0

  return isRootCausePath ? (
    <use
      id={`use-root-cause-line-${index}`}
      xlinkHref={`#root-cause-line-${index}`}
      onMouseOver={() => canvasMode === CanvasMode.default && setParentHover(from)}
      onMouseLeave={() => setParentHover(null)}
      onDoubleClick={collapseDescendants}
    />
  ) : (
    <></>
  )
}
