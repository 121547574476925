import {HttpError} from '@hconnect/common/types'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../../../shared/hooks/useApi'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {AssetConfigurationDto} from '../KilnConfig.types'

export const QueryKey = 'asset-config'

export const useGetAssetConfig = (assetId: string) => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')
  // const plantCode = useUrlParam('plantCode')
  return useQuery<AssetConfigurationDto, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantId, assetId],
    queryFn: async () => {
      const path = `asset-config/kms-data/plant/${plantId}?upmType=Department&typeUpmId=${assetId}`
      return (await axiosInstance.get<AssetConfigurationDto>(path)).data
    },
    // if config not created yet, we will get 404
    throwOnError: (error) => error?.response?.status !== 404,
    retry: (retryCount, error) => error?.response?.status !== 404 && retryCount < 3,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })
}
