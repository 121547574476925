import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {TaskGroup} from '../../types/nodeDetail.types'

export type ResponseType = {
  canManageActions: boolean
  data: Array<TaskGroup>
}

export const QueryKey = 'analysis-tasks'

export const useAnalysisTasks = (
  analysisId: string | undefined,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, analysisId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tasks?pageSize=100`
      return (await axiosInstance.get<ResponseType>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
