import ThumbDownAltOutlined from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpAltOutlined from '@mui/icons-material/ThumbUpOffAlt'
import {Tooltip, Box, Button, styled} from '@mui/material'
import React from 'react'

import {useCreateVote} from '../../hooks/votes/useCreateVote'
import {useDeleteVote} from '../../hooks/votes/useDeleteVote'
import {usePatchVote} from '../../hooks/votes/usePatchVote'
import {Vote, VoteList, NodePermissions} from '../../types/nodeDetail.types'

export const NodeVotes: React.FC<{
  voteList: VoteList
  analysisId: string | undefined
  nodeId: number
  permissions: NodePermissions
}> = ({voteList, analysisId, nodeId, permissions}) => {
  const myVote = voteList?.votes.find((v) => v.isMyVote)
  const myVoteIsLikely = myVote?.vote === Vote.likely
  const myVoteIsUnlikely = myVote?.vote === Vote.unlikely

  const likelyVotes = voteList.votes.filter((v) => v.vote === Vote.likely)
  const unlikelyVotes = voteList.votes.filter((v) => v.vote === Vote.unlikely)

  const {mutate} = usePatchVote(analysisId, nodeId, myVote?.id)
  const {mutate: createVote} = useCreateVote(analysisId, nodeId)
  const {mutate: deleteVote} = useDeleteVote(analysisId, nodeId, myVote?.id)

  const canVote = permissions.canVote && voteList

  const handleVoteChange = (vote: Vote) => {
    if (!canVote) return
    myVote !== undefined ? mutate({vote}) : createVote({vote})
  }
  const handleVoteDelete = () => {
    if (!canVote) return
    deleteVote()
  }

  const likelyTooltipTitle = (
    <>
      {likelyVotes.map((vote, index) => (
        <Box key={`likely-user-row-${index}`}>{vote.owner.name}</Box>
      ))}
    </>
  )

  const unlikelyTooltipTitle = (
    <>
      {unlikelyVotes.map((vote, index) => (
        <Box key={`unlikely-user-row-${index}`}>{vote.owner.name}</Box>
      ))}
    </>
  )

  const VoteStyledButton = styled(Button)({
    minWidth: '45px',
    width: 'auto',
    padding: '9px 12px',
    borderRadius: '6px',
    pointerEvents: canVote ? 'auto' : 'none',
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: '6px'
    }
  })

  return (
    <Box display="flex" gap={1}>
      <Tooltip
        placement="top-start"
        title={likelyVotes.length > 0 ? likelyTooltipTitle : ''}
        enterDelay={300}
        leaveDelay={200}
      >
        <span>
          {myVoteIsLikely ? (
            <VoteStyledButton
              variant={'contained'}
              color={'success'}
              onClick={handleVoteDelete}
              startIcon={<ThumbUpAltOutlined />}
              data-test-id="button-voted-likely"
            >
              {voteList.likelyCount ?? 0}
            </VoteStyledButton>
          ) : (
            <VoteStyledButton
              variant={'outlined'}
              color={'secondary'}
              onClick={() => handleVoteChange(Vote.likely)}
              startIcon={<ThumbUpAltOutlined />}
              data-test-id="button-vote-likely"
              sx={{
                background: '#FFFFFF',
                border: '1px solid rgba(0, 39, 77, 0.1)',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)!important'
              }}
            >
              {voteList.likelyCount ?? 0}
            </VoteStyledButton>
          )}
        </span>
      </Tooltip>
      <Tooltip
        placement="top-start"
        title={unlikelyVotes.length > 0 ? unlikelyTooltipTitle : ''}
        enterDelay={300}
        leaveDelay={200}
      >
        <span>
          {myVoteIsUnlikely ? (
            <VoteStyledButton
              variant={'contained'}
              color={'error'}
              onClick={handleVoteDelete}
              startIcon={<ThumbDownAltOutlined />}
              data-test-id="button-voted-unlikely"
            >
              {voteList.unlikelyCount ?? 0}
            </VoteStyledButton>
          ) : (
            <VoteStyledButton
              variant={'outlined'}
              color={'secondary'}
              onClick={() => handleVoteChange(Vote.unlikely)}
              startIcon={<ThumbDownAltOutlined />}
              data-test-id="button-vote-unlikely"
              sx={{
                background: '#FFFFFF',
                border: '1px solid rgba(0, 39, 77, 0.1)',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)!important'
              }}
            >
              {voteList.unlikelyCount ?? 0}
            </VoteStyledButton>
          )}
        </span>
      </Tooltip>
    </Box>
  )
}
