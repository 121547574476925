import {useDistinctListValues} from '../../shared/hooks/useDistinctListValues'
import {FilterConfig, FilterTypes, AutocompleteOption} from '../../shared/types/filter.types'
import {TaskStatus} from '../components/TaskStatus'

const taskTitle: FilterConfig = {
  id: 'title',
  label: 'tables.tasksList.title',
  type: FilterTypes.Text,
  available: true
}

const assignee: FilterConfig = {
  id: 'assigneeName',
  label: 'tables.tasksList.assignee',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctListValues
}

const nodeTitle: FilterConfig = {
  id: 'nodeTitle',
  label: 'tables.tasksList.nodeTitle',
  type: FilterTypes.Text,
  available: true
}

const analysisTitle: FilterConfig = {
  id: 'analysisTitle',
  label: 'tables.tasksList.analysisTitle',
  type: FilterTypes.Text,
  available: true
}

const dueDate: FilterConfig = {
  id: 'dueDate',
  label: 'tables.tasksList.dueDate',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

const resolutionDate: FilterConfig = {
  id: 'resolutionDate',
  label: 'tables.tasksList.resolutionDate',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

const plantName: FilterConfig = {
  id: 'PlantName',
  label: 'tables.tasksList.PlantName',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctListValues
}

const taskStatusOptions: AutocompleteOption[] = [
  {
    id: 'Pending',
    label: 'Pending',
    customComponent: <TaskStatus status="pending" />
  },
  {
    id: 'Ongoing',
    label: 'Ongoing',
    customComponent: <TaskStatus status="ongoing" />
  },
  {
    id: 'Done',
    label: 'Done',
    customComponent: <TaskStatus status="done" />
  }
]

const taskStatus: FilterConfig = {
  id: 'status',
  label: 'tables.tasksList.status',
  type: FilterTypes.SelectPredefined,
  options: taskStatusOptions,
  available: true
}

export const getAvailableTasksFilters = (
  blacklist: string[] | undefined = []
): Array<FilterConfig> => {
  const baseFilters: FilterConfig[] = [
    taskTitle,
    plantName,
    assignee,
    nodeTitle,
    analysisTitle,
    taskStatus,
    dueDate,
    resolutionDate
  ]

  return baseFilters
    .filter((filter) => !blacklist.includes(filter.id))
    .map((filter) => {
      if (filter.type === FilterTypes.Select) {
        filter.apiUrl = '/rootcausefailureanalysis/analyses/Tasks/distinct'
      }
      return filter
    })
}
