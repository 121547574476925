import {KilnSvgWrapper} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus/KilnMetricStatus'
import {SignalStatus, SensorReadings} from '@hconnect/common/components/kmsStatus/types'
import {countAreaState} from '@hconnect/common/components/kmsStatus/utils'
import {Box, useMediaQuery, useTheme} from '@mui/material'
import Grid from '@mui/material/Grid'
import {useMemo} from 'react'
import {useNavigate} from 'react-router-dom'

import {useSensors} from '../../shared/context/SensorsContext'
import {useGetSensorsAnomalies} from '../../shared/hooks/useGetSensorAnomalies'
import {useGetSignals} from '../../shared/hooks/useGetSignals'
import {KilnModelSkeleton} from '../components/KilnModelSkeleton'
import {useKiln} from '../context/KilnContext'
import {useGetHealthIndicators} from '../hooks/useGetHealthIndicators'
import {useGetSensorsMappings} from '../hooks/useGetSensorsMappings'

import {KilnData} from './KilnData'

interface KilnModelProps {
  hoveredArea: string | undefined
  svgClickCb: (localNames: string[]) => void
  onKilnMetricClick: (localNames: string[]) => void
}

export const KilnModel = ({hoveredArea, svgClickCb, onKilnMetricClick}: KilnModelProps) => {
  const {kilnId, kilnInfo, isWidget} = useKiln()
  const {selectedIds, sensorData} = useSensors()
  const {data: sensorMappings, isPending: isMappingLoading} = useGetSensorsMappings(kilnId)
  const {data: anomalies, isPending: anomaliesLoading} = useGetSensorsAnomalies(kilnId)
  const {data: healthIndicators, isPending: healthIndicatorsLoading} = useGetHealthIndicators({
    kilnId
  })
  const theme = useTheme()
  const onlyBigScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()

  const {data: sensorsReadings, isPending: sensorReadingsLoading} = useGetSignals({
    options: {
      select: (data) =>
        data.reduce((acc, sensor) => {
          if (sensor.localName) {
            acc[sensor.localName] = {
              lastReading: sensor.lastReading,
              lastModelBreach: sensor.lastModelBreach
            }
          }
          return acc
        }, {} as SensorReadings)
    },
    kilnId
  })
  const areaFreqs = useMemo(
    () => countAreaState(sensorData, sensorsReadings),
    [sensorData, sensorsReadings]
  )

  const activeSections = useMemo(() => {
    const sensorAreas: string[] = []
    if (sensorMappings) {
      selectedIds.map((sensorId) => {
        sensorMappings.map((mapping) => {
          const sensorIndex = mapping.localNames.findIndex((id) => id === sensorId)
          if (sensorIndex > -1) sensorAreas.push(mapping.svgId)
        })
      })
      return sensorAreas.filter((value, index, array) => array.indexOf(value) === index)
    }

    return []
  }, [selectedIds, sensorMappings])

  const kilnModel = () =>
    isMappingLoading || healthIndicatorsLoading || anomaliesLoading ? (
      <KilnModelSkeleton />
    ) : sensorMappings ? (
      <Box
        flexGrow={1}
        sx={{
          // TO PREVENT SVG OVERFLOW
          minHeight: 0,
          minWidth: 0
        }}
        data-test-id="kiln-model"
      >
        <KilnSvgWrapper
          svgClickCb={svgClickCb}
          sensorMappings={sensorMappings}
          anomalies={anomalies}
          activeAreas={activeSections}
          hoveredArea={hoveredArea}
          speedValue={healthIndicators?.speed?.value}
          isWidget={isWidget}
          kilnInfo={kilnInfo}
        />
      </Box>
    ) : (
      <></>
    )

  return (
    <Grid flexGrow={1} sx={{maxHeight: '100%'}}>
      <KilnData
        loading={healthIndicatorsLoading}
        onKilnMetricClick={onKilnMetricClick}
        healthIndicators={healthIndicators}
      />
      {onlyBigScreen && kilnModel()}
      {!onlyBigScreen && (
        <Grid
          alignSelf="flex-start"
          color="text.primary"
          direction="row"
          gap={{
            xs: 1,
            sm: 3
          }}
          container
          mb={2}
        >
          {Object.keys(areaFreqs).map((key) => (
            <Grid item xs={5} sm={3} key={key}>
              <KilnMetricStatus
                title={key}
                value={''}
                status={areaFreqs[key] as SignalStatus}
                loading={sensorReadingsLoading}
                onClick={() => navigate('kms/' + kilnId + '?area=' + key)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )
}
