import {FilterConfig, FilterTypes} from '../../shared/types/filter.types'
import {useGetStoppagePlants} from '../hooks/stoppages/useGetStoppagePlants'

const planCountry: FilterConfig = {
  id: 'plant',
  label: 'Plant',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useGetStoppagePlants
}

const stoppageTime: FilterConfig = {
  id: 'stoppageTime',
  label: 'stoppage.detail.time',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

export const getAvailableStoppageFilters = (): Array<FilterConfig> => {
  const baseFilters: FilterConfig[] = [planCountry, stoppageTime]
  return baseFilters
}
