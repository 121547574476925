import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import i18next from 'i18next'

import {useAxios} from '../../shared/hooks/useApi'
import {useUrlParam} from '../../shared/hooks/useUrlParam'

export const QueryKey = 'suggestions'

export const useGetSuggestions = (
  parentTitle?: string,
  options?: UseQueryOptions<string[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const analysisId = useUrlParam('analysisId')
  const userLanguage = i18next.language

  return useQuery<string[], AxiosError>({
    queryKey: [QueryKey, parentTitle, userLanguage],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/aiSuggestions?analysisId=${analysisId}`
      return (
        await axiosInstance.post<string[]>(path, {
          userInput: parentTitle,
          userLanguage
        })
      ).data
    },

    // these default values will get moved to the api provider soon
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    retry: false,
    // allow the callee to override the default options
    ...options
  })
}
