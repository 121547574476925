import {SignalData} from '@hconnect/common/components/kmsStatus/types'

export const getSensorGroupString = (sensorData: SignalData) => {
  return sensorData.group
  // const {grouping} = sensorData
  // if (!grouping) return 'No mapping available'
  // return Object.keys(grouping)
  //   .map((key) => grouping[key].charAt(0).toUpperCase() + grouping[key].slice(1))
  //   .join(' > ')
}

export const roundValue = (value: number): number =>
  Math.round((value + Number.EPSILON) * 1000) / 1000

export const countAreaFreqs = (sensorData?: SignalData[]) => {
  const freqs: Record<string, number> = {}
  if (!sensorData) return freqs
  sensorData.forEach((sensor) => {
    const area = sensor?.group ?? 'Unknown area'
    if (area in freqs) {
      freqs[area] += 1
    } else {
      freqs[area] = 1
    }
  })
  return freqs
}
