import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

export type ResponseType = {
  data: string
}

export const QueryKey = 'delete-node'

export const useFinishAnalysis = (analysisId: string | undefined) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/statuses`
      return (
        await axiosInstance.post<ResponseType>(path, {status: 'Finished', privacyStatus: 'Public'})
      ).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-summary']})
      await queryClient.invalidateQueries({queryKey: ['analysis-list']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return {data: true}
    }
  })
}
