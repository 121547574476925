import {Box, Typography} from '@mui/material'
import React from 'react'

import {SummaryNodeTaskType, SummaryNodeType} from '../../types/analysisPdfSummary.types'

import {NodeIcon} from './NodeIcon'
import {SummaryNode} from './SummaryNode'

export const SummaryNodeGroup: React.FC<{
  nodes: Array<SummaryNodeType>
  tasks: SummaryNodeTaskType[]
  sectionTitle: string
  noLines?: boolean
  hideInPrint?: boolean
  hideImages?: boolean
  hideTasks?: boolean
  hideComments?: boolean
}> = ({nodes, sectionTitle, noLines, tasks, hideInPrint, hideTasks, hideImages, hideComments}) => {
  return (
    <Box
      sx={{
        height: 'fit-content',
        displayPrint: hideInPrint ? 'none' : 'block'
      }}
      mt={1}
      mb={1}
      display="flex"
      displayPrint="block"
      flexDirection="column"
    >
      <Typography variant="h3" mb={2}>
        {sectionTitle}
      </Typography>
      {nodes.map((node, i) => (
        <Box key={node.id} display="flex" gap={1} mb={0}>
          <Box sx={{minWidth: '24px', maxWidth: '24px'}} display="flex" flexDirection="column">
            {!noLines && (
              <Box sx={{width: '24px', height: '24px'}}>
                <NodeIcon
                  causeProbability={node.causeProbability ?? 'unknown'}
                  isRootCause={node.isRootCause ?? false}
                />
              </Box>
            )}
            {!noLines && i < nodes.length - 1 && (
              <Box sx={{width: '55%', height: '100%', borderRight: '1px solid black'}} />
            )}
          </Box>
          <SummaryNode
            node={node}
            tasks={tasks}
            hideTasks={hideTasks}
            hideImages={hideImages}
            hideComments={hideComments}
          />
        </Box>
      ))}
    </Box>
  )
}
