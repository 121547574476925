import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

import {ApiFilter} from '../../types/filter.types'

import {OrderByApiProperty} from './useListOrderBy'

export const useListQuerySync = ({
  filters,
  order,
  filtersEnabled,
  orderEnabled
}: {
  filters?: ApiFilter[]
  order?: OrderByApiProperty[]
  filtersEnabled: boolean
  orderEnabled: boolean
}) => {
  const [, setSearchParams] = useSearchParams()

  const handlePageChange = (newPage: number) => {
    setSearchParams((params) => {
      params.set('page', newPage.toString())
      return params
    })
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParams((params) => {
      params.set('rows', event.target.value)
      params.set('page', '0')
      return params
    })
  }

  const updateSearchParams = <TData,>(name: string, data?: Array<TData>) => {
    setSearchParams((params) => {
      if (Array.isArray(data) && data.length > 0 && JSON.stringify(data) !== params.get(name)) {
        params.set(name, JSON.stringify(data))
        params.set('page', '0')
      }
      if (Array.isArray(data) && data.length === 0 && params.get(name)) {
        params.delete(name)
        params.set('page', '0')
      }
      return params
    })
  }

  // synchronize URL with filters and order
  useEffect(() => {
    if (!filtersEnabled) return
    updateSearchParams('filters', filters)
  }, [JSON.stringify(filters)])

  useEffect(() => {
    if (!orderEnabled) return
    updateSearchParams('orderBy', order)
  }, [JSON.stringify(order)])

  return {handlePageChange, handleRowsPerPageChange}
}
