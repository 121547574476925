import {StoppageListColumn} from '../types/stoppage.types'

const title: StoppageListColumn = {
  id: 'Title',
  translationKey: 'label.title',
  position: 1,
  sortable: false
}

const stoppageCode: StoppageListColumn = {
  id: 'StoppageCode',
  translationKey: 'tables.stoppageList.stoppageCode',
  position: 3,
  sortable: false
}

const plant: StoppageListColumn = {
  id: 'plant',
  translationKey: 'tables.analysisList.plant',
  position: 2,
  sortable: false
}

const stoppageStart: StoppageListColumn = {
  id: 'stoppageStart',
  translationKey: 'tables.analysisList.stoppageStart',
  position: 4,
  sortable: false
}

const stoppageEnd: StoppageListColumn = {
  id: 'stoppageEnd',
  translationKey: 'tables.analysisList.stoppageEnd',
  position: 5,
  sortable: false
}

const duration: StoppageListColumn = {
  id: 'duration',
  translationKey: 'label.duration',
  position: 6,
  sortable: false
}

const actions: StoppageListColumn = {
  id: 'actions',
  translationKey: 'RCFA',
  position: 7,
  sortable: false
}

const baseColumns: Array<StoppageListColumn> = [
  title,
  stoppageCode,
  stoppageStart,
  stoppageEnd,
  plant,
  duration,
  actions
]

export const getAvailableColumns = (blacklist: string[] | undefined = []) => {
  return structuredClone(baseColumns)
    .filter((col) => !blacklist.includes(col.id))
    .sort((a, b) => a.position - b.position)
}
