import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CheckIcon from '@mui/icons-material/Check'
import {LoadingButton} from '@mui/lab'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

export const FinishSetupDialog = ({open, onClose, handleSubmit, isValid, loading}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle typography="h3">
      {isValid ? 'Do you want to save the layout?' : 'Minimum requirements not met'}
    </DialogTitle>
    <DialogContent>
      {!isValid && (
        <DialogContentText component={Alert} severity="error">
          The kiln layout must have <b>at least 2 stations</b>, and <b>feed and speed signals</b>{' '}
          must be connected to the kiln.
        </DialogContentText>
      )}
      {isValid && (
        <DialogContentText component={Alert} severity="warning">
          Make sure that the drive position, kiln rotation and material flow are correctly set and
          that all stations are labeled.
        </DialogContentText>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} variant="text" startIcon={<ArrowBackIosIcon />}>
        Back to setup
      </Button>
      {isValid && (
        <LoadingButton
          onClick={handleSubmit}
          variant="text"
          startIcon={<CheckIcon />}
          loading={loading}
          loadingPosition="start"
        >
          Save
        </LoadingButton>
      )}
    </DialogActions>
  </Dialog>
)
