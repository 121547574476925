import {Loader} from '@hconnect/uikit/src/lib2'
import Check from '@mui/icons-material/Check'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import {Button, Box, Modal, Typography} from '@mui/material'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {useFinishAnalysis} from '../../hooks/analysis/useFinishAnalysis'
import {useGetAnalysisSummary} from '../../hooks/analysis/useGetAnalysisSummary'

export interface AnalysisFinishProperties {
  analysisId?: string
  open: boolean
  setOpen: (open: boolean) => void
}

export const FinishAnalysisModal: React.FC<AnalysisFinishProperties> = ({
  analysisId,
  open,
  setOpen
}) => {
  const handleClose = (e) => {
    setOpen(false)
    e.stopPropagation()
  }
  const id = parseInt(analysisId ?? '')
  const {isFetching, data} = useGetAnalysisSummary(id)
  const finishAnalysis = useFinishAnalysis(analysisId)
  const {t} = useTranslation()

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          p={3}
          sx={(theme) => ({
            width: '50vw',
            maxHeight: '85vh',
            minWidth: 600,
            maxWidth: 1000,
            background: theme.palette.common.white,
            borderRadius: 1,
            overflow: 'auto'
          })}
        >
          {isFetching && <Loader height="60vh" />}
          {data && (
            <>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{
                    fontWeight: 700,
                    fontSize: 20,
                    lineHeight: '24px'
                  }}
                >
                  Analysis summary
                </Typography>
                <Box p={2}>
                  <Typography sx={{fontSize: 13}}>Incident title</Typography>
                  <Typography variant="body1" mb={2} sx={{fontWeight: 500}}>
                    {data.title}
                  </Typography>
                  <Typography sx={{fontSize: 13}}>Description</Typography>
                  <Typography variant="body1" mb={2} sx={{fontWeight: 500}}>
                    <ReactQuill value={data.description} readOnly={true} theme={'bubble'} />
                  </Typography>
                  <Typography sx={{fontSize: 13}}>Stoppage code</Typography>
                  <Typography variant="body1" mb={2} sx={{fontWeight: 500}}>
                    {data.stoppageCode}
                  </Typography>
                </Box>
                <Box mb={4} sx={{maxHeight: 280, overflowY: 'auto'}}>
                  <Box
                    py={1}
                    px={2}
                    sx={(theme) => ({
                      background: theme.palette.grey.A100,
                      borderRadius: 0.5,
                      color: theme.palette.grey.A700,
                      fontSize: '13px',
                      lineHeight: '16px'
                    })}
                  >
                    Likely causes
                  </Box>
                  {data.likelyCauses &&
                    data.likelyCauses.map((cause) => (
                      <Box
                        key={`cause=${cause.id}`}
                        px={2}
                        py={2.5}
                        sx={(theme) => ({
                          height: 60,
                          fontWeight: 500,
                          lineHeight: '22px',
                          boxShadow: `inset 0px -1px 0px ${theme.palette.grey.A100}`
                        })}
                      >
                        <Typography sx={{display: 'inline'}} variant="body2">
                          {cause.title}
                        </Typography>
                        <Box sx={{float: 'right'}}>
                          {cause.taskCount > 0 && (
                            <Box
                              sx={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <ContentPasteOutlinedIcon
                                sx={(theme) => ({
                                  color: theme.palette.grey['600'],
                                  width: '15px',
                                  height: '15px',
                                  marginRight: 0.5
                                })}
                              />
                              <Typography
                                sx={(theme) => ({
                                  color: theme.palette.grey['600'],
                                  fontSize: 12,
                                  lineHeight: '16px'
                                })}
                              >
                                {cause.taskCount}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography pr={2} sx={{fontSize: 12}}>
                    Once finished, it can be downloaded in full detail as a PDF
                  </Typography>
                  <Button
                    sx={{marginLeft: 2}}
                    startIcon={<Check />}
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      finishAnalysis.mutate()
                      handleClose(e)
                    }}
                    data-test-id="analysis-finish-button"
                  >
                    {t('buttons.finishAnalysis')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  )
}
