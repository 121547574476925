import {Loader} from '@hconnect/uikit/src/lib2'
import {StructureNode} from '@settings/modules/common/types'
import React, {useContext, createContext, useMemo} from 'react'

import {useGetPlantStructure} from '../../shared/hooks/useGetPlantStructure'

interface PlantContext {
  areas: StructureNode[]
  isWidget: boolean
}

export const PlantContext = createContext<PlantContext>(null as unknown as PlantContext)

type PlantProviderProps = {
  children: React.ReactNode
  isWidget?: boolean
}

export const PlantProvider = ({children, isWidget}: PlantProviderProps) => {
  const {data: plantStructure, isPending} = useGetPlantStructure()

  // all upm areas with at least one signal
  const areas = useMemo(
    () => plantStructure?.children?.filter((area) => area.signalCount) || [],
    [plantStructure]
  )

  if (isPending) return <Loader color="grey" height="30vh" />

  return (
    <PlantContext.Provider
      value={{
        areas,
        isWidget: !!isWidget
      }}
    >
      {children}
    </PlantContext.Provider>
  )
}

export const usePlant = () => useContext(PlantContext)
