import {Button, ButtonProps, Theme, SxProps} from '@mui/material'
import React, {FC} from 'react'

type TransparentButtonProps = Omit<ButtonProps, 'sx'> & {
  customSx?: Omit<SxProps<Theme>, 'color | borderColor'>
}

export const TransparentButton: FC<TransparentButtonProps> = ({children, customSx, ...props}) => {
  return (
    <Button
      {...props}
      variant="outlined"
      sx={(theme) => ({
        ...customSx,
        color: theme.palette.common.white,
        borderColor: 'rgba(255, 255, 255, 0.4)',
        '&:hover': {
          borderColor: theme.palette.common.white
        }
      })}
    >
      {children}
    </Button>
  )
}
