import {AnalysisListType} from '@hconnect/common/types'
import {ArrowBackOutlined, ArrowForwardOutlined} from '@material-ui/icons'
import {Close} from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  DialogActions,
  DialogTitle
} from '@mui/material'
import {styled} from '@mui/system'
import useDebounce from '@react-hook/debounce'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ApiFilter, FilterActionOperation} from '../../../shared/types/filter.types'
import {formatDateTime} from '../../common/dateTimeUtils'
import {useLinkStoppage} from '../../hooks/analysis/useLinkStoppage'
import {useGetAnalysisList} from '../../hooks/analysisList/useGetAnalysisList'
import {AnalysisListInfo} from '../../types/analysis.types'
import {StoppageInfo} from '../../types/stoppage.types'
import ModalCircularProgress from '../ModalCircularProgress'

interface StoppageLinkModalProps {
  open: boolean
  handleClose: () => void
  stoppage?: StoppageInfo
}

export const CardWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  background: theme.palette.background.paper,
  width: '100%',
  flexDirection: 'column'
}))

export const StoppageLinkModal = ({open, handleClose, stoppage}: StoppageLinkModalProps) => {
  const {t} = useTranslation()

  const [selectedAnalysis, setSelectedAnalysis] = useState<AnalysisListInfo | undefined>()
  const [search, setSearch] = useDebounce<string | null>('', 500)

  const {mutate: linkStoppage, isSuccess, isPending} = useLinkStoppage()

  useEffect(() => {
    if (isSuccess) handleClose()
  }, [handleClose, isSuccess])

  const handleSubmit = () => {
    linkStoppage({
      plantId: stoppage?.plantId ?? '',
      externalId: stoppage?.id ?? '',
      stoppageStart: stoppage?.start ?? '',
      stoppageEnd: stoppage?.end ?? '',
      analysisId: selectedAnalysis?.id ?? 0
    })
  }
  const filters: ApiFilter[] = [
    {
      operation: FilterActionOperation.IN,
      property: 'HasExternalStoppage',
      values: ['false']
    },
    {property: 'status', values: ['Ongoing'], operation: FilterActionOperation.IN}
  ]
  if (stoppage?.plantId) {
    filters.push({
      operation: FilterActionOperation.IN,
      property: 'PlantNumber',
      values: [stoppage.plantId]
    })
  }
  if (search && search.length > 0) {
    filters.push({
      property: 'title',
      values: [search],
      operation: FilterActionOperation.CONTAINS
    })
  }
  const {data, isLoading} = useGetAnalysisList(0, 100, AnalysisListType.Mine, filters, [])
  const listItemColor = (d: AnalysisListInfo) =>
    selectedAnalysis?.id === d.id ? '#f5f9ff' : 'transparent'
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="stoppage-link"
      PaperProps={{
        sx: {maxWidth: '1200px'}
      }}
    >
      <DialogTitle>
        <Stack
          mb={2}
          direction="row"
          justifyContent="space-between"
          paddingRight={2}
          width={'100%'}
          alignItems={'center'}
          data-test-id="link-analysis-dialog-title"
        >
          <Typography
            variant="h2"
            sx={{
              wordWrap: 'break-word',
              display: 'flex',
              gap: 1,
              alignItems: 'flex-start'
            }}
          >
            {t('stoppage.new.link')} RCFA
          </Typography>
          <IconButton onClick={() => handleClose()} color="primary">
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            label={t('tables.stoppageList.filterByTitle')}
            data-test-id="link-analysis-search"
          />
        </Box>
        {isLoading || isPending ? (
          <ModalCircularProgress />
        ) : (
          <List sx={{maxHeight: '400px', overflow: 'scroll'}}>
            {data?.data.map((d) => (
              <React.Fragment key={d.id}>
                <ListItem
                  sx={{
                    height: '50px',
                    '&:hover': {backgroundColor: '#f5f9ff'},
                    backgroundColor: listItemColor(d)
                  }}
                  onClick={() => setSelectedAnalysis(d)}
                >
                  <Box width={'25%'} data-test-id="analysis-title">
                    {d.title}
                  </Box>
                  <Box width={'25%'}>{formatDateTime(d.stoppageStart)}</Box>
                  <Box width={'25%'}>{formatDateTime(d.stoppageEnd)}</Box>
                  <Box width={'25%'}>
                    {d.stoppageCodeText} - {d.stoppageCode}
                  </Box>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
        <DialogActions>
          <Stack
            mt={2}
            direction="row"
            justifyContent="space-between"
            paddingRight={2}
            width={'100%'}
          >
            <Button
              disabled={isPending}
              onClick={() => handleClose()}
              startIcon={<ArrowBackOutlined />}
              data-test-id="back-btn"
            >
              Back
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={selectedAnalysis === undefined || isPending}
              endIcon={<ArrowForwardOutlined />}
              variant={'contained'}
              data-test-id="select-analysis-btn"
            >
              Select
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
