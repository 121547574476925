import {MaintainAppConfig} from '@hconnect/common/hproduce/maintain.types'

export const pm: MaintainAppConfig = {
  name: 'PM',
  displayName: 'Plant monitoring',
  urlAlias: 'pm',
  whiteListedPermissionTypes: ['VIEW_KILN_MONITORING_DATA'],
  plant: {
    whiteList: ['0014']
  }
}
