import {cloneDeep} from 'lodash'
import moment from 'moment'
import {useEffect, useMemo, useReducer} from 'react'

import {
  ApiFilter,
  AutocompleteOption,
  FilterAction,
  FilterActionOperation,
  FilterActionType,
  FilterConfig,
  FilterState
} from '../../types/filter.types'
import {decodeFromQueryString} from '../../utils/strings'

export type OrderByApiProperty = {
  property: string
  descending: boolean
}

export const useListFilters = (filterConfig: Array<FilterConfig>, filterQuery?: string | null) => {
  const [filterState, dispatch] = useReducer(
    filterReducer,
    {filterConfig, filterQuery},
    createInitialState
  )

  // Listen to config changes (eg. when switching tabs)
  useEffect(() => {
    dispatch({
      type: FilterActionType.UPDATE_CONFIG,
      payload: {filterId: '', value: filterConfig}
    })
  }, [JSON.stringify(filterConfig)])

  const apiFilters = useMemo(() => {
    return Object.values(filterState)
      .filter((f) => f.available && f.apiFilter)
      .map((f) => f.apiFilter) as Array<ApiFilter>
  }, [JSON.stringify(filterState)])

  return {filterState, apiFilters, dispatch}
}

const createInitialState = ({
  filterConfig,
  filterQuery
}: {
  filterConfig: Array<FilterConfig>
  filterQuery?: string | null
}) => {
  const initialState: FilterState = {}
  const parsedQueryValues: Array<ApiFilter> = decodeFromQueryString(filterQuery)

  filterConfig.forEach((filter) => {
    const urlConfig = parsedQueryValues.find((apiFilter) => apiFilter.property === filter.id)
    if (!urlConfig) {
      initialState[filter.id] = {
        ...filter,
        visible: false
      }
    } else {
      // initial filter settings from url
      initialState[filter.id] = {
        ...filter,
        visible: true,
        apiFilter: {
          property: filter.id,
          values: urlConfig.values, // used as source for filter component values
          operation: urlConfig.operation
        }
      }
    }
  })
  return initialState
}

export const filterReducer = (state: FilterState, action: FilterAction) => {
  const newState = cloneDeep(state)
  const {filterId} = action.payload

  switch (action.type) {
    case FilterActionType.ADD_CHOICE: {
      const {value}: {value: Array<AutocompleteOption>} = action.payload
      if (value.length > 0) {
        newState[filterId].apiFilter = {
          property: filterId,
          values: value.map((v) => v.id),
          operation: FilterActionOperation.IN
        }
      } else {
        newState[filterId].apiFilter = undefined
      }
      return newState
    }

    case FilterActionType.ADD_TEXT: {
      const {value}: {value: string} = action.payload
      if (value && value !== '') {
        newState[filterId].apiFilter = {
          property: filterId,
          values: [value],
          operation: FilterActionOperation.CONTAINS
        }
      } else {
        newState[filterId].apiFilter = undefined
      }
      return newState
    }

    case FilterActionType.ADD_DATE_SINGLE: {
      const {value}: {value: Date} = action.payload
      newState[filterId].apiFilter = {
        property: filterId,
        values: [
          moment(value).toDate().toISOString(),
          moment(value).endOf('day').toDate().toISOString()
        ],
        operation: FilterActionOperation.BETWEEN
      }
      return newState
    }

    case FilterActionType.ADD_DATE_RANGE: {
      const {value}: {value: {startDate: Date; endDate: Date}} = action.payload
      newState[filterId].apiFilter = {
        property: filterId,
        values: [
          moment(value.startDate).toDate().toISOString(),
          moment(value.endDate).endOf('day').toDate().toISOString()
        ],
        operation: FilterActionOperation.BETWEEN
      }
      return newState
    }

    case FilterActionType.CLEAR_VALUE: {
      newState[filterId].apiFilter = undefined
      return newState
    }

    case FilterActionType.REMOVE: {
      newState[filterId].apiFilter = undefined
      newState[filterId].visible = false
      return newState
    }

    case FilterActionType.REMOVE_ALL: {
      Object.keys(newState).forEach((key) => {
        newState[key].apiFilter = undefined
        newState[key].visible = false
      })
      return newState
    }

    case FilterActionType.UPDATE_CONFIG: {
      const {value: newConfig}: {value: Array<FilterConfig>} = action.payload

      Object.keys(newState).forEach((filterId) => {
        const updatedFilter = newConfig.find((filter) => filter.id === filterId)
        if (updatedFilter) {
          newState[filterId] = {
            ...newState[filterId],
            ...updatedFilter
          }
        }
      })
      return newState
    }

    case FilterActionType.SET_FILTER_VISIBLE: {
      newState[filterId].visible = true
      return newState
    }

    default:
      return newState
  }
}
