import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {StyledTooltip} from '../../shared/components/styled'

export const AnalysisDescriptionTooltip = ({top, left}: {top: number; left: number}) => {
  const {t} = useTranslation()

  return (
    <StyledTooltip
      arrow
      title={
        <Typography variant="body2" color="common.white">
          {`•${t('newAnalysis.descriptionTooltip1')}`}
          <br />
          {`•${t('newAnalysis.descriptionTooltip2')}`}
          <br />
          {`•${t('newAnalysis.descriptionTooltip3')}`}
          <br />
          {`•${t('newAnalysis.descriptionTooltip4')}`}
        </Typography>
      }
    >
      <InfoOutlinedIcon
        sx={{
          position: 'relative',
          top: top,
          left: left
        }}
      />
    </StyledTooltip>
  )
}
