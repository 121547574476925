import {AnalysisStatus} from '@hconnect/common/types'
import Autorenew from '@mui/icons-material/Autorenew'
import Check from '@mui/icons-material/Check'
import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const AnalysisStatusComponent = ({
  status,
  hasPendingTasks
}: {
  status: AnalysisStatus
  hasPendingTasks: boolean
}) => {
  const {t} = useTranslation()

  const finishedTasksPending = status === AnalysisStatus.Finished && hasPendingTasks
  const finishedTasksDone = status === AnalysisStatus.Finished && !hasPendingTasks
  const iconColor = finishedTasksPending ? 'warning.light' : 'success.light'

  return (
    <Box display="flex" alignItems="center" gap="6px">
      {status === AnalysisStatus.Finished ? (
        <Check sx={{color: iconColor, fontSize: 18}} />
      ) : (
        <Autorenew sx={{color: 'text.secondary', fontSize: 18}} />
      )}
      <Stack>
        <Typography noWrap variant="body2">
          {t(`taskStatuses.${status}`)}
        </Typography>

        {finishedTasksPending && (
          <Typography noWrap variant="caption">
            {t('analysisList.tasksPending')}
          </Typography>
        )}

        {finishedTasksDone && (
          <Typography noWrap variant="caption">
            {t('analysisList.tasksFinished')}
          </Typography>
        )}
      </Stack>
    </Box>
  )
}
