import {Box, Table, TableBody, TableHead, Typography, TableRow, TableCell} from '@mui/material'
import moment from 'moment'
import React from 'react'

import {SummaryNodeTaskType} from '../../types/analysisPdfSummary.types'

export const SummaryTasksList: React.FC<{
  tasks: SummaryNodeTaskType[]
  sectionTitle: string
  hideInPrint?: boolean
}> = ({sectionTitle, tasks, hideInPrint}) => {
  const tasksByNode = tasks.reduce((group, task) => {
    const {nodeId} = task
    group[nodeId] = group[nodeId] ?? []
    group[nodeId].push(task)
    return group
  }, {})

  return (
    <Box
      sx={{
        height: 'fit-content'
      }}
      mt={1}
      mb={1}
      display="flex"
      flexDirection="column"
      displayPrint={hideInPrint ? 'none' : 'block'}
    >
      <Typography variant="h3" mb={2}>
        {sectionTitle}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Cause/Task</TableCell>
            <TableCell variant="head">Status</TableCell>
            <TableCell variant="head">Assigned to</TableCell>
            <TableCell variant="head">Due date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(tasksByNode).map((nodeId) => (
            <>
              <TableRow>
                <TableCell padding="normal" size="medium" colSpan={4}>
                  <Typography component={'span'} variant={'body1'} fontWeight={600}>
                    {tasksByNode[nodeId][0] ? tasksByNode[nodeId][0].nodeTitle : ''}
                  </Typography>
                </TableCell>
              </TableRow>
              {tasksByNode[nodeId].map((task) => (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottom: 'none'
                      }}
                    >
                      {task.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 'none'
                      }}
                    >
                      {task.status}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 'none'
                      }}
                    >
                      {task.assignee?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 'none'
                      }}
                    >
                      {task.dueDate ? moment(task.dueDate).format('DD/MM/YYYY') : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color={'#54708C'} variant={'body2'} component={'span'}>
                        Description
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>{task.description}</TableCell>
                  </TableRow>
                </>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
