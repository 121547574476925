import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

export type ResponseType = {
  data: boolean
}

export const QueryKey = 'delete-node'

export const useDeleteTask = (analysisId: string | undefined, nodeId: number) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (taskId: string) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/tasks/${taskId}`
      return (await axiosInstance.delete<ResponseType>(path, {})).data
    },

    onSuccess: async () => {
      queryClient.removeQueries({queryKey: ['analysis-tasks']})
      queryClient.removeQueries({queryKey: ['node-tasks']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      await queryClient.invalidateQueries({queryKey: ['analysis-tasks']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['tasks-master-list']})
      await queryClient.invalidateQueries({queryKey: ['node-tasks']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      return {data: true}
    }
  })
}
