import Close from '@mui/icons-material/Close'
import Delete from '@mui/icons-material/Delete'
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useCanvasContext} from '../../context/CanvasContext'
import {useDeleteNode} from '../../hooks/node/useDeleteNode'

export const NodeDelete: React.FC<{
  nodeId: number
  title: string
  analysisId: string | undefined
  closeModal: Function
  opened: boolean
  setNewNode: Function
}> = ({nodeId, analysisId, closeModal, opened, setNewNode, title}) => {
  const {t} = useTranslation()
  const {activeNode} = useCanvasContext()
  const {data, mutate, isPending: isLoading} = useDeleteNode(analysisId)

  useEffect(() => {
    if (data?.selectedNodeId !== undefined && !isLoading) {
      setNewNode(undefined)
      closeModal()
    }
  }, [data, isLoading])

  return (
    <Dialog open={opened} onClose={() => closeModal} maxWidth="md" data-test-id="remove-node-modal">
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          {t('headings.removeNode')}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <Grid container justifyContent="flex-start">
          {`${t('headings.removeNodeText')}\xa0`}
          <strong>{`${title}?\xa0`}</strong>
          {t('headings.removeNodeTextSecondary', {
            taskCount: activeNode?.taskCount || 0,
            attachmentCount: activeNode?.attachmentCount || 0,
            commentCount: activeNode?.commentCount || 0
          })}
          {'. '}
          {!!activeNode?.children?.length && t('headings.removeNodeTextChildren')}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" my={1}>
          <Button
            startIcon={<Close />}
            variant="outlined"
            color="secondary"
            onClick={() => closeModal()}
            data-test-id="node-form-cancel-button"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => mutate(nodeId)}
            sx={{marginLeft: 1}}
            data-test-id="node-form-cancel-button"
          >
            {t('buttons.deleteConfirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
