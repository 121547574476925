import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ClearIcon from '@mui/icons-material/Clear'
import {TextField, Checkbox, Autocomplete, Paper} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {autocompleteFilterSx} from '../../styles/filters'
import {Filter, AutocompleteOption} from '../../types/filter.types'

const reconcileInitialValues = (initialValues: any, options: AutocompleteOption[]) => {
  return Array.isArray(initialValues)
    ? options.filter((o) => initialValues.find((iv) => o.id === iv.id))
    : []
}

interface AutocompleteFilterProps extends Filter<AutocompleteOption> {
  options: AutocompleteOption[]
  multipleOptions: boolean
  loading?: boolean
}

export const AutocompleteFilter: React.FC<AutocompleteFilterProps> = ({
  id,
  label,
  options,
  multipleOptions,
  value,
  handleChange,
  loading
}) => {
  const filtered = reconcileInitialValues(value, options)
  const {t} = useTranslation()
  // if the value has been filtered, we must invoke the handler manually
  // because the autocomplete event is not triggered
  useEffect(() => {
    if (options.length === 0) return
    if (!Array.isArray(value) || !Array.isArray(filtered)) return
    if (filtered.length !== value.length) {
      handleChange(null, filtered)
    }
  }, [JSON.stringify(filtered), JSON.stringify(value), JSON.stringify(options)])

  return (
    <Autocomplete
      loading={loading}
      value={filtered}
      onChange={handleChange}
      multiple={multipleOptions}
      options={options}
      limitTags={1}
      id={`autocomplete-${id}`}
      data-test-id={`autocomplete-${id}`}
      disableCloseOnSelect
      popupIcon={<ArrowDropDownIcon style={{color: 'white'}} />}
      clearIcon={<ClearIcon fontSize="small" style={{color: 'white'}} />}
      ChipProps={{
        color: 'secondary',
        variant: 'outlined',
        sx: {maxWidth: '105px', width: '105px'}
      }}
      PaperComponent={(props) => <Paper elevation={8} {...props} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, {selected}) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{marginRight: 8}}
            checked={selected}
          />
          {option.customComponent ?? option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={t(label)} variant="filled" sx={autocompleteFilterSx} />
      )}
    />
  )
}
