import {AnalysisPrivacyStatus, AnalysisStatus} from '@hconnect/common/types'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Popover, Stack, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {LinearLoader} from '../../../shared/components/LinearLoader'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {ChangeAnalysisOwner} from '../../container/assignees/ChangeAnalysisOwner'
import {ManageContributors} from '../../container/assignees/ManageContributors'
import {useGetAnalysisDetail} from '../../hooks/analysis/useGetAnalysisDetail'
import {usePatchAnalysis} from '../../hooks/analysis/usePatchAnalysis'
import {AnalysisPrivacyStatusSwitch} from '../AnalysisPrivacyStatusSwitch'

export interface AnalysisSettingsModalProperties {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
}

export const AnalysisSettingsModal: React.FC<AnalysisSettingsModalProperties> = ({
  anchorEl,
  open,
  onClose
}) => {
  const analysisIdParam = useUrlParam('analysisId')
  const analysisId = Number(analysisIdParam)

  const {isFetching, data} = useGetAnalysisDetail(analysisIdParam)
  const [item, setItem] = useState<{
    privacyStatus: string | AnalysisPrivacyStatus
    status: AnalysisStatus
  }>({
    privacyStatus: AnalysisPrivacyStatus.Private,
    status: AnalysisStatus.Finished
  })

  const {t} = useTranslation()

  useEffect(() => {
    const privacyStatus = data?.privacyStatus ?? AnalysisPrivacyStatus.Private
    const status = data?.status ?? AnalysisStatus.Finished
    setItem((item) => ({...item, privacyStatus, status}))
  }, [data])

  const patchAnalysis = usePatchAnalysis()
  const canEdit = data?.permissions?.canEdit ?? false
  const canSetOwner = data?.permissions?.canSetOwner ?? false

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || ''

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <CardBox
        display="flex"
        flexDirection="column"
        gap={2.5}
        pt={0}
        sx={{
          width: '425px',
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          zIndex: 1
        }}
      >
        <Stack spacing={1} sx={{position: 'sticky', top: 0, background: '#FFF', zIndex: 1, pt: 3}}>
          <Typography variant="h4">{t('buttons.analysisSettings')}</Typography>
          <LinearLoader loading={isFetching} />
        </Stack>
        {data && (
          <>
            <AnalysisPrivacyStatusSwitch
              value={capitalize(item.privacyStatus)}
              onChange={(privacyStatus) =>
                patchAnalysis.mutate({privacyStatus: privacyStatus, Id: analysisId})
              }
            />
            <ChangeAnalysisOwner
              analysisId={analysisId}
              currentOwner={data.owner}
              canSetOwner={canSetOwner}
            />
            <ManageContributors
              analysisId={analysisId}
              contributors={data?.contributors ?? []}
              owner={data?.owner}
              canEdit={canEdit}
            />
          </>
        )}
      </CardBox>
    </Popover>
  )
}
