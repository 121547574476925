import {Loader} from '@hconnect/uikit/src/lib2'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material'
import moment from 'moment'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMeasure} from 'react-use'

import {TaskStatus} from '../../components/TaskStatus'
import {useAnalysisTasks} from '../../hooks/tasks/useGetAnalysisTasks'
import {Task, TaskGroup} from '../../types/nodeDetail.types'

import {TaskDetail} from './TaskDetail'

export const AnalysisTasksListTable: React.FC<{
  analysisId?: string
  taskId?: string
}> = ({analysisId, taskId}) => {
  const [activeTask, setActiveTask] = useState<Task | null | undefined>(null)

  const {data, isFetching} = useAnalysisTasks(analysisId)

  useEffect(() => {
    if (data) {
      let selectedTask
      data.data?.find((node) => (selectedTask = node.tasks?.find((t) => t.id === taskId)))
      if (selectedTask && !activeTask) setActiveTask(selectedTask)
    }
  }, [data])

  return (
    <>
      {!data && isFetching && <Loader height="60vh" color="primary.contrastText" />}
      <Box sx={{display: 'flex'}}>
        {data && (
          <Box
            p={2}
            mx={2}
            sx={(theme) => ({
              background: theme.palette.common.white,
              color: theme.palette.common.black,
              borderRadius: 1,
              height: 'fit-content',
              flex: '1 0 560px',
              ...(activeTask ? {width: '60%', float: 'left'} : {})
            })}
          >
            <ExpandableTable
              groups={data.data ?? []}
              onSelectItem={setActiveTask}
              selectedItem={activeTask}
            />
          </Box>
        )}
        {activeTask?.id && activeTask.nodeId && (
          <Box
            mr={2}
            p={3}
            sx={(theme) => ({
              background: theme.palette.common.white,
              minWidth: '250px',
              width: '30vw',
              color: theme.palette.common.black,
              borderRadius: 1,
              height: 'fit-content',
              boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.16)'
            })}
          >
            <TaskDetail
              nodeId={activeTask.nodeId}
              analysisId={analysisId}
              taskId={activeTask.id}
              closeAction={() => setActiveTask(null)}
              hideBackButton={true}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

const ExpandableTable: React.FC<{
  groups: Array<TaskGroup>
  onSelectItem: (item: Task) => void
  selectedItem?: Task | null
}> = ({groups, onSelectItem, selectedItem}) => {
  const {t} = useTranslation()

  const [childTableRef, {width, height}] = useMeasure<HTMLTableElement>()
  const tableRowRef = useRef<HTMLTableRowElement>(null)
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const [columnWidths, setColumnWidths] = useState<Array<number>>([])

  const onExpandChanged = useCallback(() => {
    const rows = tableBodyRef.current?.rows
    if (!rows) return

    let newColumnWidths = new Array<number>(4)

    for (const {cells} of rows) {
      if (cells.length > 2) {
        newColumnWidths = [
          cells[1].getBoundingClientRect().width,
          cells[2].getBoundingClientRect().width,
          cells[3].getBoundingClientRect().width
        ]
        break
      }
    }

    if (
      newColumnWidths &&
      (columnWidths.length !== newColumnWidths.length ||
        newColumnWidths.findIndex((w) => w !== columnWidths[w]) >= 0)
    ) {
      setColumnWidths(newColumnWidths)
    }
  }, [width, height, childTableRef])

  useEffect(() => {
    const cells = tableRowRef.current?.cells
    if (!cells) return
    const l = cells.length - 2

    for (let i = 0; i < l; i++) cells[i].style.width = `${columnWidths[i - 1]}px`
  }, [columnWidths, tableRowRef])

  return (
    <Table
      sx={{
        overflowY: 'scroll',
        tableLayout: 'fixed'
      }}
    >
      <TableHead
        sx={(theme) => ({
          background: theme.palette.grey['100'],
          borderRadius: 0.5,
          position: 'sticky',
          top: 0
        })}
      >
        <TableRow ref={tableRowRef}>
          <TableCell sx={{width: '31px'}} />
          <TableCell
            sx={(theme) => ({
              width: 'calc((100% - 30px) * 0.28)',
              color: theme.palette.grey['600'],
              paddingX: 2
            })}
          >
            {t('tables.tasksList.cause')} / {t('tables.tasksList.task')}
          </TableCell>
          <TableCell
            sx={(theme) => ({
              color: theme.palette.grey['600'],
              paddingX: 2,
              width: 'auto'
            })}
          >
            {t('tables.tasksList.description')}
          </TableCell>
          <TableCell
            sx={(theme) => ({
              color: theme.palette.grey['600'],
              paddingX: 2,
              textAlign: 'center',
              width: '80px'
            })}
          >
            {t('tables.tasksList.status')}
          </TableCell>
          <TableCell
            sx={(theme) => ({
              color: theme.palette.grey['600'],
              paddingX: 2,
              width: 'auto'
            })}
          >
            {t('label.department')}
          </TableCell>
          <TableCell
            sx={(theme) => ({
              color: theme.palette.grey['600'],
              paddingX: 2,
              textAlign: 'center',
              width: '135px',
              paddingRight: 2
            })}
          >
            {t('tables.tasksList.dueDate')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          fontSize: 16,
          cursor: 'pointer'
        }}
      >
        <TableRow key={'expandRow'}>
          <TableCell colSpan={6} sx={{paddingRight: 0, borderBottom: 'none'}}>
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '50vh'
              }}
            >
              <Table ref={childTableRef}>
                <TableBody ref={tableBodyRef}>
                  {groups.map((r) => (
                    <ExpandableTableGroupRow
                      group={r}
                      key={`group-row-${r.id}`}
                      onExpandChanged={onExpandChanged}
                      selectedGroupId={selectedItem?.nodeId}
                      onSelectItem={onSelectItem}
                      selectedItem={selectedItem}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

interface ExpandableTableRowProps {
  group: TaskGroup
  selectedGroupId?: number
  columnWidths?: Array<string>
  onSelectItem: (item: Task) => void
  selectedItem?: Task | null
  onExpandChanged?: (expanded: boolean) => void
}

export const ExpandableTableGroupRow: React.FC<ExpandableTableRowProps> = ({
  group,
  selectedGroupId,
  onSelectItem,
  selectedItem,
  onExpandChanged
}) => {
  const [expanded, setExpanded] = useState<boolean>(selectedGroupId === group.id)

  const items = group.tasks

  useEffect(() => {
    if (onExpandChanged) onExpandChanged(expanded)
  }, [onExpandChanged, expanded])

  return (
    <>
      <TableRow
        onClick={() => setExpanded(!expanded)}
        sx={{
          minHeight: '70px',
          boxShadow: ' inset 0px -1px 0px #EFF2F5',
          '&>td': {
            minHeight: '70px',
            paddingTop: 1.5,
            paddingBottom: 1.5
          }
        }}
      >
        <TableCell sx={{width: '16px', paddingX: 0}}>
          {expanded ? (
            <KeyboardArrowDown sx={(theme) => ({color: theme.palette.primary.light})} />
          ) : (
            <KeyboardArrowRight sx={(theme) => ({color: theme.palette.primary.light})} />
          )}
        </TableCell>
        <TableCell colSpan={5}>{group.title}</TableCell>
      </TableRow>
      {expanded &&
        items &&
        items.map((item) => (
          <TableRow
            key={`row-item-${item.id}`}
            onClick={() => onSelectItem(item)}
            sx={(theme) => ({
              backgroundColor: selectedItem?.id === item.id ? theme.palette.grey['100'] : 'unset',
              minHeight: '70px',
              boxShadow: ' inset 0px -1px 0px #EFF2F5',
              '&>td': {
                minHeight: '70px',
                paddingTop: 1.5,
                paddingBottom: 1.5
              }
            })}
          >
            <TableCell
              sx={{
                width: '16px',
                padding: 0,
                position: 'relative'
              }}
            >
              <Box
                sx={(theme) => ({
                  display: 'inline-block',
                  width: '16px',
                  backgroundColor: theme.palette.grey['100'],
                  top: 0,
                  left: 0,
                  bottom: 0,
                  position: 'absolute'
                })}
              />
            </TableCell>
            <TableCell sx={{width: '28%'}}>{item.title}</TableCell>
            <TableCell sx={{width: 'auto'}}>{item.description}</TableCell>
            <TableCell sx={{width: '80px'}}>
              <TaskStatus status={item.status} tSx={{fontSize: '14px'}} />
            </TableCell>
            <TableCell sx={{width: 'auto'}}>{item.assignee?.name || '-'}</TableCell>
            <TableCell
              sx={{
                width: '135px',
                paddingRight: 2,
                textAlign: 'right'
              }}
            >
              {item.dueDate ? moment(item.dueDate).format('L') : item.dueDate}
            </TableCell>
          </TableRow>
        ))}
    </>
  )
}
