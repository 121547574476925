import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantProvider, usePlant} from '../../kms/context/PlantContext'
import {SensorDetails} from '../../shared/containers/sensorDetails/SensorDetails'
import {SensorList} from '../../shared/containers/SensorList'
import {SensorsProvider} from '../../shared/context/SensorsContext'

export const PM = () => {
  const {t} = useTranslation()
  const {areas} = usePlant()

  return (
    <SensorsProvider areas={areas}>
      <>
        <PageTitle mb={0} data-test-id="kms-page-title" maxWidth="fit-content">
          {t('general.pmTitle')}
        </PageTitle>
        <Grid container spacing={2} mt={2}>
          <Grid xs={12} md={12} lg={5} xl={4}>
            <SensorList />
          </Grid>
          <Grid xs={12} md={12} lg={7} xl={8}>
            <SensorDetails />
          </Grid>
        </Grid>
      </>
    </SensorsProvider>
  )
}

export const PMContainer = () => {
  return (
    <PageContainer display="flex" flexDirection="column">
      <PlantProvider>
        <PM />
      </PlantProvider>
    </PageContainer>
  )
}
