import {TextField, Autocomplete} from '@mui/material'
import {isString} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSearchStoppages} from '../hooks/stoppages/useSearchStoppages'
import {CustomStoppageItem} from '../types/stoppage.types'

export interface SearchStoppageCodeProperties {
  onChange: (Stoppage) => void
}

export const SearchStoppageCode: React.FC<SearchStoppageCodeProperties> = ({onChange}) => {
  const {t} = useTranslation()
  const [search, setSearch] = useState<string>('')
  const [searchResults, setSearchResults] = useState<Array<CustomStoppageItem>>([])
  const {data} = useSearchStoppages(search)

  useEffect(() => {
    if (data) {
      setSearchResults(data.data)
    } else {
      setSearchResults([])
    }
  }, [data])

  return (
    <Autocomplete
      key={'key'}
      id="search-stoppages"
      freeSolo
      options={searchResults}
      onChange={(e, stoppage) => onChange(stoppage)}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue)
      }}
      inputValue={search}
      sx={{
        marginBottom: 2,
        maxWidth: 450
      }}
      getOptionLabel={(option) => (isString(option) ? option : `${option.code} - ${option.text}`)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('label.searchStoppageLabel')}
          placeholder={t('label.searchStoppagePlaceholder')}
        />
      )}
    />
  )
}
