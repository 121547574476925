import AdsClickIcon from '@mui/icons-material/AdsClick'
import {Alert, Backdrop, CircularProgress} from '@mui/material'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useNodeMoveAction} from '../../context/NodeMoveActionContext'

export const NodeMoveInfo = () => {
  const {cancelAction, apiActionLoading} = useNodeMoveAction()
  const {t} = useTranslation()

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        cancelAction()
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [cancelAction])

  return apiActionLoading ? (
    <Backdrop open={true}>
      <CircularProgress color="info" size={75} />
    </Backdrop>
  ) : (
    <Alert
      icon={<AdsClickIcon fontSize="inherit" />}
      variant="filled"
      onClose={() => cancelAction()}
      sx={{
        position: 'fixed',
        top: 200,
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 1000,
        backgroundColor: '#668099'
      }}
    >
      {t('node.moveActionInfo')}
    </Alert>
  )
}
