import {DndContext} from '@dnd-kit/core'
import {restrictToParentElement} from '@dnd-kit/modifiers'
import {KilnShellPart, MaterialFlowArrow} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {StationLayout} from '@hconnect/common/components/kmsStatus'
import {Box, Stack, Typography} from '@mui/material'
import {Dispatch, SetStateAction} from 'react'

import {Drive} from './Drive'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'
import {SignalsModalData} from './SignalsModal.types'
import {Station} from './Station'

export const KilnLayout = ({
  setSignalsModalData,
  setSignalsDisconnectWarning
}: {
  setSignalsModalData: Dispatch<SetStateAction<SignalsModalData | undefined>>
  setSignalsDisconnectWarning: Dispatch<
    SetStateAction<{
      open: boolean
      signals: string[]
      action: (() => void) | null
    }>
  >
}) => {
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()

  const handleOpen = (station: StationLayout) =>
    setSignalsModalData({type: 'station', layout: station})

  // we need space for station / drive overlays
  const positionYOffset = 85

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      onDragEnd={(event) => {
        dispatch({
          type: LayoutActionType.MOVE_X,
          payload: {id: event.active.id, deltaX: event.delta.x}
        })
      }}
    >
      <Stack>
        <Typography variant="subtitle1" alignSelf="center" color="#52667A">
          Material Flow
        </Typography>
        <MaterialFlowArrow x={0} y={0} materialFlow={kilnLayout.materialFlow} />
      </Stack>
      {/* ---------  KILN LAYOUT --------- */}
      <Box
        sx={{
          width: kilnLayout.referenceDimensions.width,
          height: kilnLayout.interactiveOverlayHeight,
          position: 'relative'
        }}
      >
        {/* KILN SHELL */}
        <Box
          sx={{
            position: 'absolute',
            top: kilnLayout.kilnShell.y + positionYOffset,
            left: kilnLayout.kilnShell.x,
            width: kilnLayout.kilnShell.width,
            height: kilnLayout.kilnShell.height
          }}
        >
          <KilnShellPart
            id={kilnLayout.kilnShell.id}
            x={0}
            y={0}
            height={kilnLayout.kilnShell.height}
            width={kilnLayout.kilnShell.width}
          />
        </Box>
        {/* STATIONS */}
        {kilnLayout.stations.map((station) => (
          <Station
            key={station.id}
            station={station}
            positionYOffset={positionYOffset}
            handleModalOpen={() => handleOpen(station)}
          />
        ))}
        {/* DRIVE */}
        <Drive
          positionYOffset={positionYOffset}
          onConnectedClick={() =>
            setSignalsModalData({
              type: 'drive',
              layout: {...kilnLayout.drive, flip: kilnLayout.materialFlow !== 'rightToLeft'}
            })
          }
          setSignalsDisconnectWarning={setSignalsDisconnectWarning}
        />
      </Box>
    </DndContext>
  )
}
