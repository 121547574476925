import React, {CSSProperties, MouseEventHandler} from 'react'

import {CanvasMode, CanvasNode, Node} from '../../types/canvasNodes.types'

const _wrapText = (
  graphNode: Node,
  x: number,
  y: number,
  centerX: number,
  centerY: number,
  stoppageCode?: string
) => {
  const words = graphNode.title.split(' ')
  const lines: Array<string> = []
  let line: Array<string> = []

  for (const word of words) {
    line.push(word)
    const actualText = line.join(' ')
    if (actualText.length > 30 && line.length !== 1) {
      line.pop()
      lines.push(line.join(' '))
      line = [word]
    }
  }

  lines.push(line.join(' '))

  const result = lines.map((l, index) => (
    <tspan
      key={`graphNodeText-${graphNode.id}-${index}`}
      x={x + centerX + (graphNode.rootCauseType && (lines.length > 1 || l.length >= 25) ? 12 : 0)}
      y={y + centerY - (lines.length / 2 - 0.75) * 16 + index * 16}
    >
      {l}
    </tspan>
  ))
  if (stoppageCode) {
    result.push(
      <tspan
        key={`graphNodeText-${graphNode.id}-${lines.length}`}
        x={x + centerX + (graphNode.rootCauseType ? 12 : 0)}
        y={y + centerY - (lines.length / 2 - 0.75) * 16 + lines.length * 16 + 5}
        style={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: '12px'
        }}
      >
        {stoppageCode}
      </tspan>
    )
  }

  return result
}
export interface NodeTextProps {
  node: CanvasNode
  nodeWidth: number
  nodeHeight: number
  styles: CSSProperties
  canvasMode: CanvasMode
  setParentActive: MouseEventHandler<SVGTextElement>
  setParentHover: (a?: CanvasNode) => void
  stoppageCode?: string
}

export const NodeText: React.FC<NodeTextProps> = ({
  node,
  nodeWidth,
  nodeHeight,
  styles,
  setParentActive,
  setParentHover,
  canvasMode,
  stoppageCode
}) => {
  const x = node.cx ? node.cx : 0
  const y = node.cy ? node.cy : 0
  const centerX = nodeWidth / 2
  const centerY = nodeHeight / 2 - 8

  return (
    <text
      style={styles}
      onClick={setParentActive}
      textAnchor="middle"
      x={x + centerX}
      y={y + centerY}
      width={nodeWidth}
      onMouseEnter={() => canvasMode === CanvasMode.default && setParentHover(node)}
      onMouseLeave={() => setParentHover(undefined)}
    >
      {_wrapText(node, x, y, centerX, centerY, stoppageCode)}
    </text>
  )
}
