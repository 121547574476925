import {SignalData} from '@hconnect/common/components/kmsStatus/types'
import {StructureNode} from '@settings/modules/common/types'
import React, {useContext, createContext, useMemo, useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

import {useGetFilteredSignals} from '../hooks/sensorContext/useGetFilteredSignals'
import {useGetSignalsMetadata} from '../hooks/sensorContext/useGetSignalsMetadata'

interface ISensorsContext {
  sensorData: SignalData[] | undefined
  selectedIds: string[]
  selectedSensors: SignalData[]
  isLoading: boolean
  isError: boolean
  toggleSensorSelected: (sensorId: string) => void
}

export const SensorsContext = createContext<ISensorsContext>(null as unknown as ISensorsContext)

type SensorsProviderProps = {
  children: React.ReactNode
  kilnId?: string
  areas?: StructureNode[]
}

export const SensorsProvider = ({children, kilnId, areas}: SensorsProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const signalsMetadata = useGetSignalsMetadata(areas)
  const {filteredSignalData, isLoading, isError} = useGetFilteredSignals(kilnId)
  const [signalData, setSignalData] = useState<SignalData[]>([])

  useEffect(() => {
    if (filteredSignalData && filteredSignalData.length) setSignalData(filteredSignalData)
    if (signalsMetadata && signalsMetadata.length) setSignalData(signalsMetadata)
  }, [filteredSignalData, signalsMetadata])

  const selectedIds = useMemo(() => searchParams.get('selected')?.split(',') ?? [], [searchParams])

  const selectedSensors = useMemo(
    () =>
      Array.isArray(signalData)
        ? signalData?.filter((sensor) => selectedIds.includes(sensor.localName)) ?? []
        : [],
    [signalData, selectedIds]
  )

  const toggleSensorSelected = (sensorId: string) => {
    setSearchParams((params) => {
      const selected = new Set(params.get('selected')?.split(','))
      if (selected.has(sensorId)) {
        selected.delete(sensorId)
      } else {
        selected.add(sensorId)
      }
      if (selected.size === 0) {
        params.delete('selected')
      } else {
        params.set('selected', Array.from(selected).join(','))
      }
      return params
    })
  }

  return (
    <SensorsContext.Provider
      value={{
        sensorData: signalData,
        selectedIds,
        selectedSensors,
        isLoading,
        isError,
        toggleSensorSelected
      }}
    >
      {children}
    </SensorsContext.Provider>
  )
}

export const useSensors = () => useContext(SensorsContext)
