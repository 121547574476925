import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {SimpleDatePicker} from '@hconnect/uikit/src/lib2/components/simpleDatePicker'
import {SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Filter, DateRange} from '../../types/filter.types'

interface DatePickerFilterProps extends Filter<Date | DateRange> {
  rangeAvailable: boolean
  value?: Date | DateRange | null
}

export const DatePickerFilter: React.FC<DatePickerFilterProps> = ({
  id,
  label,
  value,
  handleChange,
  rangeAvailable
}) => {
  const {t} = useTranslation()
  let date: Date | undefined | null = undefined
  let dateRange: DateRange | undefined = undefined

  if (rangeAvailable) {
    if (value && !(value instanceof Date)) {
      dateRange = value
    }
  } else {
    if (value && value instanceof Date) {
      date = value
    } else {
      date = null
    }
  }

  return (
    <SimpleDatePicker
      data-test-id={`datepicker-${id}`}
      fullWidth
      label={t(label)}
      sx={datePickerOnBlueSx}
      handleRangeChange={rangeAvailable ? handleChange : undefined}
      handleDateChange={!rangeAvailable ? handleChange : undefined}
      date={date}
      dateRange={dateRange}
      showClearButton
    />
  )
}

const datePickerOnBlueSx: SxProps<Theme> = (theme) => ({
  height: 58,
  '& > button': {
    borderColor: customThemeConstants().palette.mediumInputBorder,
    '&:hover': {
      borderColor: theme.palette.common.white
    }
  },
  '& .MuiButton-root:hover': {
    borderColor: `${theme.palette.common.white}!important`
  },
  '& .MuiTypography-root, .MuiSvgIcon-root': {
    color: theme.palette.common.white
  },
  '& .MuiTypography-h4': {
    fontSize: theme.typography.body2
  },
  '& .MuiTypography-caption': {
    opacity: customThemeConstants().labelOpacity
  },
  '& .MuiIconButton-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
})
