import {Close} from '@mui/icons-material'
import Check from '@mui/icons-material/Check'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Stack, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import moment, {Moment} from 'moment'
import {useSnackbar} from 'notistack'
import React, {useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {
  BaseTextField,
  ControlledAutocomplete,
  ControlledDatePicker,
  ControlledDateTimePicker,
  ControlledTextField
} from '../../../shared/components/FormInputs'
import {StyledTooltip} from '../../../shared/components/styled'
import {usePatchAnalysis} from '../../hooks/analysis/usePatchAnalysis'
import {useUpdateNode} from '../../hooks/node/useUpdateNode'
import {useSearchStoppages} from '../../hooks/stoppages/useSearchStoppages'
import {analysisAreaTypes, analysisCategories, AnalysisFull} from '../../types/analysis.types'
import {File, NodeDetailType} from '../../types/nodeDetail.types'

import {NodeAttachments} from './nodeForm/NodeAttachments'
import { AnalysisDescriptionTooltip } from '../../components/AnalysisDescriptionTooltip'

type NodeFormData = {
  title: string
  description: string
  equipmentName?: string
  category?: {id: string; label: string} | ''
  areaTypeCode?: {id: string; label: string} | ''
  stoppageCode: {id: string; label: string} | ''
  stoppageStart: Moment | null
  stoppageEnd: Moment | null
  dueDate: Moment | null
}

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['clean']
  ]
}

export const EditRootNode = ({
  node,
  closeForm,
  analysisData
}: {
  node: NodeDetailType
  closeForm: () => void
  analysisData: AnalysisFull
}) => {
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {mutateAsync: updateNode} = useUpdateNode()
  const {mutateAsync: updateAnalysis} = usePatchAnalysis()
  const {data: stoppageCodes, isLoading: stoppageCodesLoading} = useSearchStoppages('')

  const [files, setFiles] = useState<Array<File>>([])

  const stoppageCodeOptions = useMemo(
    () =>
      stoppageCodes?.data.map((stoppage) => ({
        id: stoppage.code,
        label: `${stoppage.code} - ${stoppage.text}`
      })) ?? [],
    [stoppageCodes]
  )

  const defaultValues: NodeFormData = {
    title: node.title,
    description: node.description,
    stoppageCode: analysisData.stoppage?.stoppageCode?.code
      ? {
          id: analysisData.stoppage?.stoppageCode?.code,
          label: `${analysisData.stoppage?.stoppageCode?.code} - ${analysisData.stoppage?.stoppageCode?.text}`
        }
      : '',
    stoppageStart: analysisData?.stoppage?.start ? moment(analysisData?.stoppage?.start) : null,
    stoppageEnd: analysisData?.stoppage?.end ? moment(analysisData?.stoppage?.end) : null,
    dueDate: analysisData?.dueDate ? moment(analysisData?.dueDate) : null,
    category: analysisData?.category
      ? {
          id: analysisData?.category,
          label: analysisCategories.find((t) => t.id === analysisData.category)?.label ?? ''
        }
      : '',
    areaTypeCode: analysisData?.areaTypeCode
      ? {
          id: analysisData.areaTypeCode,
          label: analysisAreaTypes.find((t) => t.id === analysisData.areaTypeCode)?.label ?? ''
        }
      : '',
    equipmentName: analysisData?.equipmentName ? analysisData.equipmentName : ''
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: {isDirty, isSubmitting}
  } = useForm({
    defaultValues
  })

  const prepareNodeData = (data: NodeFormData) => ({
    id: node.id,
    order: node.order ? node.order : 0,
    parent: node.parent ? node.parent : 0,
    description: data.description,
    title: data.title,
    newAttachments: files
  })

  const parseAreaTypeCode = (data: NodeFormData) =>
    data.areaTypeCode && 'id' in data.areaTypeCode ? data.areaTypeCode.id : data.areaTypeCode

  const onSubmit = async (data: NodeFormData) => {
    try {
      await updateNode(prepareNodeData(data))
      if (node.permissions.canEdit) {
        await updateAnalysis({
          Id: analysisData.id,
          stoppageId: analysisData.stoppage?.id,
          deleteDueDate: data.dueDate === null,
          ...(!data.stoppageCode && {deleteStoppageCode: true}),
          ...(!data.stoppageStart && {DeleteStoppageStart: true}),
          ...(!data.stoppageEnd && {DeleteStoppageEnd: true}),
          ...(data.stoppageCode && {stoppageCode: data.stoppageCode.id}),
          ...(data.stoppageStart && {stoppageStart: data.stoppageStart.toISOString()}),
          ...(data.stoppageEnd && {stoppageEnd: data.stoppageEnd.toISOString()}),
          ...(data.dueDate && {dueDate: data.dueDate.toISOString()}),
          ...(data.category && {category: data.category.label ? data.category.id : data.category}),
          ...(data.areaTypeCode && {areaTypeCode: parseAreaTypeCode(data)}),
          ...(data.equipmentName && {equipmentName: data.equipmentName})
        })
      }
      closeForm()
    } catch (error) {
      let message = t('errors.genericActionError')
      if (error instanceof AxiosError) {
        message += `: ${error?.message}`
      }
      enqueueSnackbar(message, {
        variant: 'error',
        onClick: () => closeSnackbar()
      })
    }
  }

  const validateDate = (value: Moment | undefined) => {
    if (value?.isValid?.() || !value) {
      return true
    }
    return t('shouldBe.validGeneric')
  }

  const descriptionValue = watch('description')
  const stoppageCodeValue = watch('stoppageCode')

  const stoppageEditDisabled = analysisData.type !== 'custom' || !node.permissions.canEdit

  return (
    <Stack pr={2} spacing={3}>
      <ControlledTextField
        control={control}
        label={t('label.title')}
        formDataName="title"
        rules={{
          required: t('shouldBe.notEmpty'),
          maxLength: {value: 80, message: t('shouldBe.notLonger80')}
        }}
        disabled={!node.permissions.canEdit}
      />
      <ControlledAutocomplete
        formDataName="category"
        label={t('label.searchAnalysisCategoryLabel')}
        control={control}
        options={analysisCategories}
        disableClearable={false}
        AutocompleteProps={{
          disabled: !node.permissions.canEdit
        }}
      />
      <ControlledAutocomplete
        formDataName="areaTypeCode"
        label={t('label.searchAnalysisAreaTypeLabel')}
        control={control}
        options={analysisAreaTypes}
        disableClearable={false}
        AutocompleteProps={{
          disabled: !node.permissions.canEdit
        }}
      />
      <ControlledAutocomplete
        formDataName="stoppageCode"
        label={t('stoppage.detail.stoppageCode')}
        control={control}
        options={stoppageCodeOptions}
        disableClearable={false}
        AutocompleteProps={{
          loading: stoppageCodesLoading,
          disabled: stoppageEditDisabled
        }}
      />
      <Stack direction="row" spacing={2}>
        <ControlledDateTimePicker
          control={control}
          label={t('analysis.new.stoppageStart')}
          formDataName="stoppageStart"
          rules={{validate: validateDate}}
          disabled={stoppageEditDisabled}
        />
        <ControlledDateTimePicker
          control={control}
          label={t('analysis.new.stoppageEnd')}
          formDataName="stoppageEnd"
          rules={{validate: validateDate}}
          disabled={stoppageEditDisabled}
        />
      </Stack>
      <ControlledTextField
        control={control}
        formDataName={'equipmentName'}
        label={t('analysis.new.equipment')}
        disabled={stoppageEditDisabled}
      />
      <ControlledDatePicker
        control={control}
        label={t('tables.tasksList.dueDate')}
        formDataName="dueDate"
        shouldDisableDate={(date) => {
          const now = moment()
          return date <= now
        }}
        rules={{
          validate: validateDate
        }}
        disabled={!node.permissions.canEdit}
      />
      <Stack sx={{maxWidth: '30%'}}>
        <BaseTextField
          disabled
          label={t('stoppage.detail.asset')}
          value={
            stoppageCodeValue
              ? stoppageCodes?.data.find((stoppage) => stoppage.code === stoppageCodeValue.id)
                  ?.type ?? '-'
              : '-'
          }
        />
      </Stack>
      {node.permissions.canEdit ? (
        <Controller
          name="description"
          control={control}
          rules={{
            validate: (value) => {
              const strippedValue = value?.replaceAll(/(<([^>]+)>)/gi, '')
              if (!value || strippedValue.length === 0) {
                return t('shouldBe.notEmpty')
              }
              if (strippedValue.length > 5000) {
                return t('shouldBe.notLonger5000')
              }
              return true
            }
          }}
          render={({field: {ref, onChange}, fieldState: {invalid, error}}) => (
            <Box ref={ref}>
              <AnalysisDescriptionTooltip top={35} left={440} />
              <ReactQuill
                modules={MODULES}
                theme={'snow'}
                defaultValue={descriptionValue}
                onChange={(nextDesc) => onChange(nextDesc)}
                placeholder={`${t('label.description')} *`}
                formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
              />
              {invalid && <Box>{error?.message}</Box>}
              <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
                {descriptionValue?.length
                  ? descriptionValue.replaceAll(/(<([^>]+)>)/gi, '').length
                  : 0}
                /{5000}
              </Box>
            </Box>
          )}
        />
      ) : (
        <ReactQuill value={node.description} readOnly={true} theme={'bubble'} />
      )}
      <NodeAttachments files={files} setFiles={setFiles} />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          startIcon={<Close />}
          variant="outlined"
          color="secondary"
          onClick={closeForm}
          data-test-id="node-form-cancel-button"
        >
          {t('buttons.cancel')}
        </Button>
        <LoadingButton
          startIcon={<Check />}
          color="primary"
          variant="contained"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
          data-test-id="node-form-save-button"
          loading={isSubmitting}
          loadingPosition="start"
          disabled={!isDirty && !files.length}
        >
          <span>{t('buttons.save')}</span>
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
