import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import type {UseMutateAsyncFunction} from 'react-query/types/react'

export const downloadFile = async ({
  getData,
  fileName
}: {
  getData: UseMutateAsyncFunction<Blob, AxiosError<HttpError, any>, void, unknown>
  fileName: string
}) => {
  const data = await getData()
  if (!data) return

  const url = URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', fileName)
  tempLink.click()
}
