import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface NoRootCauseModalProps {
  open: boolean
  onClose: Function
  onContinue: Function
}

export const NoRootCauseModal: React.FC<NoRootCauseModalProps> = ({open, onClose, onContinue}) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{t('analysis.noRootCause.title')}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('analysis.noRootCause.text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('buttons.no')}</Button>
        <Button onClick={() => onContinue()}>{t('buttons.yes')}</Button>
      </DialogActions>
    </Dialog>
  )
}
