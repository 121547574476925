import ClearIcon from '@mui/icons-material/Clear'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {Button, Box, Fade, Menu, MenuItem, Checkbox, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FilterAction, FilterActionType, FilterOptionVisibility} from '../../types/filter.types'

export interface FilterSelectButtonProps {
  filtersVisibility: Array<FilterOptionVisibility>
  dispatch: React.Dispatch<FilterAction>
}

export const FilterSelectButton: React.FC<FilterSelectButtonProps> = ({
  filtersVisibility,
  dispatch
}) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const filterCheckClick = (filterId: string) => {
    const filter = filtersVisibility.find((f) => f.id === filterId)
    if (!filter) return

    if (filter.visible) {
      dispatch({
        type: FilterActionType.REMOVE,
        payload: {filterId: filterId, value: null}
      })
    } else {
      dispatch({
        type: FilterActionType.SET_FILTER_VISIBLE,
        payload: {filterId: filterId, value: null}
      })
    }
  }

  const unselectAllClick = () => {
    dispatch({
      type: FilterActionType.REMOVE_ALL,
      payload: {filterId: '', value: null}
    })
  }

  const isFilterChecked = (filterId: string): boolean => {
    const active = filtersVisibility.find((f) => f.id === filterId)
    return active ? active.visible : false
  }
  return (
    <Box>
      <StyledButton
        startIcon={<FilterAltOutlinedIcon />}
        onClick={handleClick}
        variant="contained"
        data-test-id={'open-filters-options-button'}
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {t('filters.addButton')}
      </StyledButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          key={'menu-label'}
          style={{backgroundColor: 'transparent', cursor: 'default'}}
          sx={{pl: 3, pr: 3, mt: 1}}
        >
          <Typography variant="caption">{t('filters.text')}</Typography>
        </MenuItem>
        {filtersVisibility.map((filter) => {
          return (
            <MenuItem
              key={`check-box-${filter.id}`}
              data-test-id={`filter-option-check-${filter.id}`}
              onClick={() => filterCheckClick(filter.id)}
              sx={{height: 40}}
            >
              <Checkbox checked={isFilterChecked(filter.id)} size="small" />
              <Typography variant="body1"> {t(filter.label)}</Typography>
            </MenuItem>
          )
        })}
        <MenuItem
          key={'menu-button'}
          sx={{pl: 3, pr: 3, pb: 2, pt: 1}}
          style={{backgroundColor: 'transparent', cursor: 'default'}}
        >
          <Button
            variant="outlined"
            color="secondary"
            data-test-id={'close-all-filters-options-button'}
            startIcon={<ClearIcon />}
            onClick={unselectAllClick}
            style={{width: '100%'}}
          >
            {t('filters.clearButton')}
          </Button>
        </MenuItem>
      </Menu>
    </Box>
  )
}

const StyledButton = styled(Button)({
  fontWeight: 500,
  fontSize: '15px',
  width: '150px',
  height: '58px',
  background: 'rgba(255, 255, 255, 0.05)',
  border: '1px solid rgba(255, 255, 255, 0.15)',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderColor: 'rgba(255, 255, 255, 0.6)',
    boxShadow: 'none'
  }
})
