import {useEffect, useReducer} from 'react'

import {decodeFromQueryString} from '../../utils/strings'

export type OrderByApiProperty = {
  property: string
  descending: boolean
}

type OrderByActionType = 'TOGGLE' | 'CONFIG_CHANGE'

const createInitialState = ({
  availableColumns,
  orderByQuery
}: {
  availableColumns: string[]
  orderByQuery: string | null
}) => {
  const parsedQueryValues: OrderByApiProperty[] = decodeFromQueryString(orderByQuery)
  const initialState = parsedQueryValues.filter((urlConfig) =>
    availableColumns.includes(urlConfig.property)
  )
  return initialState
}

const orderByReducer = (
  state: OrderByApiProperty[],
  action: {type: OrderByActionType; payload: string | string[]}
): OrderByApiProperty[] => {
  switch (action.type) {
    case 'TOGGLE': {
      const {payload: property} = action
      if (typeof property !== 'string') throw new Error('Invalid payload')
      const currentItem = state.find((item) => item.property === property)
      // First click: sort ascending
      if (!currentItem) {
        return [...state, {property: property, descending: false}]
      }
      // Second click: sort descending
      else if (!currentItem.descending) {
        return [
          ...state.filter((column) => column.property !== property),
          {property: property, descending: true}
        ]
      }
      // Third click: remove sorting
      else {
        return [...state.filter((column) => column.property !== property)]
      }
    }
    case 'CONFIG_CHANGE': {
      const {payload: availableColumns} = action
      return state.filter((column) => availableColumns.includes(column.property))
    }
    default:
      return state
  }
}

export const useListOrderBy = <T extends string>(
  availableColumns: T[],
  orderByQuery: string | null
) => {
  const [listOrder, dispatch] = useReducer(
    orderByReducer,
    {availableColumns, orderByQuery},
    createInitialState
  )

  const handleOrderToggle = (column: T) => {
    dispatch({type: 'TOGGLE', payload: column})
  }

  useEffect(() => {
    dispatch({type: 'CONFIG_CHANGE', payload: availableColumns})
  }, [JSON.stringify(availableColumns)])

  return {listOrder, handleOrderToggle}
}
