import {DndContext, DragEndEvent, closestCenter} from '@dnd-kit/core'
import {restrictToFirstScrollableAncestor} from '@dnd-kit/modifiers'
import {SortableContext, rectSortingStrategy} from '@dnd-kit/sortable'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {isSameDate} from '@hconnect/uikit'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor'
import {Box, Button, IconButton, Modal, Stack, Typography} from '@mui/material'
import Grid from '@mui/material/Grid'
import {Chart} from 'chart.js/auto'
import {debounce} from 'lodash'
import moment, {Moment} from 'moment'
import React, {MutableRefObject, useCallback, useEffect, useState} from 'react'
import {isInclusivelyAfterDay} from 'react-dates'
import {useTranslation} from 'react-i18next'
import {useWindowSize} from 'react-use'

import {RangeDatePicker} from '../../../../shared/components/filter/RangeDatePicker'
import {useGlobalContext} from '../../../../shared/hooks/useGlobalContext'
import {CardWrapper} from '../../components/styled'
import {useSensors} from '../../context/SensorsContext'
import {ActiveView} from '../../types/sensorDetails.types'
import {getSensorGroupString} from '../../utils/sensor.utils'
import {MultiScalableChart} from '../charts/MultiScalableChart'
import {SortableChartWrapper} from '../charts/SensorChartWrapper'
import {SingleScalableChart} from '../charts/SingleScalableChart'

import {ChartSettings} from './ChartSettings'

const CHART_MIN_WIDTH = 400

interface PopoutViewProps {
  open: boolean
  combinedSensors: string[][]
  handleRemoveCombineGroup: (groupIndex: number) => void
  setActiveView: React.Dispatch<React.SetStateAction<ActiveView>>
  sensorsOrder: string[]
  onDragEnd: (e: DragEndEvent) => void
  scaleToShowThreshold: boolean
  setScaleToShowThreshold: React.Dispatch<React.SetStateAction<boolean>>
}

const handleHover = (event: MouseEvent, chart: Chart, chartRefs: any[]) => {
  if (chartRefs.length > 1) {
    chartRefs.forEach((ref) => {
      const chartRef: Chart | null = ref.current
      if (chartRef) {
        if (chartRef && chartRef !== chart) {
          const elements = chart.getElementsAtEventForMode(event, 'nearest', {}, true)
          if (elements.length > 0) {
            const element = elements[0]
            const datasetIndex = element.datasetIndex
            const index = element.index
            chartRef.tooltip?.setActiveElements([{datasetIndex, index}], {
              x: 0,
              y: 0
            })
          } else {
            chartRef.tooltip?.setActiveElements([], {
              x: 0,
              y: 0
            })
          }
          chartRef.update()
        }
      }
    })
  }
}

const clearTooltips = (chartRefs: any[]) => {
  if (chartRefs.length > 1) {
    chartRefs.forEach((ref) => {
      const chartRef: Chart | null = ref.current
      if (chartRef) {
        chartRef.tooltip?.setActiveElements([], {
          x: 0,
          y: 0
        })
        chartRef.update()
      }
    })
  }
}

export const PopoutView = ({
  open,
  combinedSensors,
  setActiveView,
  sensorsOrder,
  onDragEnd,
  handleRemoveCombineGroup,
  scaleToShowThreshold,
  setScaleToShowThreshold
}: PopoutViewProps) => {
  const {width: windowWidth} = useWindowSize()
  const [popoutColumns, setPopoutColumns] = useState(1)
  const [range, setRange] = useState<{timeFrom: Moment; timeTo: Moment}>({
    timeFrom: moment().subtract(696, 'hours'),
    timeTo: moment()
  })
  const {state} = useGlobalContext()
  const {toggleSensorSelected, selectedSensors} = useSensors()

  const maxColumns = Math.min(Math.floor(windowWidth / CHART_MIN_WIDTH), selectedSensors.length)

  const arrLength = sensorsOrder.length
  const [chartRefs, setElRefs] = React.useState<MutableRefObject<Chart | null>[]>([])
  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill(null)
        .map((_, i) => elRefs[i] || React.createRef())
    )
  }, [arrLength])

  useEffect(() => {
    if (popoutColumns > maxColumns) {
      setPopoutColumns(maxColumns)
    }
  }, [maxColumns, popoutColumns])

  useEffect(() => {
    if (state && state.user) {
      trackEvent('kmsPopoutView', {
        userId: state.user.userId,
        country: state.user.countryCode,
        product: 'Maintain',
        subProduct: 'KMS'
      })
    }
  }, [state])

  const handleRangeSelect = useCallback(
    ({timeFrom, timeTo}: {timeFrom: Moment; timeTo: Moment}) => {
      setRange({timeFrom, timeTo})
    },
    []
  )

  const handleSensorRemove = (sensorId: string) => {
    toggleSensorSelected(sensorId)
    if (selectedSensors.length <= popoutColumns && popoutColumns > 1) {
      setPopoutColumns((prev) => prev - 1)
    }
  }

  const resetZoom = () => {
    if (range.timeTo.diff(range.timeFrom, 'hours') >= 696) return
    const midTime = range.timeFrom
      .clone()
      .add(range.timeTo.diff(range.timeFrom, 'minutes') / 2, 'minutes')

    const timeTo = moment.min([midTime.endOf('month'), moment()])
    const timeFrom = timeTo.clone().subtract(696, 'hours')
    handleRangeSelect({timeFrom, timeTo})
  }

  const debouncedHover = debounce(handleHover, 50)

  return (
    <Modal
      open={open}
      onClose={() => setActiveView('default')}
      aria-labelledby="sensors-popout-view"
    >
      <CardWrapper
        sx={{
          overflow: 'none',
          maxHeight: 'calc(100vh - 30px)',
          maxWidth: 'calc(100vw - 30px)',
          width: 'calc(100vw - 30px)',
          minWidth: '800px'
        }}
        px={3}
        py={3}
        m={'15px'}
      >
        <Stack spacing={4} width="100%">
          <PopoutViewHeader
            range={range}
            maxColumns={maxColumns}
            popoutColumns={popoutColumns}
            resetZoom={resetZoom}
            scaleToShowThreshold={scaleToShowThreshold}
            setActiveView={setActiveView}
            setPopoutColumns={setPopoutColumns}
            setRange={setRange}
            setScaleToShowThreshold={setScaleToShowThreshold}
          />
          <Box
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              pr: 1,
              height: 'fit-content',
              width: '100%'
            }}
          >
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
              modifiers={[restrictToFirstScrollableAncestor]}
            >
              <SortableContext items={sensorsOrder} strategy={rectSortingStrategy}>
                <Grid container spacing={2} key={popoutColumns}>
                  {sensorsOrder.map((id, index) => {
                    if (id.startsWith('combined')) {
                      const groupIndex = parseInt(id.split('-')[1])
                      const sensorIds: string[] = combinedSensors[groupIndex]
                      if (!sensorIds?.length) return null
                      return (
                        <Grid item xs={12 / popoutColumns} key={id}>
                          <SortableChartWrapper
                            id={id}
                            title={`Multiple Sensors (${sensorIds.join(', ')})`}
                            onRemove={() => handleRemoveCombineGroup(groupIndex)}
                          >
                            <MultiScalableChart
                              sensorIds={sensorIds}
                              range={range}
                              rangeSelectCallback={handleRangeSelect}
                              chartRef={chartRefs[index]}
                            />
                          </SortableChartWrapper>
                        </Grid>
                      )
                    }
                    const sensor = selectedSensors.find((sensor) => sensor.localName === id)
                    return (
                      sensor && (
                        <Grid item xs={12 / popoutColumns} key={id}>
                          <SortableChartWrapper
                            id={id}
                            subtitle={getSensorGroupString(sensor)}
                            title={`${sensor.localName} ${sensor.description}`}
                            onRemove={() => handleSensorRemove(sensor.localName)}
                            onMouseLeave={() => clearTooltips(chartRefs)}
                          >
                            <SingleScalableChart
                              sensorId={id}
                              range={range}
                              rangeSelectCallback={handleRangeSelect}
                              scaleToShowThreshold={scaleToShowThreshold}
                              chartRef={chartRefs[index]}
                              onHover={(event, _, chart) => debouncedHover(event, chart, chartRefs)}
                            />
                          </SortableChartWrapper>
                        </Grid>
                      )
                    )
                  })}
                </Grid>
              </SortableContext>
            </DndContext>
          </Box>
        </Stack>
      </CardWrapper>
    </Modal>
  )
}

const PopoutViewHeader = ({
  range,
  setRange,
  popoutColumns,
  setPopoutColumns,
  resetZoom,
  maxColumns,
  scaleToShowThreshold,
  setScaleToShowThreshold,
  setActiveView
}) => {
  const {t} = useTranslation()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: 'background.paper',
        maxWidth: 'calc(100vw - 65px)'
      }}
    >
      <Typography variant="h4" data-test-id="popout-view-title">
        {t('sensorDetails.heading')}
      </Typography>
      <Box display="flex" gap={'12px'} alignItems="center">
        <RangeDatePicker
          isOutsideRange={(day: Date) =>
            isInclusivelyAfterDay(moment(day), moment()) && !isSameDate(day, new Date())
          }
          dateRange={[range.timeFrom.toDate() as Date, range.timeTo.toDate() as Date]}
          onChange={(value) => {
            if (value.startDate && value.endDate) {
              const endDate = moment(value.endDate)
              const startDate = moment(value.startDate)

              if (endDate.isValid()) {
                const dateDiff = endDate.diff(startDate, 'hours')
                setRange({
                  timeFrom: startDate,
                  timeTo: dateDiff < 6 ? endDate.add(6, 'hours') : endDate
                })
              } else {
                setRange({
                  timeFrom: startDate,
                  timeTo: startDate.endOf('day')
                })
              }
            } else {
              setRange({
                timeFrom: moment(value),
                timeTo: moment(value).endOf('day')
              })
            }
          }}
        />
        <Box
          display="flex"
          justifyContent={'space-between'}
          sx={{
            border: '1.5px solid rgba(0, 39, 77, 0.15)',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px 0px rgba(31, 73, 94, 0.08) inset',
            background: '#fbfbfc',
            maxWidth: 130,
            width: 'fit-content',
            height: 48,
            p: '5px'
          }}
          data-test-id="popout-columns"
        >
          <IconButton
            color="primary"
            disabled={popoutColumns <= 1}
            onClick={() => setPopoutColumns((prev) => Math.max(prev - 1, 1))}
            edge="end"
          >
            <RemoveIcon />
          </IconButton>
          <Stack alignItems="center">
            <Typography variant="caption" color="text.disabled">
              Columns
            </Typography>
            <Typography variant="h5">{popoutColumns}</Typography>
          </Stack>
          <IconButton
            color="primary"
            disabled={popoutColumns >= maxColumns}
            onClick={() => setPopoutColumns((prev) => prev + 1)}
            edge="end"
            data-test-id="add-column-btn"
          >
            <AddIcon />
          </IconButton>
        </Box>
        <ChartSettings
          scaleToShowThreshold={scaleToShowThreshold}
          setScaleToShowThreshold={setScaleToShowThreshold}
        />
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            height: '48px',
            p: 1,
            border: '1px solid #00274D1A',
            borderRadius: '6px',
            boxShadow: '0px 2px 2px 0px #00000014'
          }}
          startIcon={<YoutubeSearchedForIcon />}
          onClick={resetZoom}
          data-test-id="reset-zoom-btn"
        >
          Reset zoom
        </Button>
        <IconButton onClick={() => setActiveView('default')} data-test-id="close-btn">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
