import {useQuery} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

export const QueryKey = 'structure'

type UpmEntityType = 'Plant' | 'Area' | 'ProductionLine' | 'Department' | 'Equipment'

interface StructureNode {
  id: string
  name: string
  signalCount: number
  entityType: UpmEntityType
  children: StructureNode[] | null
  upmId: string
  entityLabel: string
}

export const useGetPlantStructure = () => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')

  return useQuery({
    queryKey: [QueryKey, plantId],
    queryFn: async () => {
      const path = `upm/structure/plant/${plantId}?includeEquipment=true`
      return (await axiosInstance.get<StructureNode>(path)).data
    },
    staleTime: Infinity
  })
}
