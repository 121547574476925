import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import Grid from '@mui/material/Unstable_Grid2'
import {useTranslation} from 'react-i18next'
import {Outlet} from 'react-router'

import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {SensorDetails} from '../../shared/containers/sensorDetails/SensorDetails'
import {SensorsProvider} from '../../shared/context/SensorsContext'
import {ModelWrapper} from '../containers/ModelWrapper'
import {KilnProvider} from '../context/KilnContext'

export const KMS = () => {
  const {t} = useTranslation()

  return (
    <>
      <PageTitle mb={0} data-test-id="kms-page-title" maxWidth="fit-content">
        {t('general.kmsTitle')}
      </PageTitle>
      <Grid container spacing={2} mt={2}>
        <ModelWrapper />
        <Grid xs={12} md={12} lg={7} xl={8}>
          <SensorDetails />
        </Grid>
      </Grid>
    </>
  )
}

export const KmsContainer = () => {
  const kilnId = useUrlParam('kilnId')

  return (
    <PageContainer display="flex" flexDirection="column">
      <KilnProvider kilnId={kilnId}>
        <SensorsProvider kilnId={kilnId}>
          <Outlet />
        </SensorsProvider>
      </KilnProvider>
    </PageContainer>
  )
}
