import CloseIcon from '@mui/icons-material/Close'
import {Stack, Box, Typography, IconButton, Divider} from '@mui/material'

import {UserInfo} from '../../types/user.types'

export const AssigneeDetail = ({
  assignee,
  removeAction,
  committed = true
}: {
  assignee: UserInfo
  removeAction?: () => void
  committed?: boolean
}) => {
  const showRemoveButton = removeAction !== undefined

  return (
    <Stack gap={1.5}>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Stack>
          <Typography variant="body1" color={committed ? 'inherit' : 'secondary'}>
            {assignee.name}
          </Typography>
          <Typography variant="caption">{assignee.email}</Typography>
        </Stack>
        {showRemoveButton && (
          <IconButton
            onClick={removeAction}
            color="primary"
            data-testid={`remove-contributor-button-${assignee.id}`}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
    </Stack>
  )
}
