import {Chart} from 'chart.js'
import moment from 'moment'

export type RangeSelectPlugin = {
  rangeSelect: RangeSelectOptions
}

export type ChartWithRangeSelectPlugin = {
  plugins: {
    rangeSelect: RangeSelectOptions
  }
}

export interface RangeSelectOnChange {
  startDate?: string
  endDate?: string
}

export type RangeSelectOptions = {
  onSelectionChanged?: (filteredDataSets) => void
  fillColor?: string | CanvasGradient | CanvasPattern
  cursorColor?: string | CanvasGradient | CanvasPattern
  cursorWidth?: number
  state?: RangeSelectState
  minimalDateRange?: number
}

interface RangeSelectState {
  canvas: HTMLCanvasElement
}

interface ActiveSelection {
  x: number
  w: number
}

export const rangeSelectPlugin = {
  id: 'rangeSelect',
  beforeInit: function (chartInstance: Chart) {
    const opts = chartInstance.config.options as ChartWithRangeSelectPlugin
    if (opts.plugins.rangeSelect) {
      const canvas = this.createOverlayCanvas(chartInstance, opts.plugins.rangeSelect)
      opts.plugins.rangeSelect = Object.assign({}, opts.plugins.rangeSelect, {
        state: {canvas: canvas}
      })

      if (chartInstance.canvas.parentElement) chartInstance.canvas.parentElement.prepend(canvas)
    }
  },
  resize(chartInstance: Chart, args: {size: {width: number; height: number}}) {
    const options = (chartInstance.config.options?.plugins as RangeSelectPlugin)?.rangeSelect
    if (options && options.state) {
      options.state.canvas.width = args.size.width
      options.state.canvas.height = args.size.height
    }
  },
  destroy(chartInstance: Chart) {
    const options = (chartInstance.config.options?.plugins as RangeSelectPlugin)?.rangeSelect
    if (options && options.state) {
      options.state.canvas.remove()
      delete options.state
    }
  },
  createOverlayCanvas(chart: Chart, options: RangeSelectOptions): HTMLCanvasElement {
    const overlay = this.createOverlayHtmlCanvasElement(chart)
    const ctx = overlay.getContext('2d')

    let selection: ActiveSelection = {x: 0, w: 0}
    let isDragging = false

    chart.canvas.addEventListener('pointerdown', (evt) => {
      const rect = chart.canvas.getBoundingClientRect()
      selection.x = this.getXInChartArea(evt.clientX - rect.left, chart)
      isDragging = true
    })

    chart.canvas.addEventListener('pointerleave', () => {
      if (!isDragging && ctx) {
        ctx.clearRect(0, 0, overlay.width, overlay.height)
      }
    })

    chart.canvas.addEventListener('pointermove', (evt) => {
      ctx?.clearRect(0, 0, chart.canvas.width, chart.canvas.height)

      const chartContentRect = chart.canvas.getBoundingClientRect()
      const currentX = this.getXInChartArea(evt.clientX - chartContentRect.left, chart)
      if (isDragging && ctx) {
        selection.w = currentX - selection.x
        ctx.fillStyle = options?.fillColor || '#00000044'
        ctx.fillRect(
          selection.x,
          chart.chartArea.top,
          selection.w,
          chart.chartArea.bottom - chart.chartArea.top
        )
      } else {
        const cursorWidth = options?.cursorWidth || 1
        if (ctx) ctx.fillStyle = options?.cursorColor || '#00000088'
        ctx?.fillRect(
          currentX,
          chart.chartArea.top,
          cursorWidth,
          chart.chartArea.bottom - chart.chartArea.top
        )
      }
    })

    chart.canvas.addEventListener('pointerup', () => {
      const onSelectionChanged = options?.onSelectionChanged
      const minimalDateRange = options?.minimalDateRange
      if (onSelectionChanged) {
        onSelectionChanged(this.getDataSetDataInSelection(selection, chart, minimalDateRange))
      }
      selection = {w: 0, x: 0}
      isDragging = false
      ctx?.clearRect(0, 0, overlay.width, overlay.height)
    })
    return overlay
  },
  createOverlayHtmlCanvasElement(chartInstance: Chart): HTMLCanvasElement {
    const overlay = document.createElement('canvas')
    overlay.style.position = 'absolute'
    overlay.style.pointerEvents = 'none'
    overlay.width = chartInstance.canvas.width
    overlay.height = chartInstance.canvas.height
    return overlay
  },
  getXInChartArea(val: number, chartInstance: Chart) {
    return Math.min(Math.max(val, chartInstance.chartArea.left), chartInstance.chartArea.right)
  },
  getDataSetDataInSelection(
    selection: ActiveSelection,
    chartInstance: Chart,
    minimalDateRange: number | undefined
  ) {
    const result: {startDate?: string; endDate?: string} = {}
    const xMin = Math.min(selection.x, selection.x + selection.w)
    const xMax = Math.max(selection.x, selection.x + selection.w)

    if (chartInstance.data.datasets[0]) {
      const datasetMeta = chartInstance.getDatasetMeta(0)
      const dataInRange = chartInstance
        .getDatasetMeta(0)
        .data.filter((data) => xMin <= data.x && xMax >= data.x)
      if (dataInRange.length) {
        const firstElementIndex = datasetMeta.data.findIndex(
          // @ts-ignore
          (data) => dataInRange[0].$context.raw === data.$context.raw
        )
        const lastElementIndex = datasetMeta.data.findIndex(
          // @ts-ignore
          (data) => dataInRange[dataInRange.length - 1].$context.raw === data.$context.raw
        )
        if (chartInstance.data.labels) {
          if (minimalDateRange) {
            const startDate = moment(chartInstance.data.labels[firstElementIndex] as string)
            const endDate = moment(chartInstance.data.labels[lastElementIndex] as string)
            const duration = moment.duration(endDate.diff(startDate))
            let seconds = duration.asSeconds()
            if (seconds < 0) seconds = seconds * -1

            if (seconds > minimalDateRange) {
              result.startDate = chartInstance.data.labels[firstElementIndex] as string
              result.endDate = chartInstance.data.labels[lastElementIndex] as string
            }
          } else {
            result.startDate = chartInstance.data.labels[firstElementIndex] as string
            result.endDate = chartInstance.data.labels[lastElementIndex] as string
          }
        }
      }
    }

    return result
  }
}
