import {GraphDetailScreen} from '@hconnect/common/types'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  useMediaQuery,
  Typography
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

export const ScreensSwitch = ({type}: {type: GraphDetailScreen}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const canDisplayText = useMediaQuery('(min-width:1300px)')

  const handleOnChange = (event: React.MouseEvent<HTMLElement>, nextType: GraphDetailScreen) => {
    if (!nextType) return
    navigate(`../${nextType}`, {relative: 'path'})
  }

  return (
    <Box sx={{float: 'left'}}>
      <ToggleButtonGroup
        exclusive
        value={type}
        onChange={handleOnChange}
        sx={{
          display: 'flex',
          border: '1px solid rgba(255, 255, 255, 0.4)',
          height: '48px'
        }}
      >
        <StyledToggleButton
          value={'graph'}
          aria-label={t('label.structure')}
          data-test-id={'display-analysis'}
        >
          <AccountTreeOutlinedIcon />
          {canDisplayText && <Typography variant="h5">{t('label.structure')}</Typography>}
        </StyledToggleButton>
        <StyledToggleButton
          value={'tasks'}
          aria-label={t('label.tasks')}
          data-test-id={'display-tasks'}
        >
          <ContentPasteOutlinedIcon />
          {canDisplayText && <Typography variant="h5">{t('label.tasks')}</Typography>}
        </StyledToggleButton>
        <StyledToggleButton
          value={'summary'}
          aria-label={t('label.analysisSummary')}
          data-test-id={'display-summary'}
        >
          <TextSnippetOutlinedIcon />
          {canDisplayText && <Typography variant="h5">{t('label.analysisSummary')}</Typography>}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

const StyledToggleButton = styled(ToggleButton)({
  width: 'fit-content',
  borderRadius: '6px !important',
  border: 'transparent !important',
  color: '#FFFFFF !important',
  display: 'flex',
  gap: 6,
  '&.Mui-selected': {
    backgroundColor: '#016AD4 !important'
  },
  '&.MuiToggleButtonGroup-grouped:not(.Mui-selected):hover': {
    background: 'transparent !important'
  },
  background: 'transparent !important'
})
