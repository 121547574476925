import {Box} from '@mui/material'
import React, {useState} from 'react'

import {NodeAttachment} from '../../container/node/NodeAttachment'
import {Attachment} from '../../types/nodeDetail.types'
import {ImageDetail} from '../ImageDetail'

export const SummaryAttachments: React.FC<{attachments: Attachment[]; hideImages?: boolean}> = ({
  attachments,
  hideImages
}) => {
  const [activeImageId, setActiveImageId] = useState<number | undefined>(undefined)

  return (
    <>
      <Box display="flex" displayPrint="none">
        {attachments.map((attachment) => (
          <NodeAttachment
            attachment={attachment}
            key={attachment.id}
            setActiveImageId={() => setActiveImageId(attachment.id)}
            imageHeight={'200px'}
          />
        ))}
        <ImageDetail
          files={attachments}
          activeId={activeImageId}
          setActiveImageId={setActiveImageId}
          nodeId={1}
          analysisId={'1'}
          canDeleteAttachment={false}
        />
      </Box>
      <Box display="none" displayPrint={hideImages ? 'none' : 'block'}>
        {attachments.map((attachment) => (
          <NodeAttachment
            key={`print-${attachment.id}`}
            attachment={attachment}
            setActiveImageId={(id) => console.log(id)}
          />
        ))}
      </Box>
    </>
  )
}
