import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Theme,
  Typography,
  styled
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useCanvasSettingsContext} from '../../context/CanvasSettingsContext'
import {useNodeDetail} from '../../hooks/node/useNodeDetail'
import {usePatchRootCause} from '../../hooks/node/usePatchRootCause'
import {RootCauseType, rootCauseTypes} from '../../types/nodeDetail.types'

export const MarkRootCause: React.FC<{
  analysisId: string
  nodeId: number
  rootCauseType?: RootCauseType
}> = ({analysisId, nodeId, rootCauseType}) => {
  const {permissions} = useCanvasSettingsContext()
  const {data} = useNodeDetail(nodeId.toString())
  const {t} = useTranslation()
  const {mutate: mutateRootCause} = usePatchRootCause(Number(analysisId), nodeId)

  // canFinish has the same business meaning: owner / admin
  const canMarkRootCause = permissions && permissions.canFinish

  const handleRootCauseChange = (event: SelectChangeEvent) => {
    const newType = event.target.value === '' ? null : (event.target.value as RootCauseType)
    if (!data) return
    mutateRootCause({rootCauseType: newType, description: data?.description, title: data?.title})
  }

  return (
    <>
      {canMarkRootCause && (
        <Select
          data-test-id="root-cause-select"
          value={rootCauseType || ''}
          onChange={handleRootCauseChange}
          displayEmpty
          renderValue={(value) => (
            <Stack direction="row" spacing={1} alignItems="center">
              <FlagOutlinedIcon sx={{fontSize: 18}} />
              <Typography sx={{textTransform: 'capitalize', fontWeight: 500}}>
                {t('node.detail.rootCause')} {value ? `- ${value}` : ''}
              </Typography>
            </Stack>
          )}
          sx={rootCauseType ? rootCauseSx : noRootCauseSx}
        >
          <MenuItem value={''} divider style={{height: '50px', paddingLeft: '30px'}}>
            <em>None</em>
          </MenuItem>
          {rootCauseTypes.map((type) => (
            <MenuItem
              key={type}
              value={type}
              divider={type !== 'other'}
              style={{height: '55px', paddingLeft: '30px'}}
            >
              <Typography sx={{textTransform: 'capitalize', fontWeight: 400}}>{type}</Typography>
            </MenuItem>
          ))}
        </Select>
      )}
      {/* Read only */}
      {rootCauseType && !canMarkRootCause && (
        <RootCauseStyledButton
          variant={'contained'}
          startIcon={<FlagOutlinedIcon />}
          data-test-id="button-is-root-cause"
          sx={{
            background: '#00274D',
            border: '1px solid rgba(0, 39, 77, 0.1)',
            cursor: 'default',
            '&:hover': {background: '#00274D'}
          }}
        >
          <Typography sx={{textTransform: 'capitalize', fontWeight: 500}}>
            {`${t('node.detail.rootCause')} - ${rootCauseType}`}
          </Typography>
        </RootCauseStyledButton>
      )}
    </>
  )
}

const RootCauseStyledButton = styled(Button)({
  minWidth: '45px',
  width: 'auto',
  padding: '9px 12px',
  borderRadius: '6px',
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    marginRight: '6px'
  }
})

const noRootCauseSx: SxProps<Theme> = {
  background: '#FFFFFF',
  height: '42px',
  border: '1px solid rgba(0, 39, 77, 0.1)',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)!important',
  borderRadius: '6px',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)'
  }
}

const rootCauseSx: SxProps<Theme> = {
  background: '#00274D',
  border: '1px solid rgba(0, 39, 77, 0.1)',
  color: 'white',
  // cursor: canMarkRootCause ? 'pointer' : 'default',
  '&:hover': {background: '#00274D'},
  height: '42px',
  borderRadius: '6px',
  '.MuiSvgIcon-root ': {
    fill: 'white !important'
  }
}
