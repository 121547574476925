import {StationParts} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {KilnMetricStatus} from '@hconnect/common/components/kmsStatus/KilnMetricStatus'
import {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'

import {HealthIndicatorsModalData} from './HealthIndicatorSignalsModal'
import {getAssetPartSignals} from './KilnConfig.helpers'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'

export const HealthIndicators = ({
  setCrankSignalsOpen,
  setIndicatorsModalData
}: {
  setCrankSignalsOpen: Dispatch<SetStateAction<boolean>>
  setIndicatorsModalData: Dispatch<SetStateAction<HealthIndicatorsModalData | undefined>>
}) => {
  const {t} = useTranslation()
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()

  return (
    <>
      <KilnMetricStatus
        title={t('kilnMetrics.speed')}
        status={kilnLayout.speedSignals.length ? 'Ok' : 'Alarm'}
        value={kilnLayout.speedSignals.length.toString()}
        onClick={() =>
          setIndicatorsModalData({
            label: t('kilnMetrics.speed'),
            availableSignals: getAssetPartSignals(
              ['kiln_shell', 'kiln'],
              kilnLayout.selectedSignals
            ),
            onSubmit: (signals) =>
              dispatch({type: LayoutActionType.SET_SPEED_SIGNALS, payload: signals}),
            selectedSignals: kilnLayout.speedSignals
          })
        }
      />
      <KilnMetricStatus
        title={t('kilnMetrics.feed')}
        status={kilnLayout.feedSignals.length ? 'Ok' : 'Alarm'}
        value={kilnLayout.feedSignals.length.toString()}
        onClick={() =>
          setIndicatorsModalData({
            label: t('kilnMetrics.feed'),
            availableSignals: getAssetPartSignals(
              ['kiln_shell', 'kiln'],
              kilnLayout.selectedSignals
            ),
            onSubmit: (signals) =>
              dispatch({type: LayoutActionType.SET_FEED_SIGNALS, payload: signals}),
            selectedSignals: kilnLayout.feedSignals
          })
        }
      />
      <KilnMetricStatus
        title={t('kilnMetrics.ovality')}
        status={kilnLayout.ovalitySignals.length ? 'Ok' : 'Alarm'}
        value={kilnLayout.ovalitySignals.length.toString()}
        onClick={() =>
          setIndicatorsModalData({
            label: t('kilnMetrics.ovality'),
            availableSignals: getAssetPartSignals(
              [
                'kiln_shell',
                'kiln',
                ...kilnLayout.stations.map((station) => `${station.id}_${StationParts.TYRE}`)
              ],
              kilnLayout.selectedSignals
            ),
            onSubmit: (signals) =>
              dispatch({type: LayoutActionType.SET_OVALITY_SIGNALS, payload: signals}),
            selectedSignals: kilnLayout.ovalitySignals
          })
        }
      />
      <KilnMetricStatus title={t('kilnMetrics.axialBalance')} disabled />
      <KilnMetricStatus
        title={t('kilnMetrics.warp')}
        value={`${Object.keys(kilnLayout.crankSignals).length.toString()} / ${
          kilnLayout.stations.length
        }`}
        status={Object.keys(kilnLayout.crankSignals).length ? 'Ok' : 'Alarm'}
        onClick={() => setCrankSignalsOpen(true)}
      />
    </>
  )
}
