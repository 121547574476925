import Close from '@mui/icons-material/Close'
import Delete from '@mui/icons-material/Delete'
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useDeleteTask} from '../../hooks/tasks/useDeleteTask'

export const TaskDelete: React.FC<{
  nodeId: number
  taskId: string
  title: string
  analysisId?: string
  closeModal: () => void
  opened: boolean
}> = ({nodeId, analysisId, closeModal, opened, title, taskId}) => {
  const {t} = useTranslation()
  // node deletion

  const {data, mutate, isPending: isLoading} = useDeleteTask(analysisId, nodeId)

  useEffect(() => {
    if (data !== undefined && !isLoading) {
      closeModal()
    }
  }, [data, isLoading, closeModal])

  return (
    <Dialog open={opened} onClose={() => closeModal} maxWidth="md" data-test-id="remove-task-modal">
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          {t('headings.removeTask')}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <Grid container justifyContent="flex-start">
          {`${t('headings.removeTaskText')} ${title} ?`}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" my={1}>
          <Button
            startIcon={<Close />}
            variant="outlined"
            color="secondary"
            onClick={() => closeModal()}
            data-test-id="node-form-cancel-button"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            startIcon={<Delete />}
            variant="contained"
            color="error"
            onClick={() => mutate(taskId)}
            sx={{marginLeft: 1}}
            data-test-id="node-form-remove-button"
          >
            {t('buttons.deleteConfirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
