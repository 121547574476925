import {PermissionType} from '@hconnect/apiclient/src/types'
import {AnalysisType} from '@hconnect/common/types'
import {PageTitle, PageContainer, TextOnBackground} from '@hconnect/uikit/src/lib2'
import {Box, Card} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'

import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {CreateAnalysisSteps} from '../components/CreateAnalysisSteps'
import {ManageContributors} from '../container/assignees/ManageContributors'
import {CreateAnalysisForm} from '../container/CreateAnalysisForm'
import {hasPermission} from '../hooks/usePermission'
import {AnalysisInfo, NewAnalysisStep} from '../types/analysis.types'
import {StoppageInfo} from '../types/stoppage.types'

export const NewAnalysisPage = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [step, setStep] = useState<NewAnalysisStep>(NewAnalysisStep.fillAnalysisForm)
  const [analysis, setAnalysis] = useState<AnalysisInfo | undefined>()

  const type = searchParams.get('type')
  const selectedStoppage: StoppageInfo | undefined = location?.state?.selectedStoppage
  const permissions = useGlobalContext().state.permissions
  const canReassign = permissions
    ? hasPermission(permissions, 'ADMINISTRATE_ROOT_CAUSE_FAILURE_ANALYSES' as PermissionType)
    : false

  if (!selectedStoppage && type === 'stoppage') {
    throw new Error('Stoppage is missing in location state')
  }

  const onAnalysisCreated = (analysis: AnalysisInfo) => {
    setAnalysis(analysis)
  }

  return (
    <PageContainer
      sx={{
        minWidth: '550px'
      }}
    >
      <PageTitle ml={3} mb={0}>
        {t('pages.createNewPage')}
      </PageTitle>
      <TextOnBackground variant="caption" ml={3} contrast="low">
        {t('pages.mainTitle')}
      </TextOnBackground>

      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3
        }}
      >
        <CreateAnalysisSteps step={step} type={type} />
        {step === NewAnalysisStep.fillAnalysisForm && (
          <CreateAnalysisForm
            onGoBack={() => navigate(-1)}
            onGoNext={() => setStep(NewAnalysisStep.addContributors)}
            onAnalysisCreated={onAnalysisCreated}
            canReassign={canReassign}
            selectedStoppage={selectedStoppage}
            analysisType={type === 'stoppage' ? AnalysisType.Cockpit : AnalysisType.Custom}
          />
        )}
        {step === NewAnalysisStep.addContributors && (
          <Card
            sx={{
              maxWidth: 800,
              minWidth: 600,
              minHeight: 250,
              padding: 4
            }}
          >
            {analysis && (
              <ManageContributors
                analysisId={analysis.id}
                canEdit={true}
                contributors={[]}
                owner={analysis.owner}
                onSucessCb={() => navigate(`../graphDetail/${analysis.id}/graph`)}
                skipAction={() => navigate(`../graphDetail/${analysis.id}/graph`)}
              />
            )}
          </Card>
        )}
      </Box>
    </PageContainer>
  )
}
