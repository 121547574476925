import {RoleType} from '@hconnect/apiclient'
import {useMutation} from '@tanstack/react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {UserInfo} from '../types/user.types'

export type ResponseType = Array<UserInfo>

export const QueryKey = 'graph-data'

type SearchUserData = {
  emailIdOrPhone?: string
  excludeAnalysisId?: number
}

export const useSearchContributors = () => {
  const axiosInstance = useAxios()
  const canAddContributorRole: RoleType[] = [
    'RELIABILITY_ADMIN',
    'GLOBAL_ADMIN',
    'MAINTENANCE_MANAGER',
    'MANAGING_DIRECTOR',
    'PLANT_DIRECTOR',
    'RELIABILITY_ENGINEER'
  ]
  const userRoles = useGlobalContext().state.user.roles ?? []
  return useMutation({
    mutationFn: async (searchParams: SearchUserData) => {
      const hasRoles = canAddContributorRole.map((r) => userRoles.includes(r))
      const hasRole = hasRoles.reduce((res, cur) => res || cur, false)
      const rcfaUserPath = 'users/reliabilityContributors/' + searchParams.emailIdOrPhone
      const userPath = `users?rowsPerPage=10&page=1&sortColumn=createdOn&sortDirection=desc&eMail=${searchParams.emailIdOrPhone}&skip=0&limit=10`

      if (hasRole) {
        const res = await axiosInstance.get<ResponseType>(userPath, {})
        if (!res.data || res.data.length === 0) {
          return (await axiosInstance.get<ResponseType>(rcfaUserPath, {})).data
        }
        return res.data
      } else {
        return (await axiosInstance.get<ResponseType>(rcfaUserPath, {})).data
      }
    }
  })
}
