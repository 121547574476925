import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {Box, Button, Grid, TextareaAutosize} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import ModalCircularProgress from '../../../components/ModalCircularProgress'
import {useAddComment} from '../../../hooks/comments/useAddComment'

export const AddCommentForm: React.FC<{
  nodeId: number
  analysisId: string
  closeForm: () => void
}> = ({nodeId, closeForm, analysisId}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const {fontFamily, fontSize, lineHeight} = theme.typography.body2

  const [comment, setComment] = useState<string>('')
  const {mutate, isPending: isLoading, isSuccess} = useAddComment(analysisId, nodeId)

  const submitComment = () => {
    if (comment && comment.length > 2) {
      mutate({text: comment})
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setComment('')
      closeForm()
    }
  }, [isLoading, isSuccess, closeForm])

  return (
    <Grid>
      {isLoading ? (
        <ModalCircularProgress />
      ) : (
        <Box>
          <TextareaAutosize
            data-test-id={'add-comment-input'}
            aria-label="comment-input"
            placeholder={t('comments.placeholder')}
            style={{
              width: '100%',
              padding: '16.5px 14px',
              borderRadius: '8px',
              fontFamily,
              fontSize,
              lineHeight,
              fontWeight: 400,
              color: '#00374D'
            }}
            onChange={(e) => setComment(e.target.value)}
          />
          <Box sx={{display: 'flex', justifyContent: 'right'}}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              color="secondary"
              onClick={() => closeForm()}
              data-test-id="node-form-cancel-button"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              disabled={comment.length < 3}
              onClick={submitComment}
              data-test-id="node-form-save-button"
              sx={{marginLeft: 1}}
            >
              {t('comments.add')}
            </Button>
          </Box>
        </Box>
      )}
    </Grid>
  )
}
