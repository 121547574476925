import {TablePagination, TableRow} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export type DataTableFooterProps = {
  page: number
  rowsNumber: number
  handlePageChange: (newPage: number) => void
  totalCount?: number
  colspan?: number
  handleRowsPerPageChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}
export const DataTableFooter = ({
  page,
  rowsNumber,
  handlePageChange,
  handleRowsPerPageChange,
  colspan,
  totalCount
}: DataTableFooterProps) => {
  const {t} = useTranslation()
  return (
    <TableRow>
      <TablePagination
        colSpan={colspan ?? 0}
        count={totalCount ?? 0}
        nextIconButtonProps={{
          color: 'primary'
        }}
        backIconButtonProps={{
          color: 'primary'
        }}
        page={page}
        onPageChange={(_, page) => handlePageChange(page)}
        onRowsPerPageChange={(e) => handleRowsPerPageChange(e)}
        rowsPerPage={rowsNumber}
        sx={{
          textAlign: 'center',
          border: 'none'
        }}
        labelRowsPerPage={t('label.rowsPerPage')}
      />
    </TableRow>
  )
}
