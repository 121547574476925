import {useDroppable} from '@dnd-kit/core'
import {Box, Fade, SxProps, Theme} from '@mui/material'

export const DroppableSensorWrapper = (props) => {
  const {setNodeRef, isOver} = useDroppable({
    id: props.id,
    disabled: props.disabled
  })

  return (
    <Box
      ref={setNodeRef}
      sx={
        isOver
          ? defaultGroupStyle
          : props.groupLength === 0
            ? emptyGroupStyle
            : props.groupLength === 1
              ? undefined
              : defaultGroupStyle
      }
      data-test-id="droppable-sensor-group"
    >
      {props.children}
      {isOver && (
        <Fade in={true} timeout={500}>
          <Box
            sx={{height: '72px', background: '#FFFFFF', opacity: 0.5, borderRadius: '8px'}}
          ></Box>
        </Fade>
      )}
    </Box>
  )
}

const emptyGroupStyle: SxProps<Theme> = {
  display: 'none'
}

const defaultGroupStyle: SxProps<Theme> = {
  display: 'flex',
  p: 2,
  background: 'rgba(84, 112, 140, 0.1)',
  borderRadius: '12px',
  height: 'fit-content',
  minHeight: '50px',
  flexDirection: 'column',
  gap: 2
}
