import {Box, CircularProgress} from '@mui/material'
import React from 'react'

const ModalCircularProgress = (): JSX.Element => (
  <Box
    sx={{
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <CircularProgress />
  </Box>
)

export default ModalCircularProgress
