import React from 'react'

import {primaryColor} from '../../styles/themeColours'
import {CanvasNode} from '../../types/canvasNodes.types'

export interface CollapsedNodeButtonProps {
  targetNode?: CanvasNode
  nodeWidth: number
  nodeHeight: number
  spaceY: number
  position: 'TOP' | 'BOTTOM'
  id: string
  xOffset?: number
}

export const CollapsedNodeButton: React.FC<CollapsedNodeButtonProps> = ({
  targetNode,
  nodeWidth,
  nodeHeight,
  spaceY,
  position,
  id,
  xOffset
}) => {
  const renderAddNodeButton = (id, x, y, index) => {
    const centerX: number = x + nodeWidth / 2 + (xOffset ?? 0)
    const nodeEndY: number = position === 'TOP' ? y - spaceY / 2 : y + nodeHeight + spaceY / 2
    const spacePart = Math.round(spaceY / 8)
    const circleRadius = Math.round((spacePart * 5) / 2)

    return (
      <g key={`${id}-button`}>
        <circle id={id} cx={centerX} cy={nodeEndY} fill={primaryColor} r={circleRadius}></circle>
        <path
          id={`${id}-path`}
          style={{
            transform: `translate(${centerX - 8}px, ${nodeEndY - 6}px)`
          }}
          d="M8.00008 2.98334C10.5267 2.98334 12.7801 4.40334 13.8801 6.65C13.4867 7.46334 12.9334 8.16334 12.2734 8.73L13.2134 9.67C14.1401 8.85 14.8734 7.82334 15.3334 6.65C14.1801 3.72334 11.3334 1.65 8.00008 1.65C7.15341 1.65 6.34008 1.78334 5.57341 2.03L6.67341 3.13C7.10675 3.04334 7.54675 2.98334 8.00008 2.98334ZM7.28675 3.74334L8.66675 5.12334C9.04675 5.29 9.35341 5.59667 9.52008 5.97667L10.9001 7.35667C10.9534 7.13 10.9934 6.89 10.9934 6.64334C11.0001 4.99 9.65341 3.65 8.00008 3.65C7.75341 3.65 7.52008 3.68334 7.28675 3.74334ZM1.34008 1.56334L3.12675 3.35C2.04008 4.20334 1.18008 5.33667 0.666748 6.65C1.82008 9.57667 4.66675 11.65 8.00008 11.65C9.01341 11.65 9.98675 11.4567 10.8801 11.1033L13.1601 13.3833L14.1001 12.4433L2.28008 0.616669L1.34008 1.56334ZM6.34008 6.56334L8.08008 8.30334C8.05341 8.31 8.02675 8.31667 8.00008 8.31667C7.08008 8.31667 6.33341 7.57 6.33341 6.65C6.33341 6.61667 6.34008 6.59667 6.34008 6.56334ZM4.07341 4.29667L5.24008 5.46334C5.08675 5.83 5.00008 6.23 5.00008 6.65C5.00008 8.30334 6.34675 9.65 8.00008 9.65C8.42008 9.65 8.82008 9.56334 9.18008 9.41L9.83341 10.0633C9.24675 10.2233 8.63341 10.3167 8.00008 10.3167C5.47341 10.3167 3.22008 8.89667 2.12008 6.65C2.58675 5.69667 3.26675 4.91 4.07341 4.29667Z"
          fill="white"
          strokeWidth={'2px'}
        />
        <line
          // for x1 we want to cancel the xOffset so we subtract it
          x1={centerX - (xOffset ?? 0)}
          y1={position === 'TOP' ? y : y + nodeHeight}
          x2={centerX}
          y2={nodeEndY}
          key={`line-collapsed-${index}`}
          stroke="#000000"
          strokeWidth={'2px'}
        />
      </g>
    )
  }

  return (
    <>
      {targetNode?.id !== undefined &&
        renderAddNodeButton(id, targetNode.cx, targetNode.cy, targetNode.index)}
    </>
  )
}
