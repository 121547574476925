import React from 'react'

import {rootCauseStrokeColor, defaultStrokeColor, primaryColor} from '../../styles/themeColours'
import {CanvasMode, Node} from '../../types/canvasNodes.types'
import {useNodeMoveAction} from '../../context/NodeMoveActionContext'

export interface CanvasPathProps {
  from: Node
  to: Node
  index: number
  nodeWidth: number
  nodeHeight: number
  spaceY: number
  canvasMode: CanvasMode
  setParentHover: Function
  collapseDescendants: (Node) => void
}

export const CanvasPath: React.FC<CanvasPathProps> = ({
  from,
  to,
  index,
  nodeWidth,
  nodeHeight,
  spaceY,
  setParentHover,
  canvasMode,
  collapseDescendants
}) => {
  const {movedNodes} = useNodeMoveAction()
  const isMovedNodePath = movedNodes.has(from.id)

  const centerX: number = nodeWidth / 2
  const centerYSpace: number = spaceY / 2

  const isRootCausePath =
    from.isOnPathToRootCauseFrom &&
    from.isOnPathToRootCauseFrom?.length > 0 &&
    to.isOnPathToRootCauseFrom &&
    to.isOnPathToRootCauseFrom?.length > 0

  const stroke = movedNodes.has(from.id)
    ? primaryColor
    : isRootCausePath
      ? rootCauseStrokeColor
      : defaultStrokeColor

  const strokeWidth = isRootCausePath ? '3px' : '2px'

  const _calculatePolygon = (from, to, index) => {
    if (from.cx === to.cx) {
      const x1 = from.cx + centerX
      const y1 = from.cy + nodeHeight
      const x2 = to.cx + centerX
      const y2 = to.cy
      return (
        <>
          <g style={{cursor: 'pointer'}} id={isRootCausePath ? `root-cause-line-${index}` : 'line'}>
            <line
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
              key={`line-${index}`}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <line
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
              key={`line-overlay-${index}`}
              data-test-id={`line-overlay-${index}`}
              strokeWidth={'16px'}
              stroke="transparent"
              style={{pointerEvents: isMovedNodePath ? 'none' : 'all'}}
              onMouseOver={() => canvasMode === CanvasMode.default && setParentHover(from)}
              onMouseLeave={() => setParentHover(null)}
              onDoubleClick={collapseDescendants}
            />
          </g>
        </>
      )
    } else {
      const startX = from.cx + centerX
      const endX = to.cx + centerX
      const startY = from.cy + nodeHeight
      const endY = to.cy
      let d = ''
      // Add start point
      d += `M ${startX} ${startY}`
      // Add curve
      const middleOfSpace = startY + centerYSpace
      // Nodes are moved only smaller space then node width
      const isLeft = endX < startX
      const diff = isLeft ? startX - endX : endX - startX
      const xDirection = from.cx > to.cx ? startX - centerYSpace : startX + centerYSpace
      const endOfLine = isLeft ? to.cx + centerX + centerYSpace : to.cx + centerX - centerYSpace

      d += ` C ${startX} ${middleOfSpace} ${startX} ${middleOfSpace} ${xDirection} ${middleOfSpace}`
      if (diff > spaceY) d += ` L ${endOfLine} ${middleOfSpace}`
      d += ` C ${endX} ${middleOfSpace} ${endX} ${middleOfSpace} ${endX} ${endY}`

      return (
        <>
          <path
            id={isRootCausePath ? `root-cause-line-${index}` : 'line'}
            style={{cursor: 'pointer', pointerEvents: isMovedNodePath ? 'none' : 'all'}}
            d={d}
            key={`line-${index}`}
            data-test-id={`line-${index}`}
            markerEnd="url(#suit)"
            fill="transparent"
            stroke={stroke}
            strokeWidth={strokeWidth}
            onMouseEnter={() => canvasMode === CanvasMode.default && setParentHover(from)}
            onMouseLeave={() => setParentHover(null)}
            onDoubleClick={collapseDescendants}
          />
        </>
      )
    }
  }

  return _calculatePolygon(from, to, index)
}
