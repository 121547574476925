import {CardBox} from '@hconnect/uikit/src/lib2'
import React, {useState, useImperativeHandle, forwardRef} from 'react'

import {OrderByApiProperty} from '../../hooks/listFiltersSort/useListOrderBy'
import {
  BaseDatatableData,
  BaseDatatableItem,
  ListColumn,
  TableSizedColumns
} from '../../types/common.types'

import {DataTableBody} from './DataTable'

export interface DataTableRef {
  clearDetail: () => void
}

export type DataTableProps<T, M> = {
  paginationSettings?: {
    page: number
    rowsNumber: number
    handlePageChange: (newPage: number) => void
    handleRowsPerPageChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  }
  tableHeaderSettings: {
    download?: {
      showDownload?: boolean
      isDownloading?: boolean
      downloadFunction?: () => void | Promise<void>
    }
    showDescription?: boolean
    customButton?: React.ReactNode
    hideHeader?: boolean
  }
  orderSettings?: {
    handleOrderToggle: (col) => void
    order?: OrderByApiProperty[]
  }
  showColumnSelection?: boolean
  tableId: string
  availableColumns: ListColumn<T>[]
  hoverColor: string
  isLoadingData: boolean
  data: BaseDatatableData<M> | undefined
  columns?: TableSizedColumns
  detailComponent?: React.ReactNode
  hideWrapper?: boolean
  widthKey?: string
  headerName?: string
  customColumnRender?: (
    columnName: string,
    data: M,
    widthKey?: string | undefined
  ) => React.ReactNode | string
  setActiveItem?: (activeItem: BaseDatatableItem<M>) => void
}
export function DataTableWrapper<T, M>(
  props: DataTableProps<T, M>,
  ref: React.ForwardedRef<DataTableRef>
) {
  const {
    tableId,
    widthKey,
    columns,
    customColumnRender,
    orderSettings,
    availableColumns,
    hoverColor,
    detailComponent,
    tableHeaderSettings,
    isLoadingData,
    data,
    hideWrapper,
    setActiveItem,
    paginationSettings,
    showColumnSelection,
    headerName
  } = props

  const [activeDetailId, setActiveDetailId] = useState<string | number | undefined>()

  useImperativeHandle(ref, () => ({
    clearDetail() {
      setActiveDetailId(undefined)
    }
  }))

  const isActiveTaskOnPhone = () => (widthKey === 'xs' || widthKey === 'sm') && activeDetailId

  const tableContent = (
    <DataTableBody<T, M>
      activeDetailId={activeDetailId}
      setActiveDetailId={setActiveDetailId}
      tableHeaderSettings={tableHeaderSettings}
      tableId={tableId}
      availableColumns={availableColumns}
      hoverColor={hoverColor}
      isLoadingData={isLoadingData}
      data={data}
      paginationSettings={paginationSettings}
      orderSettings={orderSettings}
      columns={columns}
      customColumnRender={customColumnRender}
      setActiveItem={setActiveItem}
      showColumnSelection={showColumnSelection}
      headerName={headerName}
    />
  )

  return (
    <>
      {hideWrapper ? (
        tableContent
      ) : (
        <CardBox
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            position: 'relative',
            overflowX: 'auto',
            ...(activeDetailId ? {width: '60%', float: 'left', marginRight: '10px'} : {}),
            ...(isActiveTaskOnPhone() ? {display: 'none'} : {})
          }}
        >
          {tableContent}
        </CardBox>
      )}

      {detailComponent && activeDetailId && (
        <CardBox
          pt={3}
          px={3}
          sx={{
            position: 'relative',
            overflowX: 'auto',
            marginLeft: '10px'
          }}
        >
          {detailComponent}
        </CardBox>
      )}
    </>
  )
}

export const DataTable = forwardRef(DataTableWrapper) as <T, M>(
  props: DataTableProps<T, M> & {ref?: React.ForwardedRef<DataTableRef>}
) => ReturnType<typeof DataTableWrapper>
