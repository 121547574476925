import {useQuery} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {AutocompleteOption} from '../../../shared/types/filter.types'

type PlantAndCountry = {
  plantId: string
  plantName: string
  countryCode: string
  countryName: string
}

type StoppagePlantsResponse = PlantAndCountry[]

export const QueryKey = 'stoppage-list-plants'

export const useGetStoppagePlants = () => {
  const axiosInstance = useAxios()
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async () => {
      return (
        await axiosInstance.get<StoppagePlantsResponse>(
          '/rootcausefailureanalysis/stoppages/restrictive-cement-plants'
        )
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => generateOptions(data)
  })
}

const generateOptions = (data: PlantAndCountry[]) => {
  const autocompleteOptions: AutocompleteOption[] = []

  data.forEach((option) => {
    const id = option.plantId
    const value = option.plantName

    autocompleteOptions.push({
      id: id,
      label: value
    })
  })

  return autocompleteOptions
}
