import {DndContext} from '@dnd-kit/core'
import {restrictToWindowEdges} from '@dnd-kit/modifiers'
import type {Coordinates} from '@dnd-kit/utilities'
import {Refresh} from '@material-ui/icons'
import {Box, Button} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {findNode} from '../../common/graphUtils'
import CanvasModeButtons from '../../components/canvas/CanvasModeButtons'
import {CanvasPath} from '../../components/canvas/CanvasPath'
import CanvasZoomButtons from '../../components/canvas/CanvasZoomButtons'
import {RootCauseLines} from '../../components/canvas/RootCauseLines'
import {ScrollDrag} from '../../components/canvas/ScrollDrag'
import {NodeMoveInfo} from '../../components/node/NodeMoveInfo'
import {useCanvasContext} from '../../context/CanvasContext'
import {useCanvasSettingsContext} from '../../context/CanvasSettingsContext'
import {useNodeMoveAction} from '../../context/NodeMoveActionContext'
import {useGetAnalysisDetail} from '../../hooks/analysis/useGetAnalysisDetail'
import {canvasBackgroundColor} from '../../styles/themeColours'
import {CanvasMode, CanvasNode, DEFAULT_ZOOM_VALUE} from '../../types/canvasNodes.types'
import {CreateNodeModal} from '../node/CreateNodeModal'
import {NodeDetailModal} from '../node/NodeDetailModal'

import {CanvasNodeButtons} from './CanvasNodeButtons'
import {NodeCanvasDetail} from './Node'

export interface NodeCanvasProps {
  canContribute: boolean
}

enum ModalOptions {
  DETAILS_MODAL,
  ADD_NODE_MODAL
}

const defaultCoordinates: Coordinates = {
  x: 120,
  y: 200
}

export const Canvas: React.FC<NodeCanvasProps> = ({canContribute}) => {
  const analysisId = useUrlParam('analysisId')
  const {data} = useGetAnalysisDetail(analysisId)
  const {data: refreshData} = useGetAnalysisDetail(
    analysisId,
    {refetchInterval: 30000},
    'refresh_key'
  )
  // viewPort
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const [newNode, setNewNode] = useState<number | null>(null)
  const [hoveredNode, setHoveredNode] = useState<CanvasNode | undefined>(undefined)
  const [canvasMode, setCanvasMode] = useState<CanvasMode>(CanvasMode.default)
  const [scrollToRoot, setScrollToRoot] = useState<boolean>(true)
  const [addParent, setAddParent] = useState<boolean>(false)
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM_VALUE)
  const [activeModal, setActiveModal] = useState<ModalOptions | undefined>(undefined)
  const [{x, y}, setCoordinates] = useState<Coordinates>(defaultCoordinates)
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()

  const snackbarRefreshButton = (
    <Button
      onClick={() => {
        window.location.reload()
      }}
      startIcon={<Refresh />}
    >
      {t('buttons.refresh')}
    </Button>
  )

  useEffect(() => {
    if (
      data?.updateDate &&
      refreshData?.updateDate &&
      moment(data.updateDate) < moment(refreshData.updateDate) &&
      !needRefresh
    ) {
      setNeedRefresh(true)
      closeSnackbar()
      enqueueSnackbar(t('label.analysisRefresh'), {
        variant: 'warning',
        autoHideDuration: null,
        action: snackbarRefreshButton,
        anchorOrigin: {vertical: 'bottom', horizontal: 'right'}
      })
    }
  }, [refreshData, data, closeSnackbar, enqueueSnackbar])
  /**
   * Canvas contexts
   */
  const {nodeHeight, nodeWidth, spaceY} = useCanvasSettingsContext()

  const {
    links,
    graphNodes,
    windowHeight,
    windowWidth,
    graphCenter,
    isLoading,
    updateCollapsedAncestorNode,
    collapsedAncestorNode,
    collapsedDescendantNodes,
    updateCollapsedDescendantNodes,
    dimensions,
    activeNode,
    updateActiveNode
  } = useCanvasContext()

  const {nodeMoveInProgress} = useNodeMoveAction()

  /**
   * Effects
   */

  // scroll to root node on open
  useEffect(() => {
    if (!scrollToRoot) return
    const renderedNode = findNode(0, graphNodes)
    if (renderedNode) {
      setScrollToRoot(false)
      const svg = document.getElementById('svgContainer')
      svg?.scrollTo({
        top: 0,
        left: renderedNode.cx - dimensions.width / 2 + nodeWidth / 2,
        behavior: 'smooth'
      })
    }
  }, [scrollToRoot, dimensions.width, graphNodes, nodeWidth])

  useEffect(() => {
    if (newNode) {
      const renderedNode = findNode(newNode, graphNodes)
      if (renderedNode) {
        updateActiveNode(renderedNode.id)
        setActiveModal(ModalOptions.DETAILS_MODAL)
        const svg = document.getElementById('svgContainer')
        svg?.scrollTo({
          top: renderedNode.cy - dimensions.height / 2,
          left: renderedNode.cx - dimensions.width / 2 + nodeWidth / 2,
          behavior: 'smooth'
        })
      }
    } else {
      updateActiveNode(undefined)
    }
  }, [newNode])

  useEffect(() => {
    const svg = document.getElementById('svgContainer')
    if (activeNode && svg) svg.scrollTo(activeNode.cx, activeNode.cy)
  }, [graphCenter])

  const _isDefaultMode = () => canvasMode === CanvasMode.default
  const _canContribute = () => _isDefaultMode() && canContribute

  const renderGraphNodes = () => {
    return graphNodes && !isLoading ? (
      graphNodes.map((n) => (
        <NodeCanvasDetail
          key={`canvasNode-${n.id}`}
          node={n}
          hoveredNode={hoveredNode}
          canvasMode={canvasMode}
          setHoveredNode={setHoveredNode}
          setDetailModalOpen={() => setActiveModal(ModalOptions.DETAILS_MODAL)}
          stoppageCode={n.id === 0 ? data?.stoppage?.stoppageCode?.code : undefined}
        />
      ))
    ) : (
      <></>
    )
  }

  const renderRootCauseLines = () =>
    links && !isLoading ? (
      links.map((link, index) => (
        <RootCauseLines
          key={`rootCauseLine-${link.source.id}-${link.target.id}`}
          from={link.source}
          to={link.target}
          index={index}
          canvasMode={canvasMode}
          setParentHover={setHoveredNode}
          collapseDescendants={() => updateCollapsedDescendantNodes(link.source.id, 'ADD')}
        />
      ))
    ) : (
      <></>
    )

  const renderLinks = () =>
    links.map((link, index) => (
      <CanvasPath
        key={`canvasPath-${link.source.id}-${link.target.id}`}
        from={link.source}
        to={link.target}
        index={index}
        nodeWidth={nodeWidth}
        nodeHeight={nodeHeight}
        spaceY={spaceY}
        canvasMode={canvasMode}
        setParentHover={setHoveredNode}
        collapseDescendants={() => updateCollapsedDescendantNodes(link.source.id, 'ADD')}
      />
    ))

  const viewBoxWidth = windowWidth > dimensions.width ? windowWidth : dimensions.width
  const viewBoxHeight = windowHeight > dimensions.height ? windowHeight : dimensions.height
  const parentTitle = useMemo(
    () => graphNodes.find((n) => n.id === activeNode?.parent)?.title ?? '',
    [activeNode, graphNodes]
  )
  const nodeTitle = useMemo(() => (activeNode?.title ? activeNode.title : ''), [activeNode])
  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      onDragEnd={({delta}) => {
        setCoordinates(({x, y}) => {
          let newY = y + delta.y
          if (newY < 70) newY = 70
          let newX = x + delta.x
          if (newX < 65) newX = 65

          return {
            x: newX,
            y: newY
          }
        })
      }}
    >
      <Box
        id="svgContainer"
        sx={{
          maxHeight: 'calc(100vh - 180px)',
          overflow: 'auto',
          maxWidth: '100%',
          position: 'relative'
        }}
      >
        <ScrollDrag
          canvasMode={canvasMode}
          viewBoxWidth={viewBoxWidth}
          viewBoxHeight={viewBoxHeight}
        >
          <CanvasModeButtons canvasMode={canvasMode} setCanvasMode={setCanvasMode} />
          <CanvasZoomButtons zoom={zoom} setZoom={setZoom} />

          <svg
            preserveAspectRatio="xMinYMin meet"
            id="svgImage"
            style={{
              backgroundColor: canvasBackgroundColor,
              width: `${zoom}%`
            }}
            viewBox={`0,0, ${viewBoxWidth}, ${viewBoxHeight}`}
          >
            <g key={'graphNodes'}>
              {renderGraphNodes()}
              {links && !isLoading && renderLinks()}
              {renderRootCauseLines()}
              {!isLoading && (
                <CanvasNodeButtons
                  canContribute={_canContribute()}
                  isDefaultMode={_isDefaultMode()}
                  setAddModalOpen={(addParentOption) => {
                    setAddParent(addParentOption)
                    setActiveModal(ModalOptions.ADD_NODE_MODAL)
                  }}
                  nodeWidth={nodeWidth}
                  nodeHeight={nodeHeight}
                  spaceY={spaceY}
                  activeNode={activeNode}
                  collapsedAncestorNode={collapsedAncestorNode}
                  expandAncestors={() => updateCollapsedAncestorNode(undefined)}
                  collapsedDescendantNodes={collapsedDescendantNodes}
                  expandDescendants={updateCollapsedDescendantNodes}
                />
              )}
            </g>
          </svg>
          <Box
            sx={{
              width: viewBoxWidth,
              height: viewBoxHeight,
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: canvasBackgroundColor,
              zIndex: -10
            }}
          />
        </ScrollDrag>
        {activeModal === ModalOptions.ADD_NODE_MODAL && activeNode?.id !== undefined && (
          <CreateNodeModal
            open={activeModal === ModalOptions.ADD_NODE_MODAL}
            nodeId={activeNode?.id ? activeNode.id : 0}
            nodeTitle={nodeTitle}
            parentId={activeNode?.parent ? activeNode.parent : 0}
            parentTitle={parentTitle}
            order={activeNode?.order ? activeNode.order : 0}
            closeModal={() => setActiveModal(undefined)}
            setNewNode={setNewNode}
            addParent={addParent}
            x={x}
            y={y}
          />
        )}
        {activeModal === ModalOptions.DETAILS_MODAL && activeNode?.id !== undefined && (
          <NodeDetailModal
            key={`node-detail-${activeNode.id}`}
            node={activeNode}
            closeModal={() => {
              setActiveModal(undefined)
              updateActiveNode(undefined)
            }}
            setNewNode={setNewNode}
            x={x}
            y={y}
          />
        )}
      </Box>
      {nodeMoveInProgress && <NodeMoveInfo />}
    </DndContext>
  )
}
