import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {GraphLevel} from '../../types/canvasNodes.types'
import {File} from '../../types/nodeDetail.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'graph-data'

export type NodeUpdateData = {
  id: number
  order: number
  parent: number
  title: string
  description: string
  causeProbability?: string
  newAttachments: Array<File>
}

export const useUpdateNode = () => {
  const axiosInstance = useAxios()
  const analysisId = useUrlParam('analysisId')
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (nodeData: NodeUpdateData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes`
      const formData = new FormData()
      formData.append('Id', nodeData.id.toString())
      if (nodeData.causeProbability) formData.append('CauseProbability', nodeData.causeProbability)
      if (nodeData.title) formData.append('Title', nodeData.title)
      if (nodeData.description) formData.append('Description', nodeData.description)
      if (nodeData.parent) formData.append('Parent', nodeData.parent.toString())
      if (nodeData.order !== undefined) formData.append('Order', nodeData.order.toString())
      if (nodeData.newAttachments)
        // @ts-ignore
        nodeData.newAttachments.map((file) => formData.append('NewAttachments', file))
      return (
        await axiosInstance.put<ResponseType>(path, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['node-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      queryClient.removeQueries({queryKey: ['analysis-list']})
      queryClient.removeQueries({queryKey: ['tasks-master-list']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
