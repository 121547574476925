import {useParams} from 'react-router-dom'

export const useUrlParam = (key: string) => {
  const obtainedParams = useParams()
  const value = obtainedParams[key]
  if (value === undefined) {
    throw new Error(`Illegal route, param ${key} not found`)
  }
  return value
}
