import {debounce, cloneDeep} from 'lodash'
import React, {useState, useEffect, useContext, createContext, useCallback} from 'react'

import {findNode, findNodes, findNodeInLevels, findNodesInLevels} from '../common/graphUtils'
import {CanvasLevel, CanvasNode, GraphLevel, GraphLink} from '../types/canvasNodes.types'

import {
  updateNodeVisibility,
  processNodes,
  prepareGraphNodes,
  calculateGraphWidth,
  calculateGraphCenter
} from './CanvasContext.utils'
import {useCanvasSettingsContext} from './CanvasSettingsContext'

type Dimensions = {
  width: number
  height: number
}

interface ICanvasContext {
  activeNode: CanvasNode | undefined
  collapsedAncestorNode: CanvasNode | undefined
  collapsedDescendantNodes: CanvasNode[]
  dimensions: Dimensions
  graphCenter: number
  graphNodes: CanvasNode[]
  isLoading: boolean
  links: GraphLink[]
  updateActiveNode: React.Dispatch<React.SetStateAction<number | undefined>>
  updateCollapsedAncestorNode: (nodeId: number | undefined) => void
  updateCollapsedDescendantNodes: (nodeId: number, action: 'ADD' | 'REMOVE') => void
  windowHeight: number
  windowWidth: number
}

export const CanvasContext = createContext<ICanvasContext>({} as ICanvasContext)

interface CanvasProviderProps {
  rawNodes: GraphLevel[]
  children?: React.ReactNode
}

export const CanvasProvider: React.FC<CanvasProviderProps> = ({rawNodes, children}) => {
  /**
   * State
   */
  const [graphCenter, setGraphCenter] = useState(0)
  const [graphNodes, setGraphNodes] = useState<CanvasNode[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [links, setLinks] = useState<GraphLink[]>([])
  const [windowHeight, setWindowHeight] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [collapsedAncestorNodeId, setCollapsedAncestorNodeId] = useState<number | undefined>(
    undefined
  )
  const [collapsedDescendantNodeIds, setCollapsedDescendantNodeIds] = useState<Set<number>>(
    new Set()
  )
  const [activeNodeId, setActiveNodeId] = useState<number | undefined>(undefined)

  /**
   * Canvas settings context
   */
  const {nodeHeight, nodeWidth, spaceX, spaceY} = useCanvasSettingsContext()

  /**
   * Calculated values
   */
  const collapsedAncestorNode: CanvasNode | undefined = findNode(
    collapsedAncestorNodeId,
    graphNodes
  )

  const collapsedDescendantNodes: CanvasNode[] = findNodes(
    [...collapsedDescendantNodeIds],
    graphNodes
  )

  const activeNode: CanvasNode | undefined = findNode(activeNodeId, graphNodes)

  /**
   * State update handlers
   */
  const updateCollapsedDescendantNodes = (nodeId: number, action: 'ADD' | 'REMOVE') => {
    const node = findNode(nodeId, graphNodes)
    if (!node || node.children.length === 0) return
    setCollapsedDescendantNodeIds((prev) => {
      const newSet = new Set(prev)
      if (action === 'ADD') {
        newSet.add(nodeId)
      }
      if (action === 'REMOVE') {
        newSet.delete(nodeId)
      }
      return newSet
    })
  }

  const updateCollapsedAncestorNode = (nodeId: number | undefined) => {
    const node = findNode(nodeId, graphNodes)
    if ((node && node?.parent !== undefined) || nodeId === undefined) {
      setCollapsedAncestorNodeId(nodeId)
    }
  }

  /**
   * Resize handling
   */
  const handleResize = useCallback(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }, [])

  const handleResizeDebounced = debounce(handleResize, 500)

  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounced)
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [handleResizeDebounced])

  /**
   * Calculate graph data
   */
  useEffect(() => {
    if (!rawNodes) return
    setIsLoading(true)
    const canvasLevels = cloneDeep(rawNodes) as Array<CanvasLevel>

    // Nodes visibility
    const canvasLevelsVisibility = updateNodeVisibility(
      canvasLevels,
      findNodeInLevels(collapsedAncestorNodeId, canvasLevels),
      findNodesInLevels([...collapsedDescendantNodeIds], canvasLevels)
    )

    // Nodes positions
    const {graphData: preparedGraphNodes, windowHeight} = prepareGraphNodes(
      canvasLevelsVisibility,
      spaceY,
      nodeHeight
    )

    // Graph size
    const graphWidth = calculateGraphWidth(preparedGraphNodes, spaceX, nodeWidth)
    const graphCenter = calculateGraphCenter(preparedGraphNodes, dimensions, graphWidth)

    // Final graph links & nodes to render
    const {links, graphNodes} = processNodes(preparedGraphNodes, spaceX, nodeWidth, graphCenter)

    setWindowHeight(windowHeight)
    setWindowWidth(graphWidth)
    setGraphCenter(graphCenter)
    setGraphNodes(graphNodes)
    setLinks(links)

    setIsLoading(false)
  }, [
    collapsedAncestorNodeId,
    collapsedDescendantNodeIds,
    dimensions,
    nodeHeight,
    nodeWidth,
    rawNodes,
    spaceX,
    spaceY
  ])

  return (
    <CanvasContext.Provider
      value={{
        activeNode,
        collapsedAncestorNode,
        collapsedDescendantNodes,
        dimensions,
        graphCenter,
        graphNodes,
        isLoading,
        links,
        updateActiveNode: setActiveNodeId,
        updateCollapsedAncestorNode,
        updateCollapsedDescendantNodes,
        windowHeight,
        windowWidth
      }}
    >
      {children}
    </CanvasContext.Provider>
  )
}

export const useCanvasContext = () => useContext(CanvasContext)
