import React from 'react'
import {Navigate} from 'react-router-dom'

import {PlantSelect} from './dashboard/pages/PlantSelect'
import {PmRoutes} from './plantMonitoring/Main'
import {RcfaRoutes, RcfaApp} from './rcfa/App'
import {Layout} from './shared/containers/Layout'
import {MaintainToursProvider} from './shared/providers/MaintainToursProvider'

// Lazy imports
const Dashboard = React.lazy(() => import('./dashboard/pages/Main'))

export const AppRoutes = [
  {
    path: '/',
    element: (
      <MaintainToursProvider>
        <Layout />
      </MaintainToursProvider>
    ),
    children: [
      {
        index: true,
        element: <PlantSelect appName="Maintain" />
      },
      {
        path: 'plant/:plantId',
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: 'rcfa/*',
            element: <RcfaApp />,
            children: [...RcfaRoutes]
          },
          {
            path: 'pm/*',
            children: [...PmRoutes]
          },
          {
            path: '*',
            element: <Navigate to=".." />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ]
  }
]

// eslint-disable-next-line import/no-default-export
export default AppRoutes
