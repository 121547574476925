import {HttpError} from '@hconnect/common/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'

import {useAxios} from '../../../../../shared/hooks/useApi'
import {useUrlParam} from '../../../../../shared/hooks/useUrlParam'
import {QueryKey as KilnInfoKey} from '../../../hooks/useGetKilnInfo'
import {AssetConfigurationDto} from '../KilnConfig.types'

import {QueryKey as AssetConfigKey} from './useGetAssetConfig'

export const useUpdateAssetConfig = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (kilnConfig: AssetConfigurationDto) => {
      const path = `asset-config/kms-data/plant/${plantId}?upmType=Department&typeUpmId=${kilnConfig.metadata.typeUpmId}`
      return (await axiosInstance.put(path, {...kilnConfig.payload})).data
    },
    onSuccess: async (kilnConfig: AssetConfigurationDto) => {
      queryClient.setQueryData([AssetConfigKey, plantId, kilnConfig.metadata.typeUpmId], kilnConfig)
      enqueueSnackbar('Kiln config updated successfully', {
        variant: 'success',
        autoHideDuration: 2500,
        preventDuplicate: true,
        onClick: () => closeSnackbar()
      })
      await queryClient.invalidateQueries({queryKey: [KilnInfoKey, plantId]})
    },
    onError: (error: AxiosError<HttpError>) => {
      enqueueSnackbar(`Kiln config could not be updated: ${error.message}`, {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        onClick: () => closeSnackbar()
      })
    }
  })
}
