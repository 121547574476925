import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {Comment} from '../../types/nodeDetail.types'

import {GetCommentsQueryKey} from './commentQueryKeys'

export type ResponseType = {
  canAddComment: boolean
  currentPage: number
  totalCount: number
  data: Array<Comment>
}

export const useGetComments = (
  nodeId: number | undefined,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  const analysisId = useUrlParam('analysisId')
  return useQuery<ResponseType, AxiosError>({
    queryKey: [GetCommentsQueryKey, analysisId, nodeId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/comments`
      return (await axiosInstance.get<ResponseType>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
