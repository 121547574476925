import React from 'react'

export function useArrayMemo<T>(array: T[]): T[] | undefined {
  // this holds reference to previous value
  const ref = React.useRef<T[]>()
  // check if each element of the old and new array match
  const areArraysConsideredTheSame =
    ref.current && array.length === ref.current.length
      ? array.every((element, i) => {
          return ref.current ? element === ref.current[i] : false
        })
      : // initially there's no old array defined/stored, so set to false
        false
  React.useEffect(() => {
    // only update prev results if array is not deemed the same
    if (!areArraysConsideredTheSame) {
      ref.current = array
    }
  }, [areArraysConsideredTheSame, array])
  return areArraysConsideredTheSame ? ref.current : array
}
