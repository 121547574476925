import * as React from 'react'
export const KilnSvgSkeleton = (props) => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox="0 0 1211 269"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#a)">
      <rect y={36} width={1211} height={136} rx={8} fill="#97A1A6" />
    </g>
    <rect x={0.5} y={36.5} width={1210} height={135} rx={7.5} stroke="#000" strokeOpacity={0.4} />
    <rect x={513} y={184} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={513.5} y={184.5} width={67} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={525} y={176} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={525.5} y={176.5} width={43} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={513} y={8} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={513.5} y={8.5} width={67} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={525} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={525.5} y={0.5} width={43} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <g filter="url(#b)">
      <rect x={531} y={24} width={32} height={160} rx={2} fill="#97A1A6" />
      <rect x={531.5} y={24.5} width={31} height={159} rx={1.5} stroke="#000" strokeOpacity={0.3} />
    </g>
    <rect x={1095} y={184} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={1095.5} y={184.5} width={67} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={1107} y={176} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={1107.5} y={176.5} width={43} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={1095} y={8} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={1095.5} y={8.5} width={67} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={1107} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={1107.5} y={0.5} width={43} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <g filter="url(#c)">
      <rect x={1113} y={24} width={32} height={160} rx={2} fill="#97A1A6" />
      <rect
        x={1113.5}
        y={24.5}
        width={31}
        height={159}
        rx={1.5}
        stroke="#000"
        strokeOpacity={0.3}
      />
    </g>
    <g filter="url(#d)">
      <circle cx={1087} cy={104} r={26} fill="#97A1A6" />
      <circle cx={1087} cy={104} r={25.5} stroke="#000" strokeOpacity={0.2} />
    </g>
    <circle cx={1087} cy={104} r={16} fill="#F3F8FC" />
    <circle cx={1087} cy={104} r={15.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={84} y={184} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={84.5} y={184.5} width={67} height={15} rx={1.5} stroke="#97A1A6" strokeOpacity={0.2} />
    <rect x={96} y={176} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={96.5} y={176.5} width={43} height={31} rx={1.5} stroke="#97A1A6" strokeOpacity={0.2} />
    <rect x={84} y={8} width={68} height={16} rx={2} fill="#97A1A6" />
    <rect x={84.5} y={8.5} width={67} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={96} width={44} height={32} rx={2} fill="#97A1A6" />
    <rect x={96.5} y={0.5} width={43} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <g filter="url(#e)">
      <rect x={102} y={24} width={32} height={160} rx={2} fill="#97A1A6" />
      <rect x={102.5} y={24.5} width={31} height={159} rx={1.5} stroke="#000" strokeOpacity={0.3} />
    </g>
    <rect x={806} y={202} width={86} height={16} rx={2} fill="#97A1A6" />
    <rect x={806.5} y={202.5} width={85} height={15} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <rect x={818} y={193} width={62} height={32} rx={2} fill="url(#f)" />
    <rect x={818.5} y={193.5} width={61} height={31} rx={1.5} stroke="#000" strokeOpacity={0.2} />
    <path fill="#000" fillOpacity={0.1} d="M818 196h62v6h-62z" />
    <path stroke="#000" strokeOpacity={0.1} d="M818.5 196.5h61v5h-61z" />
    <path fill="#000" fillOpacity={0.1} d="M818 206h62v6h-62z" />
    <path stroke="#000" strokeOpacity={0.1} d="M818.5 206.5h61v5h-61z" />
    <path fill="#000" fillOpacity={0.1} d="M818 216h62v6h-62z" />
    <path stroke="#000" strokeOpacity={0.1} d="M818.5 216.5h61v5h-61z" />
    <g filter="url(#g)">
      <rect x={825} y={4} width={48} height={200} rx={2} fill="#97A1A6" />
      <rect x={825.5} y={4.5} width={47} height={199} rx={1.5} stroke="#000" strokeOpacity={0.3} />
    </g>
    <path fill="#000" fillOpacity={0.1} d="M825 11h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 11.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 23h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 23.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 35h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 35.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 47h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 47.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 59h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 59.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 71h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 71.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 83h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 83.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 95h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 95.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 107h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 107.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 119h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 119.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 131h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 131.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 143h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 143.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 155h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 155.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 167h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 167.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 179h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 179.5h47v5h-47z" />
    <path fill="#000" fillOpacity={0.1} d="M825 191h48v6h-48z" />
    <path stroke="#000" strokeOpacity={0.1} d="M825.5 191.5h47v5h-47z" />
    <defs>
      <filter
        id="a"
        x={0}
        y={36}
        width={1211}
        height={136}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius={2} in="SourceAlpha" result="effect1_innerShadow_397_11194" />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow_397_11194" />
      </filter>
      <filter
        id="b"
        x={527}
        y={20}
        width={40}
        height={168}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_397_11194"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_397_11194" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_397_11194" result="shape" />
      </filter>
      <filter
        id="c"
        x={1109}
        y={20}
        width={40}
        height={168}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_397_11194"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_397_11194" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_397_11194" result="shape" />
      </filter>
      <filter
        id="d"
        x={1057}
        y={74}
        width={60}
        height={60}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_397_11194"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_397_11194" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_397_11194" result="shape" />
      </filter>
      <filter
        id="e"
        x={98}
        y={20}
        width={40}
        height={168}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_397_11194"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_397_11194" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_397_11194" result="shape" />
      </filter>
      <filter
        id="g"
        x={821}
        y={0}
        width={56}
        height={208}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="dilate"
          in="SourceAlpha"
          result="effect1_dropShadow_397_11194"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_397_11194" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_397_11194" result="shape" />
      </filter>
      <linearGradient id="f" x1={849} y1={193} x2={849} y2={225} gradientUnits="userSpaceOnUse">
        <stop stopColor="#80888C" />
        <stop offset={0.519} stopColor="#B2BBBF" />
        <stop offset={1} stopColor="#80888C" />
      </linearGradient>
    </defs>
  </svg>
)
