import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {stripHtmlTags} from '../../../shared/utils/strings'
import {NodeDetailType} from '../../types/nodeDetail.types'

const TAGS_WHITELIST: string[] = ['ol', 'li', 'strong', 'em', 'br', 'ul', 'u', 'p', 'span']

export const QueryKey = 'node-detail'

export const useNodeDetail = (
  nodeId: string | undefined,
  options?: UseQueryOptions<NodeDetailType, AxiosError>
) => {
  const analysisId = useUrlParam('analysisId')
  const axiosInstance = useAxios()
  return useQuery<NodeDetailType, AxiosError>({
    queryKey: [QueryKey, analysisId, nodeId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}`
      const data = (await axiosInstance.get<NodeDetailType>(path)).data
      data.description = stripHtmlTags(data.description, TAGS_WHITELIST)
      return data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
