import {AnalysisPrivacyStatus, AnalysisStatus, HttpError} from '@hconnect/common/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {useAxios} from '../../../shared/hooks/useApi'
import {GraphLevel} from '../../types/canvasNodes.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'graph-data'

type AnalysisUpdateData = {
  Id?: number
  contributorsToAdd?: Array<string | undefined>
  contributorsToRemove?: Array<string | undefined>
  privacyStatus?: string | AnalysisPrivacyStatus
  status?: string | AnalysisStatus
  Owner?: string
  DeleteStoppageStart?: boolean
  DeleteStoppageEnd?: boolean
  deleteStoppageCode?: boolean
  stoppageCode?: string
  stoppageStart?: string
  stoppageEnd?: string
  stoppageId?: string
  dueDate?: string
  deleteDueDate?: boolean
}

export const usePatchAnalysis = () => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  return useMutation({
    mutationFn: async (analysisData: AnalysisUpdateData) => {
      const path = '/rootcausefailureanalysis/analyses'

      return (await axiosInstance.patch<ResponseType>(path, analysisData, {})).data
    },
    onSuccess: async (data: ResponseType) => {
      await Promise.all([
        queryClient.invalidateQueries({queryKey: ['graph-data']}),
        queryClient.invalidateQueries({queryKey: ['analysis-list']}),
        queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      ])
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      queryClient.removeQueries({queryKey: ['analysis-list']})
      return data
    },
    onError: (error: AxiosError<HttpError>) => {
      if (error?.response?.status === 403) {
        enqueueSnackbar(t('errors.cannotAssignOwnerRole'), {
          variant: 'error',
          preventDuplicate: true,
          disableWindowBlurListener: true,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          onClick: () => closeSnackbar()
        })
      }
    }
  })
}
