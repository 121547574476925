import {SignalsAssetPartMap} from '@hconnect/common/components/kmsStatus/types'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {Box, Button, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import moment, {Moment} from 'moment'
import {useMemo, useState} from 'react'
import {useNavigate} from 'react-router'

import {CardWrapper} from '../../../shared/components/styled'
import {ChartWrapper} from '../../../shared/containers/charts/SensorChartWrapper'
import {CrankChart} from '../../containers/charts/CrankChart'
import {useKiln} from '../../context/KilnContext'

export const Crank = () => {
  const navigate = useNavigate()
  const {kilnInfo} = useKiln()
  const [timeFrom, setTimeFrom] = useState<Moment>(moment().subtract(24, 'hours'))
  const [timeTo, setTimeTo] = useState<Moment>(moment())
  const [timeWindow, setTimeWindow] = useState<number>(24)

  const crankDataParsed = useMemo(
    () => prepareCrankData(kilnInfo.layout.crankSignals),
    [kilnInfo.layout.crankSignals]
  )

  return (
    <CardWrapper p={3} flexDirection="column" gap={4}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h1" color="secondary.dark">
          Crank
        </Typography>
        <ToggleButtonGroup
          value={timeWindow}
          size="small"
          exclusive
          color="primary"
          onChange={(_, newTimeWindow: number) => {
            if (newTimeWindow) {
              setTimeWindow(newTimeWindow)
              setTimeTo(moment())
              setTimeFrom(moment().subtract(newTimeWindow, 'hours'))
            }
          }}
          aria-label="Time Resolution"
          sx={{
            border: 'none !important',
            '& .MuiToggleButton-root': {border: 'none !important'}
          }}
        >
          <ToggleButton value={6} data-test-id={'6h-button-range'}>
            6h
          </ToggleButton>
          <ToggleButton value={12} data-test-id={'12h-button-range'}>
            12h
          </ToggleButton>
          <ToggleButton value={24} data-test-id={'24h-button-range'}>
            24h
          </ToggleButton>
          <ToggleButton value={72} data-test-id={'3d-button-range'}>
            3d
          </ToggleButton>
          <ToggleButton value={168} data-test-id={'7d-button-range'}>
            7d
          </ToggleButton>
          <ToggleButton value={720} data-test-id={'30d-button-range'}>
            30d
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          onClick={() => navigate('..')}
          startIcon={<ArrowBackIosIcon />}
          variant="text"
          sx={{fontWeight: 400}}
        >
          Back to Kiln Monitoring
        </Button>
      </Box>
      {crankDataParsed.map(({station, crankSignalsBottom, crankSignalsTop}) => (
        <Stack key={station} spacing={2}>
          <Typography variant="h2" color="text.primary">
            {kilnInfo.layout.stations.find((s) => s.id === station)?.name ?? station}
          </Typography>
          <Grid container>
            {crankSignalsTop.length && (
              <Grid item xs={crankSignalsBottom.length ? 6 : 12}>
                <ChartWrapper title="Roller I" style={{minWidth: 0}}>
                  <CrankChart signalIds={crankSignalsTop} timeFrom={timeFrom} timeTo={timeTo} />
                </ChartWrapper>
              </Grid>
            )}
            {crankSignalsBottom.length && (
              <Grid item xs={crankSignalsTop.length ? 6 : 12}>
                <ChartWrapper title="Roller II" style={{minWidth: 0}}>
                  <CrankChart signalIds={crankSignalsBottom} timeFrom={timeFrom} timeTo={timeTo} />
                </ChartWrapper>
              </Grid>
            )}
          </Grid>
        </Stack>
      ))}
    </CardWrapper>
  )
}

const prepareCrankData = (
  data: SignalsAssetPartMap
): {
  station: string
  crankSignalsTop: string[]
  crankSignalsBottom: string[]
}[] => {
  const tempMap: {[station: string]: {top: string[]; bottom: string[]}} = {}
  Object.entries(data).forEach(([key, value]) => {
    const station = key.split('_')[0]
    if (!tempMap[station]) {
      tempMap[station] = {top: [], bottom: []}
    }
    if (key.includes('top')) {
      tempMap[station].top = value.map((signal) => signal.id)
    } else {
      tempMap[station].bottom = value.map((signal) => signal.id)
    }
  })

  return Object.entries(tempMap).map(([station, signals]) => ({
    station,
    crankSignalsTop: signals.top,
    crankSignalsBottom: signals.bottom
  }))
}
