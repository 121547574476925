import Close from '@mui/icons-material/Close'
import {Box, Grid, Typography} from '@mui/material'
import {camelCase} from 'lodash'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useDropzone} from 'react-dropzone'
import {useTranslation} from 'react-i18next'

import {activeBackground, activeBorder, primaryColor} from '../../../styles/themeColours'
import {File} from '../../../types/nodeDetail.types'

const ALLOWED_TYPES = [
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/*',
  'text/csv',
  'text/plain',
  'video/*'
]

export const NodeAttachments: React.FC<{files: Array<File>; setFiles: any}> = ({
  files,
  setFiles
}) => {
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const {getRootProps, getInputProps} = useDropzone({
    accept: ALLOWED_TYPES,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        const newFile: File = Object.assign(file, {
          id: Math.random(),
          contentType: file.type,
          preview: URL.createObjectURL(file),
          isImage: file.type.indexOf('image') >= 0
        })

        return newFile
      })
      const mergedFiles = files.concat(newFiles)
      setFiles(mergedFiles)
    },
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((row) => {
        const {file, errors} = row
        const errorMsgLoc = t(`errors.${camelCase(errors[0].code)}`)
        const key = enqueueSnackbar(`${errorMsgLoc}: '${file.name}'`, {
          variant: 'error',
          onClick: () => closeSnackbar(key)
        })
      })
    }
  })

  const removeFile = (index) => {
    let newFiles: Array<File> = []
    if (files.length > 1) {
      newFiles = files.filter((_, itemIndex) => itemIndex !== index)
    }
    setFiles(newFiles)
  }

  return (
    <>
      <Box component="section">
        <Box
          {...getRootProps()}
          sx={{
            minHeight: '150px',
            backgroundColor: activeBackground,
            border: `2px dashed ${activeBorder}`,
            borderRadius: 1,
            color: primaryColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="body2">{t('label.dropzone')}</Typography>
        </Box>
      </Box>
      <Grid>
        {files.map((file, index) => {
          return file.isImage ? (
            <Box
              key={`${file.name}-${index}`}
              m={1}
              sx={{
                height: '100px',
                float: 'left',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <Close
                onClick={() => removeFile(index)}
                sx={(theme) => ({
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  color: theme.palette.error.light,
                  backgroundColor: theme.palette.common.white,
                  borderRadius: 2,
                  width: '20px'
                })}
              />
              <Box component="img" src={file.preview} alt={file.name} sx={{maxHeight: '100px'}} />
            </Box>
          ) : (
            <Box
              m={1}
              border={1.5}
              sx={(theme) => ({
                height: '100px',
                float: 'left',
                borderColor: theme.palette.grey['300'],
                width: '125px',
                borderRadius: 0.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              })}
            >
              <Close
                onClick={() => removeFile(index)}
                sx={(theme) => ({
                  position: 'absolute',
                  top: -8,
                  right: -8,
                  color: theme.palette.error.light,
                  backgroundColor: theme.palette.common.white,
                  borderRadius: 2,
                  width: '20px'
                })}
              />
              <Box
                sx={(theme) => ({
                  width: '100px',
                  fontSize: 12,
                  lineHeight: '16px',
                  color: theme.palette.text.primary,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  margin: '0 auto'
                })}
              >
                {file.name}
              </Box>
            </Box>
          )
        })}
      </Grid>
      <Box sx={{float: 'none', clear: 'both'}} />
    </>
  )
}
