import {Box} from '@mui/material'
import React from 'react'

import {AutocompleteFilter} from '../../components/filter/Autocomplete'
import {AutocompleteWithOptions} from '../../components/filter/AutocompleteWithOptions'
import {DatePickerFilter} from '../../components/filter/DatePicker'
import {FilterSelectButton} from '../../components/filter/FilterSelectButton'
import {TextSearchFilter} from '../../components/filter/TextSearch'
import {
  AutocompleteOption,
  DateRange,
  FilterAction,
  FilterActionType,
  FilterOptionVisibility,
  FilterState,
  FilterTypes
} from '../../types/filter.types'

export const FilterWrapper = ({
  filters,
  dispatch
}: {
  dispatch: React.Dispatch<FilterAction>
  filters: FilterState
}) => {
  return (
    <Box display="flex" gap={2}>
      <Box display="flex" gap={2} flexDirection="row-reverse" flexWrap="wrap">
        {Object.values(filters)
          .filter((filter) => filter.visible && filter.available)
          .map((filter) => {
            return (
              <Box
                key={`filter-wrapper-${filter.id}`}
                sx={{flex: '0 0 250px', minWidth: '250px', width: '250px', minHeight: '58px'}}
                data-test-id={`filter-option-input-${filter.id}`}
              >
                {chooseFilterType(filter, dispatch)}
              </Box>
            )
          })}
      </Box>
      <FilterSelectButton
        filtersVisibility={Object.values(filters).filter((f) => f.available)}
        dispatch={dispatch}
      />
    </Box>
  )
}

const chooseFilterType = (filter: FilterOptionVisibility, dispatch: any) => {
  switch (filter.type) {
    case FilterTypes.Select:
      return (
        <AutocompleteWithOptions
          multipleOptions={filter.multipleOptions ? filter.multipleOptions : true}
          value={parseAutocompleteOptions(filter.apiFilter?.values)}
          apiUrl={filter.apiUrl ?? ''}
          apiParams={filter.apiParams}
          id={filter.id}
          label={filter.label}
          handleChange={(event, value: string) =>
            dispatch({
              type: FilterActionType.ADD_CHOICE,
              payload: {filterId: filter.id, value: value}
            })
          }
          optionsLoader={filter.optionsLoader}
        />
      )
    case FilterTypes.SelectPredefined:
      return (
        <AutocompleteFilter
          options={filter.options ? filter.options : []}
          multipleOptions={filter.multipleOptions ? filter.multipleOptions : true}
          value={parseAutocompleteOptions(filter.apiFilter?.values)}
          id={filter.id}
          label={filter.label}
          handleChange={(event, value) =>
            dispatch({
              type: FilterActionType.ADD_CHOICE,
              payload: {filterId: filter.id, value: value}
            })
          }
        />
      )
    case FilterTypes.Date:
      return (
        <DatePickerFilter
          rangeAvailable={filter.rangeAvailable ?? false}
          id={filter.id}
          label={filter.label}
          value={
            filter.apiFilter?.values && filter.apiFilter?.values.length === 2
              ? ([
                  new Date(filter.apiFilter.values[0]),
                  new Date(filter.apiFilter.values[1])
                ] as DateRange)
              : undefined
          }
          handleChange={(value) => {
            if (value === null || value?.startDate === null) {
              dispatch({
                type: FilterActionType.CLEAR_VALUE,
                payload: {filterId: filter.id}
              })
              return
            }
            filter.rangeAvailable
              ? dispatch({
                  type: FilterActionType.ADD_DATE_RANGE,
                  payload: {
                    filterId: filter.id,
                    value: value
                  }
                })
              : dispatch({
                  type: FilterActionType.ADD_DATE_SINGLE,
                  payload: {
                    filterId: filter.id,
                    value: value
                  }
                })
          }}
        />
      )
    case FilterTypes.Text:
    default:
      return (
        <TextSearchFilter
          id={filter.id}
          label={filter.label}
          value={filter.apiFilter?.values ?? ''}
          handleChange={(event) =>
            dispatch({
              type: FilterActionType.ADD_TEXT,
              payload: {filterId: filter.id, value: event.target.value}
            })
          }
        />
      )
  }
}

export const parseAutocompleteOptions = (initialValues) => {
  return initialValues && initialValues.length
    ? initialValues.map((o) => {
        return {label: o, id: o} as AutocompleteOption
      })
    : []
}
