import AttachFileIcon from '@mui/icons-material/AttachFile'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import React, {CSSProperties} from 'react'

import {CanvasNode} from '../../types/canvasNodes.types'

export interface NodeDetailsProps {
  node: CanvasNode
  nodeWidth: number
  nodeHeight: number
  styles: CSSProperties
}

export const NodeDetails: React.FC<NodeDetailsProps> = ({node, nodeWidth, nodeHeight, styles}) => {
  const PIX_PER_CHAR = 6

  const x = node.cx ? node.cx : 0
  const y = node.cy ? node.cy : 0

  const iconsToRender = [
    {
      name: 'tasks',
      icon: ContentPasteOutlinedIcon,
      count: node.taskCount
    },
    {
      name: 'comments',
      icon: ChatBubbleOutlineOutlinedIcon,
      count: node.commentCount
    },
    {
      name: 'attachments',
      icon: AttachFileIcon,
      count: node.attachmentCount
    }
  ].filter(({count}) => count > 0)

  return (
    <g>
      {iconsToRender.map((row, i) => {
        const Icon = row.icon
        return (
          <g key={`icon-box-${row.name}-${node.id}`}>
            <Icon
              key={`icon-${row.name}-${node.id}`}
              width={15}
              height={13}
              htmlColor={'#54708C'}
              x={x + nodeWidth - 50 - 40 * i}
              y={y + nodeHeight - 28}
            />
            <text
              style={styles}
              textAnchor="middle"
              x={x + nodeWidth - 30 - 40 * i + (row.count.toString().length - 1) * PIX_PER_CHAR}
              y={y + nodeHeight - 17}
            >
              <tspan fill={'#54708C'} fontSize={'12px'} fontWeight={400} fontFamily={'Inter'}>
                {row.count}
              </tspan>
            </text>
          </g>
        )
      })}
    </g>
  )
}
