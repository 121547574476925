import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import MoreVert from '@mui/icons-material/MoreVert'
import {Fade, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material'
import React, {useContext, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {CanvasContext} from '../../context/CanvasContext'
import {useNodeMoveAction} from '../../context/NodeMoveActionContext'
import {NodePermissions} from '../../types/nodeDetail.types'
import {CollapseToggleMenuItem} from '../CollapseToggleMenuItem'

export const NodeMenu: React.FC<{
  nodeId: number
  openEditView: () => void
  setRemoveDialogOpened: React.Dispatch<React.SetStateAction<boolean>>
  canDelete: boolean
  permissions: NodePermissions
  closeDetailModal: () => void
}> = ({nodeId, openEditView, setRemoveDialogOpened, permissions, canDelete, closeDetailModal}) => {
  const {t} = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const {
    updateCollapsedAncestorNode,
    updateCollapsedDescendantNodes,
    collapsedAncestorNode,
    collapsedDescendantNodes
  } = useContext(CanvasContext)

  const {selectSourceNode} = useNodeMoveAction()

  const open = Boolean(anchorEl)

  const hasCollapsedDescendants = Boolean(collapsedDescendantNodes.find((n) => n.id === nodeId))
  const hasCollapsedAncestors = collapsedAncestorNode?.id === nodeId

  const handleClose = () => {
    setAnchorEl(null)
  }

  const canEditNodeDetails = permissions.canEdit || permissions.canAddAttachment

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-test-id={'node-detail-more-menu'}
      >
        <MoreVert />
      </IconButton>

      <Menu
        id="node-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{textOverflow: 'none'}}
        MenuListProps={{
          sx: {
            width: 400,
            p: 2,
            '& .MuiMenuItem-root': {
              whiteSpace: 'normal',
              minHeight: '54px'
            }
          }
        }}
      >
        {canEditNodeDetails && (
          <MenuItem onClick={() => openEditView()} divider>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary={t('buttons.edit')} />
          </MenuItem>
        )}

        {canDelete && permissions.canDelete && (
          <MenuItem onClick={() => setRemoveDialogOpened(true)} divider>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary={t('buttons.delete')} />
          </MenuItem>
        )}

        {permissions.canEdit && (
          <MenuItem
            onClick={() => {
              selectSourceNode(nodeId)
              closeDetailModal()
            }}
            divider
          >
            <ListItemIcon>
              <AccountTreeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('buttons.moveNodes')} />
          </MenuItem>
        )}

        {hasCollapsedAncestors ? (
          <CollapseToggleMenuItem
            clickHandler={() => updateCollapsedAncestorNode(undefined)}
            iconVisibility={true}
            buttonLabel={t('buttons.showParentNodes')}
            buttonCaption={t('buttons.showParentNodesCaption')}
            divider
          />
        ) : (
          <CollapseToggleMenuItem
            clickHandler={() => updateCollapsedAncestorNode(nodeId)}
            iconVisibility={false}
            buttonLabel={t('buttons.hideParentNodes')}
            buttonCaption={t('buttons.hideParentNodesCaption')}
            divider
          />
        )}
        {hasCollapsedDescendants ? (
          <CollapseToggleMenuItem
            clickHandler={() => updateCollapsedDescendantNodes(nodeId, 'REMOVE')}
            iconVisibility={true}
            buttonLabel={t('buttons.showChildNodes')}
            buttonCaption={t('buttons.showChildNodesCaption')}
          />
        ) : (
          <CollapseToggleMenuItem
            clickHandler={() => updateCollapsedDescendantNodes(nodeId, 'ADD')}
            iconVisibility={false}
            buttonLabel={t('buttons.hideChildNodes')}
            buttonCaption={t('buttons.hideChildNodesCaption')}
          />
        )}
      </Menu>
    </>
  )
}
