import {isString} from 'lodash'

import {GraphLevel, CanvasNode, CanvasLevel} from '../types/canvasNodes.types'

export const findNode = (id: number | undefined, data: CanvasNode[]): CanvasNode | undefined =>
  data.find((node) => node.id === id)

export const findNodes = (ids: Array<number>, data: CanvasNode[]): CanvasNode[] => {
  return ids
    .map((nodeId) => findNode(nodeId, data))
    .filter((node) => node !== undefined) as CanvasNode[]
}

export const findNodeInLevels = (
  id: number | undefined,
  levels: CanvasLevel[]
): CanvasNode | undefined => {
  return levels.flatMap((level) => level.nodes).find((node) => node.id === id)
}

export const findNodesInLevels = (ids: Array<number>, levels: CanvasLevel[]): CanvasNode[] => {
  return ids
    .map((nodeId) => findNodeInLevels(nodeId, levels))
    .filter((node) => node !== undefined) as CanvasNode[]
}

export const findNodeLevel = (id: number, data: GraphLevel[]): number | undefined => {
  return data.find((level) => level.nodes.find((n) => n.id === id))?.level
}

export const parseAutocompleteOption = (option: string | {label: string; id: string} | null) =>
  isString(option) ? option : option ? option.id : option
