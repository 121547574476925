import {Box, Collapse, TableCell, TableRow} from '@mui/material'
import React, {Fragment} from 'react'

import {BaseDatatableItem} from '../../types/common.types'

export type DataTableRowProps<M> = {
  widthKey?: string
  customColumnRender?: (
    columnName: string,
    data: M,
    widthKey?: string | undefined
  ) => React.ReactNode | string
  hoverColor: string
  r: BaseDatatableItem<M>
  setActiveDetailId: (detail: BaseDatatableItem<M>) => void
  activeDetailId?: string | number
  columnsToRender: string[]
  showDescriptions: boolean
  showColumnSelection?: boolean
}
export const DataTableRow = <M,>({
  widthKey,
  customColumnRender,
  hoverColor,
  r,
  setActiveDetailId,
  activeDetailId,
  columnsToRender,
  showDescriptions,
  showColumnSelection
}: DataTableRowProps<M>) => {
  const haveDescription = (r) => r.description && r.description.length > 0

  return (
    <Fragment key={r.id}>
      <TableRow
        onClick={(e) => {
          e.stopPropagation()
          setActiveDetailId(r)
        }}
        sx={{
          '&:hover': {
            backgroundColor: hoverColor
          },
          height: '1px',
          ...(activeDetailId === r.id ? {backgroundColor: hoverColor} : {})
        }}
      >
        {columnsToRender.map((columnName, i, {length}) => (
          <TableCell
            key={`${r.id}-${columnName}`}
            colSpan={length - 1 === i && showColumnSelection ? 2 : 1}
            sx={{
              borderBottom: haveDescription(r) ? 'none' : 'inherit',
              cursor: 'pointer',
              height: 'inherit'
            }}
          >
            {customColumnRender ? customColumnRender(columnName, r, widthKey) : r[columnName] ?? ''}
          </TableCell>
        ))}
      </TableRow>
      {haveDescription(r) && (
        <TableRow
          key={`${r.id}-desc`}
          onClick={(e) => {
            e.stopPropagation()
            setActiveDetailId(r)
          }}
          sx={{
            '&:hover': {
              backgroundColor: hoverColor
            },
            ...(activeDetailId === r.id ? {backgroundColor: hoverColor} : {})
          }}
        >
          <TableCell
            style={{paddingBottom: 0, paddingTop: 0, cursor: 'pointer'}}
            colSpan={columnsToRender.length}
          >
            <Collapse in={showDescriptions} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  fontSize: '14px',
                  lineHeight: '22px',
                  fontWeight: '400'
                }}
              >
                {r.description}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}
