export const stripHtmlTags = (html: string, whitelist: string[]) => {
  if (whitelist.length === 0) return html.replace(/<[^>]*>/gi, '')
  return html.replace(new RegExp(`<(?!/?(${whitelist.join('|')}))[^>]*>`, 'gi'), '')
}

export const encodeToQueryString = (object: any, name: string) => {
  return object && object.length ? `&${name}=${encodeURIComponent(JSON.stringify(object))}` : ''
}

export const decodeFromQueryString = (query: string | null | undefined) => {
  return query && query.length ? JSON.parse(query) : []
}
