import {HttpError} from '@hconnect/common/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {useAxios} from '../../../shared/hooks/useApi'
import {AnalysisToCreate, AnalysisInfo} from '../../types/analysis.types'

export const QueryKey = 'create-analysis'

export const useCreateAnalysis = () => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const getErrorMessage = (error: AxiosError<HttpError>) => {
    if (error?.response?.status === 403) {
      return t('errors.cannotAssignOwnerRole')
    }
    if (error.response?.data?.detail?.startsWith('The stoppage is already covered')) {
      return t('errors.analysisExistsAlready')
    }
    return 'Analysis could not be created.'
  }

  return useMutation({
    mutationFn: async (analysisData: AnalysisToCreate) => {
      const path = '/rootcausefailureanalysis/analyses'

      return (await axiosInstance.post<AnalysisInfo>(path, analysisData)).data
    },
    onSuccess: async (data: AnalysisInfo) => {
      queryClient.removeQueries({queryKey: ['analysis-list']})
      await queryClient.invalidateQueries({queryKey: ['analysis-list']})
      await queryClient.invalidateQueries({queryKey: ['analysis-list-counts']})
      queryClient.removeQueries({queryKey: ['analysis-list-counts']})
      queryClient.removeQueries({queryKey: ['stoppages']})
      return data
    },
    onError: (error: AxiosError<HttpError>) => {
      if (error?.response?.status && error.response.status >= 400) {
        enqueueSnackbar(getErrorMessage(error), {
          variant: 'error',
          preventDuplicate: true,
          disableWindowBlurListener: true,
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          onClick: () => closeSnackbar()
        })
      }
    }
  })
}
