import {ArrowDownward, CallSplit} from '@mui/icons-material'
import {Fade, Menu, MenuItem, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {primaryColor} from '../../styles/themeColours'
import {CanvasNode} from '../../types/canvasNodes.types'

export interface CanvasPathProps {
  activeNode?: CanvasNode
  nodeWidth: number
  nodeHeight: number
  spaceY: number
  xOffset?: number
  handleClick: (addParent: boolean) => void
  handleClose: () => void
  open: boolean
  anchorEl: any
}

export const AddNodeButton: React.FC<CanvasPathProps> = ({
  activeNode,
  nodeWidth,
  nodeHeight,
  spaceY,
  xOffset,
  anchorEl,
  handleClose,
  handleClick,
  open
}) => {
  const {t} = useTranslation()

  const renderAddNodeButton = (id, x, y, index, nodeId) => {
    const centerX: number = x + nodeWidth / 2 + (xOffset ?? 0)
    const nodeEndY: number = y + nodeHeight + spaceY / 2
    const spacePart = Math.round(spaceY / 8)
    const circleRadius = Math.round((spacePart * 5) / 2)
    return (
      <>
        <g key={`${id}-add-button`}>
          <Button
            data-test-id={'new-node-add-button'}
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <circle id={id} cx={centerX} cy={nodeEndY} fill={primaryColor} r={circleRadius}>
              <text
                id={`${id}-text`}
                x={centerX - 7}
                y={nodeEndY + 6}
                fill="#FFF"
                style={{fontSize: '24px'}}
              >
                +
              </text>
            </circle>
          </Button>
          <line
            // for x1 we want to cancel the xOffset so we subtract it
            x1={centerX - (xOffset ?? 0)}
            y1={y + nodeHeight}
            x2={centerX}
            y2={nodeEndY}
            key={`line-add-${index}`}
            stroke="#000000"
            strokeWidth={'2px'}
          />
        </g>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          style={{
            marginTop: 3
          }}
        >
          {nodeId !== 0 && (
            <MenuItem
              onClick={() => handleClick(true)}
              sx={{height: 50}}
              data-test-id="parent-create-button"
            >
              <ArrowDownward sx={{marginRight: 1}} />
              {t('buttons.createParent')}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => handleClick(false)}
            sx={{height: 50}}
            data-test-id="child-create-button"
          >
            <CallSplit sx={{height: 50, transform: 'rotate(180deg)', marginRight: 1}} />
            {t('buttons.createChild')}
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <>
      {activeNode?.id !== undefined &&
        !activeNode?.collapsed &&
        renderAddNodeButton(
          'active-plus-button',
          activeNode.cx,
          activeNode.cy,
          activeNode.index,
          activeNode.id
        )}
    </>
  )
}
