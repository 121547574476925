import {UserInfo} from './user.types'

export interface Attachment {
  id: number
  fileName: string
  contentType: string
  previewUrl: string
  url: string
}

export interface NodeItem {
  description: string
  title: string
}

export interface File {
  id?: number
  preview?: string
  name: string
  isImage?: boolean
  contentType?: string
}

export interface CommentPermissions {
  canDelete: boolean
  canEdit: boolean
}

export interface Comment {
  id: string
  nodeId: number
  repliesCount: number
  text: string
  updateDate: string
  updatedBy: UserInfo
  permissions: CommentPermissions
}

export interface TaskGroup {
  id?: number
  title?: string
  tasks?: Array<Task>
}

export interface TaskPermissions {
  canDelete: boolean
  canEdit: boolean
}

export interface Task {
  id: string
  nodeId: number
  analysisId?: number
  title: string
  nodeTitle?: string
  analysisTitle?: string
  description: string
  assignee?: UserInfo
  department?: string
  dueDate?: string | null
  status?: string
  createDate: string
  createdBy: string
  permissions: TaskPermissions
  resolutionDate?: string | null
  resolutionDescription?: string | null
  attachments: Array<Attachment>
}

export interface TaskCreate {
  nodeId?: number
  title: string
  description: string
  assignee: UserInfo | null
  dueDate?: string | null
}

export interface NodePermissions {
  canDelete: boolean
  canAddAttachment: boolean
  canAddComment: boolean
  canCreateTask: boolean
  canDeleteAttachment: boolean
  canEdit: boolean
  canVote: boolean
}

export enum Vote {
  likely = 'likely',
  unlikely = 'unlikely'
}

export enum TaskStatuses {
  Pending = 'pending',
  Ongoing = 'ongoing',
  Done = 'done'
}

export const rootCauseTypes = [
  'inspection',
  'material',
  'people',
  'environment',
  'method',
  'equipment',
  'other'
] as const

export type RootCauseType = (typeof rootCauseTypes)[number]

export enum Probability {
  unknown = 'unknown',
  likely = 'likely',
  unlikely = 'unlikely',
  inconclusive = 'inconclusive'
}

export interface NodeVote {
  id: number
  nodeId: number
  vote: Vote
  isMyVote: boolean
  owner: UserInfo
}

export interface VoteList {
  currentUserVote?: Probability
  likelyCount?: number
  likelyPercentage?: number
  probability: Probability
  unlikelyCount?: number
  votes: ReadonlyArray<NodeVote>
}

export interface NodeDetailType {
  id: number
  title: string
  description: string
  causeProbability?: Probability
  parent?: number
  order?: number
  attachments: Array<Attachment>
  permissions: NodePermissions
  taskCount?: number
  attachmentCount?: number
  commentCount?: number
  votes: VoteList | undefined
  rootCauseType?: RootCauseType
}

export interface CreateNodeType {
  Parent: number
  Order?: number
  Title: string
  Description: string
  NewAttachments?: Array<File>
  ChildId?: number
  CreationMethod: NodeCreationMethod
}

export type NodeModalActiveView = 'default' | 'edit' | 'createTask' | 'viewTask'

export type NodeCreationMethod =
  | 'createdManually'
  | 'createdByAI'
  | 'createdByAIModified'
  | 'createdByAIBulk'
  | 'aiRejected'
