import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {GraphLevel} from '../../types/canvasNodes.types'
import {File} from '../../types/nodeDetail.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'node-tasks'

type TaskCreation = {
  title: string
  description: string
  department?: string
  assignee?: string
  dueDate: string | undefined | null
  newAttachments?: Array<File>
}

export const useCreateTask = (analysisId: string | undefined, nodeId: number) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (taskData: TaskCreation) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/tasks`
      const formData = new FormData()
      formData.append('Description', taskData.description)
      formData.append('Title', taskData.title)
      if (taskData.assignee) formData.append('Assignee', taskData.assignee)
      if (taskData.dueDate) formData.append('DueDate', taskData.dueDate)
      if (taskData.newAttachments) {
        // @ts-expect-error file format
        taskData.newAttachments.map((file) => formData.append('NewAttachments', file))
      }
      return (
        await axiosInstance.post<ResponseType>(path, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      queryClient.removeQueries({queryKey: [QueryKey]})
      queryClient.removeQueries({queryKey: ['analysis-tasks']})
      queryClient.removeQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      await queryClient.invalidateQueries({queryKey: [QueryKey]})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-tasks']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      return data
    }
  })
}
