import {dataTestId} from '@hconnect/uikit'
import AddIcon from '@mui/icons-material/Add'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LinkIcon from '@mui/icons-material/Link'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import {Typography, Button, ButtonProps, Tooltip, Menu, Fade, MenuItem, Portal} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {StoppageInfo} from '../../types/stoppage.types'

export const StoppageRcfaButton = ({
  stoppage,
  canCreateAnalysis,
  onClick,
  ButtonProps,
  setActiveStoppage
}: {
  stoppage: StoppageInfo
  canCreateAnalysis: boolean
  ButtonProps?: ButtonProps
  setActiveStoppage: React.Dispatch<React.SetStateAction<StoppageInfo | undefined>>
  onClick: ({
    event,
    stoppage
  }: {
    event?: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent> | undefined
    stoppage: StoppageInfo
  }) => void
}) => {
  const {t} = useTranslation()
  const analysisExists = Boolean(stoppage?.analysisId)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    if (analysisExists) {
      onClick({event, stoppage})
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        disabled={!analysisExists && !canCreateAnalysis}
        startIcon={analysisExists ? <OpenInBrowserIcon /> : <AddIcon />}
        endIcon={
          stoppage.mandatory && (
            <Tooltip
              title={t('tables.stoppageList.mandatoryTooltip')}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#1F262E',
                    color: 'common.white'
                  }
                }
              }}
            >
              <ErrorOutlineIcon color="error" />
            </Tooltip>
          )
        }
        {...dataTestId(`stoppage-button-${stoppage?.id}`)}
        {...ButtonProps}
        data-guided-tour-id={analysisExists ? 'stoppage-exists-btn' : 'stoppage-pending-btn'}
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Typography variant="body2" noWrap>
          {analysisExists && t(`taskStatuses.${stoppage?.analysisStatus}`)}
          {!analysisExists && t('taskStatuses.pending')}
        </Typography>
      </Button>
      <Portal>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              onClick({event, stoppage})
            }}
            sx={{height: 50}}
            data-test-id="analysis-create-btn"
          >
            <AddIcon sx={{marginRight: 1}} />
            {t('stoppage.new.analysis')}
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              setActiveStoppage(stoppage)
              handleClose()
            }}
            sx={{height: 50}}
            data-test-id="analysis-link-btn"
            id="analysis-link-btn"
          >
            <LinkIcon sx={{marginRight: 1}} />
            {t('stoppage.new.link')}
          </MenuItem>
        </Menu>
      </Portal>
    </>
  )
}
