import {AnalysisPrivacyStatus, AnalysisStatus, AnalysisType} from '@hconnect/common/types'

import {StoppageInfo} from './stoppage.types'
import {UserInfo} from './user.types'

export interface AnalysisContributorToBeSaved {
  id?: string
  name?: string
  email: string
}

/**
 * Base class for RCFA Analysis models for saving purposes.
 * @export
 * @interface AnalysisToBeSaved
 */
export interface AnalysisToBeSaved {
  id?: number
  title: string
  description?: string
}
/**
 * Type for a new Analysis to be created
 * @export
 * @interface AnalysisToCreate
 */
export interface AnalysisToCreate extends AnalysisToBeSaved {
  stoppageCode?: string
  stoppageId?: string
  stoppageEnd?: string
  stoppageStart?: string
  plantId?: string
  type?: AnalysisType
  contributors?: Array<AnalysisContributorToBeSaved>
  privacyStatus?: AnalysisPrivacyStatus
  owner?: string
  dueDate?: string
  category?: string
  equipmentName?: string
}

/**
 * Type for Analysis to be used for showing details.
 * @export
 * @interface AnalysisInfo
 */
export interface AnalysisInfo {
  id: number
  plantId?: string
  type?: AnalysisType
  updateDate?: string
  title: string
  description: string
  owner: UserInfo
  plant: string
  createDate: string
  status: string
  privacyStatus: string
  contributors?: Array<UserInfo>
  permissions: AnalysisUserPermissions
}

export interface AnalysisListInfo {
  id: number
  plantId?: string
  type?: AnalysisType
  updateDate?: string
  dueDate?: string
  title: string
  description: string
  owner: UserInfo
  plant: string
  createDate: string
  status: AnalysisStatus
  privacyStatus: string
  stoppageCode?: string
  stoppageCodeText?: string
  country?: string
  stoppageStart?: string
  stoppageEnd?: string
  contributors?: number
  permissions: AnalysisUserPermissions
  hasPendingTasks: boolean
  areaTypeCode?: string
  category?: string
}

export interface AnalysisUserPermissions {
  canCreate: boolean
  canContribute: boolean
  canEdit: boolean
  canView: boolean
  canManageActions: boolean
  canAddAttachment: boolean
  canDeleteAttachment?: boolean
  canDelete: boolean
  canFinish: boolean
  canReopen?: boolean
  canSetOwner: boolean
  canSetPlant: boolean
}

export interface AnalysisFull {
  id?: number
  title?: string
  description?: string
  category?: string
  areaTypeCode?: string
  plantId?: string
  type?: 'custom' | 'cockpit'
  status?: AnalysisStatus
  privacyStatus?: AnalysisPrivacyStatus
  owner: UserInfo
  stoppage?: StoppageInfo
  contributors?: Array<UserInfo> | null
  createDate?: string
  equipmentName?: string
  updateDate?: string | null
  dueDate?: string | null
  permissions?: AnalysisUserPermissions | null
}

export interface Causes {
  id: number
  title: string
  taskCount: number
  attachmentsCount: number
  commentsCount?: number
}

export interface AnalysisSummary {
  id?: number
  title?: string
  description?: string
  stoppageCode?: string
  stoppageText?: string
  likelyCauses: Array<Causes>
}

export interface AnalysisStatistics {
  totalNodes: number
  totalAttachments: number
  totalTasks: number
}

export enum NewAnalysisStep {
  selectStoppageList,
  fillAnalysisForm,
  addContributors
}

export const analysisCategories = [
  {
    label: 'Health & Safety',
    id: 'healthAndSafety'
  },
  {
    label: 'Quality',
    id: 'quality'
  },
  {
    label: 'Environment',
    id: 'environment'
  },
  {
    label: 'Production',
    id: 'production'
  },
  {
    label: 'Process',
    id: 'process'
  }
]
export const analysisAreaTypes = [
  {
    label: '05 - Electrical energy',
    id: '05'
  },
  {
    label: '06 - Fuels',
    id: '06'
  },
  {
    label: '07 - Other Auxiliary',
    id: '07'
  },
  {
    label: '10 - Quarry',
    id: '10'
  },
  {
    label: '20 - Crushing and Raw Material Handling',
    id: '20'
  },
  {
    label: '30 - Raw Material Preparation',
    id: '30'
  },
  {
    label: '40 - Clinker Production',
    id: '40'
  },
  {
    label: '50 - Cement Grinding',
    id: '50'
  },
  {
    label: '60 - Packing and Loading',
    id: '60'
  }
]
