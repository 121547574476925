import {Loader} from '@hconnect/uikit/src/lib2'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import Close from '@mui/icons-material/Close'
import Delete from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import {Box, Button, IconButton, Stack, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {formatDate, formatDateTime} from '../../common/dateTimeUtils'
import {ImageDetail} from '../../components/ImageDetail'
import {TaskStatus} from '../../components/TaskStatus'
import {useTaskDetail} from '../../hooks/tasks/useTaskDetail'
import {NodeAttachment} from '../node/NodeAttachment'

import {EditTask} from './EditTask'
import {TaskDelete} from './TaskDelete'

export const TaskDetail: React.FC<{
  nodeId: number
  analysisId?: string
  analysisTitle?: string
  nodeTitle?: string
  taskId: string
  closeAction: () => void
  hideBackButton?: boolean
  disablePadding?: boolean
}> = ({
  nodeId,
  analysisId,
  closeAction,
  taskId,
  hideBackButton,
  analysisTitle,
  nodeTitle,
  disablePadding
}) => {
  const {data, isFetching} = useTaskDetail(analysisId, nodeId, taskId)
  const {t} = useTranslation()
  const [removeDialogOpened, setRemoveDialogOpened] = useState(false)
  const [isEditActive, setIsEditActive] = useState(false)
  const [activeImageId, setActiveImageId] = useState<number | undefined>(undefined)

  const taskDone = data?.status === 'done'

  useEffect(() => {
    setIsEditActive(false)
  }, [nodeId, taskId])

  return (
    <>
      {isFetching && <Loader />}
      {data && (
        <Box style={{maxHeight: '60vh', overflow: 'auto'}}>
          {isEditActive ? (
            <>
              <Typography mb={3} variant="h2">
                {t('label.editTask')}
              </Typography>
              <EditTask
                nodeId={nodeId}
                analysisId={analysisId}
                task={data}
                closeEdit={() => setIsEditActive(false)}
              />
            </>
          ) : (
            <>
              {!hideBackButton && (
                <Button
                  startIcon={<ChevronLeftOutlinedIcon />}
                  variant="text"
                  onClick={() => closeAction()}
                  sx={{
                    padding: 0,
                    '&:hover': {
                      background: 'transparent'
                    }
                  }}
                  data-test-id="task-detail-back-button"
                >
                  <Typography variant="body2" color="primary.light">
                    {t('buttons.backToNode')}
                  </Typography>
                </Button>
              )}
              <Stack spacing={2}>
                {/* HEADER */}
                <Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      pr: disablePadding ? 0 : 2
                    }}
                  >
                    <Typography variant="h3">{data.title}</Typography>
                    <IconButton onClick={() => closeAction()} color="primary">
                      <Close />
                    </IconButton>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {t('stoppage.detail.createdBy')} {data.createdBy} {t('stoppage.detail.on')}{' '}
                    {data.createDate ? formatDateTime(data.createDate) : ''}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant="h6" color="text.secondary">
                    {t('tables.tasksList.status')}
                  </Typography>
                  <TaskStatus status={data.status} />
                </Stack>

                <Stack direction="row" spacing={4}>
                  <TaskField
                    label={t('tables.tasksList.dueDate')}
                    value={data.dueDate && formatDate(data.dueDate)}
                  />
                  {taskDone && (
                    <TaskField
                      label={t('tables.tasksList.resolutionDate')}
                      value={data.resolutionDate && formatDate(data.resolutionDate)}
                    />
                  )}
                </Stack>

                <TaskField label={t('label.department')} value={data.assignee?.name} />

                {nodeTitle && (
                  <TaskField label={t('tables.tasksList.nodeTitle')} value={nodeTitle} />
                )}

                {analysisTitle && (
                  <TaskField label={t('tables.tasksList.analysisTitle')} value={analysisTitle} />
                )}

                <TaskField label={t('tables.tasksList.description')} value={data.description} />

                {taskDone && (
                  <TaskField
                    label={t('task.resolutionDescription')}
                    value={data.resolutionDescription}
                  />
                )}

                {!isFetching && !!data?.attachments?.length && (
                  <Box display="flex" flexWrap="wrap">
                    {data.attachments.map((attachment) => (
                      <NodeAttachment
                        attachment={attachment}
                        key={`file-${attachment.id}`}
                        setActiveImageId={setActiveImageId}
                      />
                    ))}
                  </Box>
                )}
                <TaskButtons
                  data={data}
                  t={t}
                  setIsEditActive={setIsEditActive}
                  setRemoveDialogOpened={setRemoveDialogOpened}
                />
              </Stack>
              <ImageDetail
                files={data.attachments}
                activeId={activeImageId}
                setActiveImageId={setActiveImageId}
                nodeId={data.nodeId}
                analysisId={analysisId}
                canDeleteAttachment={data.permissions.canDelete}
                taskId={taskId}
              />
            </>
          )}
          <TaskDelete
            nodeId={nodeId}
            analysisId={analysisId}
            title={data.title}
            closeModal={() => {
              setRemoveDialogOpened(false)
              closeAction()
            }}
            opened={removeDialogOpened}
            taskId={taskId}
          />
        </Box>
      )}
    </>
  )
}

const TaskField = ({label, value}) => {
  return (
    <Stack>
      <Typography variant="h6" color="text.secondary">
        {label}
      </Typography>
      <Typography>{value || '-'}</Typography>
    </Stack>
  )
}

const TaskButtons = ({data, t, setIsEditActive, setRemoveDialogOpened}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      {data.permissions.canEdit && (
        <Button
          startIcon={<Edit />}
          color="primary"
          variant="contained"
          onClick={() => setIsEditActive(true)}
          data-test-id="task-edit-button"
        >
          {t('buttons.edit')}
        </Button>
      )}
      {data.permissions.canDelete && (
        <Button
          startIcon={<Delete />}
          color="error"
          variant="contained"
          onClick={() => setRemoveDialogOpened(true)}
          sx={{marginLeft: 1}}
          data-test-id="task-remove-button"
        >
          {t('buttons.delete')}
        </Button>
      )}
    </Box>
  )
}
