import {Box} from '@mui/material'

import {KilnSvgSkeleton} from '../assets/KilnSvgSkeleton'

export const KilnModelSkeleton = () => {
  return (
    <Box
      sx={{
        minHeight: 0,
        minWidth: 0,
        width: '100%',
        filter: 'blur(1px) brightness(120%)',
        '@keyframes pulse': {
          '0%': {opacity: '0.4'},
          '50%': {opacity: '0.9'},
          '100%': {opacity: '0.4'}
        },
        animation: 'pulse 2s ease-in-out infinite'
      }}
    >
      <KilnSvgSkeleton />
    </Box>
  )
}
