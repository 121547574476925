import {Box} from '@mui/material'
import {styled} from '@mui/system'

export const CardWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  background: theme.palette.background.paper,
  width: '100%',
  minHeight: '200px'
}))

export const StyledSensorBox = styled(Box)(({theme}) => ({
  display: 'flex',
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  background: theme.palette.background.paper,
  border: '1px solid #EAEDF0',
  padding: '16px',
  '&:hover': {
    cursor: 'grab'
  }
}))
