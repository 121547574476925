import {SignalStatus} from '@hconnect/common/components/kmsStatus/types'
import {Chart} from 'chart.js'

import AlarmIcon from '../../../../plantMonitoring/kms/assets/AlarmIcon.svg'
import WarningIcon from '../../../../plantMonitoring/kms/assets/WarningIcon.svg'

const warning = new Image()
const alarm = new Image()
warning.src = WarningIcon
alarm.src = AlarmIcon

const getStatusParameters = (status: SignalStatus) => {
  switch (status) {
    case 'Warning':
      return {
        icon: warning,
        pathColor: 'rgb(238, 139, 0)',
        fillColor: 'rgba(238, 139, 0, 0.1)'
      }
    case 'Alarm':
      return {
        icon: alarm,
        pathColor: 'rgb(218, 9, 1)',
        fillColor: 'rgba(218, 9, 1, 0.1)'
      }
    default:
      return {
        icon: alarm,
        pathColor: 'rgb(218, 9, 1)',
        fillColor: 'rgba(218, 9, 1, 0.1)'
      }
  }
}

export type ThresholdAreaPlugin = {
  thresholdArea: ThresholdAreaOptions
}

export type ThresholdAreaOptions = {
  high?: number
  highHigh?: number
  low?: number
  lowLow?: number
  lowStatus?: SignalStatus
  highStatus?: SignalStatus
  lowLowStatus?: SignalStatus
  highHighStatus?: SignalStatus
}

export const thresholdAreaPlugin = {
  id: 'thresholdArea',
  beforeDraw(chart: Chart, args, options: ThresholdAreaOptions) {
    const {high, highHigh, low, lowLow, lowStatus, highStatus, lowLowStatus, highHighStatus} =
      options

    if (high !== highHigh && highStatus) {
      drawThresholdAreaHigh({
        bottom: high,
        top: highHigh,
        chart: chart,
        ...getStatusParameters(highStatus)
      })
    }

    if (highHighStatus) {
      drawThresholdAreaHigh({
        bottom: highHigh,
        chart: chart,
        ...getStatusParameters(highHighStatus)
      })
    }

    if (low !== lowLow && lowStatus) {
      drawThresholdAreaLow({
        bottom: lowLow,
        top: low,
        chart: chart,
        ...getStatusParameters(lowStatus)
      })
    }

    if (lowLowStatus) {
      drawThresholdAreaLow({
        top: lowLow,
        chart: chart,
        ...getStatusParameters(lowLowStatus)
      })
    }
  }
}

const drawThresholdAreaHigh = ({
  top,
  bottom,
  chart,
  icon,
  pathColor,
  fillColor
}: {
  top?: number
  bottom?: number
  chart: Chart
  icon: HTMLImageElement
  pathColor: string
  fillColor: string
}) => {
  if (!bottom) return

  const ctx = chart.ctx
  const chartArea = chart.chartArea
  const yAxis = chart.scales.y

  if (bottom > yAxis.max) return

  const areaBottomY = yAxis.getPixelForValue(Math.max(yAxis.min, bottom ?? yAxis.min))
  const areaTopY = yAxis.getPixelForValue(Math.min(yAxis.max, top ?? yAxis.max))

  if (bottom > yAxis.min) {
    // draw line
    drawThresholdLine({
      ctx,
      pathColor,
      left: chartArea.left,
      right: chartArea.right,
      y: areaBottomY
    })

    ctx.drawImage(icon, chartArea.right + 10, areaBottomY - 6)
    ctx.restore()
  }

  // draw area
  ctx.save()
  ctx.fillStyle = fillColor
  ctx.fillRect(chartArea.left, areaTopY, chartArea.width, areaBottomY - areaTopY)
  ctx.restore()
}

const drawThresholdAreaLow = ({
  top,
  bottom,
  chart,
  icon,
  pathColor,
  fillColor
}: {
  top?: number
  bottom?: number
  chart: Chart
  icon: HTMLImageElement
  pathColor: string
  fillColor: string
}) => {
  if (top === undefined) return

  const ctx = chart.ctx
  const chartArea = chart.chartArea
  const yAxis = chart.scales.y

  if (top < yAxis.min) return

  const iconLineY = yAxis.getPixelForValue(top)

  drawThresholdLine({
    ctx,
    pathColor,
    left: chartArea.left,
    right: chartArea.right,
    y: iconLineY
  })

  ctx.drawImage(icon, chartArea.right + 10, iconLineY - 6)
  ctx.restore()

  const areaBottomY = yAxis.getPixelForValue(Math.max(yAxis.min, bottom ?? yAxis.min))

  // draw area
  ctx.save()
  ctx.fillStyle = fillColor
  ctx.fillRect(chartArea.left, areaBottomY, chartArea.width, iconLineY - areaBottomY)
  ctx.restore()
}

const drawThresholdLine = ({
  ctx,
  pathColor,
  left,
  right,
  y
}: {
  ctx: CanvasRenderingContext2D
  pathColor: string
  left: number
  right: number
  y: number
}) => {
  ctx.save()
  ctx.strokeStyle = pathColor
  ctx.lineWidth = 2
  ctx.setLineDash([5, 5])
  ctx.beginPath()
  ctx.moveTo(left, y)
  ctx.lineTo(right, y)
  ctx.stroke()
}
