import {Alert, AlertProps} from '@mui/material'
import React from 'react'

interface Type extends AlertProps {
  type: 'info' | 'success' | 'error' | 'warning'
  message: string
}
export const AlertWithLeftBorder: React.FC<Type> = ({type, message, ...otherProps}) => {
  const borderColor = () => {
    switch (type) {
      case 'warning':
        return '#EE8B00'
      case 'error':
        return '#DA0901'
      case 'success':
        return '#00AB50'
      default:
        return '#54708C'
    }
  }
  return (
    <Alert
      elevation={4}
      icon={false}
      data-test-id={`alert-left-${type}`}
      sx={{
        borderLeft: `4px solid ${borderColor()}`,
        borderRadius: 0.5,
        bgcolor: type === 'warning' ? '#FEF9F2' : '#FFFFFF',
        color: '#00274D',
        fontSize: 16,
        lineHeight: '22px'
      }}
      {...otherProps}
    >
      {message}
    </Alert>
  )
}
