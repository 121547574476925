import {Box, CircularProgress} from '@mui/material'
import {useMemo} from 'react'

import {BaseChart} from '../../../../shared/components/graphs/BaseChart'
import {areaChartOptions} from '../../../../shared/components/graphs/config/simpleLineChart.config'
import {thresholdAreaPlugin} from '../../../../shared/components/graphs/plugins'
import {ChartError} from '../../components/ChartError'
import {useGetSignalPlotData} from '../../hooks/useGetSignalPlotData'

import {getSingleSignalData} from './chartHelpers'

interface SingleSensorChartProps {
  sensorId: string
  deltaHours?: number
  scaleToShowThreshold?: boolean
}

export const SingleSensorChart = ({
  sensorId,
  deltaHours,
  scaleToShowThreshold
}: SingleSensorChartProps) => {
  const {data, isPending: isLoading} = useGetSignalPlotData({
    sensorIds: [sensorId],
    deltaHours: deltaHours
  })

  const graphUnit = data?.data[0]?.unit ?? ''
  const thresholdHighHigh = data?.data[0]?.thresholds?.highHigh
  const thresholdHigh = data?.data[0]?.thresholds?.high
  const thresholdLow = data?.data[0]?.thresholds?.low
  const thresholdLowLow = data?.data[0]?.thresholds?.lowLow
  const highStatus = data?.data[0]?.thresholds?.highStatus
  const lowStatus = data?.data[0]?.thresholds?.lowStatus
  const highHighStatus = data?.data[0]?.thresholds?.highHighStatus
  const lowLowStatus = data?.data[0]?.thresholds?.lowLowStatus

  const plugins = useMemo(() => [thresholdAreaPlugin], [])

  const thresholdConfig = useMemo(
    () => ({
      highHigh: thresholdHighHigh,
      high: thresholdHigh,
      low: thresholdLow,
      lowLow: thresholdLowLow,
      highStatus: highStatus,
      lowStatus: lowStatus,
      highHighStatus: highHighStatus,
      lowLowStatus: lowLowStatus
    }),
    [
      highHighStatus,
      highStatus,
      lowLowStatus,
      lowStatus,
      thresholdHigh,
      thresholdHighHigh,
      thresholdLow,
      thresholdLowLow
    ]
  )

  const options = useMemo(
    () =>
      areaChartOptions({
        unit: graphUnit,
        scaleToShowThreshold: scaleToShowThreshold,
        deltaHours: deltaHours,
        ...thresholdConfig
      }),
    [deltaHours, graphUnit, scaleToShowThreshold, thresholdConfig]
  )

  const chartData = useMemo(() => getSingleSignalData({data}), [data])

  return isLoading ? (
    <Box sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  ) : !isLoading && !chartData.labels?.length ? (
    <ChartError />
  ) : (
    <BaseChart data={chartData} options={options} plugins={plugins} />
  )
}
