import {ChartOptions} from 'chart.js'

import {roundValue} from '../../../../plantMonitoring/shared/utils/sensor.utils'
import {CustomPluginOptions, CustomPlugins} from '../plugins'
import {RangeSelectOnChange} from '../plugins/rangeSelectPlugin'

import {chartTooltip, chartLegend, getTooltipLabel} from './common.config'

export type ZoomLineChartOptions = CustomPluginOptions & {
  unit: string
  onRangeSelection?: (RangeSelectOnChange) => void
  minimalDateRange?: number
  scaleToShowThreshold?: boolean
  onHover?: (event, elements, chart) => void
}

const lang = navigator.language
const isUsFormat = lang.toLowerCase() === 'en-us'

export const zoomLineChartOptions = (
  customOptions: ZoomLineChartOptions
): ChartOptions & {plugins: CustomPlugins} => {
  return {
    plugins: {
      legend: {...chartLegend},
      tooltip: {
        ...chartTooltip,
        callbacks: {
          label: (context) =>
            getTooltipLabel({
              label: context.dataset.label,
              value: context.parsed.y,
              unit: customOptions.unit
            }),
          title: (tooltipItems) => tooltipItems?.[0]?.label || ''
        }
      },
      rangeSelect: {
        // enabled: true,
        onSelectionChanged: (range: RangeSelectOnChange) => {
          if (customOptions.onRangeSelection) customOptions.onRangeSelection(range)
        },
        minimalDateRange: customOptions.minimalDateRange
      },
      thresholdArea: {
        ...customOptions
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4
      }
    },
    onHover(event, elements, chart) {
      customOptions?.onHover?.(event, elements, chart)
    },
    scales: {
      y: {
        suggestedMax: customOptions.scaleToShowThreshold ? customOptions.highHigh : undefined,
        suggestedMin: customOptions.scaleToShowThreshold ? customOptions.lowLow : undefined,
        grid: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.05)'
        },
        border: {
          display: false
        },
        ticks: {
          padding: 20,
          callback: function (value) {
            const parsedValue = typeof value === 'string' ? value : roundValue(value)
            return `${parsedValue} ${customOptions.unit}`
          }
        }
      },
      x: {
        type: 'timeseries',
        time: {
          displayFormats: {
            day: isUsFormat ? 'D.MMM' : 'MMM.D',
            hour: isUsFormat ? 'H:mm, D.M' : 'H:mm M.D',
            minute: 'H:mm'
          },
          tooltipFormat: isUsFormat ? 'MM.DD.YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm:ss'
        },
        ticks: {
          padding: 10,
          source: 'auto',
          autoSkip: true,
          autoSkipPadding: 20,
          minRotation: 20,
          maxRotation: 50,
          sampleSize: 20
        },
        grid: {
          display: true,
          lineWidth: 1,
          color: 'rgba(0,0,0,0.05)'
        }
      }
    },
    layout: {
      padding: {
        right: 30
      }
    }
  }
}
