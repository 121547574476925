import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import {useTranslation} from 'react-i18next'

import {useRequestAccess} from '../hooks/analysis/useRequestAccess'

export const RequestAccessButton = ({
  analysisId,
  successCb
}: {
  analysisId: string
  successCb?: () => void
}) => {
  const {t} = useTranslation()
  const {mutate: requestAccess, isPending: isLoading} = useRequestAccess(analysisId, successCb)

  return (
    <LoadingButton
      onClick={() => requestAccess()}
      color="primary"
      variant="contained"
      startIcon={<SendIcon />}
      loading={isLoading}
      loadingPosition="start"
      sx={{
        height: 48,
        '&.MuiLoadingButton-loading': {
          color: 'grey'
        },
        '&:disabled': {
          color: 'grey',
          '& .MuiSvgIcon-root': {
            color: 'grey'
          }
        }
      }}
    >
      <span>{t('privateAnalysis.requestAccess')}</span>
    </LoadingButton>
  )
}
