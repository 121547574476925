import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {Outlet, useLocation} from 'react-router'
import {Navigate} from 'react-router-dom'

import {RcfaDialogsProvider} from './container/RcfaDialogsProvider'
import {RcfaErrorFallback} from './container/RcfaErrorFallback'
import {AnalysisList} from './pages/AnalysisListPage'
import {GraphDetailPage} from './pages/GraphDetailPage'
import {NewAnalysisPage} from './pages/NewAnalysisPage'
import {StoppageList} from './pages/StoppageList'
import {TasksList} from './pages/TasksList'
import {REL_ROUTES} from './routes'

export const RcfaRoutes = [
  {
    path: REL_ROUTES.graphDetail,
    element: <GraphDetailPage />
  },
  {
    path: REL_ROUTES.newAnalysis,
    element: <NewAnalysisPage />
  },
  {
    path: REL_ROUTES.analysisList,
    element: <AnalysisList />
  },
  {
    path: REL_ROUTES.tasksList,
    element: <TasksList />
  },
  {
    path: REL_ROUTES.stoppageList,
    element: <StoppageList />
  },
  {
    path: '*',
    element: <Navigate to={REL_ROUTES.analysisList} replace />
  }
]
export const RcfaApp = () => {
  const location = useLocation()
  return (
    <ErrorBoundary FallbackComponent={RcfaErrorFallback} resetKeys={[location.pathname]}>
      <RcfaDialogsProvider>
        <Outlet />
      </RcfaDialogsProvider>
    </ErrorBoundary>
  )
}
