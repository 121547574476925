import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {Dispatch, SetStateAction} from 'react'

export const ConfirmDialog = ({
  handleClose,
  setBlock,
  open
}: {
  open: boolean
  handleClose: () => void
  setBlock: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Do you want to leave kiln configuration ?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          All unsaved configuration will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color={'error'}
          onClick={() => {
            setBlock(false)
            handleClose()
          }}
        >
          Proceed
        </Button>
        <Button onClick={handleClose} autoFocus>
          Stay on page
        </Button>
      </DialogActions>
    </Dialog>
  )
}
