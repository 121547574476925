import React, {ReactNode, useRef, useState} from 'react'

import {DataTable, DataTableRef} from '../../../shared/components/DataTable'
import {downloadFile} from '../../../shared/helpers/fileHelpers'
import {OrderByApiProperty} from '../../../shared/hooks/listFiltersSort/useListOrderBy'
import {useListQuerySync} from '../../../shared/hooks/listFiltersSort/useListQuerySync'
import {TableSizedColumns} from '../../../shared/types/common.types'
import {ApiFilter} from '../../../shared/types/filter.types'
import {useGetTasksList} from '../../hooks/tasks/useGetTasksList'
import {useTasksListExport} from '../../hooks/tasks/useTasksListExport'
import {hoverColor} from '../../styles/themeColours'
import {Task} from '../../types/nodeDetail.types'
import {TaskListColumn, TaskListProperty} from '../../types/taskList.types'
import {TaskDetail} from '../task/TaskDetail'

export const TasksListTable: React.FC<{
  page: number
  rowsNumber: number
  widthKey: string
  customColumnRender: (columnName: string, data: Task, widthKey?: string | undefined) => ReactNode
  handleOrderToggle: (col) => void
  availableColumns: TaskListColumn[]
  columns?: TableSizedColumns
  filters?: ApiFilter[]
  order?: OrderByApiProperty[]
}> = ({
  page,
  rowsNumber,
  widthKey,
  columns,
  customColumnRender,
  filters,
  order,
  handleOrderToggle,
  availableColumns
}) => {
  const [activeTask, setActiveTask] = useState<Task | undefined>(undefined)
  const tableRef = useRef<DataTableRef | null>(null)
  const {data, isPending: isLoading} = useGetTasksList(page, rowsNumber, filters, order)
  const {handlePageChange, handleRowsPerPageChange} = useListQuerySync({
    filters,
    order,
    filtersEnabled: true,
    orderEnabled: true
  })
  const {mutateAsync: downloadAsync, isPending: isDownloading} = useTasksListExport()

  const downloadExcel = () =>
    downloadFile({
      getData: async () => downloadAsync({page, filters, order}),
      fileName: `rcfa-tasks_${new Date().toISOString()}.xlsx`
    })

  return (
    <DataTable<TaskListProperty, Task>
      ref={tableRef}
      paginationSettings={{
        page,
        rowsNumber,
        handlePageChange,
        handleRowsPerPageChange
      }}
      tableHeaderSettings={{
        showDescription: true,
        download: {
          showDownload: true,
          downloadFunction: downloadExcel,
          isDownloading
        }
      }}
      orderSettings={{
        handleOrderToggle,
        order
      }}
      widthKey={widthKey}
      columns={columns}
      customColumnRender={customColumnRender}
      tableId={'tasks-list'}
      headerName={'label.tasks'}
      availableColumns={availableColumns}
      hoverColor={hoverColor}
      data={data}
      isLoadingData={isLoading}
      setActiveItem={setActiveTask}
      detailComponent={
        activeTask && (
          <TaskDetail
            analysisTitle={activeTask.analysisTitle}
            nodeTitle={activeTask.nodeTitle}
            nodeId={activeTask.nodeId}
            analysisId={activeTask.analysisId ? activeTask.analysisId.toString() : undefined}
            taskId={activeTask.id}
            closeAction={() => {
              tableRef.current?.clearDetail()
              setActiveTask(undefined)
            }}
            hideBackButton
            disablePadding
          />
        )
      }
    />
  )
}
