import FlagIcon from '@mui/icons-material/Flag'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import React from 'react'

import {Probability} from '../../types/nodeDetail.types'

export const NodeIcon: React.FC<{
  isRootCause: boolean
  causeProbability: string
}> = ({isRootCause, causeProbability}) => {
  return (
    <svg>
      <circle
        cx="13"
        cy="13"
        r="12"
        stroke="black"
        fill={isRootCause ? 'black' : 'transparent'}
        strokeWidth={1}
      />
      {isRootCause && (
        <FlagIcon viewBox="94 -5 200 200" style={{stroke: 'white', strokeWidth: '2px'}} />
      )}
      {!isRootCause && causeProbability === (Probability.likely as string) && (
        <ThumbUpOffAltIcon viewBox="95 -3 200 200" style={{strokeWidth: '2px'}} />
      )}
      {!isRootCause && causeProbability === (Probability.unlikely as string) && (
        <ThumbDownOffAltIcon viewBox="97 -5 200 200" style={{strokeWidth: '2px'}} />
      )}
    </svg>
  )
}
