import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {GraphLevel} from '../../types/canvasNodes.types'

export type Permissions = {
  canContribute: boolean
}

export type ResponseType = {
  analysisId: number
  analysisType: 'string'
  permissions: Permissions
  levels: GraphLevel[]
}

export const QueryKey = 'graph-data'

export const useGraphData = (
  analysisId: string | undefined,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, analysisId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes`
      return (await axiosInstance.get<ResponseType>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
