import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import {ListItemIcon, ListItemText, MenuItem} from '@mui/material'
import React from 'react'

interface CollapseToggleMenuItemProps {
  clickHandler: () => void
  iconVisibility: boolean
  buttonLabel: string
  buttonCaption: string
  divider?: boolean
}

export const CollapseToggleMenuItem: React.FC<CollapseToggleMenuItemProps> = ({
  clickHandler,
  iconVisibility,
  buttonLabel,
  buttonCaption,
  divider = false
}) => {
  return (
    <MenuItem onClick={() => clickHandler()} divider={divider}>
      <ListItemIcon sx={{alignSelf: 'flex-start'}}>
        {iconVisibility ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
      </ListItemIcon>
      <ListItemText primary={buttonLabel} secondary={buttonCaption} />
    </MenuItem>
  )
}
