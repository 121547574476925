import {Probability, VoteList, RootCauseType} from './nodeDetail.types'

export const DEFAULT_ZOOM_VALUE = 100
export interface Node {
  attachmentCount: number
  causeProbability: Probability
  children?: Array<number>
  commentCount: number
  dependsOn?: Array<number>
  description?: string
  id: number
  index?: number
  isOnPathToRootCauseFrom?: Array<number>
  rootCauseType?: RootCauseType
  level?: number
  order?: number
  parent?: number
  taskCount: number
  title: string
  votes: VoteList | undefined
}

export interface CanvasNode {
  attachmentCount: number
  causeProbability: Probability
  children: Array<number>
  collapsed: boolean
  commentCount: number
  cx: number
  cy: number
  dependsOn: Array<number>
  description: string
  hasCollapsedAncestors?: boolean
  id: number
  index?: number
  isOnPathToRootCauseFrom?: Array<number>
  rootCauseType?: RootCauseType
  level: number
  order: number
  parent?: number
  position: number
  taskCount: number
  title: string
  totalTree: number
  votes: VoteList | undefined
}

export interface CanvasLevel {
  nodes: Array<CanvasNode>
  level: number
}
export interface GraphLevel {
  nodes: Array<Node>
  level: number
}

export interface GraphLink {
  source: Node
  target: Node
}

export enum CanvasMode {
  default = 'default',
  hand = 'hand'
}
