import {HttpError} from '@hconnect/common/types'
import {useQuery, UseQueryOptions, keepPreviousData} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import moment, {Moment} from 'moment'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {NormalizeType, SignalGraphData} from '../types/sensor.types'

export const QueryKey = 'signal-plot-data'

export const useGetSignalPlotData = ({
  sensorIds,
  deltaHours,
  timeFrom,
  timeTo,
  normalized = false,
  options
}: {
  sensorIds: string[]
  deltaHours?: number
  timeFrom?: Moment
  timeTo?: Moment
  normalized?: boolean
  options?: UseQueryOptions<SignalGraphData, AxiosError<HttpError>>
}) => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')

  const timeFromRoundedMinutes = timeFrom
    ? timeFrom.clone().startOf('minute')
    : moment()
        .startOf('minute')
        .subtract(deltaHours ?? 24, 'hours')

  const timeToRoundedMinutes = timeTo
    ? timeTo.clone().startOf('minute')
    : moment().startOf('minute')

  const timeFromIso = timeFromRoundedMinutes.toISOString()
  const timeToIso = timeToRoundedMinutes.toISOString()

  return useQuery<SignalGraphData, AxiosError<HttpError>>({
    queryKey: [QueryKey, plantId, sensorIds, timeFromIso, timeToIso, normalized],
    queryFn: async () => {
      const path = `/kms/${plantId}/signal/plot-data`
      return (
        await axiosInstance.get<SignalGraphData>(path, {
          params: {
            normalizeType: normalized ? NormalizeType.LOCAL_MIN_MAX : NormalizeType.NONE,
            localNames: sensorIds,
            timeTo: timeToIso,
            timeFrom: timeFromIso
          }
        })
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: false,
    retry: 3,
    retryDelay: 1000 * 3,
    placeholderData: keepPreviousData,
    // allow the callee to override the default options
    ...options
  })
}
