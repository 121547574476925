import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'

export const createRetryFunction =
  (maxCount: number) => (count: number, error: AxiosError<HttpError>) => {
    if (error?.response?.status && error?.response?.status < 500) {
      return false
    }
    return count < maxCount
  }
