import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

export const QueryKey = 'graph-data'

export function useDeleteVote(
  analysisId: string | undefined,
  nodeId: number,
  voteId: number | undefined
) {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/votes/${voteId}`

      return (await axiosInstance.delete<void>(path)).data
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['node-detail']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
    }
  })
}
