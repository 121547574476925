import {AnalysisStatus, GraphDetailScreen, GraphDetailScreens} from '@hconnect/common/types'
import {Loader, PageContainer, PageTitle, TextOnBackground} from '@hconnect/uikit/src/lib2'
import ArrowLeft from '@mui/icons-material/ArrowLeft'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import {Box, IconButton} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'

import {AnalysisSettingsModal} from '../components/analysisSettings/AnalysisSettingsModal'
import {FinishAnalysisModal} from '../components/analysisSettings/FinishAnalysisModal'
import {NoRootCauseModal} from '../components/analysisSettings/NoRootCauseModal'
import {AnalysisStatusSwitch} from '../components/AnalysisStatusSwitch'
import {ScreensSwitch} from '../components/canvas/ScreenSwitchButton'
import {AnalysisSummaryLayout} from '../container/analysisSummary/AnalysisSummaryLayout'
import {Canvas} from '../container/canvas/Canvas'
import {AnalysisTasksListTable} from '../container/task/TasksList'
import {CanvasProvider} from '../context/CanvasContext'
import {CanvasSettingsProvider} from '../context/CanvasSettingsContext'
import {NodeMoveActionProvider} from '../context/NodeMoveActionContext'
import {useGetAnalysisDetail} from '../hooks/analysis/useGetAnalysisDetail'
import {useGraphData} from '../hooks/analysis/useGraphData'
import {usePatchAnalysisStatus} from '../hooks/analysis/usePatchAnalysisStatus'
import {REL_ROUTES} from '../routes'

interface RouteParams {
  analysisId?: string
  type?: string
  taskId?: string
}

interface GraphDetailContentProps {
  analysisId: string
  type: GraphDetailScreen
  taskId?: string
}

const GraphDetailContent: React.FC<GraphDetailContentProps> = ({analysisId, type, taskId}) => {
  const [searchParams] = useSearchParams()
  const {t} = useTranslation()
  const navigate = useNavigate()

  const [locationState] = useState(useLocation().state)
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLElement | null>(null)
  const [finishAnalysisOpen, finishAnalysisSetOpen] = useState<boolean>(false)
  const [noRootCauseOpen, setNoRootCauseOpen] = useState<boolean>(false)
  const settingsRef = useRef(null)

  const analysisDetail = useGetAnalysisDetail(analysisId)
  const {data, isFetching} = useGraphData(analysisId)
  const {mutate: patchAnalysisStatus} = usePatchAnalysisStatus(Number(analysisId))

  const canEdit =
    analysisDetail.data?.permissions?.canManageActions ||
    analysisDetail.data?.permissions?.canReopen

  useEffect(() => {
    if (!settingsRef.current || searchParams.get('openSettings') !== '1') return
    setSettingsAnchorEl(settingsRef.current)
  }, [searchParams, canEdit])

  const hasRootCause = data?.levels?.some((level) => level.nodes.find((node) => node.rootCauseType))

  const secondaryTitle = (
    <Box
      onClick={() => {
        if (locationState?.pathname) {
          navigate(`${locationState.pathname}${locationState?.search}`)
        } else {
          navigate(`../${REL_ROUTES.analysisList}`)
        }
      }}
      sx={{cursor: 'pointer'}}
    >
      <TextOnBackground
        variant="body1"
        color="primary.contrastText"
        contrast="low"
        highlightOnHover
        mb={0.5}
        ml={7}
      >
        <Box component="span" sx={(theme) => ({display: 'inline-block', width: theme.spacing(3)})}>
          <ArrowLeft sx={{verticalAlign: 'text-bottom'}} />
        </Box>
        {t('buttons.backToOverview')}
      </TextOnBackground>
    </Box>
  )

  const renderSwitch = (type: GraphDetailScreen, data) => {
    switch (type) {
      case 'tasks':
        return <AnalysisTasksListTable analysisId={analysisId} taskId={taskId} />
      case 'summary':
        return <AnalysisSummaryLayout />
      default:
        return (
          <CanvasSettingsProvider permissions={data.permissions}>
            <CanvasProvider rawNodes={data.levels}>
              <NodeMoveActionProvider>
                <Canvas canContribute={data.permissions.canContribute} />
              </NodeMoveActionProvider>
            </CanvasProvider>
          </CanvasSettingsProvider>
        )
    }
  }

  const pageProps: {overflow: string} = {
    overflow: 'hidden'
  }

  return (
    <PageContainer
      data-test-id="pageTitle"
      px={0}
      pt={{xs: 0, sm: 0, md: 0, lg: 0}}
      pb={{xs: 0, sm: 0, md: 0, lg: 0}}
      sx={pageProps}
    >
      <Box
        displayPrint="none"
        sx={{
          padding: '24px 0',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          background:
            type === 'tasks'
              ? 'inherit'
              : 'radial-gradient(70.49% 70.49% at 50% 10.41%, #18699f 0%, #0f4b73 100%)'
        }}
      >
        <Box
          sx={{
            flex: '1 0 250px'
          }}
        >
          <PageTitle
            ml={7}
            mb={0}
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '1',
              whiteSpace: 'pre-wrap'
            }}
          >
            {data?.levels?.[0]?.nodes?.[0]?.title ?? t('pages.mainTitle')}
          </PageTitle>
          {secondaryTitle}
        </Box>

        <Box
          mr={7}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: '55vw',
            gap: 1,
            alignContent: 'flex-start'
          }}
        >
          <ScreensSwitch type={type} />
          <Box flexBasis="fit-content">
            {!!analysisDetail?.data?.status && (
              <AnalysisStatusSwitch
                status={analysisDetail.data.status}
                canFinish={analysisDetail.data?.permissions?.canFinish}
                canReopen={analysisDetail.data?.permissions?.canReopen}
                onChange={(newStatus) => {
                  if (newStatus === AnalysisStatus.Finished) {
                    hasRootCause ? finishAnalysisSetOpen(true) : setNoRootCauseOpen(true)
                  } else {
                    patchAnalysisStatus({status: newStatus})
                  }
                }}
              />
            )}
          </Box>
          {canEdit && (
            <IconButton
              ref={settingsRef}
              sx={(theme) => ({
                color: theme.palette.common.white,
                borderRadius: '6px',
                border: `1px solid rgba(255, 255, 255, ${settingsAnchorEl ? '0.15' : '0.4'})`,
                height: '48px',
                width: '48px',
                '&:hover': {
                  borderColor: theme.palette.common.white
                },
                backgroundColor: settingsAnchorEl && 'rgba(0, 0, 0, 0.05)'
              })}
              onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
            >
              <GroupOutlinedIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '@media print': {
            mt: 0
          }
        }}
      >
        {!data && isFetching && <Loader height="60vh" color="primary.contrastText" />}
        {data && renderSwitch(type, data)}
        <AnalysisSettingsModal
          open={Boolean(canEdit) && Boolean(settingsAnchorEl)}
          anchorEl={settingsAnchorEl}
          onClose={() => setSettingsAnchorEl(null)}
        />
        {data && analysisDetail.data?.permissions?.canFinish && (
          <>
            <NoRootCauseModal
              open={noRootCauseOpen}
              onClose={() => setNoRootCauseOpen(false)}
              onContinue={() => {
                setNoRootCauseOpen(false)
                finishAnalysisSetOpen(true)
              }}
            />
            <FinishAnalysisModal
              analysisId={analysisId}
              open={finishAnalysisOpen}
              setOpen={finishAnalysisSetOpen}
            />
          </>
        )}
      </Box>
    </PageContainer>
  )
}

export const GraphDetailPage: React.FC = () => {
  const {analysisId, type: maybeType}: RouteParams = useParams()
  const [searchParams] = useSearchParams()

  const taskId = searchParams.get('taskId') || undefined

  const type: GraphDetailScreen = GraphDetailScreens.find((s) => s === maybeType) ?? 'graph'

  return (
    <>
      {analysisId && type && (
        <GraphDetailContent analysisId={analysisId} type={type} taskId={taskId} />
      )}
    </>
  )
}
