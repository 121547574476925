import NotInterestedIcon from '@mui/icons-material/NotInterested'
import {Typography, Button} from '@mui/material'
import React from 'react'

import {StoppageInfo} from '../../types/stoppage.types'

export const StoppageTitleButton = ({
  stoppage,
  canCreateAnalysis,
  onClick
}: {
  stoppage: StoppageInfo
  canCreateAnalysis: boolean
  onClick: ({
    event,
    stoppage
  }: {
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
    stoppage: StoppageInfo
  }) => void
}) => {
  const analysisExists = Boolean(stoppage?.analysisId)
  const analysisIsPrivate = Boolean(stoppage?.isPrivateForUser)

  return (
    <Button
      variant="text"
      onClick={(event) => onClick({event, stoppage})}
      disabled={!analysisExists && !canCreateAnalysis}
      startIcon={analysisIsPrivate && <NotInterestedIcon sx={{color: 'secondary.main'}} />}
    >
      <Typography
        variant="body1"
        color={analysisIsPrivate ? 'secondary.main' : 'secondary.dark'}
        align="left"
        sx={{textTransform: 'capitalize'}}
      >
        {stoppage?.title ?? '-'}
      </Typography>
    </Button>
  )
}
