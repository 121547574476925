import {Box} from '@mui/material'
import React, {useState} from 'react'

import {CanvasMode} from '../../types/canvasNodes.types'

export const ScrollDrag: React.FC<{
  canvasMode: CanvasMode
  viewBoxWidth: number
  viewBoxHeight: number
  children: JSX.Element | JSX.Element[]
}> = ({canvasMode, children, viewBoxWidth, viewBoxHeight}) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [clientX, setClientX] = useState(0)
  const [clientY, setClientY] = useState(0)

  const onMouseDown = (e) => {
    e.preventDefault()
    setIsScrolling(true)
    setClientX(e.clientX)
    setClientY(e.clientY)
  }

  const onMouseUp = (e) => {
    e.preventDefault()
    setIsScrolling(false)
  }

  const onMouseMove = (e) => {
    e.preventDefault()
    if (isScrolling) {
      const svg = document.getElementById('svgContainer')
      svg?.scrollBy(clientX - e.clientX, clientY - e.clientY)
      setClientX(e.clientX)
      setClientY(e.clientY)
    }
  }

  const getCursor = () => {
    if (isScrolling) {
      return 'grabbing'
    }
    return canvasMode === CanvasMode.hand ? 'grab' : 'default'
  }

  return (
    <Box
      onMouseDown={canvasMode === CanvasMode.hand ? onMouseDown : () => {}}
      onMouseUp={canvasMode === CanvasMode.hand ? onMouseUp : () => {}}
      onMouseMove={canvasMode === CanvasMode.hand ? onMouseMove : () => {}}
      sx={{
        height: viewBoxHeight,
        width: viewBoxWidth,
        cursor: getCursor()
      }}
    >
      {children}
    </Box>
  )
}

export default ScrollDrag
