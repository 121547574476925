import {TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {commonFilterSx} from '../../styles/filters'
import {Filter} from '../../types/filter.types'

export const TextSearchFilter: React.FC<Filter<string>> = ({id, label, value, handleChange}) => {
  const {t} = useTranslation()
  return (
    <TextField
      data-test-id={`textsearch-${id}`}
      type="text"
      value={value}
      onChange={handleChange}
      inputProps={{maxLength: 50}}
      InputProps={{sx: {height: '58px'}}}
      variant="filled"
      label={t(label)}
      placeholder={'search'}
      sx={commonFilterSx}
      fullWidth
    />
  )
}
