import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {Comment} from '../../types/nodeDetail.types'

export type ResponseType = {
  data: boolean
}

import {GetCommentsQueryKey} from './commentQueryKeys'

export const useEditComment = (analysisId: string | undefined, nodeId: number) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (commentUpdateData: Comment) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/comments/${commentUpdateData.id}`
      return (await axiosInstance.patch<number>(path, {text: commentUpdateData.text})).status
    },
    onSuccess: async (data: number) => {
      await queryClient.invalidateQueries({queryKey: [GetCommentsQueryKey]})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      return data
    }
  })
}
