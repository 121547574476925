import {MaintainAppName} from '@hconnect/common/hproduce/maintain.types'
import {Env} from '@hconnect/common/hproduce/types'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {
  Notifications,
  TranslationKey,
  TranslationActionKey,
  Translations,
  NotificationsOptions,
  generateNotificationChannels
} from '@hconnect/common/notifications'
import {Shell, CommonHeader, useGuidedTours, TourBanner} from '@hconnect/uikit/src/lib2'
import i18n from 'i18next'
import React, {useEffect, useMemo} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {Navigate, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'

import {useAppsByWhiteList} from '../../dashboard/hooks/useAppsByWhiteList'
import {useGlobalContext} from '../hooks/useGlobalContext'
import {supportedLanguages} from '../localization'
import {getNavItems} from '../routing'

import {MaintainErrorFallback} from './MaintainErrorFallback'

type MaintainUrlApp = 'rcfa' | 'pm'

const maintainAppMap: Record<MaintainUrlApp, MaintainAppName> = {
  rcfa: 'Rcfa',
  pm: 'PM'
}

const NOTIFICATIONS_TRANSLATION_KEYS: Translations = {
  label: {
    [TranslationKey.TODAY]: 'notifications.label.today',
    [TranslationKey.YESTERDAY]: 'notifications.label.yesterday',
    [TranslationKey.NOTIFICATIONS]: 'notifications.label.notifications',
    [TranslationKey.EMPTY]: 'notifications.label.empty',
    [TranslationKey.ERROR]: 'error.label.fetchingError',
    [TranslationKey.SHOULD_DELETE]: 'notifications.label.shouldDeleteNotification'
  },
  action: {
    [TranslationActionKey.DELETE]: 'notifications.action.delete',
    [TranslationActionKey.CONFIRM_DELETE]: 'notifications.action.confirmDelete',
    [TranslationActionKey.CANCEL]: 'action.cancel'
  }
}

const getSelectedApp = (path: string): MaintainAppName => {
  const parts = path.split('/')
  if (parts.length < 3) return 'default'
  const maybeMaintainUrlApp = parts[3]
  if (Object.keys(maintainAppMap).includes(maybeMaintainUrlApp)) {
    return maintainAppMap[maybeMaintainUrlApp]
  }
  return 'default'
}

export const Layout = () => {
  const {t} = useTranslation()
  const {plantId} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const selectedApp: MaintainAppName = getSelectedApp(location.pathname)
  const globalContext = useGlobalContext().state
  const apps = useAppsByWhiteList(globalContext.whiteList, plantId)

  //  const notificationsFeatureFlagEnabled = useFeatureFlag('notifications')

  const notificationsEnabled = globalContext.user

  const appOptions = globalContext.whiteList.optionsPerApp.Maintain
  const plantNotAllowed = Boolean(plantId) && !appOptions?.some((opt) => opt.plantId === plantId)

  const {tours, tourStatuses} = useGuidedTours()

  const navItems = useMemo(() => {
    if (!plantId) return []
    const navItems = getNavItems(selectedApp, plantId, t)

    if (apps.allowed.length > 1) {
      if (selectedApp !== 'default') {
        navItems.unshift({
          label: 'Dashboard',
          url: `/plant/${plantId}/maintain_dashboard`,
          subItems: undefined,
          activateOnlyIfExact: false
        })
      }
    }
    return navItems
  }, [t, plantId, selectedApp, apps.allowed])

  useEffect(() => {
    i18n.cloneInstance({
      supportedLngs: supportedLanguages[selectedApp]
    })
  }, [selectedApp])

  const options = useMemo<NotificationsOptions>(() => {
    const channels = generateNotificationChannels(globalContext.whiteList)
    return {
      channels,
      countryCode: globalContext.user.countryCode
    }
  }, [globalContext.whiteList, globalContext.user.countryCode])

  if (plantNotAllowed) {
    return <Navigate to={'/'} replace={true} />
  }

  return (
    <Shell
      isResponsive
      boxed={false}
      header={
        <CommonHeader
          additionalActions={
            notificationsEnabled
              ? [
                  <Notifications
                    key="notification-provider"
                    options={options}
                    translations={NOTIFICATIONS_TRANSLATION_KEYS}
                  />
                ]
              : undefined
          }
          appName="Maintain"
          plant={plantId ? {plantName: getPlantName(plantId), plantId: plantId} : undefined}
          onLogout={globalContext.onLogout}
          supportedLanguages={supportedLanguages[selectedApp]}
          user={globalContext.user}
          env={process.env.REACT_APP_STAGE as Env}
          whiteList={globalContext.whiteList}
          navItems={navItems}
          navigate={(url) => navigate(url)}
          locationPath={location.pathname}
          guidedTours={{tours, statuses: tourStatuses}}
          footer={{copyrightNote: t('footer.copyrightNote')}}
        />
      }
    >
      <ErrorBoundary FallbackComponent={MaintainErrorFallback} resetKeys={[location.pathname]}>
        <TourBanner sx={{mt: 3, mx: {xs: 2, lg: 4}}} />
        <Outlet />
      </ErrorBoundary>
    </Shell>
  )
}
