import type {Dates} from '@hconnect/uikit'
import {SimplePickerComponents as SPC} from '@hconnect/uikit/src/lib2'
import DateRange from '@mui/icons-material/DateRange'
import {Checkbox, Popover, Box, FormControlLabel, Theme} from '@mui/material'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

type DateRange = [Date | null, Date | null]

export const getResponsivePopoverStyle = (theme: Theme) => ({
  marginTop: 2,
  color: theme.palette.primary.contrastText,
  '@media only screen and (max-width: 410px)': {
    display: 'flex',
    justifyContent: 'center',
    left: '0 !important',
    width: '100%',
    maxWidth: '100%'
  }
})

interface Props {
  className?: string
  date?: Date | null
  dateRange?: DateRange
  isOutsideRange?: (arg: Date) => boolean
  label?: React.ReactNode
  onChange: (value) => void
}

const formatDate = (date: Date, language: string, textFormatter?: (d: Date) => string): string =>
  textFormatter ? textFormatter(date) : date?.toLocaleDateString(language)

enum TYPES {
  DATE = 'date',
  DATE_RANGE = 'dateRange'
}

const getSelectionType = (date, dateRange) => {
  if (date) return TYPES.DATE
  if (dateRange) return TYPES.DATE_RANGE
  throw new Error('no value selected in date picker')
}

const RangeDatePicker = (props: Props) => {
  const {date, dateRange = [], isOutsideRange, onChange, className} = props
  const {t, i18n} = useTranslation()
  const {language} = i18n
  const isSingleDate = date !== undefined
  const [startDate, endDate] = dateRange
  const [selectedStartDate, setSelectedStartDate] = useState(date || startDate || null)
  const [selectedEndDate, setSelectedEndDate] = useState(endDate)
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [isRangeSelect, setShowRangeSelect] = useState(!isSingleDate)
  const [selectionType, setType] = useState(getSelectionType(date, dateRange))
  const noDateText = t('datePicker.chooseDate')

  const selectionText = useMemo(() => {
    if (selectionType === TYPES.DATE || !selectedEndDate) {
      return `${formatDate(selectedStartDate as Date, language)} - ${formatDate(
        selectedStartDate as Date,
        language
      )}`
    }
    if (selectionType === TYPES.DATE_RANGE) {
      return `${formatDate(selectedStartDate as Date, language)} - ${formatDate(
        selectedEndDate,
        language
      )}`
    }
    return noDateText
  }, [t, selectionType, language, noDateText, selectedEndDate, selectedStartDate])

  const close = useCallback(() => setOpen(false), [setOpen])

  const handleDateRangeChange = (dates: Dates<Date>) => {
    setSelectedStartDate(dates.startDate || null)
    setSelectedEndDate(dates.endDate || null)

    if (dates.endDate) {
      onChange(dates)
      setType(TYPES.DATE_RANGE)
    }
  }

  const handleSingleDateChange = (date: Date | null) => {
    if (!date) return
    setSelectedStartDate(date)
    setSelectedEndDate(date)
    onChange(date)
    setType(TYPES.DATE)
  }

  const calendarProps = {
    isOutsideRange,
    startDate: selectedStartDate,
    ...(isRangeSelect
      ? {endDate: selectedEndDate, onRangeChange: handleDateRangeChange}
      : {onDateChange: handleSingleDateChange})
  }

  return (
    <Box
      sx={{
        padding: 0,
        display: 'inline-block',
        position: 'relative',
        zIndex: 999,
        width: {
          xs: '100%',
          md: 'inherit'
        }
      }}
      className={className}
    >
      <SPC.DatePickerTextField
        buttonRef={anchorRef}
        focus={open}
        setFocus={setOpen}
        text={selectionText}
        label={t('datePicker.label')}
      />

      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: getResponsivePopoverStyle,
            id: 'simple-date-picker-popover-paper'
          }
        }}
        data-test-id="simple-date-picker-popover"
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 356
          }}
        >
          <>
            <SPC.Calendar {...calendarProps} />
            <Box
              sx={(theme) => ({
                color: theme.palette.text.primary,
                width: '100%'
              })}
            >
              <FormControlLabel
                sx={{mb: 1}}
                control={
                  <Checkbox
                    data-test-id="range-select"
                    checked={isRangeSelect}
                    onChange={() => setShowRangeSelect((prev) => !prev)}
                  />
                }
                label={'Select Range'}
              />
              <SPC.PickerForm
                applyDateRange={(value) => {
                  handleDateRangeChange(value)
                  close()
                }}
                isOutsideRange={isOutsideRange}
                isSingleDate={!isRangeSelect}
                endDate={selectedEndDate}
                startDate={selectedStartDate}
              />
            </Box>
          </>
        </Box>
      </Popover>
    </Box>
  )
}

export {RangeDatePicker}
