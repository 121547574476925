import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {useAxios} from '../../../shared/hooks/useApi'

export type ResponseType = {
  data: string
}

export const useRequestAccess = (analysisId: string, successCb?: () => void) => {
  const axiosInstance = useAxios()
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  return useMutation({
    mutationFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/access`
      return (await axiosInstance.post<ResponseType>(path)).data
    },
    onSuccess: () => {
      enqueueSnackbar(t('privateAnalysis.successMessage'), {
        variant: 'success',
        preventDuplicate: true,
        disableWindowBlurListener: true,
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        onClick: () => closeSnackbar()
      })
      successCb?.()
    },
    onError: () => {
      enqueueSnackbar('Failed to request access', {
        variant: 'error',
        preventDuplicate: true,
        disableWindowBlurListener: true,
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        onClick: () => closeSnackbar()
      })
    }
  })
}
