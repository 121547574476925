import {useDraggable} from '@dnd-kit/core'
import {CSS} from '@dnd-kit/utilities'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {Box, Typography, Button, Portal, TextField, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {DragHandle} from '../../../shared/components/DragHandle'
import {nodeDescriptionValidation, nodeTitleValidation, validateAll} from '../../common/validator'
import {AnalysisDescriptionTooltip} from '../../components/AnalysisDescriptionTooltip'
import ModalCircularProgress from '../../components/ModalCircularProgress'
import {AiSuggestions} from '../../components/node/Suggestions'
import {useBulkCreateNodes} from '../../hooks/node/useBulkCreateNodes'
import {useCreateNode} from '../../hooks/node/useCreateNode'
import {CreateNodeType, File, NodeCreationMethod, NodeItem} from '../../types/nodeDetail.types'

import {NodeAttachments} from './nodeForm/NodeAttachments'

import '../../styles/quill.snow.css'

export const CreateNodeModal: React.FC<{
  open: boolean
  addParent: boolean
  nodeId: number
  parentId: number
  nodeTitle: string
  parentTitle: string
  order: number
  closeModal: () => void
  setNewNode: React.Dispatch<React.SetStateAction<number | null>>
  x: number
  y: number
}> = ({
  nodeId,
  closeModal,
  setNewNode,
  x,
  y,
  addParent,
  parentId,
  order,
  nodeTitle,
  parentTitle
}) => {
  const {t} = useTranslation()
  const [selectedSuggestions, setSelectedSuggestions] = useState<string[]>([])
  const [suggestionsOpened, setSuggestionsOpened] = useState(false)
  const [files, setFiles] = useState<Array<File>>([])
  const [validationError, setValidationError] = useState<Map<string, string>>(new Map())
  const [item, setItem] = useState<NodeItem>({
    description: '',
    title: ''
  })
  const {mutate, isPending: isLoading} = useCreateNode()
  const {bulkCreateNodes, inProgress} = useBulkCreateNodes()

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `node-detail-${nodeId}`
  })
  const style = {
    transform: CSS.Translate.toString(transform) // Apply transform for dragging
  }

  const bulkCreationMode = selectedSuggestions.length > 1

  const validatorConfig = new Map([
    ['description', nodeDescriptionValidation],
    ['title', nodeTitleValidation]
  ])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['clean']
    ]
  }

  const clearModal = () => {
    setItem({description: '', title: ''})
    setValidationError(new Map())
    closeModal()
  }

  const doSubmit = () => {
    const validationError = validateAll(validatorConfig, item)
    setValidationError(validationError)
    if (validationError.size === 0) {
      const creationMethod = getCreationMethod(suggestionsOpened, selectedSuggestions, item.title)

      const addData: CreateNodeType = {
        Parent: nodeId,
        Title: item.title,
        Description: item.description,
        NewAttachments: files,
        CreationMethod: creationMethod
      }

      if (addParent) {
        addData.ChildId = nodeId
        addData.Parent = parentId
        addData.Order = order
      }
      mutate(addData, {
        onSuccess: (data) => {
          setNewNode(data?.selectedNodeId)
          clearModal()
        }
      })
    }
  }

  const handleSuggestionSelected = ({value, checked}: {value: string; checked: boolean}) => {
    setSelectedSuggestions((prev) =>
      checked ? [...prev, value] : prev.filter((suggestion) => suggestion !== value)
    )
  }

  const handleBulkCreate = async () => {
    await bulkCreateNodes({titles: selectedSuggestions, parentId: nodeId, onFinish: clearModal})
  }

  const getErrorText = (key: string) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  useEffect(() => {
    if (selectedSuggestions.length === 1) {
      setItem((prev) => ({...prev, title: selectedSuggestions[0]}))
    }
  }, [selectedSuggestions])

  return (
    <Portal>
      <Box
        ref={setNodeRef}
        py={3}
        pl={3}
        sx={(theme) => ({
          background: theme.palette.common.white,
          width: '20%',
          maxWidth: '513px',
          minWidth: '500px',
          borderRadius: 1,
          overflowY: 'hidden',
          color: theme.palette.common.black,
          boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.16)'
        })}
        style={{
          ...style,
          position: 'fixed',
          top: y,
          left: x
        }}
      >
        <Typography
          variant="h2"
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'flex-start',
            pb: 2
          }}
        >
          <DragHandle attributes={attributes} listeners={listeners} />
          {t('label.createNewNode')}
        </Typography>
        {isLoading || inProgress ? (
          <ModalCircularProgress />
        ) : (
          <Box
            sx={(theme) => ({
              position: 'relative',
              maxHeight: '60vh',
              overflowY: 'auto',
              background: theme.palette.common.white,
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            })}
            pr={1}
            pt={1}
          >
            {/* ----------- TITLE ----------- */}
            {!bulkCreationMode && (
              <Stack spacing={0.5}>
                <TextField
                  data-test-id="node-form-title"
                  error={validationError.has('title')}
                  fullWidth={true}
                  helperText={getErrorText('title')}
                  label={t('label.title')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setItem({...item, title: event.target.value})
                  }
                  value={item.title}
                  required
                />
                <Typography variant="caption" color="#8599AD" alignSelf={'flex-end'}>
                  {item.title.length}/{80}
                </Typography>
              </Stack>
            )}

            <Box>
              <AiSuggestions
                nodeTitle={addParent ? parentTitle : nodeTitle}
                selectedSuggestions={selectedSuggestions}
                exclusive={addParent}
                onSelected={handleSuggestionSelected}
                onExpand={() => setSuggestionsOpened(true)}
              />

              {!bulkCreationMode && (
                <Stack spacing={2}>
                  {/* ----------- DESCRIPTION ----------- */}
                  <Stack spacing={0.5}>
                    <AnalysisDescriptionTooltip top={35} left={410} />
                    <ReactQuill
                      modules={modules}
                      theme={'snow'}
                      onChange={(next) => setItem({...item, description: next})}
                      placeholder={`${t('label.description')} *`}
                      formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography
                        sx={(theme) => ({color: theme.palette.error.light, fontSize: 12})}
                      >
                        {validationError.has('description') ? getErrorText('description') : ''}
                      </Typography>
                      <Typography variant="caption" color="#8599AD">
                        {item.description.replaceAll(/(<([^>]+)>)/gi, '').length}/{5000}
                      </Typography>
                    </Box>
                  </Stack>

                  <NodeAttachments files={files} setFiles={setFiles} />
                </Stack>
              )}
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'right', gap: 1, pb: 1}}>
              <Button
                startIcon={<Close />}
                variant="outlined"
                color="secondary"
                onClick={clearModal}
                data-test-id="node-form-cancel-button"
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                startIcon={<Check />}
                color="primary"
                variant="contained"
                onClick={bulkCreationMode ? handleBulkCreate : doSubmit}
                data-test-id="node-form-save-button"
              >
                {bulkCreationMode
                  ? t('buttons.createNodesBulk', {count: selectedSuggestions.length})
                  : t('buttons.save')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Portal>
  )
}

const getCreationMethod = (
  suggestionsOpened: boolean,
  selectedSuggestions: string[],
  nodeTitle: string
): NodeCreationMethod => {
  const aISuggestionUsed = !!selectedSuggestions.length
  const titleModified = aISuggestionUsed && !selectedSuggestions.includes(nodeTitle)
  const aiRejected = suggestionsOpened && !aISuggestionUsed

  if (aiRejected) {
    return 'aiRejected'
  }

  if (titleModified) {
    return 'createdByAIModified'
  }

  if (aISuggestionUsed) {
    return 'createdByAI'
  }

  return 'createdManually'
}
