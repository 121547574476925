import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {Box, Button, Grid, TextareaAutosize} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import ModalCircularProgress from '../../../components/ModalCircularProgress'
import {useEditComment} from '../../../hooks/comments/useEditComment'
import {Comment} from '../../../types/nodeDetail.types'

export const EditComment: React.FC<{
  nodeId: number
  defaultComment: Comment
  analysisId: string
  closeForm: () => void
  setSelectedCommentId: React.Dispatch<React.SetStateAction<string | undefined>>
}> = ({nodeId, closeForm, analysisId, defaultComment, setSelectedCommentId}) => {
  const {t} = useTranslation()
  const theme = useTheme()
  const {fontFamily, fontSize, lineHeight} = theme.typography.body2

  const [comment, setComment] = useState<Comment>(defaultComment)
  const {mutate, isPending: isLoading} = useEditComment(analysisId, nodeId)

  const submitComment = () => {
    if (comment) {
      mutate(comment)
    }
  }

  useEffect(() => {
    if (isLoading) {
      setSelectedCommentId(undefined)
      closeForm()
    }
  }, [isLoading, closeForm, setSelectedCommentId])

  return (
    <Grid>
      {isLoading ? (
        <ModalCircularProgress />
      ) : (
        <Box>
          <TextareaAutosize
            data-test-id={'edit-comment-input'}
            aria-label="comment-input"
            placeholder={t('comments.placeholder')}
            style={{
              width: '100%',
              padding: '16.5px 14px',
              borderRadius: '8px',
              fontFamily,
              fontSize,
              lineHeight,
              fontWeight: 400,
              color: '#00374D'
            }}
            defaultValue={defaultComment.text}
            onChange={(e) => {
              const newValue = e.target.value ?? ''
              setComment({...comment, text: newValue})
            }}
          />
          <Box sx={{display: 'flex', justifyContent: 'right'}}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              color="secondary"
              onClick={() => closeForm()}
              data-test-id="node-form-cancel-button"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={submitComment}
              data-test-id="node-form-save-button"
              sx={{marginLeft: 1}}
            >
              {t('comments.edit')}
            </Button>
          </Box>
        </Box>
      )}
    </Grid>
  )
}
