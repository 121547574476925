import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

import {GetCommentsQueryKey} from './commentQueryKeys'

type CommentUpdateData = {
  text: string
}

export function useAddComment(analysisId: string | undefined, nodeId: number) {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (comment: CommentUpdateData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/comments`

      return (await axiosInstance.post<void>(path, comment)).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [GetCommentsQueryKey]})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
    }
  })
}
