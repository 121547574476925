import moment from 'moment'

export const formatDuration = (
  duration: number,
  unit: moment.unitOfTime.Base = 'seconds'
): string => {
  const d = moment.duration(duration, unit)
  const days = Math.trunc(d.asDays())
  let result = days > 0 ? days.toString() + 'd ' : ''
  result += `${d.hours().toString().padStart(2, '0')}`
  result += `:${d.minutes().toString().padStart(2, '0')}`
  result += `:${d.seconds().toString().padStart(2, '0')}`
  return result
}

export function formatDateTime(dateTimeIso?: string, sep?: string): string {
  const format = sep ? `DD.MM.YYYY${sep}HH:mm` : 'DD.MM.YYYY - HH:mm'
  return dateTimeIso ? moment(dateTimeIso).format(format) : ''
}
export function formatDate(dateTimeIso?: string): string {
  return dateTimeIso ? moment(dateTimeIso).format('DD.MM.YYYY') : ''
}
