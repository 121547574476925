import {PermissionType} from '@hconnect/apiclient'
import {waitForDOMElementToAppear} from '@hconnect/uikit/src'
import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'

import {hasPermission} from '../../../rcfa/hooks/usePermission'
import {useGlobalContext} from '../../../shared/hooks/useGlobalContext'

import {ReactComponent as Illustration} from './Onboarding_illustration.svg'

type KmsTour = 'onboardingTour'

export const useKmsTours = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const permissions = useGlobalContext().state.permissions

  const plantId = location.pathname?.split('/')?.[2]
  const canUseKms =
    permissions && plantId
      ? hasPermission(permissions, 'VIEW_KILN_MONITORING_DATA' as PermissionType, plantId)
      : false

  const isDashboard = location.pathname.endsWith(plantId)
  const isKms = location.pathname.includes('kms')
  const canStartKmsTour = canUseKms && (isDashboard || isKms)

  const enabledKmsTours = useMemo(
    (): Record<KmsTour, boolean> => ({
      onboardingTour: canStartKmsTour
    }),
    [canStartKmsTour]
  )

  const kmsUrl = `/plant/${plantId}/kms`

  const getStepTranslation = useCallback(
    (tourKey: KmsTour, step: number | string) => ({
      title: t(`kmsTours.${tourKey}.step${step}.title`),
      content: t(`kmsTours.${tourKey}.step${step}.text`)
    }),
    [t]
  )

  const kmsTours = useMemo(
    (): Record<KmsTour, GuidedTour> => ({
      onboardingTour: {
        title: t('kmsTours.onboardingTour.title'),
        description: t('kmsTours.onboardingTour.description'),
        onTourStarted: async () => {
          navigate(kmsUrl)
          await waitForDOMElementToAppear("[data-test-id='kms-page-title']")
        },
        image: <Illustration />,
        steps: [
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('kms-page-title'),
            ...getStepTranslation('onboardingTour', 1),
            placement: 'right',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('kms-page-title'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('kms-page-title'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('kiln-model-card'),
            ...getStepTranslation('onboardingTour', 2),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('kiln-model-card'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('kiln-model-card'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('kiln-data'),
            ...getStepTranslation('onboardingTour', 3),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
              await waitForDOMElementToAppear(testIdSelector('kiln-data'))
            },
            onGoPrevToStep: async () => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
              await waitForDOMElementToAppear(testIdSelector('kiln-data'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('kiln-model'),
            ...getStepTranslation('onboardingTour', 4),
            placement: 'auto',
            disableScrolling: true,
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('kiln-model'))
            },
            onGoPrevToStep: async () => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
              await waitForDOMElementToAppear(testIdSelector('kiln-model'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('signal-list'),
            ...getStepTranslation('onboardingTour', 5),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('signal-list'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('signal-list'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('chart-wrapper'),
            ...getStepTranslation('onboardingTour', 6),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-testid='sensor-list-item']")
              const el = document.querySelector("[data-testid='sensor-list-item']")
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('chart-wrapper'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('chart-wrapper'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('time-range'),
            ...getStepTranslation('onboardingTour', '6a'),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('time-range'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('time-range'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('compare-signals-btn') + ':not([disabled])',
            ...getStepTranslation('onboardingTour', 7),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear("[data-testid='sensor-list-item']")
              const elements = document.querySelectorAll("[data-testid='sensor-list-item']")
              const secondElement = elements?.[1]
              secondElement?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
            },
            onGoPrevToStep: async () => {
              const el = document.querySelector(testIdSelector('cancel-btn'))
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('popout-view-btn'))
              await waitForDOMElementToAppear(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('draggable-sensor-item'),
            ...getStepTranslation('onboardingTour', 8),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
              const el = document.querySelector(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('draggable-sensor-item'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
              const el = document.querySelector(
                testIdSelector('compare-signals-btn') + ':not([disabled])'
              )
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('draggable-sensor-item'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('popout-view-btn'),
            ...getStepTranslation('onboardingTour', 10),
            placement: 'auto',
            onGoNextToStep: async () => {
              const el = document.querySelector(testIdSelector('cancel-btn'))
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('popout-view-btn'))
            },
            onGoPrevToStep: async () => {
              const el = document.querySelector(testIdSelector('close-btn'))
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('popout-view-btn'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('popout-view-title'),
            ...getStepTranslation('onboardingTour', 11),
            placement: 'bottom',
            onGoNextToStep: async () => {
              const el = document.querySelector(testIdSelector('popout-view-btn'))
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
              await waitForDOMElementToAppear(testIdSelector('popout-view-title'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('popout-view-title'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('date-picker-toggle-button'),
            ...getStepTranslation('onboardingTour', 12),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('date-picker-toggle-button'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('date-picker-toggle-button'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('chart-wrapper'),
            ...getStepTranslation('onboardingTour', 13),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('chart-wrapper'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('chart-wrapper'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('reset-zoom-btn'),
            ...getStepTranslation('onboardingTour', 14),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('reset-zoom-btn'))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('reset-zoom-btn'))
            }
          },
          {
            // @ts-expect-error joyRide supports other selectors than just IDs
            target: testIdSelector('popout-columns'),
            ...getStepTranslation('onboardingTour', 15),
            placement: 'auto',
            onGoNextToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('popout-columns'))
              const el = document.querySelector(testIdSelector('add-column-btn'))
              el?.dispatchEvent(new MouseEvent('click', {bubbles: true}))
            },
            onGoPrevToStep: async () => {
              await waitForDOMElementToAppear(testIdSelector('popout-columns'))
            }
          }
        ]
      }
    }),
    [getStepTranslation, kmsUrl, navigate, t]
  )

  return {kmsTours, enabledKmsTours}
}

const testIdSelector = (dataTestId: string) => `[data-test-id='${dataTestId}']`
