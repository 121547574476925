import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'

export type ResponseType = {
  data: boolean
}

import {GetCommentsQueryKey} from './commentQueryKeys'

export const useDeleteComment = (analysisId: string | undefined, nodeId: number) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (commentId: string) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/comments/${commentId}`
      return (await axiosInstance.delete<ResponseType>(path, {})).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [GetCommentsQueryKey]})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      return {data: true}
    }
  })
}
