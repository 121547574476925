import {AnalysisListType} from '@hconnect/common/types'
import {Loader} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import React, {useEffect} from 'react'

import {DataTable} from '../../../shared/components/DataTable'
import {OrderByApiProperty} from '../../../shared/hooks/listFiltersSort/useListOrderBy'
import {useListQuerySync} from '../../../shared/hooks/listFiltersSort/useListQuerySync'
import {ApiFilter} from '../../../shared/types/filter.types'
import {formatDate, formatDateTime} from '../../common/dateTimeUtils'
import {AnalysisListNoData} from '../../components/analysisList/AnalysisListNoData'
import {AnalysisStatusComponent} from '../../components/AnalysisStatus'
import {useGetAnalysisList} from '../../hooks/analysisList/useGetAnalysisList'
import {hoverColor} from '../../styles/themeColours'
import {analysisAreaTypes, analysisCategories, AnalysisListInfo} from '../../types/analysis.types'
import {AnalysisListColumn, AnalysisListProperty} from '../../types/analysisList.types'

import {TableCellNavigate} from './AnalysisListItem'
import {ItemActions} from './ItemActions'

export const AnalysisListTable: React.FC<{
  page: number
  listType: AnalysisListType
  rowsNumber: number
  setCanCreateAnalysis: React.Dispatch<React.SetStateAction<boolean>>
  handleOrderToggle: (col: AnalysisListProperty) => void
  availableColumns: AnalysisListColumn[]
  filters?: ApiFilter[]
  order?: OrderByApiProperty[]
}> = ({
  page,
  rowsNumber,
  setCanCreateAnalysis,
  listType,
  filters,
  order,
  availableColumns,
  handleOrderToggle
}) => {
  const {data, isFetching} = useGetAnalysisList(page, rowsNumber, listType, filters, order)
  const {handlePageChange, handleRowsPerPageChange} = useListQuerySync({
    filters,
    order,
    filtersEnabled: true,
    orderEnabled: true
  })

  useEffect(() => {
    setCanCreateAnalysis(data?.permissions ? data.permissions.canCreateAnalysis : false)
  }, [data, setCanCreateAnalysis])

  // eslint-disable-next-line complexity
  const customColumnRender = (columnName: string, data: AnalysisListInfo) => {
    const analysisRoute = `../graphDetail/${data.id}/graph`
    switch (columnName) {
      case 'StoppageCode':
        return (
          <TableCellNavigate to={analysisRoute}>
            <Box>{data.stoppageCodeText ?? ''}</Box>
            <Box sx={{fontSize: '12px', color: '#54708C', fontWeight: 400}}>
              {data.stoppageCode ?? ''}
            </Box>
          </TableCellNavigate>
        )
      case 'CreateDate':
        return (
          <TableCellNavigate to={analysisRoute}>{formatDate(data.createDate)}</TableCellNavigate>
        )
      case 'Title':
        return (
          <TableCellNavigate to={analysisRoute}>
            <Box sx={{fontWeight: 500, fontSize: '15px'}}>{data.title}</Box>
          </TableCellNavigate>
        )
      case 'LastUpdate':
        return (
          <TableCellNavigate to={analysisRoute}>{formatDate(data.updateDate)}</TableCellNavigate>
        )
      case 'DueDate':
        return <TableCellNavigate to={analysisRoute}>{formatDate(data.dueDate)}</TableCellNavigate>
      case 'Contributors':
        return <TableCellNavigate to={analysisRoute}>{data.contributors}</TableCellNavigate>
      case 'PlantName':
        return (
          <TableCellNavigate
            to={analysisRoute}
          >{`${data.plant} (${data.country})`}</TableCellNavigate>
        )
      case 'Owner':
        return <TableCellNavigate to={analysisRoute}>{data.owner?.name}</TableCellNavigate>
      case 'Status':
        return (
          <TableCellNavigate to={analysisRoute}>
            {data.status ? (
              <AnalysisStatusComponent
                status={data.status}
                hasPendingTasks={data.hasPendingTasks}
              />
            ) : (
              ''
            )}
          </TableCellNavigate>
        )
      case 'stoppageStart':
        return (
          <TableCellNavigate to={analysisRoute}>
            {formatDateTime(data.stoppageStart)}
          </TableCellNavigate>
        )
      case 'stoppageEnd':
        return (
          <TableCellNavigate to={analysisRoute}>
            {formatDateTime(data.stoppageEnd)}
          </TableCellNavigate>
        )
      case 'category':
        return (
          <TableCellNavigate to={analysisRoute}>
            {data.category ? analysisCategories.find((c) => c.id === data.category)?.label : ''}
          </TableCellNavigate>
        )
      case 'areaTypeCode':
        return (
          <TableCellNavigate to={analysisRoute}>
            {data.areaTypeCode
              ? analysisAreaTypes.find((type) => type.id === data.areaTypeCode)?.label
              : ''}
          </TableCellNavigate>
        )
      case 'actions':
        return <ItemActions analysis={data} />
      default:
        return <TableCellNavigate to={analysisRoute}>{data[columnName] ?? ''}</TableCellNavigate>
    }
  }

  return (
    <Box>
      {isFetching && <Loader />}
      {!isFetching && data && data.data.length > 0 && (
        <DataTable<AnalysisListProperty, AnalysisListInfo>
          tableId={`analysis-list-${listType}`}
          paginationSettings={{
            page,
            rowsNumber,
            handlePageChange,
            handleRowsPerPageChange
          }}
          tableHeaderSettings={{
            hideHeader: true
          }}
          orderSettings={{
            handleOrderToggle,
            order
          }}
          availableColumns={availableColumns}
          hoverColor={hoverColor}
          isLoadingData={isFetching}
          data={data}
          hideWrapper
          customColumnRender={customColumnRender}
          showColumnSelection
        />
      )}
      {!isFetching && !data?.data?.length && (
        <AnalysisListNoData
          listType={listType}
          filtersActive={Array.isArray(filters) && filters.length > 0}
        />
      )}
    </Box>
  )
}
