import {Autocomplete, AutocompleteProps, Box, TextField, TextFieldProps} from '@mui/material'
import {DatePicker, DateTimePicker} from '@mui/x-date-pickers'
import {Moment} from 'moment'
import React from 'react'
import type {Control, FieldValues, Path} from 'react-hook-form'
import {Controller} from 'react-hook-form'

interface ControlledAutocompleteProps<
  TOption extends {id: string; label: string},
  TField extends FieldValues
> {
  control: Control<TField>
  formDataName: Path<TField>
  options: TOption[]
  label: string
  rules?: any
  disableClearable?: boolean
  helperText?: string
  AutocompleteProps?: Omit<
    AutocompleteProps<TOption, boolean, boolean, false>,
    'renderInput' | 'options' | 'disableClearable' | 'label'
  >
}

export const ControlledAutocomplete = <
  TOption extends {id: string; label: string},
  TField extends FieldValues
>({
  control,
  formDataName,
  options,
  label,
  rules,
  disableClearable = true,
  helperText,
  AutocompleteProps
}: ControlledAutocompleteProps<TOption, TField>) => (
  <Controller
    name={formDataName}
    control={control}
    rules={rules}
    render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
      <Autocomplete
        fullWidth
        value={value}
        disableClearable={disableClearable}
        onChange={(e, data) => onChange(data)}
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        componentsProps={{
          paper: {
            elevation: 12,
            sx(theme) {
              return {
                background: theme.palette.grey[50]
              }
            }
          }
        }}
        sx={{
          '& .MuiInputBase-root': {
            height: '52px',
            borderRadius: '4px',
            background: '#fbfbfc',
            boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
            mb: helperText && 1,
            '&.Mui-focused': {
              background: '#fbfbfc'
            },
            '&.Mui-disabled': {
              background: '#fbfbfc',
              ':hover': {
                background: '#fbfbfc'
              }
            }
          }
        }}
        renderInput={(params) => (
          <BaseTextField
            {...params}
            label={label}
            inputRef={ref}
            helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
            error={invalid}
          />
        )}
        {...AutocompleteProps}
      />
    )}
  />
)

interface ControlledTextFieldProps<TField extends FieldValues> {
  control: Control<TField>
  formDataName: Path<TField>
  rules?: any
  label: string
  children?: React.ReactNode
  TextFieldProps?: TextFieldProps
  disabled?: boolean
}

export const ControlledTextField = <TField extends FieldValues>({
  control,
  formDataName,
  rules,
  label,
  children,
  TextFieldProps,
  disabled
}: ControlledTextFieldProps<TField>) => (
  <Controller
    control={control}
    name={formDataName}
    rules={rules}
    render={({field: {ref, value, onChange}, fieldState: {invalid, error}}) => (
      <BaseTextField
        label={label}
        value={value}
        inputRef={ref}
        onChange={onChange}
        helperText={invalid && (error?.message ? error.message : 'Invalid value')}
        error={invalid}
        disabled={disabled}
        {...TextFieldProps}
      >
        {children}
      </BaseTextField>
    )}
  />
)

interface ControlledDateTimePickerProps<TField extends FieldValues> {
  control: Control<TField>
  formDataName: Path<TField>
  rules?: any
  label: string
  disabled?: boolean
  TextFieldProps?: TextFieldProps
}

export const ControlledDateTimePicker = <TField extends FieldValues>({
  control,
  formDataName,
  rules,
  label,
  disabled,
  TextFieldProps
}: ControlledDateTimePickerProps<TField>) => (
  <Box
    sx={{
      '& .MuiInputBase-root': {
        height: '52px',
        borderRadius: '4px',
        background: '#fbfbfc',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        },
        '&.Mui-disabled': {
          background: '#fbfbfc',
          ':hover': {
            background: '#fbfbfc'
          }
        }
      }
    }}
  >
    <Controller
      control={control}
      name={formDataName}
      rules={rules}
      render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
        <DateTimePicker
          inputFormat="DD/MM/YYYY HH:mm"
          // localeText -- UPDATE @mui/x-date-pickers FIRST
          label={label}
          ampm={false}
          value={value}
          disabled={disabled}
          onChange={(newValue) => onChange(newValue)}
          renderInput={(params) => (
            <TextField
              inputRef={ref}
              variant="filled"
              size="small"
              fullWidth
              label={label}
              error={invalid}
              helperText={invalid && (error?.message ? error.message : 'Invalid value')}
              {...params}
              {...TextFieldProps}
            />
          )}
        />
      )}
    />
  </Box>
)

interface ControlledDatePickerProps<TField extends FieldValues> {
  control: Control<TField>
  formDataName: Path<TField>
  rules?: any
  label: string
  disabled?: boolean
  TextFieldProps?: TextFieldProps
  shouldDisableDate?: (date: Moment) => boolean
}

export const ControlledDatePicker = <TField extends FieldValues>({
  control,
  formDataName,
  rules,
  label,
  disabled,
  shouldDisableDate,
  TextFieldProps
}: ControlledDatePickerProps<TField>) => (
  <Box
    sx={{
      '& .MuiInputBase-root': {
        height: '52px',
        borderRadius: '4px',
        background: '#fbfbfc',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        },
        '&.Mui-disabled': {
          background: '#fbfbfc',
          ':hover': {
            background: '#fbfbfc'
          }
        }
      }
    }}
  >
    <Controller
      control={control}
      name={formDataName}
      rules={rules}
      render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
        <DatePicker
          inputFormat="DD/MM/YYYY"
          // localeText -- UPDATE @mui/x-date-pickers FIRST
          label={label}
          value={value}
          disabled={disabled}
          onChange={(newValue) => onChange(newValue)}
          shouldDisableDate={shouldDisableDate}
          renderInput={(params) => (
            <TextField
              inputRef={ref}
              variant="filled"
              size="small"
              fullWidth
              label={label}
              error={invalid}
              helperText={invalid && (error?.message ? error.message : 'Invalid value')}
              {...params}
              {...TextFieldProps}
            />
          )}
        />
      )}
    />
  </Box>
)

export const BaseTextField = (props: TextFieldProps) => (
  <TextField
    variant="filled"
    size="small"
    fullWidth
    sx={{'&.MuiInputLabel-root': {borderRadius: '4px !important'}}}
    SelectProps={{
      sx: {
        display: 'flex',
        background: '#fbfbfc',
        borderRadius: '4px',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        }
      }
    }}
    InputProps={{
      sx: {
        display: 'flex',
        background: '#fbfbfc',
        borderRadius: '4px',
        boxShadow: 'inset 0px 4px 4px rgba(31, 73, 94, 0.06)',
        '&.Mui-focused': {
          background: '#fbfbfc'
        },
        '&.Mui-disabled': {
          background: '#fbfbfc',
          ':hover': {
            background: '#fbfbfc'
          }
        }
      }
    }}
    {...props}
  />
)
