import {TaskListColumn} from '../types/taskList.types'

const title: TaskListColumn = {
  id: 'title',
  translationKey: 'tables.tasksList.title',
  position: 0,
  sortable: true
}

const assignee: TaskListColumn = {
  id: 'assigneeName',
  translationKey: 'tables.tasksList.assignee',
  position: 1,
  sortable: true
}

const cause: TaskListColumn = {
  id: 'nodeTitle',
  translationKey: 'tables.tasksList.nodeTitle',
  position: 2,
  sortable: true
}

const analysis: TaskListColumn = {
  id: 'analysisTitle',
  translationKey: 'tables.tasksList.analysisTitle',
  position: 3,
  sortable: false
}

const dueDate: TaskListColumn = {
  id: 'dueDate',
  translationKey: 'tables.tasksList.dueDate',
  position: 4,
  sortable: true
}

const resolutionDate: TaskListColumn = {
  id: 'resolutionDate',
  translationKey: 'tables.tasksList.resolutionDate',
  position: 5,
  sortable: true
}

const status: TaskListColumn = {
  id: 'status',
  translationKey: 'tables.tasksList.status',
  position: 6,
  sortable: true
}

const plant: TaskListColumn = {
  id: 'plantName',
  translationKey: 'tables.tasksList.PlantName',
  position: 6,
  sortable: true
}

const baseColumns: Array<TaskListColumn> = [
  title,
  assignee,
  cause,
  analysis,
  dueDate,
  resolutionDate,
  status,
  plant
]

export const getAvailableColumns = (blacklist: string[] | undefined = []) => {
  return structuredClone(baseColumns)
    .filter((col) => !blacklist.includes(col.id))
    .sort((a, b) => a.position - b.position)
}
