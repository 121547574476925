import {Permission} from '@hconnect/common/hproduce/types'
import {useTranslation} from '@hconnect/uikit'
import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useSearchParams} from 'react-router-dom'

import {FilterWrapper} from '../../shared/containers/filters/FilterWrapper'
import {useListFilters} from '../../shared/hooks/listFiltersSort/useListFilters'
import {useListOrderBy} from '../../shared/hooks/listFiltersSort/useListOrderBy'
import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {TaskStatus} from '../components/TaskStatus'
import {getAvailableTasksFilters} from '../config/taskList.filter'
import {getAvailableColumns} from '../config/taskList.orderBy'
import {TasksListTable} from '../container/tasksList/TasksList'
import {useWidth} from '../hooks/useWidth'
import {TaskListProperty} from '../types/taskList.types'

const customColumnRender = (columnName, data, widthKey) => {
  switch (columnName) {
    case 'assigneeName':
      return data['assignee'] && data['assignee'].name ? data['assignee'].name : ''
    case 'dueDate':
    case 'resolutionDate':
      return data[columnName] ? moment(data[columnName]).format('L') : data[columnName]
    case 'plantName':
      return `${data.analysisPlant} (${data.analysisCountry})`
    case 'status':
      return data[columnName] ? (
        <TaskStatus status={data[columnName]} onlyIcon={widthKey === 'xs'} />
      ) : (
        ''
      )
    default:
      return data[columnName] ?? ''
  }
}

const parsePlantCountry = (plantId: string, permissions: Permission[]) => {
  let plantCountry: string | undefined
  for (const perm of permissions) {
    try {
      const parsedScope = JSON.parse(perm.dataScope)
      if (parsedScope.plantId === plantId) {
        plantCountry = parsedScope.countryId
        return plantCountry
      }
    } catch (e) {
      console.error('Parsing plantCountry failed', e)
    }
  }
  return plantCountry
}

const filterBlacklistColumns = (columns: TaskListProperty[], blacklist: TaskListProperty[]) =>
  columns.filter((column) => !blacklist.includes(column))

export const TasksList: React.FC = () => {
  const {t} = useTranslation()
  const {permissions} = useGlobalContext().state
  const [searchParams] = useSearchParams()
  const plantId = useUrlParam('plantId')

  const plantCountry = parsePlantCountry(plantId, permissions ?? [])
  const page = parseInt(searchParams.get('page') || '0')
  const rowsNumber = parseInt(searchParams.get('rows') || '10')
  const width = useWidth()

  const blacklistColumns: TaskListProperty[] =
    plantCountry?.toUpperCase() === 'DE' ? ['assigneeName'] : []

  const availableFilters = getAvailableTasksFilters(blacklistColumns)
  const availableColumns = getAvailableColumns(blacklistColumns)

  const {filterState, apiFilters, dispatch} = useListFilters(
    availableFilters,
    searchParams.get('filters')
  )

  const {listOrder, handleOrderToggle} = useListOrderBy(
    availableColumns.map((column) => column.id),
    searchParams.get('orderBy')
  )

  const taskListColumns: {[k: string]: TaskListProperty[]} = {
    xs: filterBlacklistColumns(['title', 'status'], blacklistColumns),
    sm: filterBlacklistColumns(['title', 'status'], blacklistColumns),
    md: filterBlacklistColumns(['title', 'assigneeName', 'dueDate', 'status'], blacklistColumns),
    lg: filterBlacklistColumns(
      ['title', 'assigneeName', 'nodeTitle', 'analysisTitle', 'dueDate', 'status', 'plantName'],
      blacklistColumns
    ),
    xl: filterBlacklistColumns(
      ['title', 'assigneeName', 'nodeTitle', 'analysisTitle', 'dueDate', 'status', 'plantName'],
      blacklistColumns
    )
  }

  return (
    <PageContainer>
      <Box display="flex" mb={3} flexWrap="wrap" gap={2} justifyContent="space-between">
        <PageTitle mb={0}>{t('tasksList.pageTitle')}</PageTitle>
        <FilterWrapper filters={filterState} dispatch={dispatch} />
      </Box>
      <Box>
        <TasksListTable
          page={page}
          filters={apiFilters}
          rowsNumber={rowsNumber}
          widthKey={width}
          columns={taskListColumns}
          customColumnRender={customColumnRender}
          order={listOrder}
          handleOrderToggle={handleOrderToggle}
          availableColumns={availableColumns}
        />
      </Box>
    </PageContainer>
  )
}
