import {AnalysisListType} from '@hconnect/common/types'
import {cloneDeep} from 'lodash'

import {AnalysisListColumn} from '../types/analysisList.types'

const incidentTitle: AnalysisListColumn = {
  id: 'Title',
  translationKey: 'tables.analysisList.incidentTitle',
  position: 0,
  sortable: true,
  noColumnSelection: true
}

const stoppage: AnalysisListColumn = {
  id: 'StoppageCode',
  translationKey: 'tables.analysisList.stoppage',
  position: 1,
  sortable: true
}

const stoppageStart: AnalysisListColumn = {
  id: 'stoppageStart',
  translationKey: 'tables.analysisList.stoppageStart',
  position: 2,
  sortable: true
}

const stoppageEnd: AnalysisListColumn = {
  id: 'stoppageEnd',
  translationKey: 'tables.analysisList.stoppageEnd',
  position: 3,
  sortable: true
}
const category: AnalysisListColumn = {
  id: 'category',
  translationKey: 'tables.analysisList.category',
  position: 4,
  sortable: false
}
const areaTypeCode: AnalysisListColumn = {
  id: 'areaTypeCode',
  translationKey: 'tables.analysisList.areaTypeCode',
  position: 5,
  sortable: false
}

const createDate: AnalysisListColumn = {
  id: 'CreateDate',
  translationKey: 'tables.analysisList.createDate',
  position: 6,
  sortable: true
}

const lastUpdated: AnalysisListColumn = {
  id: 'LastUpdate',
  translationKey: 'tables.analysisList.lastUpdated',
  position: 7,
  sortable: true
}

const dueDate: AnalysisListColumn = {
  id: 'DueDate',
  translationKey: 'tables.tasksList.dueDate',
  position: 8,
  sortable: true
}

const contributors: AnalysisListColumn = {
  id: 'Contributors', // TODO: This is not a property of AnalysisListType
  translationKey: 'tables.analysisList.contributors',
  position: 9,
  sortable: false
}

const owner: AnalysisListColumn = {
  id: 'Owner',
  translationKey: 'analysis.owner',
  position: 10,
  sortable: true
}

const plant: AnalysisListColumn = {
  id: 'PlantName',
  translationKey: 'tables.analysisList.plant',
  position: 11,
  sortable: true
}

const status: AnalysisListColumn = {
  id: 'Status',
  translationKey: 'tables.analysisList.status',
  position: 12,
  sortable: true
}

const actions: AnalysisListColumn = {
  id: 'actions',
  translationKey: '',
  position: 13,
  sortable: false,
  noColumnSelection: true
}

const baseColumns: Array<AnalysisListColumn> = [
  incidentTitle,
  stoppage,
  lastUpdated,
  createDate,
  stoppageStart,
  stoppageEnd,
  category,
  areaTypeCode,
  actions
]

export const getAvailableColumns = (
  listType: AnalysisListType,
  canReassign: boolean
): Array<AnalysisListColumn> => {
  const availableColumns = cloneDeep(baseColumns)

  switch (listType) {
    case AnalysisListType.Mine:
      availableColumns.push(plant)
      availableColumns.push(status)
      availableColumns.push(contributors)
      availableColumns.push(dueDate)
      if (canReassign) availableColumns.push(owner)
      break
    case AnalysisListType.Contributing:
      availableColumns.push(plant)
      availableColumns.push(status)
      availableColumns.push(owner)
      availableColumns.push(dueDate)
      break
    case AnalysisListType.MyPlant:
      availableColumns.push(status)
      availableColumns.push(contributors)
      availableColumns.push(dueDate)
      availableColumns.push(owner)
      break
    case AnalysisListType.Global:
      availableColumns.push(plant)
      availableColumns.push(owner)
      break
  }

  return availableColumns.sort((a, b) => a.position - b.position)
}
