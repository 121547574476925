import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {File} from '../../types/nodeDetail.types'

export type ResponseType = boolean

export const QueryKey = 'node-tasks'

type TaskEdit = {
  title: string
  description: string
  dueDate: string | undefined | null
  status: string | undefined
  department?: string
  assignee?: string
  deleteAssignee?: boolean
  deleteResolutionDate?: boolean
  deleteResolutionDescription?: boolean
  resolutionDate?: string | undefined | null
  resolutionDescription?: string | undefined | null
  newAttachments: Array<File>
}

export const useEditTask = (analysisId: string | undefined, nodeId: number, taskId?: string) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (nodeData: TaskEdit) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/tasks/${taskId}`
      const formData = new FormData()
      if (nodeData.title) formData.append('title', nodeData.title)
      if (nodeData.description) formData.append('description', nodeData.description)
      if (nodeData.dueDate) formData.append('dueDate', nodeData.dueDate.toString())
      if (nodeData.status) formData.append('status', nodeData.status.toString())
      if (nodeData.assignee) formData.append('assignee', nodeData.assignee.toString())
      if (nodeData.deleteAssignee)
        formData.append('deleteAssignee', nodeData.deleteAssignee.toString())
      if (nodeData.deleteResolutionDate)
        formData.append('deleteResolutionDate', nodeData.deleteResolutionDate.toString())
      if (nodeData.deleteResolutionDescription)
        formData.append(
          'deleteResolutionDescription',
          nodeData.deleteResolutionDescription.toString()
        )
      if (nodeData.resolutionDate)
        formData.append('resolutionDate', nodeData.resolutionDate.toString())
      if (nodeData.resolutionDescription)
        formData.append('resolutionDescription', nodeData.resolutionDescription.toString())
      if (nodeData.newAttachments)
        // @ts-expect-error file format
        nodeData.newAttachments.map((file) => formData.append('NewAttachments', file))
      return (
        await axiosInstance.patch<ResponseType>(path, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['task-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-tasks']})
      await queryClient.invalidateQueries({queryKey: ['tasks-master-list']})
      await queryClient.invalidateQueries({queryKey: ['analysis-tasks']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: [QueryKey]})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
