import ErrorIcon from '@mui/icons-material/Error'
import {Box, Skeleton} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {useFileData} from '../../hooks/attachments/useGetFile'
import {Attachment} from '../../types/nodeDetail.types'

export const NodeAttachment: React.FC<{
  attachment: Attachment
  setActiveImageId: Function
  imageHeight?: string
}> = ({attachment, setActiveImageId, imageHeight}) => {
  const [src, setSrc] = useState<string | null>(null)
  const imageUrl = attachment.previewUrl || attachment.url

  const {data, isFetching, error} = useFileData(attachment.id, imageUrl, attachment.contentType)

  useEffect(() => {
    const mimeImage = attachment.contentType.indexOf('image')

    if (data && mimeImage >= 0) {
      const blob = new Blob([data])
      const url = URL.createObjectURL(blob)
      setSrc(url)
    } else if (error) {
      console.error(
        `Downloading the attachment ${JSON.stringify(attachment)} failed with error ${
          error?.message ?? error
        }.`
      )
    }
  }, [data, isFetching, error])

  return (
    <Box
      m={1}
      sx={{
        height: imageHeight ? imageHeight : '100px',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {isFetching ? (
        <Skeleton height={100} width={100} variant="rectangular" sx={{flex: '0 0 auto'}} />
      ) : src ? (
        <Box onClick={() => setActiveImageId(attachment.id)}>
          <Box
            component="img"
            src={src}
            alt={attachment.fileName}
            sx={{maxHeight: imageHeight ? imageHeight : '100px'}}
          />
        </Box>
      ) : (
        <Box
          onClick={error ? () => setActiveImageId(attachment.id) : void 0}
          border={1.5}
          sx={(theme) => ({
            height: imageHeight ? imageHeight : '100px',
            borderColor: theme.palette.grey['300'],
            width: imageHeight ? imageHeight : '125px',
            borderRadius: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            float: 'left',
            position: 'relative'
          })}
        >
          {error && (
            <Box
              title={error.message}
              sx={(theme) => ({
                color: theme.palette.error.light,
                position: 'absolute',
                top: '15%',
                cursor: 'help'
              })}
            >
              <ErrorIcon />
            </Box>
          )}
          <Box
            onClick={() => setActiveImageId(attachment.id)}
            sx={(theme) => ({
              width: '100px',
              fontSize: 12,
              lineHeight: '16px',
              color: theme.palette.text.primary,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              margin: '0 auto'
            })}
          >
            {attachment.fileName}
          </Box>
        </Box>
      )}
    </Box>
  )
}
