import {useMemo} from 'react'

import {useGetSignals} from '../useGetSignals'

export const useGetFilteredSignals = (kilnId?: string) => {
  const {
    data: sensorData,
    isLoading,
    isError
  } = useGetSignals({
    options: {
      enabled: kilnId !== undefined
    },
    kilnId
  })

  const filteredSignalData = useMemo(
    () => (sensorData ? sensorData.map((sensor) => ({...sensor, lastReading: undefined})) : []),
    [sensorData]
  )

  return {
    filteredSignalData,
    isLoading,
    isError
  }
}
