import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration403.svg'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import React, {useState, useContext, createContext} from 'react'
import {useTranslation} from 'react-i18next'

import {RequestAccessButton} from '../components/RequestAccessButton'
import {UserInfo} from '../types/user.types'

interface RcfaDialogsProviderState {
  closeDialog: () => void
  openDialog: React.Dispatch<React.SetStateAction<DialogState | null>>
}
const RcfaDialogsContext = createContext<RcfaDialogsProviderState | undefined>(undefined)

export const useRcfaDialogs = (): RcfaDialogsProviderState => {
  const context = useContext(RcfaDialogsContext)
  if (!context)
    throw new Error(
      'Cannot use Rcfa Popups without having Rcfa Popups provider upper in the component tree'
    )
  return context
}

/**
 * Provides RCFA specific popups which can be triggered from anywhere within the app
 */
export const RcfaDialogsProvider = ({children}: {children: React.ReactNode}) => {
  const [dialogState, setDialogState] = useState<DialogState | null>(null)

  return (
    <RcfaDialogsContext.Provider
      value={{openDialog: setDialogState, closeDialog: () => setDialogState(null)}}
    >
      {children}
      <RequestAccessDialog
        open={dialogState?.dialogType === 'requestAccess'}
        analysisId={dialogState?.dialogProps?.analysisId}
        analysisOwner={dialogState?.dialogProps?.analysisOwner}
        closeDialog={() => setDialogState(null)}
      />
      {/* ADDITIONAL RCFA WIDE DIALOGS CAN BE ADDED HERE*/}
    </RcfaDialogsContext.Provider>
  )
}

type DialogType = 'requestAccess' | 'newDialogType'

export interface DialogState {
  dialogType: DialogType
  dialogProps: any // TODO: fix this, should be "meta" type that can contain props for any dialog
}

interface RcfaDialogProps {
  open: boolean
  closeDialog: () => void
}

interface RequestAccessDialogProps extends RcfaDialogProps {
  analysisId?: string
  analysisOwner?: UserInfo
}

const RequestAccessDialog = ({
  open,
  analysisId,
  analysisOwner,
  closeDialog
}: RequestAccessDialogProps) => {
  const {t} = useTranslation()
  return (
    <Dialog maxWidth={false} open={open} PaperProps={{sx: {width: 400}}} onClose={closeDialog}>
      <DialogTitle variant="h3" paddingBottom={0}>
        {t('privateAnalysis.title')}
      </DialogTitle>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
        <Box maxWidth="240px" display="flex" justifyContent="center" alignSelf="center">
          <Illustration style={{width: '100%', height: 'auto'}} viewBox="0 0 401 316" />
        </Box>
        <span />
        <DialogContentText whiteSpace={'pre-line'}>
          {t('privateAnalysis.text').replace('.', '.\n\n') + ':'}
        </DialogContentText>
        {!!analysisOwner && (
          <div>
            <DialogContentText fontWeight={500}>{analysisOwner.name}</DialogContentText>
            <DialogContentText variant="caption">{analysisOwner.email}</DialogContentText>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" startIcon={<CloseIcon />} sx={{height: 48}} onClick={closeDialog}>
          {t('buttons.cancel')}
        </Button>
        {analysisId && <RequestAccessButton analysisId={analysisId} successCb={closeDialog} />}
      </DialogActions>
    </Dialog>
  )
}
