import {HttpError} from '@hconnect/common/types'
import {useQuery, keepPreviousData, useQueries, UseQueryResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {QueryOptions2Type} from '../../../shared/types/common.types'
import {SignalsListType} from '../types/sensor.types'

export const QueryKey = 'signals'

type UseGetSignalsParams = {
  entity: 'plant' | 'area' | 'department' | 'equipment'
  entityId: string
}

export const useGetUpmSignals = (
  params: UseGetSignalsParams,
  options?: QueryOptions2Type<SignalsListType[], AxiosError<HttpError>>
) => {
  const {entity, entityId} = params
  // for now we use plantId because eg. feed signals are not on the Kiln department in UPM
  const axiosInstance = useAxios()
  return useQuery<SignalsListType[], AxiosError<HttpError>>({
    queryKey: [QueryKey, entity, entityId],
    queryFn: async () => {
      // const path = `/upm/signal/department/${entityId}?pageSize=1000`
      const path = `/upm/signal/${entity}/${entityId}?pageSize=1000`
      return (await axiosInstance.get(path)).data
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 30,
    ...options
  })
}

export const useGetUpmSignalsMultiple = <T extends UseGetSignalsParams>(
  entities: T[],
  options?: QueryOptions2Type<SignalsListType[], AxiosError<HttpError>>
): UseQueryResult<SignalsListType[], AxiosError<HttpError>>[] => {
  // for now we use plantId because eg. feed signals are not on the Kiln department in UPM
  const axiosInstance = useAxios()
  return useQueries({
    queries: entities.map((entity) => {
      return {
        queryKey: [QueryKey, entity.entity, entity.entityId],
        queryFn: async () => {
          const path = `/upm/signal/${entity.entity}/${entity.entityId}?pageSize=1000`
          return (await axiosInstance.get(path)).data
        },
        ...options
      }
    })
  })
}
