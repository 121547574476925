import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'

import {useAxios} from '../../../shared/hooks/useApi'
import {ApiFilter} from '../../../shared/types/filter.types'

export const useStoppageListExport = () => {
  const axiosInstance = useAxios()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  return useMutation({
    mutationFn: async (filters?: ApiFilter[]) => {
      const path = '/rootcausefailureanalysis/stoppages/excel'
      const filterPlantIds =
        filters?.find((filterGroup) => filterGroup.property === 'plant')?.values ?? []

      const {data} = await axiosInstance.post(
        path,
        {pageNumber: 0, plantIds: filterPlantIds},
        {
          responseType: 'arraybuffer'
        }
      )
      return new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    },
    onError: () => {
      enqueueSnackbar('Failed to generate excel export', {
        variant: 'error',
        disableWindowBlurListener: true,
        autoHideDuration: 2500,
        preventDuplicate: true,
        onClick: () => closeSnackbar()
      })
    }
  })
}
