import {RollerParts, StationParts} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {AssetPartSignal, SignalsAssetPartMap} from '@hconnect/common/components/kmsStatus/types'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Box,
  Grid
} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getAssetPartSignals} from './KilnConfig.helpers'
import {LayoutActionType} from './KilnConfig.types'
import {useKilnLayout, useKilnLayoutDispatch} from './KilnLayout.context'
import {SignalAutocomplete} from './SignalAutocomplete'

export type CrankSignalsModalProps = {
  handleClose: () => void
}

export const CrankSignalsModal = ({handleClose}: CrankSignalsModalProps) => {
  const {t} = useTranslation()
  const kilnLayout = useKilnLayout()
  const dispatch = useKilnLayoutDispatch()

  const [crankSignals, setCrankSignals] = useState(kilnLayout.crankSignals)

  const doSubmit = () => {
    dispatch({
      type: LayoutActionType.SET_CRANK_SIGNALS,
      // filter out empty keys
      payload: Object.fromEntries(
        Object.entries(crankSignals).filter(([, value]) => value.length)
      ) as SignalsAssetPartMap
    })
    handleClose()
  }

  const handleCrankSignalsChange = (newSignals: AssetPartSignal[], rollerPartId: string) => {
    setCrankSignals((prevCrankSignals) => ({
      ...prevCrankSignals,
      [rollerPartId]: newSignals
    }))
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle component="div" flexDirection="column" display="flex">
        <Typography variant="h3">{t('kilnMetrics.warp')}</Typography>
      </DialogTitle>
      <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <Alert severity="warning" sx={{width: '100%'}}>
          Only signals associated with the corresponding kiln part are available.
        </Alert>
        {kilnLayout?.stations.map((station) => (
          <Box display="flex" flexDirection="column" key={station.id} gap={1}>
            <Typography variant="h5">{station.name ?? station.id}</Typography>
            <Grid container spacing={2}>
              <CrankSignal
                stationId={station.id}
                supportRoller={StationParts.SUPPORT_ROLLER_TOP}
                crankSignals={crankSignals}
                handleCrankSignalsChange={handleCrankSignalsChange}
                selectedSignals={kilnLayout.selectedSignals}
                label="First Roller"
              />
              <CrankSignal
                stationId={station.id}
                supportRoller={StationParts.SUPPORT_ROLLER_BOTTOM}
                crankSignals={crankSignals}
                handleCrankSignalsChange={handleCrankSignalsChange}
                selectedSignals={kilnLayout.selectedSignals}
                label="Second Roller"
              />
            </Grid>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button startIcon={<Close />} variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          startIcon={<Check />}
          color="primary"
          variant="contained"
          onClick={doSubmit}
          sx={{marginLeft: 1}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CrankSignal = ({
  stationId,
  supportRoller,
  crankSignals,
  handleCrankSignalsChange,
  selectedSignals,
  label
}: {
  stationId: string
  supportRoller: StationParts.SUPPORT_ROLLER_TOP | StationParts.SUPPORT_ROLLER_BOTTOM
  crankSignals: SignalsAssetPartMap
  handleCrankSignalsChange: (newSignals: AssetPartSignal[], rollerPartId: string) => void
  selectedSignals: SignalsAssetPartMap
  label: string
}) => {
  const rollerPartId = `${stationId}_${supportRoller}`

  return (
    <Grid item xs={6}>
      <SignalAutocomplete
        label={label}
        value={crankSignals?.[rollerPartId] || []}
        onChange={(newSignals) => handleCrankSignalsChange(newSignals, rollerPartId)}
        options={getAssetPartSignals(
          [`${rollerPartId}_${RollerParts.SUPPORT_ROLLER_DEFLECTOR}`],
          selectedSignals
        )}
        AutocompleteProps={{
          getOptionDisabled: () => crankSignals?.[rollerPartId]?.length >= 2
        }}
      />
    </Grid>
  )
}
