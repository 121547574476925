import {AnalysisStatus} from '@hconnect/common/types'
import {HeidelbergMaterialsLogoComponent} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as MaintainLogo} from '../../assets/maintainIconCustom.svg'
import {UserInfo} from '../../types/user.types'

interface SummaryPrintHeaderProps {
  owner: UserInfo
  startDate: Date
  updateDate: Date
  status: AnalysisStatus
}

export const SummaryPrintHeader: React.FC<SummaryPrintHeaderProps> = ({
  owner,
  startDate,
  updateDate,
  status
}) => {
  const {t} = useTranslation(undefined, {keyPrefix: 'analysis'})

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '85px',
        borderBottom: '1px solid rgba(0, 39, 77, 0.15)',
        '@media print': {
          display: 'block',
          position: 'fixed',
          top: 0,
          width: '90%'
        }
      }}
    >
      <Box>
        <svg width={0} height={0}>
          <defs>
            <linearGradient
              id="paint0_linear_666:1393"
              x1="6.28285"
              y1="3.99996"
              x2="6.28285"
              y2="23.44"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.75" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
      <Box display="flex" mb={1}>
        <Box display="flex" justifyContent="left">
          <HeidelbergMaterialsLogoComponent height={'24px'} width={'84px'} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap={1} alignItems="center">
          <MaintainLogo width={'32px'} height={'32px'} />
          <Box display="flex" flexDirection="column">
            <Typography variant="h3">Analysis summary</Typography>
            <Typography variant="caption">{`${t('pdfSummary.header.createdBy')} ${
              owner.name
            }`}</Typography>
          </Box>
        </Box>

        <Box display="flex" gap={2}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{borderRight: '1px solid rgba(0, 39, 77, 0.15)', pr: 2}}
          >
            <Typography variant="caption">{t('pdfSummary.header.createdOn')}</Typography>
            <Typography variant="captionBold">{moment(startDate).format('lll')}</Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            {status === AnalysisStatus.Finished ? (
              <Box>
                <Typography variant="caption">{t('pdfSummary.header.finishedOn')}</Typography>
                <Typography variant="captionBold">{moment(updateDate).format('lll')}</Typography>
              </Box>
            ) : (
              <Box>
                <Typography variant="caption" component={'p'}>
                  {t('pdfSummary.header.status')}
                </Typography>
                <Typography
                  variant="captionBold"
                  style={{textTransform: 'uppercase', color: 'red'}}
                >
                  {t(`status.${status}`)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
