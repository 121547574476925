import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {Box, Button, CircularProgress, Stack, Switch, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export type DataTableHeaderProps = {
  showDownload?: boolean
  isDownloading?: boolean
  showDescription?: boolean
  showDescriptions: boolean
  headerName?: string
  tableId: string
  setShowDescriptions: React.Dispatch<React.SetStateAction<boolean>>
  downloadExcel: () => void | Promise<void>
  customButton?: React.ReactNode
}
export const DataTableHeader = ({
  showDownload,
  isDownloading,
  showDescription,
  showDescriptions,
  setShowDescriptions,
  downloadExcel,
  headerName,
  tableId,
  customButton
}: DataTableHeaderProps) => {
  const {t} = useTranslation()

  return (
    <Box display="flex" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center" pl="24px">
        <Typography variant="h3">{t(headerName ?? '')}</Typography>
        {showDownload && (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => downloadExcel()}
            color="primary"
            startIcon={<FileDownloadIcon />}
            variant="text"
            disabled={isDownloading}
          >
            {!isDownloading && t('buttons.export')}
            {isDownloading && <CircularProgress color="secondary" size={20} />}
          </Button>
        )}
      </Stack>
      <>{customButton && customButton}</>
      {showDescription && (
        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
          <Typography variant="body2">{t('tables.tasksList.showDescription')}</Typography>
          <Switch
            data-test-id={`${tableId}-description-button`}
            value={showDescriptions}
            onChange={() => setShowDescriptions((prev) => !prev)}
          />
        </Box>
      )}
    </Box>
  )
}
