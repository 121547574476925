import {roundValue} from '../../../../plantMonitoring/shared/utils/sensor.utils'

export const chartTooltip = {
  borderWidth: 0.5,
  bodySpacing: 5,
  boxPadding: 3,
  cornerRadius: 8,
  position: 'nearest',
  usePointStyle: true,
  padding: 15,
  backgroundColor: '#171f26'
} as const

export const chartLegend = {
  display: true,
  align: 'start' as const,
  labels: {
    usePointStyle: true,
    pointStyle: 'circle',
    font: {size: 16, weight: '400', family: 'Inter, sans-serif'},
    color: '#00274D',
    boxHeight: 8,
    filter: (legendItem) => legendItem.text !== 'modelMin' && legendItem.text !== 'modelMax'
  },
  onHover(e) {
    if (!e?.native?.target) return
    e.native.target.style.cursor = 'pointer'
  },
  onLeave(e) {
    if (!e?.native?.target) return
    e.native.target.style.cursor = 'default'
  }
} as const

export const getTooltipLabel = ({
  label,
  unit,
  value
}: {
  label: string | undefined
  unit: string | undefined
  value: number | undefined | null
}) => {
  const formattedValue =
    value !== null && value !== undefined ? `${roundValue(value)} ${unit}` : '-'
  return label ? `${label}: ${formattedValue}` : formattedValue
}
