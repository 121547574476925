import {Box, Skeleton, Stack, Typography} from '@mui/material'
import moment from 'moment'
import {useState} from 'react'

import {useUrlParam} from '../../../../shared/hooks/useUrlParam'
import {useGetComments} from '../../../hooks/comments/useGetComments'

import {CommentActionsButton} from './CommentActionsButton'
import {EditComment} from './EditComment'

export const NodeComments = ({nodeId, expectedCount}: {nodeId: number; expectedCount?: number}) => {
  const analysisId = useUrlParam('analysisId')
  const {isFetching, data} = useGetComments(nodeId)
  const [selectedCommentId, setSelectedCommentId] = useState<string | undefined>()

  if (isFetching && !!expectedCount) {
    return (
      <Stack spacing={1.5}>
        {Array(expectedCount)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={`comment-${index}`} height={38} variant="rounded" />
          ))}
      </Stack>
    )
  }

  return (
    <>
      {!!data?.data?.length && (
        <Stack spacing={1.5}>
          {data.data.map((comment) => (
            <Box key={`task-${comment.id}`}>
              {selectedCommentId === comment.id ? (
                <EditComment
                  nodeId={nodeId}
                  defaultComment={comment}
                  analysisId={analysisId}
                  setSelectedCommentId={setSelectedCommentId}
                  closeForm={() => setSelectedCommentId(undefined)}
                />
              ) : (
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      sx={{fontWeight: 400, color: '#00374D', fontSize: '14px', lineHeight: '20px'}}
                    >
                      {comment.text}
                    </Typography>
                    <Typography
                      sx={{fontWeight: 400, color: '#628390', fontSize: '12px', lineHeight: '18px'}}
                    >
                      {comment.updatedBy.name} on{' '}
                      {moment(comment.updateDate).format('DD.MM.YYYY hh:mm')}
                    </Typography>
                  </Stack>
                  {(comment.permissions.canDelete || comment.permissions.canEdit) && (
                    <CommentActionsButton
                      comment={comment}
                      analysisId={analysisId}
                      nodeId={nodeId}
                      setSelectedCommentId={setSelectedCommentId}
                    />
                  )}
                </Stack>
              )}
            </Box>
          ))}
        </Stack>
      )}
    </>
  )
}
