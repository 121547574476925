import {PermissionType} from '@hconnect/apiclient'
import {PageContainer, PageTitle} from '@hconnect/uikit/src/lib2'
import {Box, Stack, Typography, ToggleButtonGroup, styled, ToggleButton} from '@mui/material'
import moment from 'moment'
import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'

import {DataTable, DataTableRef} from '../../shared/components/DataTable'
import {FilterWrapper} from '../../shared/containers/filters/FilterWrapper'
import {downloadFile} from '../../shared/helpers/fileHelpers'
import {useListFilters} from '../../shared/hooks/listFiltersSort/useListFilters'
import {useListQuerySync} from '../../shared/hooks/listFiltersSort/useListQuerySync'
import {useGlobalContext} from '../../shared/hooks/useGlobalContext'
import {formatDateTime} from '../common/dateTimeUtils'
import {StoppageDetail} from '../components/stoppage/StoppageDetail'
import {StoppageLinkModal} from '../components/stoppageList/StoppageLinkModal'
import {StoppageRcfaButton} from '../components/stoppageList/StoppageRcfaButton'
import {StoppageTitleButton} from '../components/stoppageList/StoppageTitleButton'
import {getAvailableStoppageFilters} from '../config/stoppageList.filter'
import {getAvailableColumns} from '../config/stoppageList.orderBy'
import {useRcfaDialogs} from '../container/RcfaDialogsProvider'
import {useGetStoppages} from '../hooks/stoppages/useGetStoppages'
import {useStoppageListExport} from '../hooks/stoppages/useStoppageListExport'
import {hasPermission} from '../hooks/usePermission'
import {hoverColor} from '../styles/themeColours'
import {StoppageInfo, StoppageListProperty} from '../types/stoppage.types'

const getStoppageDuration = (duration?: number) => {
  return duration ? moment.duration(duration, 'seconds').humanize() : '-'
}

export const StoppageList = () => {
  const {t} = useTranslation()
  const {openDialog} = useRcfaDialogs()
  const tableRef = useRef<DataTableRef | null>(null)
  const [activeStoppage, setActiveStoppage] = useState<StoppageInfo | undefined>()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageSize = parseInt(searchParams.get('rows') || '10')
  const pageNumber = parseInt(searchParams.get('page') || '0')

  const [selectedStoppage, setSelectedStoppage] = React.useState<StoppageInfo | null>(null)
  const [filterType, setFilterType] = useState('all')
  const permissions = useGlobalContext().state.permissions

  const availableColumns = getAvailableColumns()
  const availableFilters = getAvailableStoppageFilters()
  const {filterState, apiFilters, dispatch} = useListFilters(
    availableFilters,
    searchParams.get('filters')
  )

  const {handlePageChange, handleRowsPerPageChange} = useListQuerySync({
    filters: apiFilters,
    filtersEnabled: true,
    orderEnabled: false
  })

  const {data, isPending: isLoading} = useGetStoppages({pageSize, pageNumber, filters: apiFilters})
  const {mutateAsync: downloadAsync, isPending: isDownloading} = useStoppageListExport()

  const canCreateAnalysis = (plantId?: string) => {
    return permissions
      ? hasPermission(permissions, 'CREATE_ROOT_CAUSE_FAILURE_ANALYSES' as PermissionType, plantId)
      : false
  }

  const downloadExcel = () =>
    downloadFile({
      getData: async () => downloadAsync(apiFilters),
      fileName: `stoppage-list_${new Date().toISOString()}.xlsx`
    })

  const handleStoppageButtonClick = ({
    event,
    stoppage
  }: {
    event?: React.MouseEvent<HTMLLIElement | HTMLButtonElement>
    stoppage: StoppageInfo
  }) => {
    event?.stopPropagation()

    const analysisExists = Boolean(stoppage?.analysisId)
    const analysisIsPrivate = Boolean(stoppage?.isPrivateForUser)
    const hasCreatePermission = canCreateAnalysis(stoppage.plantId)

    if (analysisIsPrivate) {
      openDialog({
        dialogType: 'requestAccess',
        dialogProps: {analysisId: stoppage.analysisId, analysisOwner: stoppage.analysisOwner}
      })
    } else if (analysisExists) {
      navigate(`/plant/${stoppage.plantId}/rcfa/graphDetail/${stoppage.analysisId}/graph`, {
        state: location
      })
    } else if (!analysisExists && hasCreatePermission) {
      navigate(`/plant/${stoppage.plantId}/rcfa/new?type=stoppage`, {
        state: {selectedStoppage: stoppage}
      })
    }
  }

  const customColumnRender = (columnName: string, data: StoppageInfo) => {
    switch (columnName) {
      case 'Title':
        return (
          <StoppageTitleButton
            stoppage={data}
            canCreateAnalysis={canCreateAnalysis(data?.plantId)}
            onClick={handleStoppageButtonClick}
          />
        )
      case 'StoppageCode':
        return (
          <Stack>
            <Typography variant="subtitle1" sx={{textTransform: 'capitalize'}}>
              {data?.stoppageCode?.text ?? '-'}
            </Typography>
            {data?.stoppageCode?.code && (
              <Typography variant="caption">{data.stoppageCode.code}</Typography>
            )}
          </Stack>
        )
      case 'stoppageStart':
        return <Typography variant="body1">{formatDateTime(data?.start)}</Typography>
      case 'stoppageEnd':
        return <Typography variant="body1">{formatDateTime(data?.end)}</Typography>
      case 'duration':
        return (
          <Typography variant="body1" noWrap>
            {getStoppageDuration(data?.duration)}
          </Typography>
        )
      case 'plant':
        return (
          <Typography variant="body1">{`${data?.plantName} (${data?.countryCode})`}</Typography>
        )
      case 'actions':
        return (
          <StoppageRcfaButton
            stoppage={data}
            setActiveStoppage={setActiveStoppage}
            canCreateAnalysis={canCreateAnalysis(data?.plantId)}
            onClick={handleStoppageButtonClick}
            ButtonProps={{id: `stoppage-button-${data.id}`}}
          />
        )
      default:
        return data[columnName] ?? ''
    }
  }

  const filterData = () => {
    switch (filterType) {
      case 'mandatory':
        // eslint-disable-next-line no-case-declarations
        const rows = data?.data ?? []

        return data?.data
          ? {
              ...data,
              data: rows.filter((stoppage) => stoppage.mandatory)
            }
          : data
      default:
        return data
    }
  }

  const headerButton = (
    <Box sx={{float: 'left'}}>
      <ToggleButtonGroup
        exclusive
        value={filterType}
        onChange={(_, type: string) => setFilterType(type || 'all')}
        sx={{
          display: 'flex',
          border: '1px solid #f5f6f7',
          height: '48px',
          backgroundColor: '#f5f6f7'
        }}
      >
        <StyledToggleButton value={'all'} aria-label={t('label.all')} data-test-id={'display-all'}>
          <Typography variant="h5">{t('label.all')}</Typography>
        </StyledToggleButton>
        <StyledToggleButton
          value={'mandatory'}
          aria-label={t('label.mandatory')}
          data-test-id={'display-mandatory'}
        >
          <Typography variant="h5">{t('label.mandatory')}</Typography>
        </StyledToggleButton>
      </ToggleButtonGroup>
    </Box>
  )

  return (
    <PageContainer>
      <Box display="flex" mb={3} flexWrap="wrap" gap={2} justifyContent="space-between">
        <PageTitle mb={0} data-test-id="stoppage-list-title">
          {t('label.stoppageList')}
        </PageTitle>
        <FilterWrapper filters={filterState} dispatch={dispatch} />
      </Box>
      <DataTable<StoppageListProperty, StoppageInfo>
        ref={tableRef}
        tableHeaderSettings={{
          showDescription: true,
          customButton: headerButton,
          download: {
            showDownload: true,
            downloadFunction: downloadExcel,
            isDownloading
          }
        }}
        customColumnRender={customColumnRender}
        tableId={'stoppages-list'}
        availableColumns={availableColumns}
        hoverColor={hoverColor}
        data={filterData()}
        isLoadingData={isLoading}
        setActiveItem={setSelectedStoppage}
        detailComponent={
          selectedStoppage && (
            <StoppageDetail
              stoppage={selectedStoppage}
              closeAction={() => {
                tableRef.current?.clearDetail()
                setSelectedStoppage(null)
              }}
              moveToNextStep={(e) =>
                handleStoppageButtonClick({event: e, stoppage: selectedStoppage})
              }
              showNextStep={
                !selectedStoppage?.analysisId && canCreateAnalysis(selectedStoppage.plantId)
              }
            />
          )
        }
        paginationSettings={{
          page: pageNumber,
          rowsNumber: pageSize,
          handlePageChange,
          handleRowsPerPageChange
        }}
      />
      <StoppageLinkModal
        open={activeStoppage !== undefined}
        handleClose={() => setActiveStoppage(undefined)}
        stoppage={activeStoppage}
      />
    </PageContainer>
  )
}

const StyledToggleButton = styled(ToggleButton)({
  width: 'fit-content',
  borderRadius: '6px !important',
  border: 'transparent !important',
  display: 'flex',
  gap: 6,
  '&.Mui-selected': {
    backgroundColor: '#016AD4 !important',
    color: '#FFFF !important'
  },
  '&.MuiToggleButtonGroup-grouped:not(.Mui-selected):hover': {
    background: 'transparent !important'
  },
  background: 'transparent !important'
})
