import {AnalysisPrivacyStatus, AnalysisType} from '@hconnect/common/types'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'
import {
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Stack,
  Paper,
  Autocomplete,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers'
import {debounce, isString} from 'lodash'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ReactQuill from 'react-quill'

import {useUrlParam} from '../../shared/hooks/useUrlParam'
import {formatDateTime} from '../common/dateTimeUtils'
import {parseAutocompleteOption} from '../common/graphUtils'
import {
  nodeDescriptionValidation,
  nodeTitleValidation,
  shouldNotBeEmptyString,
  shouldNotBeInPast,
  validateAll
} from '../common/validator'
import {AnalysisPrivacyStatusSwitch} from '../components/AnalysisPrivacyStatusSwitch'
import {StoppageSection} from '../components/stoppage/StoppageSection'
import {useCreateAnalysis} from '../hooks/analysis/useCreateAnalysis'
import {
  analysisAreaTypes,
  analysisCategories,
  AnalysisInfo,
  AnalysisToCreate
} from '../types/analysis.types'
import {StoppageInfo} from '../types/stoppage.types'
import {UserInfo} from '../types/user.types'

import {RcfaUserSearch} from './assignees/RcfaUserSearch'
import {SearchStoppageCode} from './searchStoppageCode'
import {AnalysisDescriptionTooltip} from '../components/AnalysisDescriptionTooltip'
export interface CreateAnalysisFormProperties {
  onGoBack: () => void
  onGoNext: () => void
  onAnalysisCreated: (analysis: AnalysisInfo) => void
  selectedStoppage?: StoppageInfo
  analysisType?: AnalysisType
  canReassign: boolean
}

type CreateAnalysisItem = {
  title: string
  description: string
  privacyStatus: AnalysisPrivacyStatus
  stoppageStart: string | null
  stoppageEnd: string | null
  equipment: string | {text: string; id: string}
  owner: UserInfo | null
  category: string | null
  areaType: string | null
  dueDate: moment.Moment
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    ['clean']
  ]
}

const getTextLength = (description: string | undefined) =>
  description?.length ? description.replaceAll(/(<([^>]+)>)/gi, '').length : 0

export const CreateAnalysisForm: React.FC<CreateAnalysisFormProperties> = ({
  onGoBack,
  onGoNext,
  selectedStoppage,
  analysisType,
  onAnalysisCreated,
  canReassign
}) => {
  const {t} = useTranslation()
  const {mutate, isPending: isLoading} = useCreateAnalysis()
  const [item, setItem] = useState<CreateAnalysisItem>({
    title: selectedStoppage?.title ?? '',
    description: selectedStoppage?.description ?? '',
    privacyStatus: AnalysisPrivacyStatus.Public,
    stoppageStart: selectedStoppage?.start ?? null,
    stoppageEnd: selectedStoppage?.end ?? null,
    equipment: selectedStoppage?.equipment ?? '',
    owner: null,
    category: null,
    areaType: null,
    dueDate: moment().add(30, 'days')
  })
  const [customStoppage, setCustomStoppage] = useState<StoppageInfo | null>(null)
  const [validationError, setValidationError] = useState(new Map())
  const [isWithStoppage, setIsWithStoppage] = useState<boolean>(true)
  const plantId = useUrlParam('plantId')

  const validatorConfig = new Map([
    ['description', shouldNotBeEmptyString],
    ['description', nodeDescriptionValidation],
    ['title', nodeTitleValidation],
    ['dueDate', shouldNotBeInPast]
  ])

  const debouncedMutate = useMemo(() => debounce(mutate, 500), [mutate])

  const buildOptionalParams = (createObject): AnalysisToCreate => {
    if (selectedStoppage?.stoppageCode?.code || customStoppage?.code)
      createObject.stoppageCode = selectedStoppage?.stoppageCode?.code ?? customStoppage?.code
    if (selectedStoppage?.id || customStoppage?.id)
      createObject.stoppageId = selectedStoppage?.id ?? customStoppage?.id
    if (item.stoppageStart) createObject.stoppageStart = item.stoppageStart
    if (item.stoppageEnd) createObject.stoppageEnd = item.stoppageEnd
    if (item.owner) createObject.owner = item.owner.id
    if (item.category) createObject.category = item.category
    if (item.areaType) createObject.areaTypeCode = item.areaType
    if (item.equipment)
      createObject.equipmentName =
        typeof item.equipment === 'string' ? item.equipment : item.equipment.text

    return createObject
  }

  const doSubmit = () => {
    const validationError = validateAll(validatorConfig, item)
    setValidationError(validationError)
    if (validationError.size === 0 && !moment(item.dueDate).isBefore(moment().startOf('day'))) {
      const createObject: AnalysisToCreate = {
        title: item.title,
        description: item.description,
        privacyStatus: item.privacyStatus,
        type: analysisType,
        plantId: selectedStoppage?.plantId ?? plantId,
        dueDate: item.dueDate.toISOString()
      }

      debouncedMutate(buildOptionalParams(createObject), {
        onSuccess: (data) => {
          onAnalysisCreated(data)
          onGoNext()
        }
      })
    }
  }

  const getErrorText = (key: string) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const displayStoppageSelect = useMemo(
    () => analysisType === AnalysisType.Custom && isWithStoppage,
    [analysisType, isWithStoppage]
  )

  const handleOnChange = (_: React.MouseEvent<HTMLElement>, nextType: boolean) => {
    if (nextType === undefined) return
    setIsWithStoppage(nextType)
  }

  const setOwner = (assignee) => {
    setItem((item) => ({...item, owner: assignee}))
  }

  return (
    <Paper
      id="create-analysis-form"
      sx={{
        width: 490,
        height: 'fit-content',
        padding: 3
      }}
    >
      <Stack spacing={2.5}>
        <Typography variant={'h3'} sx={{fontSize: 18, flexGrow: 1, overflow: 'hidden', mb: 1}}>
          {t('cards.defineDetails')}
        </Typography>

        <TextField
          data-test-id="node-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t('label.analysisTitle')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem((item) => ({...item, title: event.target.value}))
          }
          defaultValue={selectedStoppage?.title}
          required
        />
        <Autocomplete
          key={'analysis-category'}
          id="analysis-category"
          options={analysisCategories}
          onChange={(_, category) =>
            setItem((item) => ({
              ...item,
              category: parseAutocompleteOption(category)
            }))
          }
          sx={{
            marginBottom: 2,
            maxWidth: 450
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('label.searchAnalysisCategoryLabel')}
              placeholder={t('label.searchAnalysisCategoryPlaceholder')}
            />
          )}
        />
        <Autocomplete
          key={'analysis-areaType'}
          id="analysis-areaType"
          options={analysisAreaTypes}
          onChange={(e, category) =>
            setItem((item) => ({
              ...item,
              areaType: isString(category) ? category : category ? category.id : category
            }))
          }
          sx={{
            marginBottom: 2,
            maxWidth: 450
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('label.searchAnalysisAreaTypeLabel')}
              placeholder={t('label.searchAnalysisAreaTypePlaceholder')}
            />
          )}
        />
        {!selectedStoppage && (
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={isWithStoppage}
            onChange={handleOnChange}
          >
            <ToggleButton
              value={true}
              aria-label={t('label.structure')}
              data-test-id={'with-stoppage'}
              sx={{
                border: 'transparent !important',
                margin: '0 !important',
                width: '100% !important'
              }}
            >
              {t('label.withStoppage')}
            </ToggleButton>
            <ToggleButton
              value={false}
              aria-label={t('label.tasks')}
              data-test-id={'non-stoppage'}
              sx={{
                border: 'transparent !important',
                margin: '0 !important',
                width: '100% !important'
              }}
            >
              {t('label.withoutStoppage')}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {displayStoppageSelect && (
          <>
            <SearchStoppageCode onChange={(stoppage) => setCustomStoppage(stoppage)} />
            <TextField
              id="equipment"
              data-test-id="stoppage-equipment-input"
              label={t('analysis.new.equipment')}
              onChange={(e) => {
                setItem((item) => ({
                  ...item,
                  equipment: e.target.value
                }))
              }}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                id="dateStart"
                data-test-id="stoppage-start-input"
                label={t('analysis.new.stoppageStart')}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setItem((item) => ({
                    ...item,
                    stoppageStart: e.target.value
                      ? moment(e.target.value).toISOString()
                      : e.target.value
                  }))
                }}
                sx={{
                  flexGrow: 1
                }}
              />
              <TextField
                id="dateEnd"
                data-test-id="stoppage-end-input"
                label={t('analysis.new.stoppageEnd')}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setItem((item) => ({
                    ...item,
                    stoppageEnd: e.target.value
                      ? moment(e.target.value).toISOString()
                      : e.target.value
                  }))
                }}
                sx={{
                  flexGrow: 1
                }}
              />
            </Stack>
          </>
        )}
        {selectedStoppage && (
          <Stack spacing={2} sx={{pl: 2}}>
            <StoppageSection
              label={t('analysis.new.equipment')}
              value={`${selectedStoppage?.equipment?.text}`}
              valueProps={{sx: {textTransform: 'none'}}}
              captionProps={{sx: {textTransform: 'capitalize'}}}
            />
            <StoppageSection
              label={t('stoppage.detail.stoppageCode')}
              value={`${selectedStoppage?.stoppageCode?.code} - ${selectedStoppage?.stoppageCode?.text}`}
              valueProps={{sx: {textTransform: 'none'}}}
              captionProps={{sx: {textTransform: 'capitalize'}}}
            />
            <StoppageSection
              label={t('stoppage.detail.time')}
              value={`${selectedStoppage.start ? formatDateTime(selectedStoppage.start) : ''} - ${
                selectedStoppage.end ? formatDateTime(selectedStoppage.end) : ''
              }`}
              valueProps={{sx: {textTransform: 'none'}}}
              captionProps={{sx: {textTransform: 'capitalize'}}}
            />
          </Stack>
        )}
        <DatePicker
          value={item.dueDate}
          label={'Due Date'}
          inputFormat="DD.MM.YYYY"
          views={['day']}
          disablePast
          onChange={(newDueDate) =>
            newDueDate && setItem((item) => ({...item, dueDate: newDueDate}))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={validationError.get('dueDate') && t('task.dueDateIsInThePast')}
              sx={{
                width: '100%',
                marginBottom: validationError.get('dueDate') ? 3 : 2
              }}
            />
          )}
        />
        <Box mb={3} style={{display: 'flex', flexDirection: 'column'}}>
          <AnalysisDescriptionTooltip top={30} left={415} />
          <ReactQuill
            modules={modules}
            theme={'snow'}
            onChange={(next) => setItem({...item, description: next})}
            placeholder={`${t('label.description')} *`}
            formats={['bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
          />
          {validationError.has('description') && (
            <Box ml={1} sx={{float: 'left'}}>
              <Typography sx={(theme) => ({color: theme.palette.error.light, fontSize: 12})}>
                {getErrorText('description')}
              </Typography>
            </Box>
          )}
          <Box sx={(theme) => ({color: theme.palette.grey['300'], alignSelf: 'flex-end'})}>
            {getTextLength(item.description)}/{5000}
          </Box>
        </Box>
        {canReassign && (
          <RcfaUserSearch
            assignee={item.owner ?? null}
            label={t('analysis.changeOwner.owner')}
            onChange={setOwner}
            legacyDesign
          />
        )}
        <AnalysisPrivacyStatusSwitch
          value={item.privacyStatus}
          onChange={(privacyStatus) => setItem((item) => ({...item, privacyStatus}))}
        />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ArrowBack />}
            onClick={onGoBack}
            data-test-id="create-analysis-cancel-button"
          >
            {t('buttons.back')}
          </Button>
          <Button
            color="primary"
            disabled={isLoading}
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={doSubmit}
            data-test-id="create-analysis-save-button"
            id="create-analysis-save-button"
          >
            {isLoading ? (
              <CircularProgress sx={{color: 'white'}} size={28} />
            ) : (
              t('buttons.startAnalysis')
            )}
          </Button>
        </Box>
      </Stack>
    </Paper>
  )
}
