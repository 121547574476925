import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {AlertWithLeftBorder} from '../../../shared/components/Alert'

const ChartError = () => {
  const {t} = useTranslation()

  return (
    <Box mb={2} mt={2}>
      <AlertWithLeftBorder type={'error'} message={t('chart.noData')} />
    </Box>
  )
}

export {ChartError}
