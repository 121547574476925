import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {mergeSxs} from '@hconnect/uikit/src/lib2/utils'
import {SxProps, Theme} from '@mui/material'

export const commonFilterSx: SxProps<Theme> = (theme) => ({
  '& .MuiFilledInput-root': {
    border: 1,
    borderColor: customThemeConstants().palette.mediumInputBorder,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.common.white
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.common.white
    }
  },
  '& .MuiFilledInput-input': {
    color: '#ffffff',
    padding: '20px 12px 10px'
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    '&.Mui-focused': {
      color: '#ffffff',
      opacity: '0.5'
    }
  },
  '& .MuiInputLabel-filled': {
    color: '#ffffff',
    opacity: '0.5'
  }
})

export const autocompleteFilterSx: SxProps<Theme> = mergeSxs(commonFilterSx, {
  '& .MuiSelect-icon': {
    color: '#ffffff'
  },
  '& .MuiAutocomplete-tag': {
    color: '#ffffff'
  }
})
