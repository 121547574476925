import {HttpError} from '@hconnect/common/types'
import {useMutation, useQueryClient, UseMutationOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {GraphLevel} from '../../types/canvasNodes.types'
import {CreateNodeType} from '../../types/nodeDetail.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'create-node'

export const useCreateNode = (
  options?: UseMutationOptions<ResponseType, AxiosError<HttpError>, CreateNodeType>
) => {
  const analysisId = useUrlParam('analysisId')
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation<ResponseType, AxiosError<HttpError>, CreateNodeType>({
    mutationFn: async (nodeData: CreateNodeType) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes`
      const formData = new FormData()
      formData.append('Parent', nodeData.Parent.toString())
      formData.append('Description', nodeData.Description.toString())
      formData.append('Title', nodeData.Title)
      formData.append('CreationMethod', nodeData.CreationMethod)
      if (nodeData.Order) formData.append('Order', nodeData.Order.toString())
      if (nodeData.ChildId) formData.append('ChildId', nodeData.ChildId.toString())
      // @ts-ignore
      nodeData.NewAttachments.map((file) => formData.append('NewAttachments', file))
      return (
        await axiosInstance.post<ResponseType>(path, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      ).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries()
      return data
    },
    ...options
  })
}
