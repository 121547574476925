import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {QueryKey} from '../analysisList/useGetAnalysisList'

export type ResponseType = {
  data: boolean
}

export const useDeleteAnalysis = () => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (analysisId: number) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}`
      return (await axiosInstance.delete(path, {})).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: [QueryKey]})
      queryClient.removeQueries({queryKey: ['stoppages']})
      return {data: true}
    }
  })
}
