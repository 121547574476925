import React, {useContext, createContext} from 'react'

import {AnalysisUserPermissions} from '../types/analysis.types'

interface ICanvasSettingsContext {
  nodeHeight: number
  nodeWidth: number
  spaceX: number
  spaceY: number
  permissions?: AnalysisUserPermissions
}

const defaultSettings: ICanvasSettingsContext = {
  nodeHeight: 105,
  nodeWidth: 300,
  spaceX: 24,
  spaceY: 64
}

export const CanvasSettingsContext = createContext<ICanvasSettingsContext>(defaultSettings)

export const CanvasSettingsProvider: React.FC<{
  permissions: AnalysisUserPermissions
  children?: React.ReactNode
}> = ({children, permissions}) => {
  return (
    <CanvasSettingsContext.Provider value={{...defaultSettings, permissions}}>
      {children}
    </CanvasSettingsContext.Provider>
  )
}

export const useCanvasSettingsContext = () => useContext(CanvasSettingsContext)
