import {Typography, Stack, TypographyProps} from '@mui/material'

type StoppageSectionProps = {
  label: string
  value?: string
  labelBottom?: string
  valueProps?: TypographyProps
  captionProps?: TypographyProps
}

export const StoppageSection = ({
  label,
  value,
  labelBottom,
  valueProps,
  captionProps
}: StoppageSectionProps) => {
  return (
    <Stack>
      <Typography variant="caption" {...captionProps}>
        {label}
      </Typography>
      <Typography variant="subtitle1" sx={{textTransform: 'capitalize'}} {...valueProps}>
        {value ?? '-'}
      </Typography>
      {labelBottom && (
        <Typography variant="caption" mt={0.3}>
          {labelBottom}
        </Typography>
      )}
    </Stack>
  )
}
