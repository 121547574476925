import {useDraggable} from '@dnd-kit/core'

import {DragHandle} from '../../../../shared/components/DragHandle'
import {StyledSensorBox} from '../styled'

export const DraggableSensorWrapper = (props) => {
  const {attributes, listeners, setNodeRef} = useDraggable({
    id: props.id
  })

  return (
    <StyledSensorBox
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      gap={3}
      sx={{opacity: props.isActive ? 0.5 : 1}}
      data-test-id="draggable-sensor-item"
    >
      <DragHandle />
      {props.children}
    </StyledSensorBox>
  )
}
