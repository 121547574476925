import {buttonOnBlueSx} from '@hconnect/uikit/src/lib2'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import {ReactComponent as Illustration} from '@hconnect/uikit/src/lib2/pages/illustration404.svg'
import {ArrowBack} from '@mui/icons-material'
import {Button, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const ErrorDeletedAnalysis = ({onGoBack}: {onGoBack: () => void}) => {
  const {t} = useTranslation()

  return (
    <BaseErrorPage
      caption={t('deletedAnalysis.title')}
      description={t('deletedAnalysis.text')}
      illustration={<Illustration />}
    >
      <Stack direction="row" spacing={2}>
        <Button onClick={() => onGoBack()} sx={buttonOnBlueSx} startIcon={<ArrowBack />}>
          {t('buttons.back')}
        </Button>
      </Stack>
    </BaseErrorPage>
  )
}
