import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import ModalCircularProgress from '../../components/ModalCircularProgress'
import {useGetAnalysisDetail} from '../../hooks/analysis/useGetAnalysisDetail'
import {useNodeDetail} from '../../hooks/node/useNodeDetail'

import {EditNode} from './EditNode'
import {EditRootNode} from './EditRootNode'

export const EditNodeForm = ({
  nodeId,
  closeForm,
  DragHandle
}: {
  nodeId: number
  closeForm: () => void
  DragHandle: JSX.Element
}) => {
  const analysisId = useUrlParam('analysisId')
  const {t} = useTranslation()

  const isMainNode = nodeId === 0
  const {data: nodeDetail, isPending: nodeDetailLoading} = useNodeDetail(nodeId.toString())
  const {data: analysisData, isPending: analysisDataLoading} = useGetAnalysisDetail(analysisId, {
    enabled: isMainNode
  })

  const canRenderRootEdit = isMainNode && nodeDetail && analysisData

  return (
    <Stack
      sx={(theme) => ({
        maxHeight: '55vh',
        overflowY: 'auto',
        background: theme.palette.common.white
      })}
      spacing={3}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        paddingRight={2}
      >
        <Typography
          variant="h2"
          sx={{
            wordWrap: 'break-word',
            display: 'flex',
            gap: 1,
            alignItems: 'flex-start'
          }}
        >
          {DragHandle}
          {t('label.editNode')}
        </Typography>
      </Stack>
      {(nodeDetailLoading || (isMainNode && analysisDataLoading)) && <ModalCircularProgress />}
      {canRenderRootEdit && (
        <EditRootNode node={nodeDetail} analysisData={analysisData} closeForm={closeForm} />
      )}
      {!isMainNode && nodeDetail && <EditNode node={nodeDetail} closeForm={closeForm} />}
    </Stack>
  )
}
