import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'

export type ResponseType = {
  mineCount: number
  contributingCount: number
  globalCount: number
  myPlantCount?: number
}

export const QueryKey = 'analysis-list-counts'

export const useGetAnalysisListCounts = (options?: UseQueryOptions<ResponseType, AxiosError>) => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, plantId],
    queryFn: async () => {
      const path = '/rootcausefailureanalysis/analyses/userstatistics'
      return (await axiosInstance.post<ResponseType>(path, {plantId}, {})).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
