import CheckIcon from '@mui/icons-material/Check'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {likelyColor, primaryColor} from '../styles/themeColours'
import {NewAnalysisStep} from '../types/analysis.types'

const Check = () => (
  <CheckIcon
    sx={{
      fontSize: '18px',
      position: 'absolute',
      top: '11px',
      left: '12px'
    }}
  />
)

const getStepBackground = (show: boolean) => (show ? {backgroundColor: primaryColor} : {})

const getStepFontWeight = (showSecondStep: boolean, showThirdStep: boolean) =>
  !showSecondStep && !showThirdStep ? 'bold' : ''

export interface CreateAnalysisStepsProperties {
  step: NewAnalysisStep
  type: string | null
}

export const CreateAnalysisSteps: React.FC<CreateAnalysisStepsProperties> = ({step, type}) => {
  const {t} = useTranslation()

  const showSecondStep =
    type === 'stoppage'
      ? step === NewAnalysisStep.fillAnalysisForm
      : step === NewAnalysisStep.addContributors

  const showThirdStep = type === 'stoppage' && step === NewAnalysisStep.addContributors

  const firstStepIcon = () =>
    showSecondStep || showThirdStep ? (
      <Check />
    ) : (
      <Typography variant="h4" sx={{position: 'absolute', top: 9, left: 15}}>
        1
      </Typography>
    )

  const secondStepIcon = () =>
    showThirdStep ? (
      <Check />
    ) : (
      <Typography variant="h4" sx={{position: 'absolute', top: 9, left: 13}}>
        2
      </Typography>
    )

  return (
    <Box
      sx={{
        position: 'relative',
        margin: '10px auto',
        flexGrow: 0,
        flexShrink: 0,
        paddingRight: type === 'stoppage' ? '250px !important' : '90px'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 40,
          height: 40,
          borderRadius: 2.5,
          marginBottom: '5px',
          ...(showSecondStep || showThirdStep
            ? {backgroundColor: likelyColor}
            : {
                backgroundColor: primaryColor,
                border: 'none'
              })
        }}
      >
        {firstStepIcon()}
      </Box>
      <Box
        border={1}
        sx={{
          width: 110,
          borderColor: 'rgba(255, 255, 255, 0.2)',
          background: 'rgba(255, 255, 255, 0.2)',
          position: 'absolute',
          top: 20,
          left: 50
        }}
      />

      <Box
        border={showSecondStep ? 0 : 1}
        sx={(theme) => ({
          position: 'absolute',
          borderColor: theme.palette.common.white,
          width: 40,
          height: 40,
          borderRadius: 2.5,
          left: 170,
          top: 0,
          ...getStepBackground(showSecondStep),
          ...(showThirdStep ? {backgroundColor: likelyColor} : {})
        })}
      >
        {secondStepIcon()}
      </Box>
      {type === 'stoppage' && (
        <>
          <Box
            border={1}
            sx={{
              width: 110,
              borderColor: 'rgba(255, 255, 255, 0.2)',
              background: 'rgba(255, 255, 255, 0.2)',
              position: 'absolute',
              top: 20,
              left: 220
            }}
          />
          <Box
            border={showThirdStep ? 0 : 1}
            sx={(theme) => ({
              position: 'absolute',
              borderColor: theme.palette.common.white,
              width: 40,
              height: 40,
              borderRadius: 2.5,
              left: 340,
              top: 0,
              ...getStepBackground(showThirdStep)
            })}
          >
            <Typography variant="h4" sx={{position: 'absolute', top: 9, left: 13}}>
              3
            </Typography>
          </Box>
        </>
      )}
      <Box>
        <Typography
          variant="body1"
          sx={{
            display: 'inline',
            position: 'relative',
            left: -40,
            fontWeight: getStepFontWeight(showSecondStep, showThirdStep)
          }}
        >
          {type === 'stoppage' ? t('label.pickStoppage') : t('label.enterDetails')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: 'inline',
            position: 'absolute',
            left: 135,
            width: '200px',
            fontWeight: showSecondStep ? 'bold' : ''
          }}
        >
          {type === 'stoppage' ? t('label.enterDetails') : t('label.addContributors')}
        </Typography>
        {type === 'stoppage' && (
          <Typography
            variant="body1"
            sx={{
              display: 'inline',
              position: 'absolute',
              left: 320,
              width: '200px',
              fontWeight: showThirdStep ? 'bold' : ''
            }}
          >
            {t('label.addContributors')}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
