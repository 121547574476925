import {DndContext, DragOverlay, pointerWithin, DragEndEvent} from '@dnd-kit/core'
import {SignalData} from '@hconnect/common/components/kmsStatus/types'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined'
import {Box, Button, Typography} from '@mui/material'
import {findIndex} from 'lodash'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {DragHandle} from '../../../../shared/components/DragHandle'
import {useGlobalContext} from '../../../../shared/hooks/useGlobalContext'
import {DraggableSensorWrapper} from '../../components/combineView/DraggableSensorWrapper'
import {DroppableSensorWrapper} from '../../components/combineView/DroppableSensorWrapper'
import {SensorTextBox} from '../../components/SensorTextBox'
import {StyledSensorBox} from '../../components/styled'

interface SensorCombineViewProps {
  onCancel: () => void
  onDone: (sensorGroups: string[][]) => void
  selectedSensors: SignalData[]
  combinedSensors: string[][]
}

export const CombineView = ({
  selectedSensors,
  combinedSensors,
  onCancel,
  onDone
}: SensorCombineViewProps) => {
  const {t} = useTranslation()
  const [activeId, setActiveId] = useState<string | null>(null)
  const [sensorGroups, setSensorGroups] = useState<string[][]>(() =>
    sensorGroupsInit(selectedSensors, combinedSensors)
  )
  const {state} = useGlobalContext()

  useEffect(() => {
    if (state && state.user) {
      trackEvent('kmsCombinedView', {
        userId: state.user.userId,
        country: state.user.countryCode,
        product: 'Maintain',
        subProduct: 'KMS'
      })
    }
  }, [state])

  const activeSensor = useMemo(
    () => selectedSensors.find((sensor) => sensor.localName === activeId),
    [selectedSensors, activeId]
  )

  const activeSensorGroup = useMemo(
    () => findGroupById(sensorGroups, activeId),
    [sensorGroups, activeId]
  )

  useEffect(() => {
    setSensorGroups((groups) => sensorGroupsInit(selectedSensors, groups))
  }, [selectedSensors])

  function handleDragEnd(event: DragEndEvent) {
    const {over, active} = event
    setActiveId(null)
    if (!active.id) return

    const parsedActiveId = typeof active.id === 'string' ? active.id : active.id.toString()

    const currentGroupIndex = findGroupById(sensorGroups, parsedActiveId)

    // dropping to current container
    if (over?.id === currentGroupIndex) return
    if (!over && sensorGroups[currentGroupIndex].length === 1) return

    setSensorGroups((groups) => {
      const newGroups = structuredClone(groups)
      // remove from current group, 0 length group can be created
      // we always keep number of groups same as number of sensors
      newGroups[currentGroupIndex] = newGroups[currentGroupIndex].filter(
        (id) => id !== parsedActiveId
      )

      // dropping outside of any container
      if (!over) {
        const newGroupIndex = findIndex(newGroups, (group) => group.length === 0)

        if (newGroupIndex === -1) {
          newGroups.push([parsedActiveId])
          return newGroups
        }

        newGroups[newGroupIndex] = [parsedActiveId]
        return newGroups
      }
      // add to existing group
      newGroups[over.id].push(parsedActiveId)
      return newGroups
    })
  }

  function handleDragStart(event) {
    const {active} = event
    setActiveId(active.id as string)
  }

  return (
    <Box display="flex" flexDirection="column" color="text.primary" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Combine Sensors</Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="text"
            startIcon={<CloseIcon />}
            onClick={() => onCancel()}
            data-test-id="cancel-btn"
          >
            {t('combineView.cancel')}
          </Button>
          <Button
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={() => onDone(sensorGroups)}
          >
            {t('combineView.done')}
          </Button>
        </Box>
      </Box>
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        collisionDetection={pointerWithin}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          mb={2}
        >
          <PanToolOutlinedIcon />
          <Typography variant="caption" fontStyle="italic">
            {t('combineView.helpText')}
          </Typography>
        </Box>

        <DragOverlay>
          {activeId ? (
            <StyledSensorBox
              gap={3}
              sx={{
                zIndex: 99999,
                cursor: 'grabbing',
                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.12)'
              }}
            >
              <DragHandle />
              <SensorTextBox sensor={activeSensor} />
            </StyledSensorBox>
          ) : null}
        </DragOverlay>

        {sensorGroups.map((group, index) => {
          return (
            <DroppableSensorWrapper
              key={index}
              id={index}
              groupLength={group.length}
              disabled={activeSensorGroup === index}
            >
              {group.map((sensorId) => {
                const sensor = selectedSensors.find((sensor) => sensor.localName === sensorId)
                return (
                  sensor && (
                    <DraggableSensorWrapper
                      key={sensorId}
                      id={sensorId}
                      isActive={sensorId === activeId}
                    >
                      <SensorTextBox sensor={sensor} />
                    </DraggableSensorWrapper>
                  )
                )
              })}
            </DroppableSensorWrapper>
          )
        })}
      </DndContext>
    </Box>
  )
}

const findGroupById = (sensorGroups: string[][], sensorId?: string | null) => {
  if (!sensorId) return -1
  return sensorGroups.findIndex((group) => group.includes(sensorId))
}

const sensorGroupsInit = (selectedSensors: SignalData[], combinedSensors: string[][]) => {
  // filter combined sensors that were deselected
  combinedSensors.forEach((group, index) => {
    combinedSensors[index] = group.filter((sensorId) =>
      selectedSensors.find((sensor) => sensor.localName === sensorId)
    )
  }, combinedSensors)

  // add group for additionaly selected sensors
  selectedSensors.forEach((sensor) => {
    const groupIndex = findGroupById(combinedSensors, sensor.localName)
    if (groupIndex === -1) {
      combinedSensors.push([sensor.localName])
    }
  })

  return combinedSensors.filter((group) => group.length > 0)
}
