import {useDraggable} from '@dnd-kit/core'
import {CSS} from '@dnd-kit/utilities'
import {Box, Portal} from '@mui/material'
import React, {useState} from 'react'

import {DragHandle} from '../../../shared/components/DragHandle'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {CanvasNode} from '../../types/canvasNodes.types'
import {NodeModalActiveView} from '../../types/nodeDetail.types'
import {CreateTask} from '../task/CreateTask'
import {TaskDetail} from '../task/TaskDetail'

import {EditNodeForm} from './EditNodeForm'
import {NodeDetail} from './NodeDetail'

export const NodeDetailModal: React.FC<{
  closeModal: () => void
  setNewNode: React.Dispatch<React.SetStateAction<number | null>>
  x: number
  y: number
  node: CanvasNode
}> = ({closeModal, setNewNode, x, y, node}) => {
  const analysisId = useUrlParam('analysisId')

  const [activeView, setActiveView] = useState<NodeModalActiveView>('default')
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null)

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: `node-detail-${node.id}`
  })

  const style = {
    transform: CSS.Translate.toString(transform) // Apply transform for dragging
  }

  const openTaskDetail = (taskId: string) => {
    setActiveTaskId(taskId)
    setActiveView('viewTask')
  }

  return (
    <Portal>
      <Box
        ref={setNodeRef}
        pl={3}
        pr={1}
        py={4}
        sx={(theme) => ({
          background: theme.palette.common.white,
          width: 'fit-content',
          maxWidth: '550px',
          minWidth: '490px',
          borderRadius: 1,
          maxHeight: '80%',
          color: theme.palette.common.black,
          boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 8px 24px rgba(0, 0, 0, 0.16)'
        })}
        style={{
          ...style,
          position: 'fixed',
          top: y,
          left: x
        }}
      >
        {/* MODAL CONTENT */}
        {activeView === 'default' && (
          <NodeDetail
            canvasNode={node}
            setActiveView={setActiveView}
            openTaskDetail={openTaskDetail}
            DragHandle={<DragHandle attributes={attributes} listeners={listeners} />}
            closeModal={closeModal}
            setNewNode={setNewNode}
          />
        )}
        {activeView === 'edit' && (
          <EditNodeForm
            nodeId={node.id}
            closeForm={() => setActiveView('default')}
            DragHandle={<DragHandle attributes={attributes} listeners={listeners} />}
          />
        )}
        {activeView === 'createTask' && (
          <CreateTask
            nodeId={node.id}
            analysisId={analysisId}
            onClose={() => setActiveView('default')}
            DragHandle={<DragHandle attributes={attributes} listeners={listeners} />}
          />
        )}
        {activeView === 'viewTask' && activeTaskId && (
          <TaskDetail
            closeAction={() => {
              setActiveTaskId(null)
              setActiveView('default')
            }}
            analysisId={analysisId}
            nodeId={node.id}
            taskId={activeTaskId}
          />
        )}
      </Box>
    </Portal>
  )
}
