import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'

import {OrderByApiProperty} from '../../../shared/hooks/listFiltersSort/useListOrderBy'
import {useAxios} from '../../../shared/hooks/useApi'
import {ApiFilter} from '../../../shared/types/filter.types'

export const QueryKey = 'tasks-master-list-export'

type TasksExportVariables = {
  page: number
  filters?: ApiFilter[]
  order?: OrderByApiProperty[]
}

export const useTasksListExport = () => {
  const axiosInstance = useAxios()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  return useMutation({
    mutationFn: async ({page, filters, order}: TasksExportVariables) => {
      const path = '/rootcausefailureanalysis/analyses/tasks/excel'
      const {data} = await axiosInstance.post(
        path,
        {pageNumber: page, filters, orderBy: order},
        {
          responseType: 'arraybuffer'
        }
      )

      return new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    },
    onError: () => {
      enqueueSnackbar('Failed to generate excel export', {
        variant: 'error',
        disableWindowBlurListener: true,
        autoHideDuration: 2500,
        preventDuplicate: true,
        onClick: () => closeSnackbar()
      })
    }
  })
}
