import {ZoomOutMap, ZoomInMap} from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import Remove from '@mui/icons-material/Remove'
import {Box} from '@mui/material'
import React, {useState} from 'react'

import {DEFAULT_ZOOM_VALUE} from '../../types/canvasNodes.types'

type IconButtonProps = {
  onClick: () => void
  active?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

export interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element
  msFullscreenElement?: Element
  webkitFullscreenElement?: Element
  msExitFullscreen?: () => void
  mozCancelFullScreen?: () => void
  webkitExitFullscreen?: () => void
}

export interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void
  mozRequestFullScreen?: () => void
  webkitRequestFullscreen?: () => void
  webkitRequestFullScreen?: () => void
}

const IconButton = ({onClick, active, children, disabled}: IconButtonProps) => (
  <Box
    onClick={() => {
      if (!disabled) onClick()
    }}
    sx={(theme) => ({
      background: active ? theme.palette.primary.main : theme.palette.common.black,
      opacity: '0.7',
      borderRadius: 1,
      width: 44,
      height: 44,
      marginBottom: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    })}
  >
    {children}
  </Box>
)

const CanvasZoomButtons: React.FC<{
  zoom: number
  setZoom: (number) => void
}> = ({zoom, setZoom}) => {
  const [isInFullScreen, setIsInFullScreen] = useState<boolean>(false)

  const isInFullScreenFn = () => {
    const doc = document as DocumentWithFullscreen
    return (
      (doc.fullscreenElement && true) ||
      (doc.webkitFullscreenElement && true) ||
      (doc.mozFullScreenElement && true) ||
      (doc.msFullscreenElement && true)
    )
  }

  const toggleFullScreen = async () => {
    const isFS = isInFullScreenFn()

    const docElm = document.documentElement as DocumentElementWithFullscreen
    const doc = document as DocumentWithFullscreen
    if (!isFS) {
      if (docElm.requestFullscreen) {
        await docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else if (doc.exitFullscreen) {
      await doc.exitFullscreen()
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen()
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen()
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen()
    }
    setIsInFullScreen(!isFS)
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 355,
        left: 14
      }}
    >
      <IconButton disabled={zoom >= 400} onClick={() => setZoom(zoom + 4)}>
        <Add sx={{fontSize: 22, cursor: 'pointer'}} />
      </IconButton>

      <IconButton disabled={zoom <= 0} onClick={() => setZoom(zoom - 4)}>
        <Remove sx={{fontSize: 22, cursor: 'pointer'}} />
      </IconButton>

      <IconButton
        disabled={zoom === DEFAULT_ZOOM_VALUE}
        onClick={() => setZoom(DEFAULT_ZOOM_VALUE)}
      >
        <FullscreenExitIcon sx={{fontSize: 22, cursor: 'pointer'}} />
      </IconButton>
      <IconButton onClick={toggleFullScreen}>
        {isInFullScreen ? <ZoomInMap /> : <ZoomOutMap />}
      </IconButton>
    </Box>
  )
}

export default CanvasZoomButtons
