import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {nodeDescriptionValidation, nodeTitleValidation, validateAll} from '../../common/validator'
import {NodeDescriptionBox} from '../../components/node/NodeDescriptionBox'
import {useCreateTask} from '../../hooks/tasks/useCreateTask'
import {File, TaskCreate} from '../../types/nodeDetail.types'
import {RcfaUserSearch} from '../assignees/RcfaUserSearch'
import {NodeAttachments} from '../node/nodeForm/NodeAttachments'

export const CreateTask: React.FC<{
  nodeId: number
  analysisId?: string
  onClose: () => void
  DragHandle: JSX.Element
}> = ({nodeId, analysisId, onClose, DragHandle}) => {
  const {t} = useTranslation()
  const {mutate, isPending: isLoading} = useCreateTask(analysisId, nodeId)

  const [files, setFiles] = useState<Array<File>>([])
  const [item, setItem] = useState<TaskCreate>({
    description: '',
    title: '',
    assignee: null,
    dueDate: null
  })
  const [validationError, setValidationError] = useState(new Map())

  const validatorConfig = new Map([
    ['description', nodeDescriptionValidation],
    ['title', nodeTitleValidation]
  ])

  const getErrorText = (key: string) => {
    const errorKey = validationError.get(key)
    return errorKey && t(errorKey)
  }

  const doSubmit = () => {
    const validationError = validateAll(validatorConfig, item)
    setValidationError(validationError)
    if (validationError.size === 0) {
      mutate(
        {
          title: item.title,
          description: item.description,
          assignee: item?.assignee?.id ?? '',
          dueDate: item.dueDate,
          newAttachments: files
        },
        {onSuccess: () => onClose()}
      )
    }
  }

  const setAssignee = (assignee) => {
    setItem((item) => ({...item, assignee: assignee}))
  }

  return (
    <Box>
      {isLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          spacing={2.5}
          pr={1}
          sx={(theme) => ({
            maxHeight: '55vh',
            overflowY: 'auto',
            background: theme.palette.common.white
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            paddingRight={2}
          >
            <Typography
              variant="h2"
              sx={{
                wordWrap: 'break-word',
                display: 'flex',
                gap: 1,
                alignItems: 'flex-start'
              }}
            >
              {DragHandle}
              {t('label.createTask')}
            </Typography>
          </Stack>
          <Stack>
            <TextField
              variant="filled"
              data-test-id="node-form-title"
              error={validationError.has('title')}
              fullWidth={true}
              helperText={getErrorText('title')}
              label={t('label.title')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setItem({...item, title: event.target.value})
              }
              required
            />
            <Typography variant="caption" fontSize="14px" alignSelf="flex-end">
              {item.title.length}/{80}
            </Typography>
          </Stack>
          <RcfaUserSearch
            assignee={item.assignee}
            onChange={setAssignee}
            label={t('tables.tasksList.assignee')}
            legacyDesign
            textfieldVariant="filled"
          />
          <TextField
            variant="filled"
            fullWidth={true}
            id="date"
            label={t('tables.tasksList.dueDate')}
            type="date"
            defaultValue={item.dueDate ? moment(item.dueDate) : null}
            InputLabelProps={{
              shrink: true
            }}
            onChange={(e) => {
              setItem({
                ...item,
                dueDate: e.target.value ? moment(e.target.value).toISOString() : e.target.value
              })
            }}
          />
          {moment(item.dueDate).isBefore(moment().startOf('day')) && (
            <FormHelperText>{t('task.dueDateIsInThePast')}</FormHelperText>
          )}
          <Stack>
            <NodeDescriptionBox
              background={true}
              errorCode={validationError.get('description')}
              onChange={(next) => setItem({...item, description: next})}
              required
            />
            <Typography variant="caption" fontSize="14px" alignSelf="flex-end">
              {item.description.length}/{5000}
            </Typography>
          </Stack>
          <Box mb={3}>
            <NodeAttachments files={files} setFiles={setFiles} />
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'right'}}>
            <Button
              startIcon={<Close />}
              variant="outlined"
              color="secondary"
              onClick={() => onClose()}
              data-test-id="node-form-cancel-button"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              startIcon={<Check />}
              color="primary"
              variant="contained"
              onClick={doSubmit}
              data-test-id="node-form-save-button"
              sx={{marginLeft: 1}}
            >
              {t('buttons.save')}
            </Button>
          </Box>
        </Stack>
      )}
    </Box>
  )
}
