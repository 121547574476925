import {Loader} from '@hconnect/uikit/src/lib2'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useDeleteAnalysis} from '../../hooks/analysis/useDeleteAnalysis'
import {useGetAnalysisStatistics} from '../../hooks/analysis/useGetAnalysisStatistics'

export const AnalysisDelete: React.FC<{
  analysisId: number
  closeModal: () => void
  opened: boolean
}> = ({analysisId, closeModal, opened}) => {
  const {isFetching, data: statisticsData} = useGetAnalysisStatistics(analysisId)
  const {t} = useTranslation()
  const {t: ta} = useTranslation(undefined, {keyPrefix: 'analysis.remove'})

  const {data, mutate, isPending: isLoading} = useDeleteAnalysis()
  useEffect(() => {
    if (data !== undefined && !isLoading) {
      closeModal()
    }
  }, [data, isLoading, closeModal])

  const handleClose = (e) => {
    closeModal()
    e.stopPropagation()
  }

  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="md" data-test-id="remove-analysis-modal">
      <DialogTitle>
        <Grid container>{ta('header')}</Grid>
      </DialogTitle>
      {isFetching && <Loader />}
      {statisticsData && (
        <DialogContent>
          <Grid spacing={2}>
            <p>{`${ta('text1')}`}</p>
            <span>{`${ta('text2')}`}</span>
            <ul style={{marginTop: 0}}>
              <li>{`${statisticsData.totalNodes} ${ta('nodesText')}`}</li>
              <li>{`${statisticsData.totalTasks} ${ta('tasksText')}`}</li>
              <li>{`${statisticsData.totalAttachments} ${ta('attachmentsText')}`}</li>
            </ul>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            startIcon={<CloseIcon />}
            className={'outlined'}
            onClick={handleClose}
            data-test-id="node-form-cancel-button"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            variant="contained"
            onClick={() => mutate(analysisId)}
            data-test-id="node-form-cancel-button"
            sx={{marginLeft: 1}}
          >
            {t('buttons.deleteConfirm')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
