import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CheckIcon from '@mui/icons-material/Check'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

export const SignalsDisconnectWarning = ({open, onClose, onConfirm, signals}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle typography="h3">Confirm layout change</DialogTitle>
    <DialogContent>
      <DialogContentText component={Alert} severity="warning">
        {`If you perform this action, the layout will change and the following signals will have to be
        reconnected: ${signals.join(', ')}`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} variant="text" startIcon={<ArrowBackIosIcon />}>
        Back to setup
      </Button>
      <Button onClick={onConfirm} variant="text" startIcon={<CheckIcon />}>
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
)
