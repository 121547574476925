import NearMe from '@mui/icons-material/NearMe'
import PanTool from '@mui/icons-material/PanTool'
import {Box} from '@mui/material'
import React from 'react'

import {CanvasMode} from '../../types/canvasNodes.types'

type IconButtonProps = {
  onClick: () => void
  active: boolean
  children?: React.ReactNode
}

const IconButton = ({onClick, active, children}: IconButtonProps) => (
  <Box
    onClick={onClick}
    sx={(theme) => ({
      background: active ? theme.palette.primary.main : theme.palette.common.black,
      opacity: '0.7',
      borderRadius: 1,
      width: 44,
      height: 44,
      marginBottom: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    })}
  >
    {children}
  </Box>
)

const CanvasModeButtons: React.FC<{canvasMode: CanvasMode; setCanvasMode: Function}> = ({
  canvasMode,
  setCanvasMode
}) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 250,
        left: 14
      }}
    >
      <IconButton
        active={canvasMode === CanvasMode.default}
        onClick={() => setCanvasMode(CanvasMode.default)}
      >
        <NearMe sx={{fontSize: 22, cursor: 'pointer'}} />
      </IconButton>

      <IconButton
        active={canvasMode === CanvasMode.hand}
        onClick={() => setCanvasMode(CanvasMode.hand)}
      >
        <PanTool sx={{fontSize: 22, cursor: 'pointer'}} />
      </IconButton>
    </Box>
  )
}

export default CanvasModeButtons
