import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {Task} from '../../types/nodeDetail.types'

export type ResponseType = {
  canManageActions: boolean
  data: Array<Task>
}

export const QueryKey = 'node-tasks'

export const useNodeTasks = (
  nodeId: string | undefined,
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  const analysisId = useUrlParam('analysisId')

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, analysisId, nodeId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/tasks`
      return (await axiosInstance.get<ResponseType>(path)).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
