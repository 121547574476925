import {DraggableAttributes} from '@dnd-kit/core'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {IconButton} from '@mui/material'

interface DragHandleProps {
  attributes?: DraggableAttributes
  listeners?: any
}

export const DragHandle = ({attributes, listeners}: DragHandleProps) => {
  return (
    <IconButton
      aria-label="drag handle"
      {...attributes}
      {...listeners}
      sx={{
        p: 0,
        '& :hover': {
          cursor: 'grab'
        },
        '& :active': {
          cursor: 'grabbing'
        }
      }}
    >
      <DragIndicatorIcon fontSize="medium" />
    </IconButton>
  )
}
