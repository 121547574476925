import {useQuery} from '@tanstack/react-query'

import {ApiFilter, AutocompleteOption} from '../types/filter.types'

import {useAxios} from './useApi'
import {analysisAreaTypes, analysisCategories} from '../../rcfa/types/analysis.types'

type DistinctValue = {
  id?: string
  value: string
  displayValue?: string
}

type DistinctValuesResponse = {
  currentPage: number
  totalCount: number
  data: DistinctValue[]
}

export const QueryKey = 'distinct-analysis-vals'

type UseDistinctListProps = {
  id: string
  apiUrl: string
  filters?: ApiFilter[]
  apiParams?: object
}

export const useDistinctListValues = ({id, apiUrl, filters, apiParams}: UseDistinctListProps) => {
  const axiosInstance = useAxios()
  return useQuery({
    queryKey: [QueryKey, id, apiUrl, filters, apiParams],
    queryFn: async () => {
      return (
        await axiosInstance.post<DistinctValuesResponse>(
          apiUrl,
          {pageSize: null, pageNumber: 0, property: id, filters: filters, ...apiParams},
          {}
        )
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    retry: false,
    select: (data) => generateOptions(data.data)
  })
}

const generateOptions = (data: DistinctValue[]) => {
  const autocompleteOptions: AutocompleteOption[] = []
  const processedValues = new Set<string>()

  data.forEach((option) => {
    const id = option.value
    const value = option.displayValue ?? option.value

    if (processedValues.has(value)) return
    processedValues.add(value)

    autocompleteOptions.push({
      id: id,
      label: value
    })
  })

  return autocompleteOptions
}

export const useDistinctCategoryListValues = ({
  id,
  apiUrl,
  filters,
  apiParams
}: UseDistinctListProps) => {
  const axiosInstance = useAxios()
  return useQuery({
    queryKey: [QueryKey, id, apiUrl, filters, apiParams],
    queryFn: async () => {
      return (
        await axiosInstance.post<DistinctValuesResponse>(
          apiUrl,
          {pageSize: null, pageNumber: 0, property: id, filters: filters, ...apiParams},
          {}
        )
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    retry: false,
    select: (data) => generateCategoryOptions(data.data)
  })
}

const generateCategoryOptions = (data: DistinctValue[]) => {
  const autocompleteOptions: AutocompleteOption[] = []
  const processedValues = new Set<string>()

  data.forEach((option) => {
    const id = option.value
    const value =
      analysisCategories.find((c) => c.id.toLowerCase() === option.value.toLowerCase())?.label ??
      null
    if (!value) return
    if (processedValues.has(value)) return
    processedValues.add(value)

    autocompleteOptions.push({
      id: id,
      label: value
    })
  })

  return autocompleteOptions
}

export const useDistinctAreaTypeListValues = ({
  id,
  apiUrl,
  filters,
  apiParams
}: UseDistinctListProps) => {
  const axiosInstance = useAxios()
  return useQuery({
    queryKey: [QueryKey, id, apiUrl, filters, apiParams],
    queryFn: async () => {
      return (
        await axiosInstance.post<DistinctValuesResponse>(
          apiUrl,
          {pageSize: null, pageNumber: 0, property: id, filters: filters, ...apiParams},
          {}
        )
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    throwOnError: true,
    retry: false,
    select: (data) => generateAreaTypeOptions(data.data)
  })
}

const generateAreaTypeOptions = (data: DistinctValue[]) => {
  const autocompleteOptions: AutocompleteOption[] = []
  const processedValues = new Set<string>()

  data.forEach((option) => {
    const id = option.value
    const value = analysisAreaTypes.find((a) => a.id === option.value)?.label ?? null
    if (!value) return
    if (processedValues.has(value)) return
    processedValues.add(value)

    autocompleteOptions.push({
      id: id,
      label: value
    })
  })

  return autocompleteOptions
}
