import {AnalysisPrivacyStatus} from '@hconnect/common/types'
import LanguageIcon from '@mui/icons-material/Language'
import PermIdentity from '@mui/icons-material/PermIdentity'
import {Typography, ToggleButton, ToggleButtonGroup, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface AnalysisPrivacyStatusSwitchProps {
  value: AnalysisPrivacyStatus | string
  onChange?: (value: AnalysisPrivacyStatus) => void
}

export const AnalysisPrivacyStatusSwitch = React.memo<AnalysisPrivacyStatusSwitchProps>(
  ({value, onChange}) => {
    const {t} = useTranslation()

    const handleOnChange = (event, newValue) => {
      if (onChange && newValue) onChange(newValue)
    }

    return (
      <Stack>
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={handleOnChange}
          fullWidth
          sx={{
            height: '48px'
          }}
        >
          <ToggleButton
            sx={{
              borderRadius: '6px !important',
              border: 'transparent !important',
              '&.Mui-selected': {
                color: '#FFFFFF !important',
                backgroundColor: '#016AD4 !important'
              }
            }}
            value={AnalysisPrivacyStatus.Private}
            aria-label={t('label.privacyStatusPrivate')}
          >
            <PermIdentity
              sx={{
                display: 'block',
                float: 'left',
                marginRight: '5px'
              }}
            />
            <span>{t('analysisPrivacy.private')}</span>
          </ToggleButton>
          <ToggleButton
            sx={{
              borderRadius: '6px !important',
              border: 'transparent !important',
              '&.Mui-selected': {
                color: '#FFFFFF !important',
                backgroundColor: '#016AD4 !important'
              }
            }}
            value={AnalysisPrivacyStatus.Public}
            aria-label={t('label.privacyStatusPublic')}
          >
            <LanguageIcon
              sx={{
                display: 'block',
                float: 'left',
                marginRight: '5px'
              }}
            />
            <span>{t('analysisPrivacy.public')}</span>
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="caption" sx={{mt: 1}}>
          {value === (AnalysisPrivacyStatus.Public as string)
            ? t('label.privacyStatusPublic')
            : t('label.privacyStatusPrivate')}
        </Typography>
      </Stack>
    )
  }
)
