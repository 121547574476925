import {AnalysisStatus} from '@hconnect/common/types'
import {Close, Check} from '@mui/icons-material'
import {Button, Dialog, DialogActions, DialogTitle, Grid} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {usePatchAnalysisStatus} from '../../hooks/analysis/usePatchAnalysisStatus'

export const ReopenAnalysisModal: React.FC<{
  analysisId: number
  closeModal: () => void
  opened: boolean
}> = ({analysisId, closeModal, opened}) => {
  const {t} = useTranslation()
  const {t: ta} = useTranslation(undefined, {keyPrefix: 'analysis.reopen'})

  const {data, mutate, isPending: isLoading} = usePatchAnalysisStatus(analysisId)
  useEffect(() => {
    if (data !== undefined && !isLoading) {
      closeModal()
    }
  }, [data, isLoading, closeModal])

  const handleClose = (e) => {
    closeModal()
    e.stopPropagation()
  }

  return (
    <Dialog open={opened} onClose={handleClose} maxWidth="md" data-test-id="reopen-analysis-modal">
      <DialogTitle>
        <Grid container>{ta('header')}</Grid>
      </DialogTitle>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button
            startIcon={<Close />}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            data-test-id="node-form-cancel-button"
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            startIcon={<Check />}
            color="primary"
            variant="outlined"
            onClick={(e) => {
              mutate({
                status: AnalysisStatus.Ongoing
              })
              handleClose(e)
            }}
            data-test-id="node-form-cancel-button"
            sx={{marginLeft: 1}}
          >
            {t('buttons.reopen')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
