import React from 'react'

import {AddNodeButton} from '../../components/canvas/AddNodeButton'
import {CollapsedNodeButton} from '../../components/canvas/CollapsedNodeButton'
import {CanvasNode} from '../../types/canvasNodes.types'

export interface CanvasNodeButtonsProps {
  canContribute: boolean
  isDefaultMode: boolean
  setAddModalOpen: (addParentOption: boolean) => void
  activeNode?: CanvasNode
  nodeWidth: number
  nodeHeight: number
  spaceY: number
  collapsedAncestorNode?: CanvasNode
  expandAncestors: (n: CanvasNode) => void
  collapsedDescendantNodes: Array<CanvasNode>
  expandDescendants: (nodeId: number, action: 'ADD' | 'REMOVE') => void
}

export const CanvasNodeButtons: React.FC<CanvasNodeButtonsProps> = ({
  canContribute,
  isDefaultMode,
  setAddModalOpen,
  spaceY,
  nodeWidth,
  nodeHeight,
  activeNode,
  collapsedAncestorNode,
  expandAncestors,
  collapsedDescendantNodes,
  expandDescendants
}) => {
  const cursorStyle = isDefaultMode ? {cursor: 'pointer'} : {}
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      {canContribute && (
        <>
          <AddNodeButton
            handleClick={(addParent: boolean) => {
              setAnchorEl(null)
              setAddModalOpen(addParent)
            }}
            handleClose={handleClose}
            open={open}
            anchorEl={anchorEl}
            activeNode={activeNode}
            nodeWidth={nodeWidth}
            nodeHeight={nodeHeight}
            spaceY={spaceY}
            xOffset={
              collapsedDescendantNodes.find((node) => node.id === activeNode?.id) === undefined
                ? 0
                : 30
            }
          />
          <use
            id="use-active-plus-button"
            xlinkHref="#active-plus-button"
            onClick={handleClick}
            style={cursorStyle}
          />
          <use
            id="use-active-plus-button-text"
            xlinkHref="#active-plus-button-text"
            onClick={handleClick}
            style={cursorStyle}
          />
        </>
      )}
      {collapsedAncestorNode && (
        <>
          <CollapsedNodeButton
            id="collapsed-ancestors-button"
            targetNode={collapsedAncestorNode}
            nodeWidth={nodeWidth}
            nodeHeight={nodeHeight}
            spaceY={spaceY}
            position="TOP"
            xOffset={activeNode?.id ? -30 : 0}
          />
          <use
            id="use-collapsed-ancestors-button"
            xlinkHref="#collapsed-ancestors-button"
            onClick={() => expandAncestors(collapsedAncestorNode)}
            style={cursorStyle}
          />
          <use
            id="use-collapsed-ancestors-button-path"
            xlinkHref="#collapsed-ancestors-button-path"
            onClick={() => expandAncestors(collapsedAncestorNode)}
            style={cursorStyle}
          />
        </>
      )}
      {collapsedDescendantNodes.length > 0 && (
        <>
          {collapsedDescendantNodes.map((node) => {
            if (node?.collapsed) return
            const id = `collapsed-descendant-button-${node.id}`
            return (
              <>
                <CollapsedNodeButton
                  key={node.id}
                  id={id}
                  targetNode={node}
                  nodeWidth={nodeWidth}
                  nodeHeight={nodeHeight}
                  spaceY={spaceY}
                  position="BOTTOM"
                  xOffset={node.id === activeNode?.id ? -30 : 0}
                />
                <use
                  id={`use-${id}`}
                  xlinkHref={`#${id}`}
                  onClick={() => expandDescendants(node.id, 'REMOVE')}
                  style={cursorStyle}
                />
                <use
                  id={`use-${id}-path`}
                  xlinkHref={`#${id}-path`}
                  onClick={() => expandDescendants(node.id, 'REMOVE')}
                  style={cursorStyle}
                />
              </>
            )
          })}
        </>
      )}
    </>
  )
}
