import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {Vote} from '../../types/nodeDetail.types'

type VoteUpdateData = {
  vote: Vote
}

export function usePatchVote(
  analysisId: string | undefined,
  nodeId: number,
  voteId: number | undefined
) {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (voteData: VoteUpdateData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/tree/nodes/${nodeId}/votes/${voteId}`

      return (await axiosInstance.patch<void>(path, voteData)).data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['node-detail']})
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
    }
  })
}
