import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {useAxios} from '../../../shared/hooks/useApi'
import {ApiFilter} from '../../../shared/types/filter.types'
import {StoppageInfo} from '../../types/stoppage.types'

export const QueryKey = 'stoppages'

type ResponseType = {
  currentPage: number
  totalCount: number
  data: StoppageInfo[]
}

const getFilterGroupValues = (filters: ApiFilter[] | undefined, property: string) => {
  if (!filters) return undefined
  return filters.find((filterGroup) => filterGroup.property === property)?.values
}

export const useGetStoppages = ({
  pageSize,
  pageNumber,
  filters,
  options
}: {
  pageSize: number
  pageNumber: number
  filters?: ApiFilter[]
  options?: UseQueryOptions<ResponseType, AxiosError>
}) => {
  const axiosInstance = useAxios()

  const filterPlantIds = getFilterGroupValues(filters, 'plant')
  const stoppageTime = getFilterGroupValues(filters, 'stoppageTime')

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, pageSize, pageNumber, filterPlantIds, stoppageTime],
    queryFn: async () => {
      const path = '/rootcausefailureanalysis/stoppages'
      return (
        await axiosInstance.post<ResponseType>(path, {
          pageSize,
          pageNumber,
          plantIds: filterPlantIds,
          ...(stoppageTime && {startDate: stoppageTime[0], endDate: stoppageTime[1]})
        })
      ).data
    },
    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 2,

    // allow the callee to override the default options
    ...options
  })
}
