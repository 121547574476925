import SyncProblemIcon from '@mui/icons-material/SyncProblem'
import {Box, Tooltip, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

interface LastSensorReadingProps {
  lastReading?: string
}

export const LastSensorReading = ({lastReading}: LastSensorReadingProps) => {
  const {t} = useTranslation()
  const lang = navigator.language
  const format = lang.toLowerCase() === 'en-us' ? 'MM/DD HH:mm' : 'DD/MM HH:mm'
  const error = lastReading && moment().diff(lastReading, 'minutes') > 5
  const color = error ? 'error.main' : 'text.primary'

  return (
    <Box display="flex" flexWrap="nowrap" gap={0.5} alignItems="center">
      <Typography
        noWrap
        variant={'caption'}
        color={color}
        component={'span'}
        sx={{display: 'flex'}}
        fontWeight={error ? 500 : undefined}
      >
        {lastReading ? moment(lastReading).format(format) : '-'}
      </Typography>
      {error && (
        <Tooltip title={t('sensorList.staleDataTooltip')}>
          <SyncProblemIcon color="error" sx={{fontSize: '16px'}} />
        </Tooltip>
      )}
    </Box>
  )
}
