import {AnalysisStatus, AnalysisListType} from '@hconnect/common/types'
import {cloneDeep} from 'lodash'

import {
  useDistinctAreaTypeListValues,
  useDistinctCategoryListValues,
  useDistinctListValues
} from '../../shared/hooks/useDistinctListValues'
import {FilterConfig, FilterTypes, AutocompleteOption} from '../../shared/types/filter.types'
import {AnalysisStatusComponent} from '../components/AnalysisStatus'

const title: FilterConfig = {
  id: 'title',
  label: 'tables.analysisList.incidentTitle',
  type: FilterTypes.Text,
  available: true
}

const stoppageCode: FilterConfig = {
  id: 'stoppageCode',
  label: 'tables.analysisList.stoppage',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctListValues
}

const lastUpdated: FilterConfig = {
  id: 'lastUpdate',
  label: 'tables.analysisList.lastUpdated',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

const dueDate: FilterConfig = {
  id: 'dueDate',
  label: 'tables.analysisList.dueDate',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

const owner: FilterConfig = {
  id: 'owner',
  label: 'analysis.owner',
  type: FilterTypes.Select,
  available: false,
  optionsLoader: useDistinctListValues
}

const plant: FilterConfig = {
  id: 'plantName',
  label: 'Plant',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctListValues
}

const planCountry: FilterConfig = {
  id: 'PlantCountry',
  label: 'Country',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctListValues
}

const category: FilterConfig = {
  id: 'Category',
  label: 'Category',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctCategoryListValues
}

const areaTypeCode: FilterConfig = {
  id: 'AreaTypeCode',
  label: 'Area Type',
  type: FilterTypes.Select,
  available: true,
  optionsLoader: useDistinctAreaTypeListValues
}
const statusOptions: AutocompleteOption[] = [
  {
    id: 'FinishedTasksDone',
    label: 'Finished (tasks done)',
    customComponent: (
      <AnalysisStatusComponent status={AnalysisStatus.Finished} hasPendingTasks={false} />
    )
  },
  {
    id: 'FinishedTasksPending',
    label: 'Finished (tasks pending)',
    customComponent: (
      <AnalysisStatusComponent status={AnalysisStatus.Finished} hasPendingTasks={true} />
    )
  },
  {
    id: 'Ongoing',
    label: 'In Progress',
    customComponent: (
      <AnalysisStatusComponent status={AnalysisStatus.Ongoing} hasPendingTasks={true} />
    )
  }
]

const status: FilterConfig = {
  id: 'status',
  label: 'tables.analysisList.status',
  type: FilterTypes.SelectPredefined,
  options: statusOptions,
  available: false
}

const stoppageTime: FilterConfig = {
  id: 'stoppageTime',
  label: 'stoppage.detail.time',
  type: FilterTypes.Date,
  rangeAvailable: true,
  available: true
}

const baseFilters: FilterConfig[] = [
  title,
  stoppageCode,
  lastUpdated,
  plant,
  planCountry,
  status,
  dueDate,
  owner,
  stoppageTime,
  areaTypeCode,
  category
]

const setFilterAvailability = (filters: FilterConfig[], filterId: string, available: boolean) => {
  const filter = filters.find((f) => f.id === filterId)
  if (filter) filter.available = available
}

export const getAvailableFilters = (
  listType: AnalysisListType,
  canReassign: boolean,
  plantId: string
): Array<FilterConfig> => {
  const filters = cloneDeep(baseFilters)

  switch (listType) {
    case AnalysisListType.Mine:
      setFilterAvailability(filters, 'status', true)
      if (canReassign) setFilterAvailability(filters, 'owner', true)
      break
    case AnalysisListType.Contributing:
      setFilterAvailability(filters, 'status', true)
      setFilterAvailability(filters, 'owner', true)
      break
    case AnalysisListType.MyPlant:
      setFilterAvailability(filters, 'status', true)
      setFilterAvailability(filters, 'owner', true)
      setFilterAvailability(filters, 'plantName', false)
      break
    case AnalysisListType.Global:
      setFilterAvailability(filters, 'owner', true)
      setFilterAvailability(filters, 'dueDate', false)
      break
  }

  filters.forEach((filter) => {
    if (filter.type === FilterTypes.Select) {
      filter.apiUrl = `/rootcausefailureanalysis/analyses/${listType}/distinct`
      filter.apiParams = {
        plantId
      }
    }
  })

  return filters
}
