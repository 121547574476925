import {TableCell, TableHead, TableRow} from '@mui/material'
import React from 'react'

import {OrderByApiProperty} from '../../hooks/listFiltersSort/useListOrderBy'
import {ListColumn} from '../../types/common.types'
import {SortableListHeader} from '../SortableListHeader'

import {ColumnSelection} from './ColumnSelection/ColumnSelection'

export type DataTableHeadProps<T> = {
  handleOrderToggle?: (col) => void
  availableColumns: ListColumn<T>[]
  order?: OrderByApiProperty[]
  columnsToRender: string[]
  filteredColumns: ListColumn<T>[]
  showColumnSelection?: boolean
  setFilteredColumns: (newFilteredColumns: ListColumn<T>[]) => void
}
export const DataTableHead = <T,>({
  order,
  handleOrderToggle,
  availableColumns,
  columnsToRender,
  filteredColumns,
  setFilteredColumns,
  showColumnSelection
}: DataTableHeadProps<T>) => {
  return (
    <TableHead
      sx={(theme) => ({
        background: theme.palette.grey['100'],
        borderRadius: 0.5,
        position: 'sticky',
        top: 0,
        minHeight: '30px'
      })}
    >
      <TableRow sx={{position: 'relative'}}>
        {columnsToRender.map((columnName) => (
          <TableCell key={columnName}>
            <SortableListHeader
              labelTranslationKey={
                availableColumns.find((c) => (c.id as unknown as string) === columnName)
                  ?.translationKey ?? ''
              }
              sortable={
                availableColumns.find((c) => (c.id as unknown as string) === columnName)
                  ?.sortable ?? false
              }
              id={columnName}
              currentDesc={order?.find((o) => o.property === columnName)?.descending}
              handleOrderToggle={handleOrderToggle}
              padding={0}
            />
          </TableCell>
        ))}
        {showColumnSelection && (
          <TableCell key={'column-selection'} height={'40px'}>
            <ColumnSelection<T>
              columns={availableColumns}
              filteredColumns={filteredColumns}
              setFilteredColumns={setFilteredColumns}
              loading={false}
            />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
