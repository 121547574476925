export const setSearchAfterSvgClick = (params: URLSearchParams, localNames: string[]) => {
  const selected = params.get('selected')?.split(',')
  // UNSELECT IF ALL GROUP MEMBERS ARE ALREADY SELECTED
  if (localNames.every((id) => selected?.includes(id))) {
    const newIds = selected?.filter((id) => !localNames.includes(id))
    if (newIds?.length) {
      params.set('selected', newIds.join(','))
      params.set('filtered', newIds.join(','))
    } else {
      params.delete('selected')
      params.delete('filtered')
    }
    return params
  }
  const newSelected = new Set([...(selected ?? []), ...localNames])
  params.set('selected', Array.from(newSelected).join(','))
  params.set('filtered', Array.from(newSelected).join(','))
  return params
}
