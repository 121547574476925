import ArrowForward from '@mui/icons-material/ArrowForward'
import Close from '@mui/icons-material/Close'
import {Button, Typography, Stack, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {formatDateTime} from '../../common/dateTimeUtils'
import {StoppageInfo} from '../../types/stoppage.types'

import {StoppageSection} from './StoppageSection'

export const StoppageDetail: React.FC<{
  stoppage: StoppageInfo
  closeAction: () => void
  moveToNextStep: (e) => void
  showNextStep?: boolean
}> = ({stoppage, closeAction, moveToNextStep, showNextStep = true}) => {
  const {t} = useTranslation()

  const handleClose = (e) => {
    closeAction()
    e.stopPropagation()
  }

  return (
    <Stack spacing={2} sx={{maxHeight: '65vh', overflowY: 'auto'}}>
      {/* TITLE */}
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="h2" mb={1}>
            {t('stoppage.detail.stoppageDetail')}
          </Typography>
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
        </Stack>
        <Typography variant="caption">
          {t('stoppage.detail.createdBy')} {stoppage.createdBy} {t('stoppage.detail.on')}{' '}
          {stoppage.createDate ? formatDateTime(stoppage.createDate) : ''}
        </Typography>
        <Typography variant="caption">
          {t('stoppage.detail.updatedBy')} {stoppage.updatedBy} {t('stoppage.detail.on')}{' '}
          {stoppage.updateDate ? formatDateTime(stoppage.updateDate) : ''}
        </Typography>
      </Stack>

      {/* ASSET & CODE */}
      <Stack direction="row" spacing={4}>
        <StoppageSection label={t('stoppage.detail.asset')} value={stoppage?.stoppageCode?.type} />
        <StoppageSection
          label={t('stoppage.detail.stoppageCode')}
          value={stoppage?.stoppageCode?.text}
          labelBottom={stoppage?.stoppageCode?.code}
        />
      </Stack>

      {/* TIME */}
      <StoppageSection
        label={t('stoppage.detail.time')}
        value={`${stoppage.start ? formatDateTime(stoppage.start) : ''} - ${
          stoppage.end ? formatDateTime(stoppage.end) : ''
        }`}
      />

      {/* EQUIPMENT */}
      <StoppageSection
        label={t('stoppage.detail.equipment')}
        value={stoppage?.equipment?.text}
        labelBottom={stoppage?.equipment?.id}
      />

      {/* DESCRIPTION */}
      <StoppageSection
        label={t('stoppage.detail.description')}
        value={stoppage?.description}
        valueProps={{variant: 'body1'}}
      />
      {showNextStep && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowForward />}
          data-test-id="create-analysis-continue-button"
          onClick={(e) => moveToNextStep(e)}
          sx={{alignSelf: 'flex-end', width: 'fit-content', minHeight: '48px'}}
        >
          {t('buttons.continueCreate')}
        </Button>
      )}
    </Stack>
  )
}
