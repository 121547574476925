import {
  StationPart,
  buildStationLocations,
  DrivePart,
  buildDriveLocations,
  KilnShellPart,
  buildKilnShellLocations
} from '@hconnect/common/components/kmsKilnSvgWrapper'

import {SignalsModalData} from './SignalsModal.types'

export const buildLocations = ({
  id,
  signalsModalData
}: {
  id: string
  signalsModalData: SignalsModalData
}) => {
  if (signalsModalData.type === 'drive') {
    return buildDriveLocations({id: id, position: signalsModalData.layout.position})
  }

  if (signalsModalData.type === 'station') {
    return buildStationLocations({
      id,
      thrustRollerPosition: signalsModalData.layout.thrustRollerPosition
    })
  }

  if (signalsModalData.type === 'kiln-shell') {
    return buildKilnShellLocations({id: id})
  }

  return []
}

export const getKilnPartSvg = (signalsModalData: SignalsModalData) => {
  if (signalsModalData.type === 'drive') {
    return (
      <DrivePart
        id={signalsModalData.type}
        x={0}
        y={0}
        height={355}
        width={175}
        position={signalsModalData.layout.position}
        showLocation
        flip={signalsModalData.layout.flip}
      />
    )
  }

  if (signalsModalData.type === 'station') {
    return (
      <StationPart
        id={signalsModalData.type}
        x={0}
        y={0}
        height={305}
        width={140}
        showLocation
        thrustRollerPosition={signalsModalData.layout.thrustRollerPosition}
      />
    )
  }

  if (signalsModalData.type === 'kiln-shell') {
    return (
      <KilnShellPart id={signalsModalData.type} x={0} y={0} height={80} width={560} showLocation />
    )
  }

  return null
}
