import {AnalysisPrivacyStatus, AnalysisStatus} from '@hconnect/common/types'
import {useMutation, useQueryClient} from '@tanstack/react-query'

import {useAxios} from '../../../shared/hooks/useApi'
import {GraphLevel} from '../../types/canvasNodes.types'

export type ResponseType = {
  analysisId: number
  levels: GraphLevel[]
  selectedNodeId: number
}

export const QueryKey = 'graph-data'

type AnalysisUpdateData = {
  privacyStatus?: string | AnalysisPrivacyStatus
  status?: string | AnalysisStatus
}

export const usePatchAnalysisStatus = (analysisId: number | undefined) => {
  const axiosInstance = useAxios()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (analysisData: AnalysisUpdateData) => {
      const path = `/rootcausefailureanalysis/analyses/${analysisId}/statuses`

      return (await axiosInstance.post<ResponseType>(path, analysisData, {})).data
    },
    onSuccess: async (data: ResponseType) => {
      await queryClient.invalidateQueries({queryKey: ['graph-data']})
      await queryClient.invalidateQueries({queryKey: ['analysis-detail']})
      await queryClient.invalidateQueries({queryKey: ['analysis-list']})
      queryClient.removeQueries({queryKey: ['analysis-pdfsummary']})
      return data
    }
  })
}
