import {Box, CircularProgress, LinearProgress} from '@mui/material'
import {Moment} from 'moment'
import {useMemo} from 'react'

import {BaseChart} from '../../../../shared/components/graphs/BaseChart'
import {areaChartOptions} from '../../../../shared/components/graphs/config/simpleLineChart.config'
import {thresholdAreaPlugin} from '../../../../shared/components/graphs/plugins'
import {ChartError} from '../../../shared/components/ChartError'
import {getSingleSignalData} from '../../../shared/containers/charts/chartHelpers'
import {useGetCrankPlotData} from '../../hooks/useGetCrankPlotData'

interface CrankChartProps {
  signalIds: string[]
  timeFrom: Moment
  timeTo: Moment
}

export const CrankChart = ({signalIds, timeFrom, timeTo}: CrankChartProps) => {
  const {data, isPending, isFetching} = useGetCrankPlotData({
    sensorIds: signalIds,
    timeFrom,
    timeTo
  })

  const graphUnit = data?.data[0]?.unit ?? ''
  const thresholdHighHigh = data?.data[0]?.thresholds?.highHigh
  const thresholdHigh = data?.data[0]?.thresholds?.high
  const highStatus = data?.data[0]?.thresholds?.highStatus
  const highHighStatus = data?.data[0]?.thresholds?.highHighStatus

  const plugins = useMemo(() => [thresholdAreaPlugin], [])

  const thresholdConfig = useMemo(
    () => ({
      highHigh: thresholdHighHigh,
      high: thresholdHigh,
      highStatus: highStatus,
      highHighStatus: highHighStatus
    }),
    [highHighStatus, highStatus, thresholdHigh, thresholdHighHigh]
  )

  const options = useMemo(
    () =>
      areaChartOptions({
        unit: graphUnit,
        scaleToShowThreshold: true,
        ...thresholdConfig
      }),
    [graphUnit, thresholdConfig]
  )

  const chartData = useMemo(
    () =>
      getSingleSignalData({
        data,
        labelsOverride: {
          avgValues: 'Crank - Bucket Avg',
          maxValues: 'Crank - Bucket Max',
          minValues: 'Crank - Bucket Min'
        }
      }),
    [data]
  )

  return isPending ? (
    <Box sx={{height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CircularProgress />
    </Box>
  ) : !isPending && !chartData.labels?.length ? (
    <ChartError />
  ) : (
    <div>
      {isFetching ? <LinearProgress /> : <div style={{height: '4px'}} />}
      <BaseChart data={chartData} options={options} plugins={plugins} />
    </div>
  )
}
