import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import {LoadingButton} from '@mui/lab'
import {Dialog, DialogContent, Typography, Stack, IconButton} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePatchAnalysis} from '../../hooks/analysis/usePatchAnalysis'
import {UserInfo} from '../../types/user.types'

import {AssigneeDetail} from './AssigneeDetail'
import {RcfaUserSearch} from './RcfaUserSearch'

type ChangeAnalysisOwnerDialogProps = ChangeAnalysisOwnerProps & {
  opened: boolean
}

export const ChangeAnalysisOwnerDialog = ({
  analysisId,
  onClose,
  opened,
  currentOwner
}: ChangeAnalysisOwnerDialogProps) => {
  const handleClose = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose?.()
    e?.stopPropagation()
  }

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      maxWidth="xl"
      data-test-id="change-owner-analysis-modal"
    >
      <DialogContent sx={{minWidth: 400}}>
        <ChangeAnalysisOwner
          analysisId={analysisId}
          currentOwner={currentOwner}
          onClose={handleClose}
          canSetOwner={true}
        />
      </DialogContent>
    </Dialog>
  )
}

type ChangeAnalysisOwnerProps = {
  analysisId: number
  currentOwner: UserInfo
  canSetOwner: boolean
  onClose?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ChangeAnalysisOwner = ({
  analysisId,
  onClose,
  currentOwner,
  canSetOwner
}: ChangeAnalysisOwnerProps) => {
  const {t} = useTranslation()
  const [newOwner, setNewOwner] = useState<UserInfo | null>(null)
  const {mutate, isPending: isLoading} = usePatchAnalysis()

  const showCloseButton = onClose !== undefined
  const submitDisabled = !newOwner || newOwner.id === currentOwner.id

  const changeOwner = () => {
    if (!newOwner?.id) return
    mutate(
      {
        Id: analysisId,
        Owner: newOwner.id
      },
      {
        onSuccess: () => {
          onClose?.()
          setNewOwner(null)
        }
      }
    )
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{t('analysis.changeOwner.owner')}</Typography>
        {showCloseButton && (
          <IconButton onClick={onClose} color="primary">
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <AssigneeDetail assignee={currentOwner} />
      {canSetOwner && (
        <>
          <RcfaUserSearch
            label={t('buttons.changeOwner')}
            assignee={newOwner}
            onChange={(newAssignee) => setNewOwner(newAssignee)}
          />
          <LoadingButton
            startIcon={<CheckIcon />}
            color="secondary"
            variant="outlined"
            onClick={() => changeOwner()}
            loading={isLoading}
            loadingPosition="start"
            disabled={submitDisabled}
            sx={{
              width: 'fit-content',
              minWidth: 100,
              height: 48,
              alignSelf: 'flex-end',
              border: '1px solid #00274D1A',
              boxShadow: '0px 2px 2px 0px #00000014'
            }}
          >
            <span>{t('buttons.done')}</span>
          </LoadingButton>
        </>
      )}
    </Stack>
  )
}
