import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useEffect} from 'react'
import {Outlet} from 'react-router'

import {useGlobalContext} from '../../../shared/hooks/useGlobalContext'
import {PMContainer} from '../components/PM'

export const PmMain = () => {
  const {state} = useGlobalContext()

  useEffect(() => {
    if (state && state.user) {
      trackEvent('kmsUserLoggedIn', {
        userId: state.user.userId,
        country: state.user.countryCode,
        product: 'Maintain',
        subProduct: 'KMS'
      })
    }
  }, [state])

  return <Outlet />
}

export const PmInternalRoutes = [
  {
    index: true,
    element: <PMContainer />
  }
]
