import {arrayMove} from '@dnd-kit/sortable'
import {Stack, Typography} from '@mui/material'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import {usePrevious} from 'react-use'

import {ReactComponent as NoSelected} from '../../assets/NoSelected.svg'
import {CardWrapper} from '../../components/styled'
import {useSensors} from '../../context/SensorsContext'
import {ActiveView} from '../../types/sensorDetails.types'

import {CombineView} from './CombineView'
import {DefaultView} from './DefaultView'
import {PopoutView} from './PopoutView'

export const SensorDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {t} = useTranslation()
  const {selectedSensors} = useSensors()
  const urlActiveView = searchParams.get('activeView') as ActiveView
  const [activeView, setActiveView] = useState<ActiveView>(urlActiveView ?? 'default')
  const [timeWindow, setTimeWindow] = useState(72)
  const [scaleToShowThreshold, setScaleToShowThreshold] = useState(true)
  const [combinedSensors, setCombinedSensors] = useState<string[][]>(
    JSON.parse(searchParams.get('combinedSensors') ?? '[]')
  )
  const urlSensorOrder = JSON.parse(searchParams.get('sensorsOrder') ?? '[]')

  const [sensorsOrder, setSensorsOrder] = useState(
    urlSensorOrder.length > 0 ? urlSensorOrder : selectedSensors.map((sensor) => sensor.localName)
  )
  const [sensorsOrderPrev, setSensorsOrderPrev] = useState(
    urlSensorOrder.length > 0 ? urlSensorOrder : selectedSensors.map((sensor) => sensor.localName)
  )

  const prevActiveView = usePrevious(activeView)
  const isSelected = useMemo(() => selectedSensors.length > 0, [selectedSensors])

  const handleCombineViewClose = () => {
    setActiveView(prevActiveView === 'popout' ? prevActiveView : 'default')
  }

  const removeCombineGroup = (groupIndex: number) => {
    setCombinedSensors((prev) => {
      const newGroups = structuredClone(prev)
      newGroups[groupIndex] = []
      return newGroups
    })
    setSensorsOrder((prev) =>
      prev.filter((orderId) => !orderId.startsWith(`combined-${groupIndex}`))
    )
  }

  useEffect(() => {
    if (JSON.stringify(sensorsOrder) !== JSON.stringify(sensorsOrderPrev)) {
      setSearchParams((params) => {
        params.set('sensorsOrder', JSON.stringify(sensorsOrder))
        params.set('combinedSensors', JSON.stringify(combinedSensors))
        return params
      })
      setSensorsOrderPrev(sensorsOrder)
    }
  }, [sensorsOrder, combinedSensors])

  useEffect(() => {
    setSearchParams((params) => {
      params.set('activeView', activeView)
      return params
    })
  }, [activeView])

  const updateCombinedSensors = (sensorGroups: string[][]) => {
    setCombinedSensors(sensorGroups)
    setSensorsOrder((prev) => {
      const relevantGroups = sensorGroups
        .map((_, i) => `combined-${i}`)
        .filter((g, i) => sensorGroups[i].length > 1)
      const updatedGroups = prev.filter(
        (orderId) => relevantGroups.includes(orderId) || !orderId.startsWith('combined-')
      )
      const addedGroups = relevantGroups.filter((orderId) => !updatedGroups.includes(orderId))
      return [...addedGroups, ...updatedGroups]
    })
    handleCombineViewClose()
  }

  function handleDragEnd(event) {
    const {active, over} = event
    if (active.id !== over.id) {
      setSensorsOrder((items) => {
        const oldIndex = items.indexOf(active.id as string)
        const newIndex = items.indexOf(over.id as string)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  useEffect(() => {
    setSensorsOrder((prev) => {
      const selectedIds = selectedSensors.map((sensor) => sensor.localName)
      const newOrder = prev.filter(
        (sensorId) => selectedIds.includes(sensorId) || sensorId.startsWith('combined-')
      )
      const addedSensorIds = selectedSensors
        .filter((sensor) => !prev.includes(sensor.localName))
        .map((sensor) => sensor.localName)

      return [...addedSensorIds, ...newOrder]
    })
  }, [selectedSensors])

  return (
    <>
      <CardWrapper
        flexDirection="column"
        height={isSelected ? 'fit-content' : '100%'}
        overflow="hidden"
        sx={{backgroundColor: !isSelected ? 'transparent' : undefined}}
        p={2}
      >
        {!isSelected && (
          <Stack justifyContent="center" alignItems="center" height="100%" spacing={3}>
            <NoSelected />
            <Typography
              variant="body1"
              fontStyle="italic"
              color="rgba(255, 255, 255, 0.6)"
              maxWidth="300px"
            >
              {t('sensorDetails.noSelected')}
            </Typography>
          </Stack>
        )}
        {isSelected && activeView === 'default' && (
          <DefaultView
            combinedSensors={combinedSensors}
            handleRemoveCombineGroup={removeCombineGroup}
            setActiveView={setActiveView}
            sensorsOrder={sensorsOrder}
            onDragEnd={handleDragEnd}
            scaleToShowThreshold={scaleToShowThreshold}
            setScaleToShowThreshold={setScaleToShowThreshold}
            timeWindow={timeWindow}
            setTimeWindow={setTimeWindow}
          />
        )}
        {isSelected && activeView === 'combine' && (
          <CombineView
            selectedSensors={selectedSensors}
            combinedSensors={combinedSensors}
            onCancel={handleCombineViewClose}
            onDone={updateCombinedSensors}
          />
        )}
      </CardWrapper>
      {isSelected && activeView === 'popout' && (
        <PopoutView
          combinedSensors={combinedSensors}
          handleRemoveCombineGroup={removeCombineGroup}
          open={true}
          setActiveView={setActiveView}
          sensorsOrder={sensorsOrder}
          onDragEnd={handleDragEnd}
          scaleToShowThreshold={scaleToShowThreshold}
          setScaleToShowThreshold={setScaleToShowThreshold}
        />
      )}
    </>
  )
}
