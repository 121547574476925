import {cloneDeep} from 'lodash'

import {findNode, findNodeLevel} from '../common/graphUtils'
import {CanvasLevel, CanvasNode, GraphLevel, GraphLink} from '../types/canvasNodes.types'

const setNodePropertyInLevels = (
  nodeIds: Set<number>,
  property: string,
  value: unknown,
  data: CanvasLevel[]
): CanvasLevel[] => {
  return data.map((canvasLevel) => {
    for (const node of canvasLevel.nodes) {
      if (nodeIds.has(node.id)) {
        node[property] = value
      }
    }
    return canvasLevel
  })
}

const collapseAncestors = (node: CanvasNode, data: CanvasLevel[]): CanvasLevel[] => {
  const dataUpdated = cloneDeep(data)
  if (node.parent === undefined) return dataUpdated
  return setNodePropertyInLevels(getAncestorIds(node, dataUpdated), 'collapsed', true, dataUpdated)
}

const collapseDescendants = (nodes: CanvasNode[], data: CanvasLevel[]): CanvasLevel[] => {
  let dataUpdated = cloneDeep(data)
  if (nodes.length === 0) return dataUpdated
  nodes.forEach((node) => {
    if (node.children.length === 0) return
    dataUpdated = setNodePropertyInLevels(
      getDescendantIds(node, dataUpdated),
      'collapsed',
      true,
      dataUpdated
    )
  })
  return dataUpdated
}

const getAllIds = (data: Array<GraphLevel>): Set<number> => {
  const allIds: Set<number> = new Set()
  for (const level of data) {
    for (const node of level.nodes) {
      allIds.add(node.id)
    }
  }
  return allIds
}

const getDescendantIds = (node: CanvasNode, data: Array<GraphLevel>): Set<number> => {
  const descendantIds: Set<number> = new Set()

  const nodeLevel = findNodeLevel(node.id, data)

  if (!node.children || nodeLevel === undefined) {
    return descendantIds
  }

  node.children.forEach((id) => descendantIds.add(id))
  for (let l = nodeLevel + 1; l < data.length; l++) {
    for (const n of data[l].nodes) {
      if (n?.children && descendantIds.has(n.id)) {
        n.children.forEach((id) => descendantIds.add(id))
      }
    }
  }
  return descendantIds
}

const getAncestorIds = (node: CanvasNode, data: Array<GraphLevel>): Set<number> => {
  const descendants = getDescendantIds(node, data)
  const all = getAllIds(data)
  const ancestorIds: Set<number> = new Set()
  all.forEach((id) => {
    if (!descendants.has(id) && id !== node.id) {
      ancestorIds.add(id)
    }
  })
  return ancestorIds
}

const recalculateParentsSizes = (graphData: CanvasNode[]): CanvasNode[] => {
  const data = [...graphData]
  data.reverse()
  for (const node of data) {
    const parent = findNode(node?.parent, graphData)
    if (parent) {
      const value = node.totalTree ? node.totalTree : 1
      parent.totalTree += value
    }
  }

  return graphData
}

const calculateTreeNodeOffset = (
  offsetFromLeft: number,
  node: CanvasNode,
  parent: CanvasNode,
  nodeWidth: number,
  spaceX: number
) => {
  const totalTree = node.totalTree ?? 0
  const children = parent.children?.length ?? 0
  if (totalTree) {
    const moveIndex = totalTree / 2 - 0.5
    offsetFromLeft += moveIndex * nodeWidth + moveIndex * spaceX
    if (parent.cx > 0) {
      const moveIndex = children > 1 ? parent.totalTree / 2 - 0.5 : 0
      const moveValue = parent.cx - moveIndex * nodeWidth - moveIndex * spaceX
      if (offsetFromLeft < moveValue) {
        offsetFromLeft = parent.totalTree > 1 ? moveValue : parent.cx
      }
    }
    const completeWidth = offsetFromLeft + (moveIndex + 1) * nodeWidth + moveIndex * spaceX
    // side effect
    node.cx = offsetFromLeft
    offsetFromLeft = completeWidth + spaceX
  } else {
    const moveIndex = parent.totalTree / 2 - 0.5
    const moveValue = parent.cx - moveIndex * nodeWidth - moveIndex * spaceX
    if (offsetFromLeft < moveValue) offsetFromLeft = parent.totalTree > 1 ? moveValue : parent.cx
    // side effect
    node.cx = offsetFromLeft
    offsetFromLeft += nodeWidth + spaceX
  }

  return offsetFromLeft
}

export const updateNodeVisibility = (
  data: CanvasLevel[],
  collapsedAncestorNode: CanvasNode | undefined,
  collapsedDescendantNodes: CanvasNode[]
): CanvasLevel[] => {
  let dataUpdated = cloneDeep(data)
  if (collapsedAncestorNode) {
    dataUpdated = collapseAncestors(collapsedAncestorNode, dataUpdated)
    dataUpdated = setNodePropertyInLevels(
      new Set([collapsedAncestorNode.id]),
      'hasCollapsedAncestors',
      true,
      dataUpdated
    )
  }
  if (collapsedDescendantNodes.length > 0) {
    dataUpdated = collapseDescendants(collapsedDescendantNodes, dataUpdated)
  }
  return dataUpdated
}

export const prepareGraphNodes = (
  canvasLevels: CanvasLevel[],
  spaceY: number,
  nodeHeight: number
) => {
  const graphData: Array<CanvasNode> = []
  let lastY = spaceY
  let index = 0

  for (const n of canvasLevels) {
    // only process nodes which are not marked as collapsed
    for (const graphNode of n.nodes.filter((n) => !n.collapsed)) {
      graphNode.position = index
      graphNode.level = n.level
      graphNode.totalTree = 0
      if (graphNode.parent !== null && graphNode.parent !== undefined)
        graphNode.dependsOn = [graphNode.parent]

      graphData.push(graphNode)
      // we need space for a button above the node with collapsed ancestors
      if (graphNode?.hasCollapsedAncestors) {
        // remove hardcoded value
        lastY = lastY + 20
      }
      graphNode.cy = lastY
      index += 1
    }
    // if all nodes in a level are collapsed we do not adjust lastY
    lastY = index === 0 ? lastY : lastY + spaceY + nodeHeight
    index = 0
  }

  return {graphData: recalculateParentsSizes(graphData), windowHeight: lastY < 530 ? 530 : lastY}
}

export const calculateGraphWidth = (
  graphNodes: CanvasNode[],
  spaceX: number,
  nodeWidth: number
) => {
  let graphWidth = 0

  for (const node of graphNodes) {
    // node with collapsed ancestors is treated as a root
    if (node.level === 0 || node?.hasCollapsedAncestors) {
      graphWidth =
        (node.totalTree === 0 ? 1 : node.totalTree) * nodeWidth + (node.totalTree + 1) * spaceX
    }
  }

  return graphWidth
}

export const calculateGraphCenter = (
  graphNodes: CanvasNode[],
  dimensions: {width: number},
  graphWidth: number
) => {
  let graphCenter = 0
  const center = graphWidth / 2

  for (const node of graphNodes) {
    // node with collapsed ancestors is treated as a root
    if (node.level === 0 || node?.hasCollapsedAncestors) {
      graphCenter = dimensions.width > center ? dimensions.width / 2 : center
    }
  }

  return graphCenter
}

export const processNodes = (
  graphNodes: CanvasNode[],
  spaceX: number,
  nodeWidth: number,
  graphCenter: number
) => {
  const customLinks: Array<GraphLink> = []
  const graphData: Array<CanvasNode> = []
  let offsetFromLeft = spaceX
  let prevLevel = 0

  for (const node of graphNodes) {
    if (node.level !== prevLevel) {
      offsetFromLeft = spaceX
      prevLevel = node.level ?? 0
    }

    node.cx = graphCenter + spaceX / 2

    if (node.parent !== null && node.parent !== undefined) {
      // NEED TO ALIGN WITH PARENT NODE - parent is already processed in array of nodes
      const parent = findNode(node.parent, graphData)
      // if we are rendering just a subgraph the parent node can be undefined
      if (parent) {
        customLinks.push({source: parent, target: node})
        offsetFromLeft = calculateTreeNodeOffset(offsetFromLeft, node, parent, nodeWidth, spaceX)
      }
    }

    graphData.push(node)
  }

  return {links: customLinks, graphNodes: graphData}
}
