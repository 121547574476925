import {MaintainAppName} from '@hconnect/common/hproduce/maintain.types'

import {links as rcfaLinks, LinkTo} from '../../rcfa/routes'
import {Plant} from '../types/plant.types'

type AppLinks = {
  [K in MaintainAppName]: LinkTo[]
}

export const defaultDashboardLinks: LinkTo[] = [
  {
    to: '/rcfa',
    translationKey: 'Root Cause Analysis'
  },
  {
    to: '/pm',
    translationKey: 'Plant Monitoring',
    subItems: [
      {to: '/pm', translationKey: 'Plant Monitoring', activateOnlyIfExact: true},
      {to: '/pm/kms', translationKey: 'Kiln Monitoring', activateOnlyIfExact: true}
    ]
  }
]

export const appLinks: AppLinks = {
  Rcfa: rcfaLinks,
  PM: [
    {
      to: '/pm',
      translationKey: 'Plant Monitoring',
      activateOnlyIfExact: true
    },
    {
      to: '/pm/kms',
      translationKey: 'Kiln Monitoring',
      activateOnlyIfExact: true
    }
  ],
  default: defaultDashboardLinks,
  Maintain: []
}

export const getNavItems = (appName: MaintainAppName, plantId: Plant['plantId'], t) => {
  return appLinks[appName].map((link) => ({
    label: t(link.translationKey),
    url: `/plant/${plantId}${link.to}`,
    activateOnlyIfExact: link.activateOnlyIfExact,
    subItems: link.subItems?.map((subItem) => ({
      label: t(subItem.translationKey),
      url: `/plant/${plantId}${subItem.to}`,
      activateOnlyIfExact: subItem.activateOnlyIfExact
    }))
  }))
}
