import {Loader} from '@hconnect/uikit/src/lib2'
import {TableFooter} from '@material-ui/core'
import {Table, TableBody, TableCell, TableRow, Typography} from '@mui/material'
import React, {Fragment, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {OrderByApiProperty} from '../../hooks/listFiltersSort/useListOrderBy'
import {
  BaseDatatableData,
  BaseDatatableItem,
  ListColumn,
  TableSizedColumns
} from '../../types/common.types'

import {DataTableFooter} from './TableFooter'
import {DataTableHead} from './TableHead'
import {DataTableHeader} from './TableHeader'
import {DataTableRow} from './TableRow'

const sizes = ['xs', 'sm', 'md', 'lg', 'xl']

export type DataTableBodyProps<T, M> = {
  paginationSettings?: {
    page: number
    rowsNumber: number
    handlePageChange: (newPage: number) => void
    handleRowsPerPageChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  }
  tableHeaderSettings: {
    download?: {
      showDownload?: boolean
      isDownloading?: boolean
      downloadFunction?: () => void | Promise<void>
    }
    showDescription?: boolean
    customButton?: React.ReactNode
    hideHeader?: boolean
  }
  orderSettings?: {
    handleOrderToggle: (col) => void
    order?: OrderByApiProperty[]
  }
  showColumnSelection?: boolean
  tableId: string
  availableColumns: ListColumn<T>[]
  hoverColor: string
  isLoadingData: boolean
  data: BaseDatatableData<M> | undefined
  columns?: TableSizedColumns
  widthKey?: string
  headerName?: string
  customColumnRender?: (
    columnName: string,
    data: M,
    widthKey?: string | undefined
  ) => React.ReactNode | string
  setActiveItem?: (activeItem: BaseDatatableItem<M>) => void
  activeDetailId?: string | number
  setActiveDetailId: React.Dispatch<React.SetStateAction<string | number | undefined>>
}
export function DataTableBody<T, M>(props: DataTableBodyProps<T, M>) {
  const {
    tableId,
    widthKey,
    columns,
    customColumnRender,
    orderSettings,
    availableColumns,
    hoverColor,
    tableHeaderSettings,
    isLoadingData,
    data,
    setActiveItem,
    paginationSettings,
    showColumnSelection,
    headerName,
    activeDetailId,
    setActiveDetailId
  } = props

  const {download, showDescription, hideHeader, customButton} = tableHeaderSettings
  const {showDownload, isDownloading, downloadFunction} = download ?? {}
  const {t} = useTranslation()
  const [columnsToRender, setColumnsToRender] = useState<Array<string>>([])
  const [showDescriptions, setShowDescriptions] = useState<boolean>(false)

  const activateDetail = (detail: BaseDatatableItem<M>) => {
    if (setActiveItem) setActiveItem(detail)
    setActiveDetailId(detail.id)
  }
  const savePreferredColumns = (preferredColumns: ListColumn<T>[]) => {
    localStorage.setItem(tableId, JSON.stringify(preferredColumns))
  }

  const getPreferredColumns = (): ListColumn<T>[] => {
    const cachedString = localStorage.getItem(tableId)
    return cachedString ? JSON.parse(cachedString) : availableColumns
  }

  const [filteredColumns, setFilteredColumns] = useState<ListColumn<T>[]>(getPreferredColumns())

  const changeFilteredColumns = (newFilteredColumns: ListColumn<T>[]) => {
    savePreferredColumns(newFilteredColumns)
    setFilteredColumns(newFilteredColumns)
  }

  const tableColumns = useMemo(
    () => availableColumns.filter((c) => filteredColumns.findIndex((f) => f.id === c.id) > -1),
    [availableColumns, filteredColumns]
  )

  const downloadExcel = async () => {
    if (downloadFunction) await downloadFunction()
  }

  useEffect(() => {
    let newColumns: Array<string> = []
    const customWidthKey =
      activeDetailId && widthKey !== 'xs' && widthKey !== 'sm' ? 'md' : widthKey
    if (customWidthKey && widthKey) {
      let shouldInclude = true
      sizes.map((size) => {
        if (columns && shouldInclude && columns[size]) {
          newColumns = columns[size]
        }
        if (customWidthKey === size) shouldInclude = false
        return true
      })
    } else {
      availableColumns.map((c) => newColumns.push(c.id as unknown as string))
    }

    setColumnsToRender(
      showColumnSelection
        ? newColumns.filter(
            (v) => tableColumns.findIndex((f) => (f.id as unknown as string) === v) > -1
          )
        : newColumns
    )
  }, [
    widthKey,
    activeDetailId,
    columns,
    availableColumns,
    filteredColumns,
    showColumnSelection,
    tableColumns
  ])

  return (
    <Fragment key={'dataTable'}>
      {!hideHeader && (
        <DataTableHeader
          showDescriptions={showDescriptions}
          setShowDescriptions={setShowDescriptions}
          downloadExcel={downloadExcel}
          showDownload={showDownload}
          isDownloading={isDownloading}
          showDescription={showDescription}
          headerName={headerName}
          tableId={tableId}
          customButton={customButton}
        />
      )}
      <Table sx={{height: 'fit-content'}}>
        {isLoadingData && (
          <caption style={{textAlign: 'center'}}>
            <Loader />
          </caption>
        )}
        <DataTableHead
          handleOrderToggle={orderSettings?.handleOrderToggle}
          availableColumns={availableColumns}
          columnsToRender={columnsToRender}
          order={orderSettings?.order}
          filteredColumns={filteredColumns}
          setFilteredColumns={changeFilteredColumns}
          showColumnSelection={showColumnSelection}
        />
        <TableBody>
          {data?.data.map((r) => (
            <DataTableRow
              showColumnSelection={showColumnSelection}
              key={r.id}
              hoverColor={hoverColor}
              r={r}
              setActiveDetailId={activateDetail}
              columnsToRender={columnsToRender}
              showDescriptions={showDescriptions}
              activeDetailId={activeDetailId}
              customColumnRender={customColumnRender}
              widthKey={widthKey}
            />
          ))}
          {!isLoadingData && data && data.data.length === 0 && (
            <TableRow>
              <TableCell colSpan={columnsToRender.length} sx={{textAlign: 'center', p: 4}}>
                <Typography fontStyle="italic" fontWeight={400} color="text.secondary">
                  {t('errors.noData')}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {!isLoadingData && paginationSettings && (
          <TableFooter>
            <DataTableFooter
              page={paginationSettings.page}
              rowsNumber={paginationSettings.rowsNumber}
              handlePageChange={paginationSettings.handlePageChange}
              handleRowsPerPageChange={paginationSettings.handleRowsPerPageChange}
              colspan={columnsToRender.length}
              totalCount={data?.totalCount ?? 0}
            />
          </TableFooter>
        )}
      </Table>
    </Fragment>
  )
}
