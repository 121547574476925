import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import {Box, Checkbox, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {ListColumn} from '../../../types/common.types'

import {useStyles} from './ColumnSelection.styles'

type ColumnSelectionProps<T> = {
  columns: ListColumn<T>[]
  filteredColumns: ListColumn<T>[]
  setFilteredColumns: (newFilteredColumns: ListColumn<T>[]) => void
  loading: boolean
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
}
export const ColumnSelection = <T,>({
  filteredColumns,
  setFilteredColumns,
  columns
}: ColumnSelectionProps<T>) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleHideOrShowColumn = (filteredColumn: ListColumn<T>) => {
    setFilteredColumns(
      filteredColumns.map((column) => column.id).includes(filteredColumn.id)
        ? filteredColumns.filter((column) => column.id !== filteredColumn.id)
        : [...filteredColumns, filteredColumn]
    )
  }

  const handleColumns = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const allColumnsSelected = filteredColumns.length === columns.length

  const columnSelectMenu = (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem data-test-id={'column-menu-item-all'} style={{padding: '4px 16px'}}>
        <Checkbox
          disableRipple
          color="primary"
          checked={allColumnsSelected}
          onClick={() =>
            setFilteredColumns(
              allColumnsSelected ? columns.filter((c) => c.noColumnSelection === true) : columns
            )
          }
          icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
          checkedIcon={<span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />}
          indeterminate={!allColumnsSelected && filteredColumns.length > 0}
          classes={{indeterminate: classes.checkboxIndeterminateIcon}}
        />
        <Typography style={{fontWeight: 500, color: '#00274D'}}>All</Typography>
      </MenuItem>
      <Box style={{padding: '0 10px'}}>
        {columns
          .filter((column) => !column.noColumnSelection)
          .map((column, index) => {
            const mappedFilteredColumn = filteredColumns.map((column) => column.id)
            return (
              <MenuItem
                key={index}
                data-test-id={`column-menu-item-${column.translationKey}`}
                style={{padding: '4px 16px'}}
              >
                <Checkbox
                  disableRipple
                  color="primary"
                  checked={mappedFilteredColumn.includes(column.id)}
                  onClick={() => handleHideOrShowColumn(column)}
                  icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
                  checkedIcon={
                    <span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />
                  }
                  classes={{indeterminate: classes.checkboxIndeterminateIcon}}
                />
                <Typography
                  style={{fontWeight: 500, color: '#00274D'}}
                  onClick={() => handleHideOrShowColumn(column)}
                >
                  {t(column.translationKey)}
                </Typography>
              </MenuItem>
            )
          })}
      </Box>
    </Menu>
  )

  return (
    <Box sx={{position: 'absolute', top: 7, right: 5}}>
      <IconButton
        onClick={handleColumns}
        style={{
          height: 'fit-content',
          color: '#016AD4',
          boxShadow: 'none',
          border: 'none'
        }}
      >
        <ViewWeekOutlinedIcon />
      </IconButton>
      {columnSelectMenu}
    </Box>
  )
}
