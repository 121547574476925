import {AnalysisListType} from '@hconnect/common/types'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {OrderByApiProperty} from '../../../shared/hooks/listFiltersSort/useListOrderBy'
import {useAxios} from '../../../shared/hooks/useApi'
import {useUrlParam} from '../../../shared/hooks/useUrlParam'
import {BaseDatatableData} from '../../../shared/types/common.types'
import {ApiFilter} from '../../../shared/types/filter.types'
import {AnalysisListInfo} from '../../types/analysis.types'

export type PagePermissions = {
  canCreateAnalysis: boolean
}

export interface ResponseType extends BaseDatatableData<AnalysisListInfo> {
  currentPage: number
  data: Array<AnalysisListInfo>
  totalCount: number
  permissions: PagePermissions
}

export const QueryKey = 'analysis-list'

export const useGetAnalysisList = (
  page: number,
  rowsNumber: number,
  listType: AnalysisListType,
  filters?: ApiFilter[],
  order?: OrderByApiProperty[],
  options?: UseQueryOptions<ResponseType, AxiosError>
) => {
  const axiosInstance = useAxios()
  const plantId = useUrlParam('plantId')
  const apiFilters = filters ? [...filters] : []

  const stoppageTimeIndex = apiFilters?.findIndex((f) => f.property === 'stoppageTime')
  if (stoppageTimeIndex !== undefined && stoppageTimeIndex >= 0) {
    const stoppageTimeFilter = apiFilters[stoppageTimeIndex]
    apiFilters?.splice(stoppageTimeIndex, 1)
    apiFilters?.push({...stoppageTimeFilter, property: 'stoppageStart'})
    apiFilters?.push({...stoppageTimeFilter, property: 'stoppageEnd'})
  }

  return useQuery<ResponseType, AxiosError>({
    queryKey: [QueryKey, page, rowsNumber, listType, apiFilters, order, plantId],
    queryFn: async () => {
      const path = `/rootcausefailureanalysis/analyses/${listType}`
      return (
        await axiosInstance.post<ResponseType>(
          path,
          {
            pageSize: rowsNumber,
            pageNumber: page,
            filters: apiFilters,
            permissionForPlantId: plantId,
            plantId,
            orderBy: order
          },
          {}
        )
      ).data
    },

    // these default values will get moved to the api provider soon
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,

    // allow the callee to override the default options
    ...options
  })
}
