import {AnalysisListType} from '@hconnect/common/types'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as ContributingEmptyState} from '../../icons/contributing_empty_state.svg'
import {ReactComponent as MineEmptyState} from '../../icons/mine_empty_state.svg'

const TRANSLATION_IDS = {
  [AnalysisListType.Mine]: {
    lineOne: 'analysisList.mineEmpty.lineOne',
    lineTwo: 'analysisList.mineEmpty.lineTwo'
  },
  [AnalysisListType.Contributing]: {
    lineOne: 'analysisList.contributingEmpty.lineOne',
    lineTwo: 'analysisList.contributingEmpty.lineTwo'
  },
  [AnalysisListType.Global]: {
    lineOne: 'analysisList.globalEmpty.lineOne',
    lineTwo: 'analysisList.globalEmpty.lineTwo'
  },
  [AnalysisListType.MyPlant]: {
    lineOne: 'analysisList.globalEmpty.lineOne',
    lineTwo: 'analysisList.contributingEmpty.lineTwo'
  },
  filteredEmpty: {
    lineOne: 'analysisList.filteredEmpty.lineOne',
    lineTwo: 'analysisList.filteredEmpty.lineTwo'
  }
}

export const AnalysisListNoData: React.FC<{
  listType: AnalysisListType
  filtersActive: boolean
}> = ({listType, filtersActive}) => {
  const {t} = useTranslation()
  const key = filtersActive ? 'filteredEmpty' : listType

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', margin: '32px'}}>
      <Box sx={{textAlign: 'center'}}>
        {listType === AnalysisListType.Mine ? <MineEmptyState /> : <ContributingEmptyState />}
        <Typography sx={{color: '#54708C', marginTop: '32px'}}>
          {t(TRANSLATION_IDS[key].lineOne)}
        </Typography>
        <Typography sx={{color: '#54708C'}}>{t(TRANSLATION_IDS[key].lineTwo)}</Typography>
      </Box>
    </Box>
  )
}
